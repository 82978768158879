import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import CONFIG from "../../util/config";
import {CisUI} from "../../util/CISUI";
import axios from "../../util/Api";

const UserProfile = () => {
    const dispatch = useDispatch();
    const [accountCodeText, setaccountCodeText] = useState("Hide Account Code?");
    const userLocal = localStorage.getItem('userData') || "";

    // let userID = 0;
    let userName = "";
    let companyName = "";
    if (userLocal !== "") {
        const user = JSON.parse(userLocal);
        // userID = user['id'];
        userName = user['name'];
        companyName = user['companyName'] || "";
    }

    // const history = useHistory();

    // let userData = useSelector((state) => state.auth.profileData);


    useEffect(() => {
        if (userLocal !== "") {
           // dispatch(getUserProfileData(userID));
            const act_code_show = CisUI().getUserInfo('act_code_show');
            if(act_code_show === 1) {
                setaccountCodeText("Hide Account Code?");
            }
            else {
                setaccountCodeText("Show Account Code?");
            }
        }
    }, [userLocal]);
    // console.log(userData)

    const setVerticalLayout = () => {
        console.log("Clicked on Window reload");
        localStorage.setItem("layout", "vertical");
        window.location.reload();
    }

    const setAccountCodeShowHide = () => {
        axios.get(CONFIG.apiserver+"accountcodeset")
            .then((res) => {
                console.log(res.data);
                if(res.data.status === 1) {
                    localStorage.setItem("userData",JSON.stringify(res.data.userdata));
                    const act_code_show = res.data.userdata.act_code_show;
                    if(act_code_show === 1) {
                        setaccountCodeText("Hide Account Code?");
                    }
                    else {
                        setaccountCodeText("Show Account Code?");
                    }
                    window.location.reload();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    let ProfilePicURL = "https://via.placeholder.com/150";
    const image = CisUI().getUserInfo('image');
    if(image === "" || image === null || image === undefined) {} else {
        ProfilePicURL = CONFIG.profile_pic_url+""+image;
        console.log(ProfilePicURL);
    }


    const userMenuOptions = (
        <ul className="gx-user-popover">
            <li>
                <Link to={`/profile/myaccount`}>My Account</Link>
            </li>
            <li>
                <Link to={`/profile/changepassword`}>Change Password</Link>
            </li>
            <li onClick={setVerticalLayout}>Vertical Layout</li>
            <li onClick={setAccountCodeShowHide}>{accountCodeText}</li>
            <li onClick={() => dispatch(userSignOut())}>Logout
            </li>
        </ul>
    );

    // const userInfo = localStorage.getItem("userData");
    // const user = JSON.parse(userInfo);

    return (

        <>
            <div className="gx-flex-row gx-align-items-center gx-avatar-row">
                <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
                    {/*<Avatar src={Config.profile_pic_url + userData.image}*/}
                    <Avatar src={ProfilePicURL}
                            className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
                    <span className="gx-avatar-name" style={{color: "#000"}}>
                        <span>{userName}</span>
                        <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/>
                    </span>
                </Popover>
            </div>
            <div className="gx-font-weight-light" style={{ color: "#545454", marginTop: '-10px', paddingLeft : '58px', fontSize: '14px'}}>{companyName}</div>
        </>

    )
};

export default UserProfile;
