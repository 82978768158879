import React from "react";
import {Route, Switch} from "react-router-dom";

import MemberList from "../../containers/yotta/donation/member/list";
import NewMember from "../../containers/yotta/donation/member/new";
import NewCollection from "../../containers/yotta/donation/collection/new";
import CollectionEdit from "../../containers/yotta/donation/collection/collectionedit";
import CollectionList from "../../containers/yotta/donation/collection/list";
import MemberStatement from "../../containers/yotta/donation/collection/memstatement";
import Contributionbyroles from "../../containers/yotta/donation/collection/contributionbyroles";

import NewLocalExpense from "../../containers/yotta/donation/local/expenses";
import LocalExpenses from "../../containers/yotta/donation/local/expenselist";
import WeeklyReport from "../../containers/yotta/donation/local/weeklyreport";
import ContributionReport from "../../containers/yotta/donation/local/lccontribution";
import MonthlyReport from "../../containers/yotta/donation/local/monthlyreport";
import LocalSummaryReport from "../../containers/yotta/donation/local/localsummary";
import LocalAnalyticsReport from "../../containers/yotta/donation/local/analyticschart";

import RegionExpenses from "../../containers/yotta/donation/region/expenselist";
import NewRegionExpenses from "../../containers/yotta/donation/region/expenses";
import RegionContribution from "../../containers/yotta/donation/region/contributionReport";
import RegionSummaryReport from "../../containers/yotta/donation/region/regionsummaryReport";
import RegionMonthlyReport from "../../containers/yotta/donation/region/regionMonthlyReport";
import RegionAnalyticsDashboard from "../../containers/yotta/donation/region/regiondashboard";


import DistrictExpenses from "../../containers/yotta/donation/district/expenselist";
import NewDistrictExpenses from "../../containers/yotta/donation/district/expenses";
import DistrictContribution from "../../containers/yotta/donation/district/districtcontribution";
import DistrictSummaryReport from "../../containers/yotta/donation/district/districtsummaryreport";
import DistrictMonthlyReport from "../../containers/yotta/donation/district/monthlyreport";
import DistrictMonthlyReportS from "../../containers/yotta/donation/district/districtmonthlyreport";
import DistrictDashboard from "../../containers/yotta/donation/district/districtdashboard";

import NationalContribution from "../../containers/yotta/donation/nation/nationcontributionreport";
import NationExpenseReport from "../../containers/yotta/donation/nation/nationexpenseReport";
import NationGrossReport from "../../containers/yotta/donation/nation/nationgrossreport";
import MinistryCollection from "../../containers/yotta/donation/ministry/new";
import MinistryCollectionList from "../../containers/yotta/donation/ministry/list";
import NewOthersCollection from "../../containers/yotta/donation/collection/othersnewcollection";
import Expenseedit_old from "../../containers/yotta/donation/collection/expenseedit";
import TithlyCollectionHistory from "../../containers/yotta/donation/collection/tithlycollection";
import SubmittedReport from "../../containers/yotta/donation/submittedreport/submittedreport";
import NewOthersMinistryCollection from "../../containers/yotta/donation/ministry/otherministryincomeadd";
import CashAppReprot from "../../containers/yotta/donation/customreport/cashappreport";
import ExpenseDetails from "../../containers/yotta/donation/local/expensedetails";
import RegionReport from "../../containers/yotta/donation/region/regionreport";
import MultipleCollection from "../../containers/yotta/donation/collection/multiplecollection";
import PendingCollectionHistory from "../../containers/yotta/donation/collection/pendingcollection";
import DepositeedCollectionHistory from "../../containers/yotta/donation/collection/depositedcollections";
import NewFundRaiseInvoice from "../../containers/yotta/donation/fundraise/newinvoice";
import FundRaiseInvoiceList from "../../containers/yotta/donation/fundraise/invoicelist";
import FledgeInvoiceDetails from "../../containers/yotta/donation/fundraise/invoicedetails";
import NewBuildingFundInvoice from "../../containers/yotta/donation/fundraise/buildingfund/newinvoice";
import FundRaisInvoiceDetails from "../../containers/yotta/donation/fundraise/invoicedetails";
import FundInvoicePayment from "../../containers/yotta/donation/fundraise/payment";
import FundInvoiceEdit from "../../containers/yotta/donation/fundraise/editinvoice";
import Pledgereceivable from "../../containers/yotta/donation/fundraise/pledgereceivable";


const Donation = ({match}) => (
    <Switch>
        <Route path={`${match.url}/contbbyroles`} component={Contributionbyroles}/>
        <Route path={`${match.url}/memstatement`} component={MemberStatement}/>
        <Route path={`${match.url}/memberlist`} component={MemberList}/>
        <Route path={`${match.url}/newmember`} component={NewMember}/>
        <Route path={`${match.url}/memberedit/:id`} component={NewMember}/>
        {/*For Tithe Collection*/}
        <Route path={`${match.url}/newcollection`} component={NewCollection}/>
        <Route path={`${match.url}/pendingcollection`} component={PendingCollectionHistory}/>
        <Route path={`${match.url}/multiplecollection`} component={MultipleCollection}/>
        <Route path={`${match.url}/otherscontribution`} component={NewOthersCollection}/>
        <Route path={`${match.url}/collections`} component={CollectionList}/>
        <Route path={`${match.url}/depositedcollection`} component={DepositeedCollectionHistory}/>
        <Route path={`${match.url}/collectionedit/:id`} component={CollectionEdit}/>
        <Route path={`${match.url}/lcnewexpense`} component={NewLocalExpense}/>
        <Route path={`${match.url}/lcexpreport`} component={LocalExpenses}/>
        <Route path={`${match.url}/lcweeklyrprt`} component={WeeklyReport}/>
        <Route path={`${match.url}/lccontribution`} component={ContributionReport}/>
        <Route path={`${match.url}/lcmontcolose`} component={MonthlyReport}/>
        <Route path={`${match.url}/lcsummaryrprt`} component={LocalSummaryReport}/>
        <Route path={`${match.url}/lcrpchart`} component={LocalAnalyticsReport}/>

        <Route path={`${match.url}/rgexpense`} component={NewRegionExpenses}/>
        <Route path={`${match.url}/rgexpreport`} component={RegionExpenses}/>
        <Route path={`${match.url}/rgcontribution`} component={RegionContribution}/>
        <Route path={`${match.url}/rgeasterconv`} component={NewCollection}/>
        <Route path={`${match.url}/rgsummaryrprt`} component={RegionSummaryReport}/>
        <Route path={`${match.url}/rgmontcolose`} component={RegionMonthlyReport}/>
        <Route path={`${match.url}/rgrpchart`} component={RegionAnalyticsDashboard}/>
        <Route path={`${match.url}/regionreport`} component={RegionReport}/>

        <Route path={`${match.url}/dstnewexp`} component={NewDistrictExpenses}/>
        <Route path={`${match.url}/dstexpreport`} component={DistrictExpenses}/>
        <Route path={`${match.url}/dstcontribution`} component={DistrictContribution}/>
        <Route path={`${match.url}/dstsummaryrprt`} component={DistrictSummaryReport}/>
        <Route path={`${match.url}/dstmontcolose`} component={DistrictMonthlyReport}/>
        <Route path={`${match.url}/dstrprt`} component={DistrictDashboard}/>
        <Route path={`${match.url}/dstaddcontribution`} component={NewCollection}/>
        <Route path={`${match.url}/districtreport`} component={DistrictMonthlyReportS}/>


        <Route path={`${match.url}/ntcontribution`} component={NationalContribution}/>
        <Route path={`${match.url}/ntexpreport`} component={NationExpenseReport}/>
        <Route path={`${match.url}/ntgrossanalysis`} component={NationGrossReport}/>

        <Route path={`${match.url}/mnewcollection`} component={MinistryCollection}/>
        <Route path={`${match.url}/ministryincome`} component={MinistryCollectionList}/>
        <Route path={`${match.url}/lcexpneseedit/:id`} component={Expenseedit_old}/>
        <Route path={`${match.url}/tithlycollection`} component={TithlyCollectionHistory}/>
        <Route path={`${match.url}/submittedreport`} component={SubmittedReport}/>
        <Route path={`${match.url}/ministryincomeother`} component={NewOthersMinistryCollection}/>
        <Route path={`${match.url}/cashappreport`} component={CashAppReprot}/>
        <Route path={`${match.url}/lcexpneseopenbymonth/:id`} component={ExpenseDetails}/>

        <Route path={`${match.url}/newfundraise`} component={NewFundRaiseInvoice}/>
        <Route path={`${match.url}/buildingfund`} component={NewBuildingFundInvoice}/>
        <Route path={`${match.url}/fundraisinghistory`} component={FundRaiseInvoiceList}/>
        <Route path={`${match.url}/fundraisedetails/:id`} component={FundRaisInvoiceDetails}/>
        <Route path={`${match.url}/fundpayment/:id`} component={FundInvoicePayment}/>
        <Route path={`${match.url}/fundraisedit/:id`} component={FundInvoiceEdit}/>
        <Route path={`${match.url}/pledgereceivable`} component={Pledgereceivable}/>



    </Switch>
);

export default Donation;
