import React, {useState, useEffect} from "react";
import {Form, Col, Input, Collapse, Card, notification} from 'antd';
import jsonfile from './balancesheet.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import moment from "moment";

const Search = Input.Search;
const Panel = Collapse.Panel;

const StatementOfFinancialPositions = () => {
    const value = 0;
    const [state, setState] = useState({
        reqURL: Config.apiserver + jsonfile.urls.liststmnt,
        filtervalue: '',
        subtitle: '',
    });

    const [header, setHeader] = useState("");
    const [period, setPeriod] = useState("For the end of " + CisUI().getMonthTitle(moment().format("YYYY-12-31")));
    const [reportyear, setReportYear] = useState({
        currentYear: "as of " + CisUI().getMonthTitle(moment().format("YYYY-12-31")),
        lastYear: ""
    });

    const [resp, setResp] = useState({
        status: 0,
        msg: "Statement of Financial Position",
        company: {},
        data: []
    });

    const [hide, setHide] = useState([]);
    const [reporttype, setReportType] = useState("periodical");
    const [company, setCompany] = useState({});

    const ref = React.createRef();
    const formRef = React.createRef();

    const [data, setData] = useState([]);

    useEffect(() => {
        if (CisUI().getUserInfo('com_id') > 0) {
            getLedgerData();
        }
    }, []);


    const getLedgerData = (url = "") => {
        document.getElementById("loader").style.display = "block";
        let urls = state.reqURL;
        if (url !== "") {
            urls = url;
        }
        axios.get(urls)
            .then(res => {
                //console.log(res)
                if (res.data.status === 1) {
                    setResp(res.data);
                    //setData(res.data.data);
                    //console.log(res.data.data.assets[0].currentAsset);
                    //console.log(res.data.data[0]["assets"]["currentAsset"]);
                    setCompany(res.data.company);
                    setHeader(res.data.company.legal_name);

                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                document.getElementById("loader").style.display = "none";
            });
    }

    const history = useHistory();


    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if (list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name}/>
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        let wkd = "";
        let wk = "";
        let dt = [];
        console.log(values);
        const reporttype = values["reporttype"];
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    // wk = formRef.current.getFieldValue('date');
                    const dtv = values[key] || moment().format("YYYY-01-01") + "," + moment().format("YYYY-12-31");
                    const dtvc = values[key] || "";
                    if (dtvc === "") {
                        dt.push(moment().format("YYYY-01-01"));
                        dt.push(moment().format("YYYY-12-31"));
                    } else {
                        dt = CisUI().ParseDateFormat(dtv);
                    }
                    //console.log("Dates : "+dt[1]);
                    return `${key}=${dt}`
                } else if (key === 'month') {
                    wk = formRef.current.getFieldValue('month');
                    // console.log("Selected Week : "+ wk);
                    wkd = CisUI().getMonthTitle(formRef.current.getFieldValue('month'));
                    return `${key}=${wkd[1]}`
                } else if (key === 'reporttype') {
                    setReportType(values['reporttype'] || "periodical")
                    return `${key}=${values[key]}`
                } else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        if (values['reporttype'] === 'comparative') {
            setPeriod("Comparative Report For the End of " + CisUI().getMonthTitle(dt[1]) + " and " + CisUI().getMonthTitle(moment(dt[1], "YYYY-MM-DD").subtract(1, 'year')));
            setReportYear({
                currentYear: "as of " + CisUI().getMonthTitle(dt[1]),
                lastYear: "as of " + CisUI().getMonthTitle(moment(dt[1], "YYYY-MM-DD").subtract(1, 'year'))
            });
        } else {
            setPeriod("For the End of " + CisUI().getMonthTitle(dt[1]));
            setReportYear({
                currentYear: "as of " + CisUI().getMonthTitle(dt[1]),
                lastYear: ""
            });
        }

        // console.log("ReportType: "+reporttype);

        const qsup = qs.replaceAll("undefined", "");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup);
        const newURL = Config.apiserver + jsonfile.urls.liststmnt + "?" + qsup;
        setState({reqURL: newURL});

        getLedgerData(newURL);
    };

    const handleOnFilter = ({target: {name, value}}) => {
        //filterValue = value;
        // console.log("filteringwith :"+ value);
        setState({filtervalue: "test"});
    }

    let searchView = "";
    if (jsonfile.has_search) {
        searchView = <Card className="advance-search-card no-print">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']}>
                <Panel header="Advance Search" key="1">
                    <Form
                        form={form}
                        {...CisUI().formItemLayout}
                        ref={formRef}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.search, "", 1,"",CisUI().PeriodFiled(form))}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const print = () => {
        window.open(Config.baseurl + "reports/stmntoffinpositionprint.html?url=" + window.btoa(state.reqURL) + "&filename=statement_of_financial_position&data=stmntoffinpositionData&subtitle=" + period + "&rptype=stmntoffinposition", "_blank");
    }

    const listAction = () =>
        (
            <div className="no-print">
                <a id="rploader" style={{width: '40px', height: '40px', display: 'none'}}>
                    <img src="/assets/images/loader.svg" style={{width: '40px', height: '40px'}} alt="loader"/> Please
                    wait ...
                </a>
                {/*<button className="btn btn-export-excel btn-circle-md" onClick={(e) => getAPIData()} title="Export to Excel"><i*/}
                {/*    className="fas fa-file-excel"/></button>*/}
                {/*<button className="btn btn-export-pdf btn-circle-md" onClick={exportToPDF} title="Export to PDF"><i*/}
                {/*    className="fas fa-file-pdf"/></button>*/}
                {/*<button className="btn btn-export-word btn-circle-md" onClick={exportToDocs} title="Export MS Word"><i*/}
                {/*    className="fas fa-file-word"/></button>*/}
                <button onClick={print} className="btn btn-export-print btn-circle-md" title="Print"><i
                    className="fas fa-print"/></button>
            </div>
        );

    const getExtra = (
        <>
            {listAction()}
        </>
    );


    const setCollapse = (id) => {
        console.log("Slcct: ", id);
        if (hide.indexOf(id) > -1) {
            var index = hide.indexOf(id); // Let's say it's Bob.
            hide.splice(index, 1);
            document.getElementById("icon_" + id).setAttribute("class", "fas fa-chevron-right")
            var row = document.getElementsByClassName("disp_" + id);
            var thisYear = document.getElementsByClassName("this_" + id);
            var lastYear = document.getElementsByClassName("lasty_" + id);

            var i;
            for (i = 0; i < row.length; i++) {
                //row[i].style.display = 'inherit';
                //row[i].removeAttribute('style');
                row[i].style.display = 'none';
            }

            for (i = 0; i < thisYear.length; i++) {
                thisYear[i].removeAttribute('style');
                if(reporttype === 'comparative') {
                    lastYear[i].removeAttribute('style');
                }
            }
        } else {
            const hd = hide;
            hd.push(id);
            setHide(hd);
            document.getElementById("icon_" + id).setAttribute("class", "fas fa-chevron-down");
            var row = document.getElementsByClassName("disp_" + id);
            var thisYear = document.getElementsByClassName("this_" + id);
            var lastYear = document.getElementsByClassName("lasty_" + id);
            var i;
            for (i = 0; i < row.length; i++) {
                //row[i].style.display = 'none';
                row[i].removeAttribute('style');
            }

            for (i = 0; i < thisYear.length; i++) {
                //row[i].style.display = 'none';
                thisYear[i].style.display = "none";
                if(reporttype === 'comparative') {
                    lastYear[i].style.display = "none";
                }
            }
        }
        console.log("hides : ", hide);
    }

    let totalDebit = 0;
    let totalCredit = 0;

    return (
        <>
            {searchView}
            <Card extra={getExtra}>
                {CisUI().showLoading}
                {resp.status === 1 ?
                    <div>
                        <div className="table-responsive">
                            <div style={{textAlign: 'center'}}>
                                <h2>{header}</h2>
                                <h4>Statement of Financial Positions</h4>
                                <h4>{period}</h4>
                            </div>

                            <table className="statement table mx-auto w-auto">
                                <thead>
                                <tr>
                                    <th style={{width: '400px'}}>Particulars</th>
                                    <th style={{textAlign: 'right', width: '130px'}}>{reportyear.currentYear}</th>
                                    {reporttype === 'comparative' ?
                                        <th style={{textAlign: 'right', width: '130px'}}>{reportyear.lastYear}</th>
                                        : ""}
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td colSpan={3} style={{fontWeight: 'bold'}}>ASSETS</td>
                                </tr>
                                <tr>
                                    <td colSpan={3} style={{fontWeight: 'bold'}}>
                                        <div className="subItemText">Current Asset</div>
                                    </td>
                                </tr>
                                {
                                    resp.data[0]["assets"]["currentAsset"].map((itmes, index) => {
                                        var row = [];
                                        row.push(<tr>
                                            <td style={{fontWeight: 'normal'}}>
                                                <div className="subItemText2">
                                                    {itmes["childTree"].length > 0 ?
                                                        <span style={{marginLeft: '-10px'}}><i id={"icon_" + itmes.id}
                                                                                               onClick={() => setCollapse(itmes.id)}
                                                                                               className="fas fa-chevron-right"/> </span>
                                                        : <></>}
                                                    {itmes.name}
                                                </div>
                                            </td>
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'normal'
                                            }}><span className={"this_" + itmes.id} >{CisUI().getCurrencyFormated1(itmes.thisYear)}</span></td>
                                            {reporttype === 'comparative' ?
                                                <td style={{
                                                    textAlign: 'right',
                                                    fontWeight: 'normal'
                                                }}><span className={"lasty_" + itmes.id} >{CisUI().getCurrencyFormated1(itmes.lastYear)}</span></td>
                                                : ""}
                                        </tr>)
                                        itmes["childTree"].map((itm, indx) => {
                                            if(itm.row === 'total')
                                            {
                                                row.push(<tr className={"disp_" + itmes.id} style={{display: "none"}}>
                                                    <td style={{fontWeight: 'normal'}}>
                                                        <div className="subItemTextTotal">{itm.name}</div>
                                                    </td>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'bold'
                                                    }}>{CisUI().getCurrencyFormated1(itm.thisYear)}</td>
                                                    {reporttype === 'comparative' ? <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'bold'
                                                    }}>{CisUI().getCurrencyFormated1(itm.lastYear)}</td> : ""}
                                                </tr>)
                                            }
                                            else {
                                                row.push(<tr className={"disp_" + itmes.id} style={{display: "none"}}>
                                                    <td style={{fontWeight: 'normal'}}>
                                                        <div className="subItemText3">{itm.name}</div>
                                                    </td>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'normal'
                                                    }}>{CisUI().getCurrencyFormated1(itm.thisYear)}</td>
                                                    {reporttype === 'comparative' ? <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'normal'
                                                    }}>{CisUI().getCurrencyFormated1(itm.lastYear)}</td> : ""}
                                                </tr>)
                                            }
                                        })
                                        return row;
                                    })
                                }
                                <tr className="showTotalBorder">
                                    <td style={{fontWeight: 'bold'}}>
                                        <div className="subItemText3">Total Current Assets</div>
                                    </td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.data[0]["assets"]["totalCurAssetY1"])}</td>
                                    {reporttype === 'comparative' ? <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.data[0]["assets"]["totalCurAssetY2"])}</td> : ""}
                                </tr>
                                <tr>
                                    <td colSpan={3} style={{fontWeight: 'bold'}}>
                                        <div className="subItemText">Non-Current Asset</div>
                                    </td>
                                </tr>
                                {
                                    resp.data[0]["assets"]["othersAsset"].map((itmes, index) => {
                                        var row = [];
                                        row.push(<tr>
                                            <td style={{fontWeight: 'normal'}}>
                                                <div className="subItemText2">
                                                    {itmes["childTree"].length > 0 ?
                                                        <span style={{marginLeft: '-10px'}}><i id={"icon_" + itmes.id}
                                                                                               onClick={() => setCollapse(itmes.id)}
                                                                                               className="fas fa-chevron-right"/> </span>
                                                        : <></>}
                                                    {itmes.name}
                                                </div>
                                            </td>
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'normal'
                                            }}><span className={"this_" + itmes.id} >{CisUI().getCurrencyFormated1(itmes.thisYear)}</span></td>
                                            {reporttype === 'comparative' ? <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'normal'
                                            }}><span className={"lasty_" + itmes.id} >{CisUI().getCurrencyFormated1(itmes.lastYear)}</span></td> : ""}
                                        </tr>)
                                        itmes["childTree"].map((itm, indx) => {
                                            if(itm.row === 'total')
                                            {
                                                row.push(<tr className={"disp_" + itmes.id} style={{display: "none"}}>
                                                    <td style={{fontWeight: 'normal'}}>
                                                        <div className="subItemTextTotal">{itm.name}</div>
                                                    </td>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'bold'
                                                    }}>{CisUI().getCurrencyFormated1(itm.thisYear)}</td>
                                                    {reporttype === 'comparative' ? <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'bold'
                                                    }}>{CisUI().getCurrencyFormated1(itm.lastYear)}</td> : ""}
                                                </tr>)
                                            }
                                            else {
                                                row.push(<tr className={"disp_" + itmes.id} style={{display: "none"}}>
                                                    <td style={{fontWeight: 'normal'}}>
                                                        <div className="subItemText3">{itm.name}</div>
                                                    </td>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'normal'
                                                    }}>{CisUI().getCurrencyFormated1(itm.thisYear)}</td>
                                                    {reporttype === 'comparative' ? <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'normal'
                                                    }}>{CisUI().getCurrencyFormated1(itm.lastYear)}</td> : ""}
                                                </tr>)
                                            }
                                        })
                                        return row;
                                    })
                                }
                                <tr className="showTotalBorder">
                                    <td style={{fontWeight: 'bold'}}>
                                        <div className="subItemText3">Total Non-Current Assets</div>
                                    </td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.data[0]["assets"]["totalOtrAssetY1"])}</td>
                                    {reporttype === 'comparative' ? <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.data[0]["assets"]["totalOtrAssetY2"])}</td> : ""}
                                </tr>

                                <tr>
                                    <td colSpan={3} style={{fontWeight: 'bold'}}>
                                        <div className="subItemText">Fixed Assets</div>
                                    </td>
                                </tr>
                                {
                                    resp.data[0]["assets"]["fixedAssets"].map((itmes, index) => {
                                        var row = [];
                                        row.push(<tr>
                                            <td style={{fontWeight: 'normal'}}>
                                                <div className="subItemText2">
                                                    {itmes["childTree"].length > 0 ?
                                                        <span style={{marginLeft: '-10px'}}><i id={"icon_" + itmes.id}
                                                                                               onClick={() => setCollapse(itmes.id)}
                                                                                               className="fas fa-chevron-right"/> </span>
                                                        : <></>}
                                                    {itmes.name}
                                                </div>
                                            </td>
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'normal'
                                            }}><span className={"this_" + itmes.id} >{CisUI().getCurrencyFormated1(itmes.thisYear)}</span></td>
                                            {reporttype === 'comparative' ? <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'normal'
                                            }}><span className={"lasty_" + itmes.id} >{CisUI().getCurrencyFormated1(itmes.lastYear)}</span></td> : ""}
                                        </tr>)
                                        itmes["childTree"].map((itm, indx) => {
                                            if(itm.row === 'total')
                                            {
                                                row.push(<tr className={"disp_" + itmes.id} style={{display: "none"}}>
                                                    <td style={{fontWeight: 'normal'}}>
                                                        <div className="subItemTextTotal">{itm.name}</div>
                                                    </td>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'bold'
                                                    }}>{CisUI().getCurrencyFormated1(itm.thisYear)}</td>
                                                    {reporttype === 'comparative' ? <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'bold'
                                                    }}>{CisUI().getCurrencyFormated1(itm.lastYear)}</td> : ""}
                                                </tr>)
                                            }
                                            else {
                                                row.push(<tr className={"disp_" + itmes.id} style={{display: "none"}}>
                                                    <td style={{fontWeight: 'normal'}}>
                                                        <div className="subItemText3">{itm.name}</div>
                                                    </td>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'normal'
                                                    }}>{CisUI().getCurrencyFormated1(itm.thisYear)}</td>
                                                    {reporttype === 'comparative' ? <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'normal'
                                                    }}>{CisUI().getCurrencyFormated1(itm.lastYear)}</td> : ""}
                                                </tr>)
                                            }
                                        })
                                        return row;
                                    })
                                }

                                <tr className="showTotalBorder">
                                    <td style={{fontWeight: 'bold'}}>
                                        <div className="subItemText3">Total Fixed Assets</div>
                                    </td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.data[0]["assets"]["totalFixAssetY1"])}</td>
                                    {reporttype === 'comparative' ? <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.data[0]["assets"]["totalFixAssetY2"])}</td> : ""}
                                </tr>

                                <tr>
                                    <td colSpan={3} style={{fontWeight: 'bold'}}>
                                        <div className="subItemText">Long Term Assets</div>
                                    </td>
                                </tr>
                                {
                                    resp.data[0]["assets"]["longAssets"].map((itmes, index) => {
                                        var row = [];
                                        row.push(<tr>
                                            <td style={{fontWeight: 'normal'}}>
                                                <div className="subItemText2">
                                                    {itmes["childTree"].length > 0 ?
                                                        <span style={{marginLeft: '-10px'}}><i id={"icon_" + itmes.id}
                                                                                               onClick={() => setCollapse(itmes.id)}
                                                                                               className="fas fa-chevron-right"/> </span>
                                                        : <></>}
                                                    {itmes.name}
                                                </div>
                                            </td>
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'normal'
                                            }}><span className={"this_" + itmes.id} >{CisUI().getCurrencyFormated1(itmes.thisYear)}</span></td>
                                            {reporttype === 'comparative' ? <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'normal'
                                            }}><span className={"lasty_" + itmes.id} >{CisUI().getCurrencyFormated1(itmes.lastYear)}</span></td> : ""}
                                        </tr>)
                                        itmes["childTree"].map((itm, indx) => {
                                            if(itm.row === 'total')
                                            {
                                                row.push(<tr className={"disp_" + itmes.id} style={{display: "none"}}>
                                                    <td style={{fontWeight: 'normal'}}>
                                                        <div className="subItemTextTotal">{itm.name}</div>
                                                    </td>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'bold'
                                                    }}>{CisUI().getCurrencyFormated1(itm.thisYear)}</td>
                                                    {reporttype === 'comparative' ? <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'bold'
                                                    }}>{CisUI().getCurrencyFormated1(itm.lastYear)}</td> : ""}
                                                </tr>)
                                            }
                                            else {
                                                row.push(<tr className={"disp_" + itmes.id} style={{display: "none"}}>
                                                    <td style={{fontWeight: 'normal'}}>
                                                        <div className="subItemText3">{itm.name}</div>
                                                    </td>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'normal'
                                                    }}>{CisUI().getCurrencyFormated1(itm.thisYear)}</td>
                                                    {reporttype === 'comparative' ? <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'normal'
                                                    }}>{CisUI().getCurrencyFormated1(itm.lastYear)}</td> : ""}
                                                </tr>)
                                            }
                                        })
                                        return row;
                                    })
                                }
                                <tr className="showTotalBorder">
                                    <td style={{fontWeight: 'bold'}}>
                                        <div className="subItemText3">Total Long Term Assets</div>
                                    </td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.data[0]["assets"]["totalLongAssetY1"])}</td>
                                    {reporttype === 'comparative' ? <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.data[0]["assets"]["totalLongAssetY2"])}</td> : ""}
                                </tr>

                                <tr className="showTotalBorderDouble">
                                    <td className="clmintent" style={{fontWeight: 'bold'}}>Total ASSETS</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.data[0]["assets"]["totalAssetY1"])}</td>
                                    {reporttype === 'comparative' ? <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.data[0]["assets"]["totalAssetY2"])}</td> : ""}
                                </tr>

                                <tr>
                                    <td colSpan={3} style={{fontWeight: 'bold'}}></td>
                                </tr>

                                <tr>
                                    <td colSpan={3} style={{fontWeight: 'bold'}}>
                                        <div style={{paddingTop: '20px'}}>LIABILITIES AND NET ASSETS</div>
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan={3} style={{fontWeight: 'bold'}}>
                                        <div className="subItemText">Current Liabilities</div>
                                    </td>
                                </tr>
                                {
                                    resp.data[0]["liability"]["currentLiability"].map((itmes, index) => {
                                        var row = [];
                                        row.push(<tr>
                                            <td style={{fontWeight: 'normal'}}>
                                                <div className="subItemText2">
                                                    {itmes["childTree"].length > 0 ?
                                                        <span style={{marginLeft: '-10px'}}><i id={"icon_" + itmes.id}
                                                                                               onClick={() => setCollapse(itmes.id)}
                                                                                               className="fas fa-chevron-right"/> </span>
                                                        : <></>}
                                                    {itmes.name}
                                                </div>
                                            </td>
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'normal'
                                            }}><span className={"this_" + itmes.id} >{CisUI().getCurrencyFormated1(itmes.thisYear)}</span></td>
                                            {reporttype === 'comparative' ? <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'normal'
                                            }}><span className={"lasty_" + itmes.id} >{CisUI().getCurrencyFormated1(itmes.lastYear)}</span></td> : ""}
                                        </tr>)
                                        itmes["childTree"].map((itm, indx) => {
                                            if(itm.row === 'total')
                                            {
                                                row.push(<tr className={"disp_" + itmes.id} style={{display: "none"}}>
                                                    <td style={{fontWeight: 'normal'}}>
                                                        <div className="subItemTextTotal">{itm.name}</div>
                                                    </td>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'bold'
                                                    }}>{CisUI().getCurrencyFormated1(itm.thisYear)}</td>
                                                    {reporttype === 'comparative' ? <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'bold'
                                                    }}>{CisUI().getCurrencyFormated1(itm.lastYear)}</td> : ""}
                                                </tr>)
                                            }
                                            else {
                                                row.push(<tr className={"disp_" + itmes.id} style={{display: "none"}}>
                                                    <td style={{fontWeight: 'normal'}}>
                                                        <div className="subItemText3">{itm.name}</div>
                                                    </td>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'normal'
                                                    }}>{CisUI().getCurrencyFormated1(itm.thisYear)}</td>
                                                    {reporttype === 'comparative' ? <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'normal'
                                                    }}>{CisUI().getCurrencyFormated1(itm.lastYear)}</td> : ""}
                                                </tr>)
                                            }
                                        })
                                        return row;
                                    })
                                }
                                <tr className="showTotalBorder">
                                    <td style={{fontWeight: 'bold'}}>
                                        <div className="subItemText3">Total Current Liabilities</div>
                                    </td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.data[0]["liability"]["totalCurLiabilityY1"])}</td>
                                    {reporttype === 'comparative' ? <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.data[0]["liability"]["totalCurLiabilityY2"])}</td> : ""}
                                </tr>

                                <tr>
                                    <td colSpan={3} style={{fontWeight: 'bold'}}>
                                        <div className="subItemText">Non-Current Liabilities</div>
                                    </td>
                                </tr>
                                {
                                    resp.data[0]["liability"]["othersLiablity"].map((itmes, index) => {
                                        var row = [];
                                        row.push(<tr>
                                            <td style={{fontWeight: 'normal'}}>
                                                <div className="subItemText2">
                                                    {itmes["childTree"].length > 0 ?
                                                        <span style={{marginLeft: '-10px'}}><i id={"icon_" + itmes.id}
                                                                                               onClick={() => setCollapse(itmes.id)}
                                                                                               className="fas fa-chevron-right"/> </span>
                                                        : <></>}
                                                    {itmes.name}
                                                </div>
                                            </td>
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'normal'
                                            }}><span className={"this_" + itmes.id} >{CisUI().getCurrencyFormated1(itmes.thisYear)}</span></td>
                                            {reporttype === 'comparative' ? <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'normal'
                                            }}><span className={"lasty_" + itmes.id} >{CisUI().getCurrencyFormated1(itmes.lastYear)}</span></td> : ""}
                                        </tr>)
                                        itmes["childTree"].map((itm, indx) => {
                                            if(itm.row === 'total')
                                            {
                                                row.push(<tr className={"disp_" + itmes.id} style={{display: "none"}}>
                                                    <td style={{fontWeight: 'normal'}}>
                                                        <div className="subItemTextTotal">{itm.name}</div>
                                                    </td>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'bold'
                                                    }}>{CisUI().getCurrencyFormated1(itm.thisYear)}</td>
                                                    {reporttype === 'comparative' ? <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'bold'
                                                    }}>{CisUI().getCurrencyFormated1(itm.lastYear)}</td> : ""}
                                                </tr>)
                                            }
                                            else {
                                                row.push(<tr className={"disp_" + itmes.id} style={{display: "none"}}>
                                                    <td style={{fontWeight: 'normal'}}>
                                                        <div className="subItemText3">{itm.name}</div>
                                                    </td>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'normal'
                                                    }}>{CisUI().getCurrencyFormated1(itm.thisYear)}</td>
                                                    {reporttype === 'comparative' ? <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'normal'
                                                    }}>{CisUI().getCurrencyFormated1(itm.lastYear)}</td> : ""}
                                                </tr>)
                                            }
                                        })
                                        return row;
                                    })
                                }
                                <tr className="showTotalBorder">
                                    <td style={{fontWeight: 'bold'}}>
                                        <div className="subItemText3">Total Non-Current Liabilities</div>
                                    </td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.data[0]["liability"]["totalOtrLiabilityY1"])}</td>
                                    {reporttype === 'comparative' ? <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.data[0]["liability"]["totalOtrLiabilityY2"])}</td> : ""}
                                </tr>
                                <tr className="showTotalBorder">
                                    <td className="clmintent" style={{fontWeight: 'bold'}}>Total LIABILITIES</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.data[0]["liability"]["totalLiabilityY1"])}</td>
                                    {reporttype === 'comparative' ? <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.data[0]["liability"]["totalLiabilityY2"])}</td> : ""}
                                </tr>
                                <tr>
                                    <td colSpan={3} style={{fontWeight: 'bold'}}>
                                        <div className="subItemText">Net Assets/Net Income</div>
                                    </td>
                                </tr>
                                {
                                    resp.data[0]["liability"]["netIncome"].map((itmes, index) => {
                                        var row = [];
                                        row.push(<tr>
                                            <td style={{fontWeight: 'normal'}}>
                                                <div className="subItemText2">
                                                    {itmes["childTree"].length > 0 ?
                                                        <span style={{marginLeft: '-10px'}}><i id={"icon_" + itmes.id}
                                                                                               onClick={() => setCollapse(itmes.id)}
                                                                                               className="fas fa-chevron-right"/> </span>
                                                        : <></>}
                                                    {itmes.name}
                                                </div>
                                            </td>
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'normal'
                                            }}><span className={"this_" + itmes.id} >{CisUI().getCurrencyFormated1(itmes.thisYear)}</span></td>
                                            {reporttype === 'comparative' ? <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'normal'
                                            }}><span className={"lasty_" + itmes.id} >{CisUI().getCurrencyFormated1(itmes.lastYear)}</span></td> : ""}
                                        </tr>)
                                        itmes["childTree"].map((itm, indx) => {
                                            if(itm.row === 'total')
                                            {
                                                row.push(<tr className={"disp_" + itmes.id} style={{display: "none"}}>
                                                    <td style={{fontWeight: 'normal'}}>
                                                        <div className="subItemTextTotal">{itm.name}</div>
                                                    </td>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'bold'
                                                    }}>{CisUI().getCurrencyFormated1(itm.thisYear)}</td>
                                                    {reporttype === 'comparative' ? <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'bold'
                                                    }}>{CisUI().getCurrencyFormated1(itm.lastYear)}</td> : ""}
                                                </tr>)
                                            }
                                            else {
                                                row.push(<tr className={"disp_" + itmes.id} style={{display: "none"}}>
                                                    <td style={{fontWeight: 'normal'}}>
                                                        <div className="subItemText3">{itm.name}</div>
                                                    </td>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'normal'
                                                    }}>{CisUI().getCurrencyFormated1(itm.thisYear)}</td>
                                                    {reporttype === 'comparative' ? <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'normal'
                                                    }}>{CisUI().getCurrencyFormated1(itm.lastYear)}</td> : ""}
                                                </tr>)
                                            }
                                        })
                                        return row;
                                    })
                                }
                                <tr className="showTotalBorder">
                                    <td style={{fontWeight: 'bold'}}>
                                        <div className="subItemText3">Total Net Assets/Net Income</div>
                                    </td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.data[0]["liability"]["totalIncomeY1"])}</td>
                                    {reporttype === 'comparative' ? <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.data[0]["liability"]["totalIncomeY2"])}</td> : ""}
                                </tr>
                                <tr className="showTotalBorderDouble">
                                    <td className="clmintent" style={{fontWeight: 'bold'}}>Total Liabilities and Net
                                        Assets
                                    </td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.data[0]["liability"]["totalLiabilityEndY1"])}</td>
                                    {reporttype === 'comparative' ? <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.data[0]["liability"]["totalLiabilityEndY2"])}</td> : ""}
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    : <></>}

            </Card>
        </>
    );
};

export default StatementOfFinancialPositions;