import {Form, Row,Col,Button,Select, Card,notification} from 'antd';
import React, {useState} from "react";
import jsonfile from './approver.json'
import {CisUI} from '../../../../util/CISUI'
import InputFields from '../../../../util/InputField'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "axios";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import IntlMessages from "../../../../util/IntlMessages";
import InputOnly from "../../../../util/InputOnly";

const NewApproverMulty = (props) => {

    const [loading, setLoading] = useState([]);
    const [type, setType] = useState([
        {
            id: "Transactional",
            name: "Transactional",
            minimum: 0,
            maximum: 0,
            status: 1,
        },
        {
            id: "HR",
            name: "HR",
            minimum: 0,
            maximum: 0,
            status: 1,
        },
        {
            id: "Bill",
            name: "Bill",
            minimum: 0,
            maximum: 0,
            status: 1,
        },
        {
            id: "Procurement",
            name: "Procurement",
            minimum: 0,
            maximum: 0,
            status: 1,
        },
        {
            id: "Voucher",
            name: "Voucher",
            minimum: 0,
            maximum: 0,
            status: 1,
        },
        {
            id: "Expense Request",
            name: "Expense Request",
            minimum: 0,
            maximum: 0,
            status: 1,
        },
        {
            id: "Credit Note",
            name: "Credit Note",
            minimum: 0,
            maximum: 0,
            status: 1,
        },
        {
            id: "Others Payment",
            name: "Others Payment",
            minimum: 0,
            maximum: 0,
            status: 1,
        },
        {
            id: "Journal",
            name: "Journal",
            minimum: 0,
            maximum: 0,
            status: 1,
        }
    ]);

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }


    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {
        values['items'] = type;
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type : "success",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    const ItemCellChange = idx => e => {
        const { name, value } = e.target;
        const rows = [...type];
        console.log("selectedITems:"+ JSON.stringify(rows[idx]));

        if(name === "min") {
            rows[idx] = {
                id: rows[idx].id,
                name: rows[idx].name,
                minimum: value,
                maximum: rows[idx].maximum,
                status: rows[idx].status
            };
        }
        else if(name === "max") {
            rows[idx] = {
                id: rows[idx].id,
                name: rows[idx].name,
                minimum: rows[idx].minimum,
                maximum: value,
                status: rows[idx].status
            };
        }
        else if(name === "status") {
            let status = 0;
            rows[idx] = {
                id: rows[idx].id,
                name: rows[idx].name,
                minimum: rows[idx].minimum,
                maximum: rows[idx].maximum,
                status: value
            };
        }

        setType(rows);
    };

    //console.log("UserData : "+userData);

    return (
        <Card title={<IntlMessages id={Title} />} extra={
            isEdit === 1
                ? CisUI().addAction('../../'+jsonfile.urls.list)
                : CisUI().addAction('../'+jsonfile.urls.list)
        }>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                {/*{InputFields(jsonfile.input,userData,isEdit,props)}*/}
                {InputOnly(jsonfile.input2,userData,isEdit,props,"",redirectto)}

                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Minimum Amount($)</th>
                            <th>Maximum Amount($)</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        type.map((item,index)=>{
                            return <tr>
                                <td>{item.name}</td>
                                <td><input type="number" name="min" value={item.minimum} onChange={ItemCellChange(index)} className="form-control" /></td>
                                <td><input type="number" name="max" value={item.maximum} onChange={ItemCellChange(index)} className="form-control" /></td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            <IntlMessages id={"Submit"} />
                        </Button>
                        <a className="ant-btn ant-alert-info" onClick={() => history.goBack()} ><IntlMessages id={"Cancel"} /></a>
                    </Col>
                </Row>

            </Form>
        </Card>
    );
};

export default NewApproverMulty;