import React, {useState, useEffect} from "react";
import { Form, Row, Col, Input, Button, Modal, Collapse ,Card, Table, notification} from 'antd';
import jsonfile from './journal.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import ActionButton from '../../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {ExclamationCircleOutlined} from '@ant-design/icons';

const Search = Input.Search;
const Panel = Collapse.Panel;
const {confirm} = Modal;

let  searchView = "";

const JournalDetails = (props) => {
    const value = 0;

    const [state, setState] = useState([]);
    const [entry, setEntryDetails] = useState([]);

    const [loading,setLoading] = useState(false);
    const [local,setLocal] = useState({});
    const [isDonation,setIsDonation] = useState(false);
    const [difference,setDifference] = useState(0);
    const [selected,setSelected] = useState([]);
    const [selectedck,setSelectedCK] = useState([]);
    const [total,setTotal] = useState({
        debit : 0,
        credit : 0
    });

    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

    const showModalCofirm = () => e => {
        setIsConfirmModalVisible(true);
    };

    const handleOkConfirm = () => {
        // JournalVoid();
        setIsConfirmModalVisible(false);
    };

    const handleCancelConfirm = () => {
        setIsConfirmModalVisible(false);
    };


    const history = useHistory();

    const [form] = Form.useForm();

    useEffect(() => {
        getReconcileData();
    }, []);

    const getReconcileData = () => {
        document.getElementById("loader").style.display = "block";
        const jID = props.match.params.id;
        axios.get(Config.apiserver + jsonfile.urls.view+"/"+jID)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    setState(res.data.data);
                    const dt = res.data.data;
                    if(dt.length > 0) {
                        if(dt[0].remark === 'Donation payment') {
                            setIsDonation(true);
                        }
                        setEntryDetails({
                            date : dt[0].date,
                            company : dt[0].company,
                            entry_number : dt[0].entry_number,
                            status : dt[0].status
                        })
                    }

                    setTotal({
                        debit : res.data.totalDebit,
                        credit : res.data.totalCredit
                    });

                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                document.getElementById("loader").style.display = "none";
            });
    }

    const onFinish = values => {
        console.log('Received values of form: ', values);
        const qsup = CisUI().getQueryStrings(values);
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.alltransactions+"/?type=2&"+qsup;
        setState({reqURL: newURL});
    };

    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    const setSelection = (e) => {
        let slct = selected;
        const vl = parseInt(e.target.value);
        if(e.target.checked) {
            if(selected.some(item => item === vl)) {

            }
            else {
                slct.push(vl);
            }
        }
        else {
            if(selected.some(item => item === vl)) {
                var index = slct.indexOf(vl); // Let's say it's Bob.
                slct.splice(index,1);
            }
        }
        setSelected(slct);

        let receipt = 0;
        let payment = 0;
        slct.map((value,indx) => {
            state.transaction.map((itm,ind) => {
                if(itm.id === value) {
                    receipt += itm.debit;
                    payment += itm.credit;
                }
            });
        });

        console.log(receipt);
        //let gap = receipt - payment;
        const totalGap = state.ending_balance - (state.balance + receipt - payment);
        setDifference(totalGap);

    }

    const JournalVoid = () => {
        document.getElementById("loader").style.display = "block";
        const jID = props.match.params.id;
        axios.post(Config.apiserver + jsonfile.urls.void+"/"+jID)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    notification.warning({
                        message: 'Alert',
                        type: "success",
                        description: res.data.msg
                    });
                    window.location.reload();
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                document.getElementById("loader").style.display = "none";
            });
    }

    const showConfirmVoid = () => {
        confirm({
            title: 'Journal Void Confirmation',
            icon: <ExclamationCircleOutlined/>,
            okText: "Yes",
            cancelText: "No",
            content: <>
                <div className="row">
                    <div className="col-md-12">
                        <h4>Are you sure you want to void this journal?</h4>
                    </div>
                </div>
            </>,
            onOk() {
                console.log('OK');
                JournalVoid();
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    }

    const extraItems = (
        <>
            <Button type="info" onClick={() => history.goBack()} htmlType="back">Back</Button>
            {
                entry.status === 3 ? <>
                    <Button type="info" htmlType="button">Voided</Button>
                </> : <>
                    {
                        isDonation === false ? <>
                            <Button id="submitbutton" type="primary" htmlType="button">
                                <Link to={"../journaledit/"+entry.entry_number}>Edit</Link>
                            </Button>
                            <Button type="danger" onClick={showConfirmVoid} htmlType="button">Void</Button>
                        </> : <></>
                    }
                </>
            }


        </>
    );

    let totalDebit = 0;
    let totalCredit = 0;


    return (
        <>
            <Card title={"Entry details - "+entry.entry_number} extra={extraItems}>
                {CisUI().showLoading}
                <div className="row">
                    <div className="col-md-12">
                        <div>
                            <label><strong>Company</strong> : </label> {entry.company}<br />
                            <label><strong>Date</strong> : </label> {CisUI().DateFormat(entry.date)}<br />
                            <label><strong>Entry Number</strong> : </label> {entry.entry_number}<br />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="table-responsive" style={{marginTop : '20px'}}>
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>Account</th>
                                    <th>Description</th>
                                    <th>Fund Program</th>
                                    <th>Functional Expense</th>
                                    <th>Restriction Type</th>
                                    <th>Class</th>
                                    <th style={{textAlign : 'right'}}>Debit</th>
                                    <th style={{textAlign : 'right'}}>Credit</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    state.map((items,index) => {
                                        return <tr>
                                            <td>{items.account}</td>
                                            <td>{items.description}</td>
                                            <td>{items.fund_program}</td>
                                            <td>{items.func_expense}</td>
                                            <td style={{textTransform: 'capitalize'}}>{items.restriction_type}</td>
                                            <td>{items.class}</td>
                                            <td style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(items.debit)}</td>
                                            <td style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(items.credit)}</td>
                                        </tr>
                                    })
                                }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={6}>Total = </th>
                                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(total.debit)}</th>
                                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(total.credit)}</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                </div>
            </Card>

        </>
    );
};

export default JournalDetails;