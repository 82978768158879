import React, {useState, useEffect} from "react";
import { Form ,Card, Collapse} from 'antd';
import jsonfile from './invoice.json';
import {Link, useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import ActionButton from '../../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import './style.css';
import axios from "../../../../util/Api";

const Panel = Collapse.Panel;


const PledgeReciveable = () => {
    const history = useHistory();
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.pledgereceivable,
        filtervalue : '',
        subtitle: ''
    });
    const [summary, setSummary] = useState({
        total: 0,
        paid: 0,
        due: 0
    })

    const journalPost = (val) => {
        if(val === 1) {
            return 'Yes';
        }
        return 'No';
    }

    const headers = {
        id: {invisible: true},
        created_at: {invisible: true},
        credit_amount: {invisible: true},
        status: {invisible: true},
        receiveable: {invisible: true},
        company_name: {
            text: <IntlMessages id="Company Name"/>,
            sortable: true,
            filterable: false,
        },
        member_name: {
            text: <IntlMessages id="Member Name"/>,
            sortable: true,
            filterable: false,
        },
        invoice_number: {
            text: <IntlMessages id="Invoice Number"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                <Link to={"fundraisedetails/"+row.id}>{value}</Link>
            )
        },
        total: {
            text: <div style={{textAlign: 'right', marginRight:"10px"}}><IntlMessages id="Pledge Amount"/></div>,
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        paid_amount: {
            text: <div style={{textAlign: 'right', marginRight:"10px"}}><IntlMessages id="Paid Amount"/></div>,
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        due: {
            text: <div style={{textAlign: 'right', marginRight:"10px"}}><IntlMessages id="Receivable Amount"/></div>,
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(row.total - (row.paid_amount))
            )
        },
        date: {
            text: <IntlMessages id="Date"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        accounts: {
            text: <IntlMessages id="Accounts"/>,
            sortable: true,
            filterable: false
        }
    };

    const getSummary = () => {
        axios.get(state.reqURL)
            .then((res) => {
                // console.log(res.data);
                if(res.data.status === 1) {
                    setSummary(res.data.summary);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        getSummary();
    }, []);

    const orderedHeaders = jsonfile.listView.titlereceivable;

    const [form] = Form.useForm();

    const onFinish = values => {
        // console.log('Received values of form: ', values);
        const data = {
            ...values,
            // 'purchase_date' : values['purchase_date'].format("YYYY-MM-DD"),
        }
        // console.log(data);
        let wk = "";
        // console.log(data);
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    wk = values['date'];
                    const dt = CisUI().ParseDateFormat(values[key]);
                    // setPeriod("Between "+CisUI().DateFormat(dt[0])+" and "+CisUI().DateFormat(dt[1]));
                    return `${key}=${dt}`
                } else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.pledgereceivable+"/?"+qsup;
        setState({reqURL: newURL});
    };

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header={<IntlMessages id="Advance Search" />}  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input,"",1,"date",CisUI().PeriodFiled(form))}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {CisUI().listActionButtonPrint("../"+jsonfile.urls.add, "", state.reqURL, "data", 'pledge_receiveable', jsonfile, state.subtitle, 'landscape')}
        </>
    );

    return (
        <>
            {searchView}
            <Card title={"Pledge Receivable"} extra={getExtra}>
                {/*{CisUI().listAction(jsonfile.urls.add)}*/}
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filterValue}
                    // perPage={CisUI().perPage}
                    // sortable
                    // withLinks
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />

                <div style={{textAlign: 'center !important', marginTop: '30px'}}>
                    <table align="center" className={"table table-bordered"} style={{width: '300px'}}>
                        <tr>
                            <th style={{textAlign: "right"}}>Total Pledge Amount</th>
                            <td>: {CisUI().getCurrencyFormated1(summary.total)}</td>
                        </tr>
                        <tr>
                            <th style={{textAlign: "right"}}>Total Paid Amount</th>
                            <td>: {CisUI().getCurrencyFormated1(summary.paid)}</td>
                        </tr>
                        <tr>
                            <th style={{textAlign: "right"}}>Total Receivable Amount</th>
                            <td >: {CisUI().getCurrencyFormated1(summary.due)}</td>
                        </tr>
                    </table>
                </div>

            </Card>
        </>
    );
};

export default PledgeReciveable;