import React, {useState, useMemo} from "react";
import {Layout, Popover} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import languageData from "../languageData";
import HorizontalNav from "../HorizontalNav";
import {Link} from "react-router-dom";
import {switchLanguage, toggleCollapsedSideNav} from "../../../appRedux/actions";
import UserProfile from "../../Topbar/UserProfile";
import moment from "moment";
import {CisUI} from "../../../util/CISUI";
import {HelpOutline} from "@material-ui/icons";
import AppNotification from "../../../components/AppNotification";
import MailNotification from "../../../components/MailNotification";

const {Header} = Layout;
// const Option = Select.Option;
// const menu = (
//     <Menu onClick={handleMenuClick}>
//         <Menu.Item key="1">Products</Menu.Item>
//         <Menu.Item key="2">Apps</Menu.Item>
//         <Menu.Item key="3">Blogs</Menu.Item>
//     </Menu>
// );

// function handleMenuClick(e) {
//     message.info('Click on menu item.');
// }
//
// function handleChange(value) {
//     console.log(`selected ${value}`);
// }

const HorizontalDefault = () => {

    const dispatch = useDispatch();

    // const [searchText, setSearchText] = useState('');
    const locale = useSelector(({settings}) => settings.locale);
    const {navCollapsed} = useSelector(({common}) => common);

    const languageMenu = () => (
        <CustomScrollbars className="gx-popover-lang-scroll">
            <ul className="gx-sub-popover">
                {languageData.map(language =>
                    <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
                        dispatch(switchLanguage(language))
                    }>
                        <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
                        <span className="gx-language-text">{language.name}</span>
                    </li>
                )}
            </ul>
        </CustomScrollbars>);

    // const updateSearchChatUser = (evt) => {
    //     setSearchText(evt.target.value)
    // };

    const [showArrow, setShowArrow] = useState(true);
    const [arrowAtCenter, setArrowAtCenter] = useState(false);
    const token = localStorage.getItem("token");

    const mergedArrow = useMemo(() => {
        if (arrowAtCenter) return { arrowPointAtCenter: true };
        return showArrow;
    }, [showArrow, arrowAtCenter]);

    const content = (
        <>
            As per the COPUSA finance policy, statement of Tithes and Offering for the <br /> previous month will automatically be closed by the dates indicated below of the following month;
            <br /> <br /> <strong>Local Account Sheet:</strong> 5th of the following month
            <br /> <strong>District Account Sheet:</strong> 8th of the following month
            <br /> <strong>Regional Account Sheet:</strong> 10th of the following month
            <br /><br />
            You may contact your RAAM, Regional Deacon or the National office if you need any further assistance.
        </>
    );

    const today = moment().format("DD");

    return (
        <div className="gx-header-horizontal">
            <Header
                className="gx-header-horizontal-main">
                <div className="gx-container">
                    <div className="gx-header-horizontal-main-flex">

                        <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3">
                            <i className="gx-icon-btn icon icon-menu"
                               onClick={() => {
                                   console.log("navCollapsed", !navCollapsed)
                                   dispatch(toggleCollapsedSideNav(!navCollapsed));
                               }}
                            />

                        </div>
                        <Link to="/main/dashboard" className="gx-d-block gx-d-lg-none gx-pointer gx-w-logo">
                            <img alt="" src="/assets/coplogo.png"/></Link>
                        <Link to="/main/dashboard" className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo">
                            <img alt="" src="/assets/coplogo.png" style={{width: "200px"}}/></Link>

                        {
                            today >= 28 || today <= 10 ? <>
                                <div style={{marginLeft: '100px',}}>
                                    <span style={{fontWeight: "bold", color: 'red'}}>Important Notice:</span> Your Monthly returns submission will be closing soon.
                                    <Popover placement="bottomRight" title={<span style={{fontWeight: "bold"}}>
                                Details
                                </span>} content={content} arrow={mergedArrow}>
                                        <span style={{fontWeight: "bold", color: "red", textDecoration: "underline", fontStyle: "italic"}}>View Details</span>
                                    </Popover>
                                </div>
                            </> : <></>
                        }


                        <ul className="gx-header-notifications gx-ml-auto">



                            <li className="gx-msg">
                                <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
                                         content={<MailNotification/>} trigger="click">
                                <span className="gx-pointer gx-status-pos gx-d-block">
                                    <i className="icon icon-chat-new"/>
                                    <span className="gx-status gx-status-rtl gx-small gx-orange"/>
                                </span>
                                </Popover>
                            </li>
                            <li>
                                <a href="https://tithe.ly/my-churches" data-tooltip="Click here to access in Tithly" data-flow="bottom" target="_blank" rel="noopener noreferrer">
                                    <img src={'/assets/tithly_logo.png'} style={{height: '30px'}} alt="" />
                                </a>
                            </li>
                            {/*<li>*/}
                            {/*    <a href="https://rock.copusa.church/" target="_blank" rel="noopener noreferrer">*/}
                            {/*        <img src={'/assets/rock_logo.png'} style={{height: '35px'}} alt="" />*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                            <li>
                                <a href={"https://chms.mycopusa.com/emslogin?token="+token} title={"CHMS"} data-tooltip="Click to login in CHMS" data-flow="bottom" target="_blank" rel="noopener noreferrer">
                                    <img src={'/assets/images/logo.png'} style={{height: '35px'}} alt="" />
                                </a>
                            </li>
                            {/*{*/}
                            {/*    ((CisUI().getUserInfo("comarea") === 4)) ?*/}
                            {/*        <>*/}
                            {/*        {*/}
                            {/*            (CisUI().getUserInfo('usefor') === 14 || CisUI().getUserInfo('usefor') === 15 || CisUI().getUserInfo('usefor') === 16) ? <>*/}
                            {/*                <li>*/}
                            {/*                    <a href={"https://www.conference.copusa.org/account/?rf=fmslogin&tp=region&region="+CisUI().getUserInfo("region_id")} target="_blank" rel="noopener noreferrer">*/}
                            {/*                        <i className="icon icon-sweet-alert" style={{position: "relative", top: "3px", margin: "0px 5px 0px 0px", fontSize: "15px"}}/>*/}
                            {/*                        <span style={{ fontSize: "15px", color: "#000"}}>Conference</span>*/}
                            {/*                    </a>*/}
                            {/*                </li>*/}
                            {/*            </> : <></>*/}
                            {/*        }*/}
                            {/*        </> : ((CisUI().getUserInfo("comarea") === 5)) ?*/}
                            {/*            <>*/}
                            {/*            {*/}
                            {/*                (CisUI().getUserInfo('usefor') === 20 || CisUI().getUserInfo('usefor') === 21) ? <>*/}
                            {/*                    <li>*/}
                            {/*                        <a href={"https://www.conference.copusa.org/account/?rf=fmslogin&tp=district&district="+CisUI().getUserInfo("district_id")} target="_blank" rel="noopener noreferrer">*/}
                            {/*                            <i className="icon icon-sweet-alert" style={{position: "relative", top: "3px", margin: "0px 5px 0px 0px", fontSize: "15px"}}/>*/}
                            {/*                            <span style={{ fontSize: "15px", color: "#000"}}>Conference</span>*/}
                            {/*                        </a>*/}
                            {/*                    </li>*/}
                            {/*                </> : <></>*/}
                            {/*            }*/}
                            {/*            </>*/}
                            {/*            : ((CisUI().getUserInfo('usefor') === 7 || CisUI().getUserInfo("usefor") === 9) || CisUI().getUserInfo("usefor") === 1) ?*/}
                            {/*                <>*/}
                            {/*                    <li>*/}
                            {/*                        <a href={"https://www.conference.copusa.org/account/?rf=fmslogin&tp=nation&nation=USA"} target="_blank" rel="noopener noreferrer">*/}
                            {/*                            <i className="icon icon-sweet-alert" style={{position: "relative", top: "3px", margin: "0px 5px 0px 0px", fontSize: "15px"}}/>*/}
                            {/*                            <span style={{ fontSize: "15px", color: "#000"}}>Conference</span>*/}
                            {/*                        </a>*/}
                            {/*                    </li>*/}
                            {/*                </> : <></>*/}
                            {/*}*/}

                            {
                                CisUI().getUserInfo('usefor') === 14 ?
                                    <>
                                        <li>
                                            <a href="https://officecopusa.org/appraisal" target="_blank" data-tooltip="Click here to access in Appraisal" data-flow="bottom" rel="noopener noreferrer">
                                                <i className="icon icon-sweet-alert" style={{position: "relative", top: "3px", margin: "0px 5px 0px 0px", fontSize: "15px"}}/>
                                                <img alt="" src="/assets/coplogo.png" style={{width: "200px"}}/>
                                            </a>
                                        </li>
                                    </>: <></>
                            }


                            <li>
                                <a href="https://support.mycopusa.com/" target="_blank" data-tooltip="Support Center" data-flow="bottom" rel="noopener noreferrer">
                                    <HelpOutline style={{color: 'darkblue'}}  />
                                   {/*<i className="icon icon-sweet-alert" style={{position: "relative", top: "3px", margin: "0px 5px 0px 0px", fontSize: "15px"}}/>*/}
                                   {/*<span style={{ fontSize: "15px", color: "#000"}}></span>*/}
                               </a>
                            </li>

                            <li className="gx-notify">
                                <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<AppNotification/>}
                                         trigger="click">
                                    <span className="gx-pointer gx-d-block"><i className="icon icon-notification"/></span>
                                </Popover>
                            </li>

                            <li className="gx-language">
                                <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
                                         content={languageMenu()} trigger="click">
                                  <span className="gx-pointer gx-flex-row gx-align-items-center"><i
                                      className={`flag flag-24 flag-${locale.icon}`}/>
                                  </span>
                                </Popover>
                            </li>
                            <li className="gx-user-nav" style={{marginLeft: "0", marginTop: '-10px'}}>
                                {/*<UserInfo/>*/}
                                <UserProfile/>
                            </li>
                        </ul>
                    </div>
                </div>
            </Header>
            <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block">
                <div className="gx-container">
                    <div className="gx-header-horizontal-nav-flex">
                        <HorizontalNav/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HorizontalDefault;
