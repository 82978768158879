import {Upload, message} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import React from  "react";
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../util/config";


const { Dragger } = Upload;


const MyDocument = (props) => {
    const token = localStorage.getItem('token');
    const propst = {
        name: 'file',
        multiple: true,
        action: Config.apiserver+"document/fileupload",
        method: 'post',
        processData: true,
        data(file) {
            const x4 = 2;
            const formData = new FormData();
            formData.set("file", file);
            formData.set("x2", "y2");
            formData.append("x3", true);
            formData.append("x4", x4.toString());
            formData.append("directory", "user_common");
            formData.append("dir_type", 100);
            formData.append("directory_id", 0);
            formData.append("caption", "");
            return formData;
        },
        headers: {
            Authorization : token
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            console.log(JSON.stringify(status));
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <Dragger {...propst}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined/>
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                            band files
                        </p>
                    </Dragger>
                </div>
            </div>
        </>
    );
}

export default MyDocument;