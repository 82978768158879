import React, {useEffect, useState} from "react";
import {Form, Row, Col, Button, Radio, Checkbox, DatePicker, Input, Card,notification} from 'antd';
import jsonfile from './40kform.json';
import {CisUI} from '../../../../util/CISUI';
import InputFields from '../../../../util/InputField';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import moment from "moment";
import 'moment/locale/es-us';
import InputOnly from "../../../../util/InputOnly";
import type { RadioChangeEvent } from 'antd';
import IntlMessages from "../../../../util/IntlMessages";

const New401KForm = (props) => {

    const LeaveTypes = RemoteJson("vacationtype");
    const [form] = Form.useForm();
    const history = useHistory();

    const [loading, setLoading] = useState([]);
    const [value, setValue] = useState("");
    const [maritial, setMariatil] = useState("");
    const [status, setStatus] = useState("");
    const [data, setData] = useState({});
    const [signature, setSignature] = useState(CisUI().getUserInfo('name'));

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    if(isEdit === 1 ) {
        // userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }

    useEffect(()=>{
        if(isEdit === 1) {
            axios.get(Config.apiserver + jsonfile.urls.view+"/"+userID)
                .then((res) => {
                    //console.log(res.data);
                    if (res.data.status === 1) {
                        if (res.data.data) {
                            userData = res.data.data;
                            //console.log(userData);
                            setData(userData);
                            setStatus(userData?.p_status);
                            setSignature(userData?.signature);
                            setValue(userData?.type);
                            setMariatil(userData?.maritial_status);

                            const type = userData?.p_status;
                            if(type === 'start') {
                                form.setFieldsValue({
                                    'start_at': moment(userData?.plan_to),
                                    'start_amount': (userData?.rate)
                                });
                            }
                            else if(type === 'change') {
                                form.setFieldsValue({
                                    'change_at': moment(userData?.plan_to),
                                    'change_amount': (userData?.rate)
                                });
                            }
                            else if(type === 'resume') {
                                form.setFieldsValue({
                                    'resume_at': moment(userData?.plan_to),
                                    'resume_amount': (userData?.rate)
                                });
                            }
                            else if(type === 'suspend') {
                                form.setFieldsValue({
                                    'suspend_at': moment(userData?.plan_to),
                                    'suspend_amount': (userData?.rate)
                                });
                            }

                            form.setFieldsValue({
                                'name': userData?.signature,
                                'date': moment(userData?.date),
                                'effective_date': moment(userData?.effective_date),
                            });

                        } else {
                            setData({});
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setData({})
                });
        }
    },[])



    const onFinish = (values) => {
        // // console.log(values);
        document.getElementById("loader").style.display = "block";
        values["type"] = value;
        values["marital_status"] = maritial;
        values["status"] = status;

        axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg)
                    history.push(redirectto);
                }
                else {
                    CisUI().Notification('error', res.data.msg)
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    const onChange = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };
    const onChangeMaritial = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setMariatil(e.target.value);
    };
    const onChangeSatus = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setStatus(e.target.value);
    };
    const onSignatureSet = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setSignature(e.target.value);
    };


    //console.log("UserData : "+userData);

    return (
        <Card title={Title} extra={
            isEdit === 1
                ? CisUI().addAction('../../'+jsonfile.urls.list)
                : CisUI().addAction('../'+jsonfile.urls.list)
        }>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                {/*{InputFields(jsonfile.input,userData,isEdit,props)}*/}
                <div align={"center"} style={{textAlign: 'center', margin: 'auto'}}>
                    <div style={{textAlign: 'center', width: '1024px', display: 'inline-block'}} align={"center"}>
                        <Card>

                            <Row>
                                <Col className={"col-md-12"} style={{textAlign: 'center'}}>
                                    <img src={"/assets/images/logo.png"} style={{width: '100px'}} />
                                    <div style={{fontSize: '30px'}}>THE CHURCH OF PENTECOST U.S.A., INC.</div>

                                    <div style={{fontSize: '12px', textAlign: 'justify', padding: '10px'}}>
                                        PLEASE USE THIS FORM TO NOTIFY FINANCE/HR TO START YOUR 401K CONTRIBUTIONS, CHANGE YOUR 401K CONTRIBUTION RATE, SUSPEND YOUR 401K CONTRIBUTION OR RESUME YOUR 401K CONTRIBUTION. THE FINANCE/HR TEAM WILL USE THIS FORM TO UPDATE PAYROLL. BY ELECTING TO MAKE CONTRIBUTIONS TO THE PLAN, YOU ARE AUTHORIZING THE EMPLOYER TO WITHHOLD THOSE CONTRIBUTIONS FROM YOUR PAY.
                                    </div>

                                    <div style={{textAlign: 'left', marginTop: '20px', marginBottom: '20px'}}>
                                        <div style={{fontSize: '20px'}}>Select an Option</div>
                                        <Radio.Group onChange={onChange} value={value}>
                                            <Radio value={"NEW ENROLLMENT"}>NEW ENROLLMENT</Radio> <br/>
                                            <Radio value={"CHANGE CONTRIBUTION"}>CHANGE CONTRIBUTION</Radio> <br/>
                                            <Radio value={"RE-ENROLLMENT"}>RE-ENROLLMENT</Radio> <br/>
                                            <Radio value={"DISCONTINUE CONTRIBUTION"}>DISCONTINUE CONTRIBUTION</Radio> <br/>
                                        </Radio.Group>

                                    </div>
                                    <div style={{textAlign: 'left', marginTop: '20px', marginBottom: '20px'}}>
                                        <div style={{fontSize: '20px'}}>Employee Information</div>
                                        <Row>
                                            <Col className={"col-md-12"}>
                                                {InputOnly(jsonfile.input,data,isEdit,props)}
                                            </Col>
                                        </Row>
                                    </div>

                                    <div style={{textAlign: 'left', marginTop: '20px', marginBottom: '20px'}}>
                                        <div style={{fontSize: '20px'}}>Marital Status</div>
                                        <Radio.Group onChange={onChangeMaritial} value={maritial}>
                                            <Radio value={"SINGLE"}>SINGLE</Radio> <br/>
                                            <Radio value={"MARRIED"}>MARRIED</Radio> <br/>
                                            <Radio value={"DIVORCED"}>DIVORCED</Radio> <br/>
                                            <Radio value={"WIDOW/WIDOWER"}>WIDOW/WIDOWER</Radio> <br/>
                                        </Radio.Group>
                                    </div>

                                    <div style={{textAlign: 'left', marginTop: '20px', marginBottom: '20px'}}>
                                        <div style={{fontSize: '20px', padding: '10px'}}>CONTRIBUTION (PLEASE CHECK ONLY ONE BOX AND PROVIDE REQUESTED INFORMATION WHERE INDICATED)</div>
                                        <table className={"table table-bordred"}>
                                            <thead>
                                                <tr>
                                                    <th>Type</th>
                                                    <th>Description</th>
                                                    <th>Plan at / to</th>
                                                    <th>Rate (%) or Amount ($)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Checkbox name={"start"} onChange={onChangeSatus} checked={status === "start"} value={"start"}>Start</Checkbox>
                                                    </td>
                                                    <td>I WANT TO START MY PARTICIPATION IN THE PLAN AT</td>
                                                    <td>
                                                        <Form.Item
                                                            id={"start_at"}
                                                            name={"start_at"}
                                                            initialValue={status === 'start' ? data?.plan_to : null}
                                                        >
                                                            <DatePicker format={CisUI().dateFormat} disabled={status !== "start"} />
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item
                                                            id={"start_amount"}
                                                            name={"start_amount"}
                                                            initialValue={status === 'start' ? data?.rate : null}
                                                        >
                                                            <Input disabled={status !== "start"} />
                                                        </Form.Item>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Checkbox name={"change"} onChange={onChangeSatus} checked={status === "change"} value={"change"}>CHANGE</Checkbox>
                                                    </td>
                                                    <td>I WANT TO CHANGE MY RATE OF CONTRIBUTION TO</td>
                                                    <td>
                                                        <Form.Item
                                                            id={"change_at"}
                                                            name={"change_at"}
                                                            initialValue={status === 'change' ? data?.plan_to : null}
                                                        >
                                                            <DatePicker format={CisUI().dateFormat} disabled={status !== "change"} />
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item
                                                            id={"change_amount"}
                                                            name={"change_amount"}
                                                            initialValue={status === 'change' ? data?.rate : null}
                                                        >
                                                            <Input disabled={status !== "change"} />
                                                        </Form.Item>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Checkbox name={"resume"} onChange={onChangeSatus} checked={status === "resume"} value={"resume"}>RESUME</Checkbox>
                                                    </td>
                                                    <td>I WANT TO RESUME MY PARTICIPATION IN THE PLAN AT</td>
                                                    <td>
                                                        <Form.Item
                                                            id={"resume_at"}
                                                            name={"resume_at"}
                                                            initialValue={status === 'resume' ? data?.plan_to : null}
                                                        >
                                                            <DatePicker format={CisUI().dateFormat} disabled={status !== "resume"} />
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item
                                                            id={"resume_amount"}
                                                            name={"resume_amount"}
                                                            initialValue={status === 'resume' ? data?.rate : null}
                                                        >
                                                            <Input disabled={status !== "resume"} />
                                                        </Form.Item>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Checkbox name={"suspend"} onChange={onChangeSatus} checked={status === "suspend"} value={"suspend"}>SUSPEND</Checkbox>
                                                    </td>
                                                    <td>I WANT TO SUSPEND MY PARTICIPATION IN THE PLAN</td>
                                                    <td>
                                                        <Form.Item
                                                            id={"suspend_at"}
                                                            name={"suspend_at"}
                                                            initialValue={status === 'suspend' ? data?.plan_to : null}
                                                        >
                                                            <DatePicker format={CisUI().dateFormat} disabled={status !== "suspend"} />
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item
                                                            id={"suspend_amount"}
                                                            name={"suspend_amount"}
                                                            initialValue={status === 'suspend' ? data?.rate : null}
                                                        >
                                                            <Input disabled={status !== "suspend"} />
                                                        </Form.Item>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div style={{textAlign: 'left', marginTop: '20px', marginBottom: '20px'}}>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Item
                                                    name={"effective_date"}
                                                    label={"Effective Date"}
                                                    initialValue={moment(userData?.effective_date || "")}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: ""
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker allowClear format={CisUI().dateFormat} className="gx-mb-3 gx-w-100" />
                                                </Form.Item>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Item
                                                    name={"date"}
                                                    label={"Date"}
                                                    initialValue={moment(userData?.date || "")}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: ""
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker allowClear format={CisUI().dateFormat} className="gx-mb-3 gx-w-100" />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Item
                                                    name={"name"}
                                                    label={"Name/Signature"}
                                                    initialValue={signature}
                                                    onChange={onSignatureSet}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: ""
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder={"Enter your name"} />
                                                </Form.Item>
                                                <div id="signshow" style={{fontFamily: 'signature4', color: 'blue', textAlign: 'center', fontSize: '40px'}}>{signature}</div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>

                                <Col lg={24} xs={24} style={{ textAlign: "center" }}>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Col>

                            </Row>

                        </Card>
                    </div>
                </div>
            </Form>
        </Card>
    );
};

export default New401KForm;