import React, {useState, useEffect} from "react";
import { Form, Col, Input, Table, Badge, Collapse ,Card, notification} from 'antd';
import jsonfile from './ledger.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import LedgerGenerate from "./LedgerGenerate";
const { Column } = Table;


const Search = Input.Search;
const Panel = Collapse.Panel;

const SingleLedger = (props) => {
    const value = 0;
    const jID = props.match.params.id;
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.singleledger+"/"+jID,
        filtervalue : '',
        subtitle : '',
    });

    const [account,setAccount] = useState({
        account_number : "",
        account_name : ""
    });
    const [hide,setHide] = useState([]);

    const ref = React.createRef();
    const formRef = React.createRef();

    const [data,setData] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    useEffect(() => {
        if(CisUI().getUserInfo('com_id') > 0) {
            getLedgerData();
        }
    }, []);

    const getLedgerData = (url = "") => {
        document.getElementById("loader").style.display = "block";
        let urls = state.reqURL;
        if(url !== "") {
            urls = url;
        }

        axios.get(urls)
            .then((res) => {
                console.log(res)
                if (res.data.status === 1) {
                    setData(res.data.data);
                    setAccount(res.data.account);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                document.getElementById("loader").style.display = "none";
            })
            .finally(() => {
                setIsLoading(false);
            });

    }

    const history = useHistory();

    const headers = {
        // _id: {
        //     text: '#',
        //     invisible: false,
        //     filterable: false,
        //     transform: (value) => `${++value}`,
        // },
        id: {
            invisible : true
        },
        date: {
            text: <IntlMessages id="Date"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        debit: {
            text: <IntlMessages id="Debit"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        credit: {
            text: <IntlMessages id="Credit"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        }
    };

    const orderedHeaders = jsonfile.listView.title;

    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if(list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name} />
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        let wkd = "";
        let wk = "";
        let dt = "";
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    wk = formRef.current.getFieldValue('date');
                    const dt = CisUI().ParseDateFormat(values[key]);
                    return `${key}=${dt}`
                } else if (key === 'month') {
                    wk = formRef.current.getFieldValue('month');
                    // console.log("Selected Week : "+ wk);
                    wkd = CisUI().getMonthTitle(formRef.current.getFieldValue('month'));
                    return `${key}=${wkd[1]}`
                } else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined", "");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup);
        const newURL = Config.apiserver + jsonfile.urls.list + "?" + qsup;
        setState({reqURL: newURL});

        getLedgerData(newURL);
    };

    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        ref={formRef}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.search,"",1,"",CisUI().PeriodFiled(form))}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {CisUI().listActionReport("../"+jsonfile.urls.add,"",state.reqURL,"data","general_ledger",jsonfile,"Journal", "landscape")}
        </>
    );

    const setCollapse = (id) => {
        console.log("Slcct: ", id);
        if(hide.indexOf(id) > -1) {
            var index = hide.indexOf(id); // Let's say it's Bob.
            hide.splice(index,1);
            document.getElementById("icon_"+id).setAttribute("class","fas fa-chevron-down");
            var row = document.getElementsByClassName("disp_"+id);
            var i;
            for (i = 0; i < row.length; i++) {
                //row[i].style.display = 'inherit';
                row[i].removeAttribute('style');
            }
        }
        else {
            const hd = hide;
            hd.push(id);
            setHide(hd);
            document.getElementById("icon_"+id).setAttribute("class","fas fa-chevron-right")
            var row = document.getElementsByClassName("disp_"+id);
            var i;
            for (i = 0; i < row.length; i++) {
                row[i].style.display = 'none';
            }
        }
        console.log("hides : ", hide);
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            console.log(selected, selectedRows, changeRows);
        },
    };

    const getDetailsLink = (source,id) => {
        if(source === 'Bill Payment') {
            return "../../"+jsonfile.detaillink.bill+"/"+id;
        }
        else if(source === 'Journal') {
            return "../../"+jsonfile.detaillink.journal+"/"+id;
        }
        else if(source === 'Vendor Bill') {
            return "../../"+jsonfile.detaillink.bill+"/"+id;
        }
        else if(source === 'Ledger') {
            return "../../"+jsonfile.detaillink.ledgerdetails+"/"+id;
        }
        else if(source === 'Invoice') {
            return "../../"+jsonfile.detaillink.invoice+"/"+id;
        }
        else if(source === 'Credit Note') {
            return "../../"+jsonfile.detaillink.creditnote+"/"+id;
        }
        else {
            return "../../"+jsonfile.detaillink.journal+"/"+id;
        }
    }

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (date) => (
                <>{CisUI().DateFormat(date)}</>
            ),
        },
        {
            title: 'Type',
            dataIndex: 'source',
            key: 'source',
        },
        {
            title: 'Name',
            dataIndex: 'benificiary',
            key: 'benificiary',
        },
        {
            title: 'Description/Memo',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Ref. No',
            dataIndex: 'ref_no',
            key: 'ref_no',
        },
        {
            title: 'Check#',
            dataIndex: 'trx_no',
            key: 'trx_no',
        },
        {
            title: 'Debit',
            dataIndex: 'debit',
            key: 'debit',
            align: 'right',
            render: (debit,record) => (
                <Link to={getDetailsLink(record.source_ref,record.ref_id)}>{CisUI().getCurrencyFormated1(debit)}</Link>
            ),
        },
        {
            title: 'Credit',
            dataIndex: 'credit',
            key: 'credit',
            align: 'right',
            render: (credit,record) => (
                <Link to={getDetailsLink(record.source_ref,record.ref_id)}>{CisUI().getCurrencyFormated1(credit)}</Link>
            ),
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            align: 'right',
            render: (balance,record) => (
                <Link to={getDetailsLink(record.source_ref,record.ref_id)}>{CisUI().getCurrencyFormated1(balance)}</Link>
            ),
        }
    ];

    return (
        <>
            {searchView}
            <Card title={account.account_number+"-"+account.account_name} extra={getExtra}>
                {CisUI().showLoading}

                <div className="table-responsive">
                    {data.length > 0 ? <Table
                        className="table"
                        columns={columns}
                        // rowSelection={{ ...rowSelection, checkStrictly }}
                        pagination={{ defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['30', '50', '100','200','500']}}
                        dataSource={data}
                    /> : 'Loading...'}
                </div>

            </Card>
        </>
    );
};

export default SingleLedger;