import {Form, Row, Col, Input, Upload, InputNumber, message, DatePicker, Button, Card, Select, Checkbox, notification} from 'antd';
import React, {Component,useState, useEffect} from "react";
import jsonfile from './localexpenses.json';
import {CisUI} from '../../../../util/CISUI';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import InputOnly from "../../../../util/InputOnly";
import { UploadOutlined } from '@ant-design/icons';
const { Option } = Select;
// const Option = Select.Option;
const {TextArea} = Input;


const NewExpenses = (props) => {

    const formRef = React.createRef();

    const [loading, setLoading] = useState([]);
    const [officeExpnese, setOfficeExpense] = useState([]);
    const [inputList, setInputList] = useState([]);


    const [form] = Form.useForm();

    let endpoint = jsonfile.urls.add;
    const redirectto = "../../../"+jsonfile.urls.list;
    const history = useHistory();

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    const userID = props.match.params.id;
    let userData = [];
    console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
    }


    const onFinish = (values) => {
        setLoading({loading:true})
        console.log("Input Data : "+values);
        console.log("ExpenseData : "+inputList);
        document.getElementById("loader").style.display = "block";
        const newArray = inputList.filter(value => JSON.stringify(value) !== '{}');

        const wk = values["month"];
        // console.log("Selected Week : "+ wk);
        const mnts = CisUI().ParseDateFormatFromMonth(wk);
        // console.log("Month :"+mnts);
        values['month'] = mnts;


        console.log("AfterFilter : "+inputList);
        values['expenses'] = newArray;
        let msg = "Sorry! request not processed, please try again";
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                document.getElementById("loader").style.display = "none";
                if(res.data.status === 1) {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading:false});
            })
            .catch(errors => {
                console.log(errors);
                // msg = (errors);
                setLoading({loading:false});
                //setResponse([]);
                document.getElementById("loader").style.display = "none";
            });
    };

    let dataOptions = [];

    // useEffect(() => {
    //     setInputList(dataOptions);
    // }, []);

    const callOfficeExpense = () => {
        axios.get(Config.apiserver+"donation/expensetype?area=5")
            .then((res) => {
                // console.log(res.data);
                if(res.data.status === 1) {
                    dataOptions = res.data.data;
                    setOfficeExpense(res.data.data);
                    const dataex = [];
                    dataOptions.map((value,index) => {
                        dataex[index] = {
                            account_id : value.id,
                            account_number : value.account_number,
                            account_name : value.account_name,
                            name : value.account_number+"-"+value.account_name,
                            expensetype : value.id,
                            amount : '',
                            note : '',
                            files : [],
                            file_location : []
                        };
                    });
                    setInputList(dataex);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        callOfficeExpense();
    }, []);


    const token = localStorage.getItem('token');
    let formData = new FormData();
    let fileUID = "";
    const uplod = {
        name: 'file',
        action: Config.apiserver+"fileupload",
        method : 'post',
        headers: {
            'Authorization': token,
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
            if (!isJpgOrPng) {
                message.error('You can only upload jpg, png,and pdf file only!');
            }
            return isJpgOrPng;
        },
        onChange(info) {
            formData.append('file', info.file);
            fileUID = info.file.uid;
            console.log("index : "+info.file.uid);
            console.log(JSON.stringify(info));
            if(info.file.status === "done") {
                if (info.file.xhr.status === 200) {
                    console.log("ServerResponse : "+info.file.response);
                    const data = info.file.response;
                    if(data.status === 1) {
                        const list = [...inputList];
                        let files = list[data.line_index].files || [];
                        let file_location = list[data.line_index].file_location || [];
                        files.push(data.data.id);
                        file_location.push(data.data.location);
                        list[data.line_index].files = files;
                        list[data.line_index].file_location = file_location;
                        setInputList(list);
                        console.log("Files : "+JSON.stringify(list[data.line_index]));
                        message.success(`${info.file.name} file uploaded successfully`);
                    }

                } else {
                    message.error(`${info.file.name} file upload failed.`);
                }
            }
        }
    }

    //console.log("UserData : "+userData);

    const handleInputChange = (e, index) => {
        if(e && e.target) {
            const {name, value} = e.target;

            const list = [...inputList];

            //list[index]["expensetype"] = inputList[index].expensetype;
            list[index][name] = value;
            setInputList(list);
            //list[index]["expensetype"] = dataOptions[index].id;
            //setInputList(list);
            // console.log(JSON.stringify(inputList));
        }
        else {
            const list = [...inputList];
            //list[index]["expensetype"] = e;
            //setInputList(list);
        }
    };

// handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

// handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { }]);
    };



    return (
        <Card title={Title}>
            {CisUI().showLoading}
            {
                isEdit === 1
                    ? CisUI().addAction("../../"+jsonfile.urls.list)
                    : CisUI().addAction("../"+jsonfile.urls.list)
            }
            <Form
                form={form}
                ref={formRef}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >
                {InputOnly(jsonfile.input,userData,isEdit)}

                <Row gutter={24}>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Account Name</th>
                            <th>Amount</th>
                            <th>Note</th>
                            <th>Attachment</th>
                        </tr>
                        </thead>

                        {inputList.map((x, i) => {
                            return (
                                <>
                                    <tr>
                                        <td>{x.name}
                                            <input type="hidden" id={"expensetype_"+x.id} name="expensetype" value={x.id} />
                                        </td>
                                        <td>
                                            <input
                                                className="ant-input"
                                                name="amount"
                                                id={"amount_"+x.id}
                                                placeholder="Amount"
                                                value={x.amount}
                                                onChange={e => handleInputChange(e, i)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                className="ant-input"
                                                name="note"
                                                id={"note_"+x.id}
                                                placeholder="Enter a note"
                                                value={x.note}
                                                onChange={e => handleInputChange(e, i)}
                                            />
                                        </td>
                                        <td>
                                            <Upload data= {
                                                {
                                                    file : formData,
                                                    type : "districtexpense",
                                                    file_id : fileUID,
                                                    row_id : x.id,
                                                    line_index : i
                                                }
                                            } {...uplod}>
                                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                            </Upload>
                                        </td>
                                    </tr>
                                </>
                            );
                        })}

                    </table>

                </Row>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center', marginTop : '30px'}}>
                        <Button id="submitbutton" type="primary" htmlType="submit">
                            Submit
                        </Button>
                        <Button type="info" onClick={() => history.push(redirectto)} htmlType="back">Cancel</Button>
                    </Col>
                </Row>

            </Form>


        </Card>
    );
};

export default NewExpenses;