import React, { useState } from "react";
import { Row, Col, Form, Card, Input, Button, notification, Divider, Checkbox, Select, Upload, DatePicker } from 'antd';
import jsonfile from './employee_phone.json';
import { useHistory } from "react-router-dom";
import RemoteJson from "../../../../../util/gerremotedata";
import InputFieldV2 from "../../../../../util/InputFieldV2";
import { CisUI } from "../../../../../util/CISUI";
import Config from "../../../../../util/config";
import axios from "axios";
import { UploadOutlined } from "@ant-design/icons";

const EmployeePhone = (props) => {

    const history = useHistory();
    const { form } = Form.useForm();
    const [isShow, setIsShow] = useState(false);


    const options = [
        {
            label: 'Enrollment',
            value: 'Enrollment',
        },
        {
            label: 'Change / Replace Sim',
            value: 'Change / Replace Sim',
        },
        {
            label: 'Change /upgrade Phone',
            value: 'Change /upgrade Phone',
        },
    ];

    const hrOptions = [
        {
            label: "Approved",
            value: "Approved"
        },
        {
            label: "Pending",
            value: "Pending"
        },
        {
            label: "Declined",
            value: "Declined"
        }
    ]

    const handleResidence = (e) => {
        e.target.checked === true ? setIsShow(true) : setIsShow(false);
    }

    const userID = props.editEmpId;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {
        userData = RemoteJson(jsonfile.urls.view + "/" + userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + jsonfile.urls.list;
        // props.empid(userID);
    }

    const onFinish = (values) => {
        // console.log("Input Data : " + values);
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    props.empid(res.data.emp_id)
                    // history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    }

    return (
        <Card title={isEdit === 1 ? jsonfile.edittitle : jsonfile.addtitle}
            extra={
                <>
                    {
                        isEdit === 1
                            ? CisUI().addAction(redirectto)
                            : CisUI().addAction(redirectto)
                    }
                </>
            }
        >
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                <Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <p style={{ margin: "10px 0" }}>Four (4) Phone Lines For All Full Time Employees, His/her Spouse And Two (2) Biological Children Between The
                            Ages Of 11-21 Years</p>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item>
                            <Checkbox.Group options={options} onChange={() => { }} />
                        </Form.Item>
                    </Col>
                </Row>

                {InputFieldV2(jsonfile.input, userData, isEdit, props)}

                <Row>
                    <Col span={24}>
                        <Form.Item>
                            <Checkbox.Group options={hrOptions} onChange={() => { }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col lg={8} md={12} sm={24} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name="name"
                                label="Name"
                            >
                                <Input placeholder="Enter name" />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                className="form-input"
                                name="file"
                                label="Signature"
                            >
                                <Upload>
                                    <Button>
                                        <UploadOutlined /> Click to upload
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name="date"
                                label="Date"
                            >
                                <DatePicker
                                    allowClear
                                    format={CisUI().dateFormat}
                                    className="gx-mb-3 gx-w-100"
                                    placeholder="YYYY-MM-DD"
                                />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{ textAlign: 'center' }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Col>
                </Row>

            </Form>
        </Card>
    );
}

export default EmployeePhone;