import {Form, Row, Col, Input, Button, Card, Upload, message, Collapse, Select, DatePicker, Checkbox, notification, Spin} from 'antd';
import React, {useState, useEffect} from "react";
import jsonfile from './journal.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import SelectData from "../../../../util/selectdata";
import moment from "moment";
import StaticSelectData from "../../../../util/StaticSelectData";
import StaticSelectedData from "../../../../util/StaticSelectData";
import { UploadOutlined } from '@ant-design/icons';

const {Option} = Select;

const NewJournal = (props) => {

    const formRef = React.createRef();

    const [loading, setLoading] = useState([]);

    const [state, setState] = useState({
        data: [],
        rows: []
    })

    const [loaded, setLoaded] = useState(false);
    const [vendor, setVendor] = useState([]);

    const [customer, setCustomer] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [accounts, setAccount] = useState([]);
    const [payable, setPayable] = useState([]);
    const [fund, setFund] = useState([]);
    const [bankaccount, setBankAccount] = useState([]);
    const [expense, setExpense] = useState([]);
    const [project, setProject] = useState([]);
    const [cashaccount, setCashAccount] = useState([]);
    const [isCash, setIsCash] = useState(true);

    const [benificiariesData, setBenificiaryData] = useState([]);

    const [podata, setPOdata] = useState([]);
    const [ttl, setTTL] = useState(
        {
            totalDebit: 0,
            totalCredit: 0
        }
    );

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + "accounts/journlalist";

    if (isEdit === 1) {
        //userData = RemoteJson();
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + "accounts/journlalist";
    }


    const classifications = SelectData("classifications");
    const Companies = SelectData("company/companylists");

    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {
        setLoading({loading: true})
        values["items"] = state.rows;
        // console.log(JSON.stringify(values));
        // console.log("Debit: "+ttl.totalDebit);
        // console.log("Credit: "+ttl.totalCredit);


        if(ttl.totalDebit === ttl.totalCredit && state.rows.length > 1) {

            if(ttl.totalDebit >0 && ttl.totalCredit > 0) {
                document.getElementById("loader").style.display = "block";
                axios.post(Config.apiserver + endpoint, values)
                    .then(res => {
                        console.log(res)
                        document.getElementById("loader").style.display = "none";
                        if (res.data.status === 1) {
                            notification.success({
                                message: 'Success',
                                type: "success",
                                description: res.data.msg
                            });
                            history.push(redirectto);
                        } else {
                            notification.warning({
                                message: 'Alert',
                                type: "warning",
                                description: res.data.msg
                            });
                        }
                        setLoading({loading: false});
                    })
                    .catch(errors => {
                        // console.log(errors.response.data.errors);
                        setLoading({loading: false});
                        document.getElementById("loader").style.display = "none";
                    });
            }
            else {
                notification.warning({
                    message: 'Alert',
                    type: "warning",
                    description: "Minimum item should be 2, total debit side and total credit side should be same"
                });
            }
        }
        else {
            notification.warning({
                message: 'Alert',
                type: "warning",
                description: "Minimum item should be 2, total debit side and total credit side should be same"
            });
        }


    };


    const onCompanyChange = value => {
        console.log("selected : " + value);
        let fl = Companies.filter(item => item.id === value);
        if (fl.length > 0) {
            const company = fl[0];
            axios.get("billentrydataall?com_id=" + value)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.status === 1) {
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setBankAccount(res.data.bank);
                        setExpense(res.data.expense);
                        setEmployee(res.data.employee);
                        setCustomer(res.data.customer);
                        setProject(res.data.project);
                        setCashAccount(res.data.cashaccount);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            formRef.current.setFieldsValue({
                vendor: "",
                accounts_payable: "",
                accounts_id: ""
            });
        }
    }
    const comID = CisUI().getUserInfo('com_id');

    useEffect(() => {
        if (comID > 0) {
            onCompanyChangeForComapny(comID);
        }
    }, []);

    const onCompanyChangeForComapny = value => {
        console.log("selected : " + value);
        if (value > 0) {
            const company = value;
            axios.get("billentrydataall?com_id=" + value)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.status === 1) {
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setBankAccount(res.data.bank);
                        setExpense(res.data.expense);
                        setEmployee(res.data.employee);
                        setCustomer(res.data.customer);
                        setProject(res.data.project);
                        setCashAccount(res.data.cashaccount);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            formRef.current.setFieldsValue({
                vendor: "",
                accounts_payable: "",
                accounts_id: ""
            });
        }
    }

    const ItemCellChangeAccount = idx => e => {
        console.log("selectedValued:" + e);
        console.log("selectedValuedID:" + idx);

        const rows = [...state.rows];
        rows[idx] = {
            account_id: e,
            description: rows[idx].description,
            bank_account_id: rows[idx].bank_account_id,
            fund_program: rows[idx].fund_program,
            func_expense_id : rows[idx].func_expense_id,
            restriction_type : rows[idx].restriction_type,
            debit: rows[idx].debit,
            credit: rows[idx].credit,
            classification : rows[idx].classification,
            files : rows[idx].files,
            file_location : rows[idx].file_location
        };

        setState({
            data : state.data,
            rows: rows
        });

    }

    const ItemCellChange = idx => e => {
        const {name, value} = e.target;
        const rows = [...state.rows];
        // console.log("selectedValued:" + e);
        console.log("selectedValue:" + value);
        if (name === "account_id") {
            rows[idx] = {
                account_id: value,
                description: rows[idx].description,
                bank_account_id: rows[idx].bank_account_id,
                fund_program: rows[idx].fund_program,
                func_expense_id : rows[idx].func_expense_id,
                restriction_type : rows[idx].restriction_type,
                debit: rows[idx].debit,
                credit: rows[idx].credit,
                classification : rows[idx].classification,
                files : rows[idx].files,
                file_location : rows[idx].file_location
            };
        } else if (name === "description") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                description: value,
                bank_account_id: rows[idx].bank_account_id,
                fund_program: rows[idx].fund_program,
                func_expense_id : rows[idx].func_expense_id,
                restriction_type : rows[idx].restriction_type,
                debit: rows[idx].debit,
                credit: rows[idx].credit,
                classification : rows[idx].classification,
                files : rows[idx].files,
                file_location : rows[idx].file_location
            };
        } else if (name === "fund_program") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                description: rows[idx].description,
                bank_account_id : rows[idx].bank_account_id,
                fund_program: value,
                func_expense_id : rows[idx].func_expense_id,
                restriction_type : rows[idx].restriction_type,
                debit: rows[idx].debit,
                credit: rows[idx].credit,
                classification : rows[idx].classification,
                files : rows[idx].files,
                file_location : rows[idx].file_location
            };
        } else if (name === "func_expense_id") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                description: rows[idx].description,
                bank_account_id: rows[idx].bank_account_id,
                fund_program: rows[idx].fund_program,
                restriction_type: rows[idx].restriction_type,
                func_expense_id : value,
                debit: rows[idx].debit,
                credit: rows[idx].credit,
                classification : rows[idx].classification,
                files : rows[idx].files,
                file_location : rows[idx].file_location
            };
        } else if (name === "debit") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                description: rows[idx].description,
                bank_account_id: rows[idx].bank_account_id,
                fund_program: rows[idx].fund_program,
                func_expense_id : rows[idx].func_expense_id,
                restriction_type : rows[idx].restriction_type,
                debit: value,
                credit: rows[idx].credit,
                classification : rows[idx].classification,
                files : rows[idx].files,
                file_location : rows[idx].file_location
            };
        }
        else if (name === "credit") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                description: rows[idx].description,
                bank_account_id: rows[idx].bank_account_id,
                fund_program: rows[idx].fund_program,
                func_expense_id : rows[idx].func_expense_id,
                restriction_type : rows[idx].restriction_type,
                debit: rows[idx].debit,
                credit: value,
                classification : rows[idx].classification,
                files : rows[idx].files,
                file_location : rows[idx].file_location
            };
        }
        else if (name === "bank_account_id") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                description: rows[idx].description,
                bank_account_id: value,
                fund_program: rows[idx].fund_program,
                func_expense_id : rows[idx].func_expense_id,
                restriction_type : rows[idx].restriction_type,
                debit: rows[idx].debit,
                credit: rows[idx].credit,
                classification : rows[idx].classification,
                files : rows[idx].files,
                file_location : rows[idx].file_location
            };
        }
        else if (name === "restriction_type") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                description: rows[idx].description,
                bank_account_id: rows[idx].bank_account_id,
                fund_program: rows[idx].fund_program,
                func_expense_id : rows[idx].func_expense_id,
                restriction_type : value,
                debit: rows[idx].debit,
                credit: rows[idx].credit,
                classification : rows[idx].classification,
                files : rows[idx].files,
                file_location : rows[idx].file_location
            };
        }
        else if (name === "classification") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                description: rows[idx].description,
                bank_account_id: rows[idx].bank_account_id,
                fund_program: rows[idx].fund_program,
                func_expense_id : rows[idx].func_expense_id,
                restriction_type : rows[idx].restriction_type,
                debit: rows[idx].debit,
                credit: rows[idx].credit,
                classification : value,
                files : rows[idx].files,
                file_location : rows[idx].file_location
            };
        }

        setState({
            data : state.data,
            rows: rows
        });

        // console.log(rows);

        let tDebit = 0;
        let ttlCredit = 0;
        rows.map((item, idx) => {
            if(parseFloat(item.debit) > 0) {
                tDebit += parseFloat(item.debit);
            }
            if(parseFloat(item.credit) > 0) {
                ttlCredit += parseFloat(item.credit);
            }
        });
        console.log("D: "+parseFloat(tDebit).toFixed(2));
        console.log("C: "+parseFloat(ttlCredit).toFixed(2));
        setTTL({
            totalDebit : parseFloat(tDebit).toFixed(2),
            totalCredit : parseFloat(ttlCredit).toFixed(2)
        });

    };

    const handleAddRow = () => {
        const item = {
            account_id: 0,
            description: '',
            bank_account_id: 0,
            fund_program: 0,
            func_expense_id : 0,
            restriction_type : null,
            debit: 0,
            credit: 0,
            classification : null,
            files : [],
            file_location : []
        };
        setState({
            data: state.data,
            rows: [...state.rows, item]
        });
    };

    useEffect(() => {
        handleAddRow();
    }, []);

    const handleRemoveRow = idx => e => {
        let filteredArray = state.rows.filter((item, index) => index !== idx)
        setState({
            data: state.data,
            rows: filteredArray
        });
    };

    const onTabPress = () => {
        if (window.event.keyCode === 9) {
            window.event.preventDefault();
            alert("Execute ajax call after tab pressed");
        }
    }

    const token = localStorage.getItem('token');
    let formData = new FormData();
    let fileUID = "";
    const uplod = {
        name: 'file',
        action: Config.apiserver+"fileupload",
        method : 'post',
        headers: {
            'Authorization': token,
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
            if (!isJpgOrPng) {
                message.error('You can only upload jpg, png,and pdf file only!');
            }
            return isJpgOrPng;
        },
        onChange(info) {
            formData.append('file', info.file);
            fileUID = info.file.uid;
            console.log("index : "+info.file.uid);
            console.log(JSON.stringify(info));
            if(info.file.status === "done") {
                if (info.file.xhr.status === 200) {
                    console.log("ServerResponse : "+info.file.response);
                    const data = info.file.response;
                    if(data.status === 1) {
                        const list = [...state.rows];
                        let files = list[data.line_index].files || [];
                        let file_location = list[data.line_index].file_location || [];
                        files.push(data.data.id);
                        file_location.push(data.data.location);
                        list[data.line_index].files = files;
                        list[data.line_index].file_location = file_location;
                        setState({
                            data : state.data,
                            rows: list
                        });
                        console.log("Files : "+JSON.stringify(list[data.line_index]));
                        message.success(`${info.file.name} file uploaded successfully`);
                    }

                } else {
                    message.error(`${info.file.name} file upload failed.`);
                }
            }
        }
    }


    const formField = (

        <>
            <div className="row">
                {
                    comID > 0 ? <></> :

                        <div className="col-md-4">
                            <Form.Item
                                name="com_id"
                                label="Company"
                                rules={[
                                    {
                                        required: true,
                                        message: "Select an company"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select an company"
                                    optionFilterProp="children"
                                    onChange={onCompanyChange}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {
                                        Companies.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                }

                <div className="col-md-4">

                    <Form.Item
                        name="thread"
                        label="Entry No"
                        initialValue={"J"+Math.floor(Date.now() / 1000)}
                        rules={[
                            {
                                required: true,
                                message: "Entry number is required"
                            },
                        ]}
                    >
                        <Input readOnly placeholder="Enter a number"/>
                    </Form.Item>

                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="date"
                        label="Date"
                        initialValue={moment()}
                        rules={[
                            {
                                required: true,
                                message: "date"
                            },
                        ]}
                    >
                        <DatePicker format={CisUI().dateFormat} placeholder="Date"/>
                    </Form.Item>
                </div>

            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: "right"}}>
                            <a className="btn btn-primary" onClick={handleAddRow}>Add Lines</a>
                        </div>
                    </div>
                    <table id="itemtable" className="ptable table table-bordered">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Account</th>
                            <th>Item Description</th>
                            <th>Fund Program</th>
                            <th>Functional Expense</th>
                            <th>Restriction Type</th>
                            <th>Classification</th>
                            <th>Debit</th>
                            <th>Credit</th>
                            <th>Attachment</th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.rows.map((item, idx) => (
                            <tr id="addr0" key={idx}>
                                <td style={{width: '30px'}}><a onClick={handleRemoveRow(idx)}
                                                               className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a></td>
                                <td>
                                    <Select
                                        showSearch
                                        placeholder="Select an account"
                                        optionFilterProp="children"
                                        style={{width : '100%'}}
                                        onChange={ItemCellChangeAccount(idx)}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            accounts.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                    {/*<select name="account_id" onChange={ItemCellChange(idx)} className="form-control select2" >*/}
                                    {/*    <option value="0">--Select--</option>*/}
                                    {/*    {*/}
                                    {/*        accounts.map((items, index) =>*/}
                                    {/*            <option value={items.id}>{items.name}</option>*/}
                                    {/*        )*/}
                                    {/*    }*/}
                                    {/*</select>*/}
                                </td>
                                <td style={{width: '300px'}}><input className="form-control text-right"
                                                                    style={{textAlign: 'left'}}
                                                                    placeholder="Description" name="description"
                                                                    value={item.description}
                                                                    onChange={ItemCellChange(idx)}/></td>
                                <td style={{width: '150px'}}>
                                    <select name="fund_program" onChange={ItemCellChange(idx)} className="form-control" >
                                        <option value="0">--Select--</option>
                                        {
                                            fund.map((items, index) =>
                                                <option value={items.id}>{items.name}</option>
                                            )
                                        }
                                    </select>
                                </td>
                                <td style={{width: '190px'}}>
                                    <select name="func_expense_id" onChange={ItemCellChange(idx)} className="form-control" >
                                        <option value="0">--Select--</option>
                                        {
                                            expense.map((items, index) =>
                                                <option value={items.id}>{items.name}</option>
                                            )
                                        }
                                    </select>
                                </td>
                                <td style={{width: '160px'}}>
                                    <select name="restriction_type" required onChange={ItemCellChange(idx)} className="form-control" >
                                        <option value="0">--Select--</option>
                                        {
                                            StaticSelectData("restrictiontype").map((items, index) =>
                                                <option value={items.id}>{items.name}</option>
                                            )
                                        }
                                    </select>
                                </td>
                                <td>
                                    <select name="classification" required onChange={ItemCellChange(idx)} className="form-control" >
                                        <option value="0">--Select--</option>
                                        {
                                            classifications.map((items, index) =>
                                                <option value={items.id}>{items.name}</option>
                                            )
                                        }
                                    </select>
                                </td>
                                <td style={{width: '130px'}}>
                                    <input className="form-control text-right"
                                                                   style={{textAlign: 'right'}} name="debit"
                                                                   value={item.debit}
                                                                   onChange={ItemCellChange(idx)}/></td>
                                <td style={{width: '130px'}}>
                                    <input className="form-control text-right"
                                                                   style={{textAlign: 'right'}} name="credit"
                                                                   value={item.credit}
                                                                   onKeyPress={onTabPress}
                                                                   onChange={ItemCellChange(idx)}/></td>
                                <td style={{width: '120px'}}>
                                    <Upload data= {
                                        {
                                            file : formData,
                                            type : "journal",
                                            file_id : fileUID,
                                            row_id : item.id,
                                            line_index : idx
                                        }
                                    } {...uplod}>
                                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                    </Upload>
                                </td>

                            </tr>
                        ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={7}>Total = </th>
                                <th style={{textAlign: "right"}}>{CisUI().getCurrencyFormated1(ttl.totalDebit)}</th>
                                <th style={{textAlign: "right"}}>{CisUI().getCurrencyFormated1(ttl.totalCredit)}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>
    )


    return (
        <Card title={Title}>
            {
                isEdit === 1
                    ? CisUI().addAction('../../accounts/journlalist')
                    : CisUI().addAction('../accounts/journlalist')
            }
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                ref={formRef}
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{remember: true}}
            >
                {
                    formField
                }

                <div className="row">
                    <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                        <Button id="submitbutton" type="primary" htmlType="submit">
                            Save
                        </Button>
                        <a className="ant-btn ant-alert-info" onClick={() => history.goBack()} >Cancel</a>
                    </div>
                </div>

            </Form>
        </Card>
    );
};

export default NewJournal;