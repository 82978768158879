import React, {useState, useEffect} from "react";
import {Form, Input, DatePicker, Card, Select, notification, Button} from 'antd';
import jsonfile from './billpayment.json';
import {CisUI} from '../../../../util/CISUI';
import InputFields from '../../../../util/InputField';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import moment from "moment";
import 'moment/locale/es-us';
import RemoteJson from "../../../../util/gerremotedata";

const {Option} = Select;
const queryString = require('query-string');

const BillPaymentEdit = (props) => {


    const [state, setState] = useState([]);
    const [payments, setPayment] = useState([]);
    const [paymentmethod, setPaymentmethod] = useState([]);
    const [bankaccounts, setBankAccounts] = useState([]);
    const [cashaccounts, setCashAccounts] = useState([]);
    const [isCash, setIsCash] = useState(false);

    const userID = props.match.params.id;
    let userData = "";
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 1;
    if(userID > 0) {
        isEdit = 1;
    }

    // userData = RemoteJson(jsonfile.urls.view+"/"+userID);
    // console.log(userData)
    let Title = "Bill Payment Edit";
    let endpoint = jsonfile.urls.add;
    let redirectto = "../../"+jsonfile.urls.list;



    const [form] = Form.useForm();
    const history = useHistory();

    const getEditData = () => {
        document.getElementById("loader").style.display = "block";
        axios.get(Config.apiserver + "vendor/paymentdetails/" + userID)
            .then((res) => {
                console.log(res.data);
                if(res.data.status === 1) {
                    const data = res.data.data;
                    setState(data);
                    setPaymentmethod(res.data.paymentMethod);
                    setBankAccounts(res.data.banakAccounts);
                    setCashAccounts(res.data.cashaccounts);
                    setPayment(res.data.payment);

                    form.setFieldsValue({
                        date : moment(data.date,"YYYY-MM-DD"),
                        title : data.title,
                        payment_method : data.type,
                        bank_account_id : data.bank_account_id,
                        reference_no : data.reference,
                        amount : data.amount,
                    });
                }
                document.getElementById("loader").style.display = "none";

            })
            .catch(error => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            })

    }

    useEffect(() => {
        getEditData()
    }, []);

    const onFinish = (values) => {
        // setLoading({loading:true})
        values["bill_id"] = state.bill_id;
        values["bill_no"] = state.bill_no;
        // console.log("Input Data : "+values);
        // console.log(values);
        if (values['amount'] <= state.bill_amount) {
            document.getElementById("loader").style.display = "block";
            axios.post(Config.apiserver + jsonfile.urls.edit+"/"+userID, values, CisUI().HeaderRequest)
                .then(res => {
                    console.log(res)
                    if (res.data.status === 1) {
                        notification.success({
                            message: 'Success',
                            type: "success",
                            description: res.data.msg
                        });
                        //window.location.reload();
                    } else {
                        notification.warning({
                            message: 'Alert',
                            type: "warning",
                            description: res.data.msg
                        });
                    }
                    document.getElementById("loader").style.display = "none";
                })
                .catch(errors => {
                    document.getElementById("loader").style.display = "none";
                    console.log(errors);
                });
        } else {
            notification.warning({
                message: 'Alert',
                type: "warning",
                description: "Amount should not be more than actual bill amount "+CisUI().getCurrencyFormated1(state.bill_amount)
            });
        }
    };


    return (
        <Card title={Title} extra={ CisUI().addAction('../../'+jsonfile.urls.list) }>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                onFinish={onFinish}
                className="ant-advanced-search-form"
                initialValues={{ remember: true }}
            >
                <div className="row">
                    <div className="col-md-4">
                        <Form.Item
                            name="date"
                            label="Date"
                            initialValue={moment()}
                            rules={[
                                {
                                    required: true,
                                    message: "Select a date"
                                },
                            ]}
                        >
                            <DatePicker format={CisUI().dateFormat} className="gx-mb-3 gx-w-100" placeholder="Date"/>
                        </Form.Item>
                    </div>

                    <div className="col-md-4">
                        <Form.Item
                            name="title"
                            label="Title"
                            rules={[
                                {
                                    required: true,
                                    message: "Please Enter Title"
                                },
                            ]}
                        >
                            <Input className="gx-mb-3 gx-w-100" placeholder="Title"/>
                        </Form.Item>
                    </div>

                    {/*<div className="col-md-4">*/}
                    {/*    <Form.Item*/}
                    {/*        name="account_id"*/}
                    {/*        label="Account"*/}
                    {/*        rules={[*/}
                    {/*            {*/}
                    {/*                required: true,*/}
                    {/*                message: "Enter reference number"*/}
                    {/*            },*/}
                    {/*        ]}*/}
                    {/*    >*/}
                    {/*        <Select*/}
                    {/*            showSearch*/}
                    {/*            placeholder="Select a account"*/}
                    {/*            optionFilterProp="children"*/}
                    {/*            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}*/}
                    {/*        >*/}
                    {/*            {*/}
                    {/*                cashaccounts.map((items, index) =>*/}
                    {/*                    <Option key={++index} value={items.id}>{items.name}</Option>*/}

                    {/*            )}*/}
                    {/*        </Select>*/}
                    {/*    </Form.Item>*/}
                    {/*</div>*/}

                    <div className="col-md-4">
                        <Form.Item
                            name="payment_method"
                            label="Payment Method"
                            rules={[
                                {
                                    required: true,
                                    message: "Select payment method"
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select a payment method"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {
                                    paymentmethod.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name}</Option>

                                )}
                            </Select>
                        </Form.Item>
                    </div>

                    <div className="col-md-4">
                        <Form.Item
                            name="bank_account_id"
                            label="Bank Account / Cash Account"
                            rules={[
                                {
                                    required: true,
                                    message: "Select bank account"
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select a bank account"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {
                                    isCash ?
                                        cashaccounts.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name})</Option>
                                        )
                                        :
                                        bankaccounts.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.account_no}-{items.account_name}({items.bank_name}</Option>
                                        )
                                }
                            </Select>
                        </Form.Item>
                    </div>

                    <div className="col-md-4">
                        <Form.Item
                            name="reference_no"
                            label="Reference No"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter reference no"
                                },
                            ]}
                        >
                            <Input className="gx-mb-3 gx-w-100" placeholder="Enter a reference no"/>
                        </Form.Item>
                    </div>

                    <div className="col-md-4">
                        <Form.Item
                            name="amount"
                            label="Amount"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter amount"
                                },
                            ]}
                        >
                            <Input className="gx-mb-3 gx-w-100" readOnly={true} placeholder="Enter a amount"/>
                        </Form.Item>
                    </div>

                </div>

                <div className="row">
                    <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                        <Button id="submitbutton" type="primary" htmlType="submit">
                            Save
                        </Button>
                        <Button type="info" onClick={() => history.push(redirectto)} htmlType="back">Cancel</Button>
                    </div>
                </div>

            </Form>
        </Card>
    );
};

export default BillPaymentEdit;