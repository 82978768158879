import React from "react";
import {Route, Switch} from "react-router-dom";

import EmployeeList from "../../containers/yotta/hrm/employee/EmployeeList";
import NewEmployee from "../../containers/yotta/hrm/employee/NewEmployee";
import DepartmentList from "../../containers/yotta/hrm/department/DepartmentList";
import NewDepartment from "../../containers/yotta/hrm/department/NewDepartment";
import DesignationList from "../../containers/yotta/hrm/designation/DesignationList";
import NewDesignation from "../../containers/yotta/hrm/designation/NewDesignation";
import EmploymentTypeList from "../../containers/yotta/hrm/employment_type/EmploymentTypeList";
import NewEmploymentType from "../../containers/yotta/hrm/employment_type/NewEmploymentType";
import ShiftList from "../../containers/yotta/hrm/shift/ShiftList";
import NewShift from "../../containers/yotta/hrm/shift/NewShift";
import EditShift from "../../containers/yotta/hrm/shift/EditShift";
import PayrollList from "../../containers/yotta/hrm/payroll/PayrollList";
import NewPayroll from "../../containers/yotta/hrm/payroll/NewPayroll";
import SalaryList from "../../containers/yotta/hrm/salary/SalaryList";
import NewSalary from "../../containers/yotta/hrm/salary/NewSalary";
import NewVaction from "../../containers/yotta/hrm/vacation/NewVaction";
import NewExpenseClaim from "../../containers/yotta/hrm/expenseclaim/newexpenseclaim";
import New401KForm from "../../containers/yotta/hrm/401k/newform";
import FourOKkList from "../../containers/yotta/hrm/401k/list";
import VacationList from "../../containers/yotta/hrm/vacation/VacationList";
import HRExpneseList from "../../containers/yotta/hrm/expenseclaim/expenselsit";
import HRExpenseDetails from "../../containers/yotta/hrm/expenseclaim/expdetails";
import Detail401KForm from "../../containers/yotta/hrm/401k/details";
import Pendingvaction from "../../containers/yotta/hrm/vacation/pendingvaction";
import ChangeAddress from "../../containers/yotta/hrm/reqform/ChangeAddress/ChangeAddress";
import DepositAuthorization from "../../containers/yotta/hrm/reqform/DepositAuthorization/DepositAuthorization";
import EmployeeExit from "../../containers/yotta/hrm/reqform/EmployeeExitForm/EmployeeExit";
import EmployeePhone from "../../containers/yotta/hrm/reqform/EmployeePhone/EmployeePhone";
import EvaluationForm from "../../containers/yotta/hrm/reqform/EvaluationForm/EvaluationForm";
import ITAsset from "../../containers/yotta/hrm/reqform/ITAsset/ITAsset";
import RecruitmentRequisition from "../../containers/yotta/hrm/reqform/RecruitmentRequisition/RecruitmentRequisition";
import TrainingRequestForm from "../../containers/yotta/hrm/reqform/TrainingRequest/TrainingRequestForm";
import VacationDetails from "../../containers/yotta/hrm/vacation/vacationdetails";
import PendingHRExpneseList from "../../containers/yotta/hrm/expenseclaim/pendingexpenseclaim";
import SysChangeRequest from "../../containers/yotta/hrm/sysrequest/syschangerequest";

const HRM = ({match}) => (
    <Switch>
        <Route path={`${match.url}/empaccounts`} component={EmployeeList}/>
        <Route path={`${match.url}/newempaccount`} component={NewEmployee}/>
        <Route path={`${match.url}/empaccountedit/:id`} component={NewEmployee}/>
        <Route path={`${match.url}/departments`} component={DepartmentList}/>
        <Route path={`${match.url}/newdepartment`} component={NewDepartment}/>
        <Route path={`${match.url}/departmentedit/:id`} component={NewDepartment}/>
        <Route path={`${match.url}/designation`} component={DesignationList}/>
        <Route path={`${match.url}/newdesignation`} component={NewDesignation}/>
        <Route path={`${match.url}/designationedit/:id`} component={NewDesignation}/>
        <Route path={`${match.url}/employmenttype`} component={EmploymentTypeList}/>
        <Route path={`${match.url}/newemploymenttype`} component={NewEmploymentType}/>
        <Route path={`${match.url}/employmenttypeedit/:id`} component={NewEmploymentType}/>
        <Route path={`${match.url}/shift`} component={ShiftList}/>
        <Route path={`${match.url}/newshift`} component={NewShift}/>
        <Route path={`${match.url}/shiftedit/:id`} component={EditShift}/>
        <Route path={`${match.url}/payroll`} component={PayrollList}/>
        <Route path={`${match.url}/newpayroll`} component={NewPayroll}/>
        <Route path={`${match.url}/payrolledit/:id`} component={NewPayroll}/>
        <Route path={`${match.url}/salarylist`} component={SalaryList}/>
        <Route path={`${match.url}/newsalary`} component={NewSalary}/>
        <Route path={`${match.url}/vacationrequest`} component={NewVaction}/>
        <Route path={`${match.url}/myvactions`} component={VacationList}/>
        <Route path={`${match.url}/pendingvaction`} component={Pendingvaction}/>
        <Route path={`${match.url}/vacationedit/:id`} component={NewVaction}/>
        <Route path={`${match.url}/vacationdetail/:id`} component={VacationDetails}/>
        <Route path={`${match.url}/salaryedit/:id`} component={NewSalary}/>
        <Route path={`${match.url}/newexpenseclaim`} component={NewExpenseClaim}/>
        <Route path={`${match.url}/expenseclaim`} component={HRExpneseList}/>
        <Route path={`${match.url}/pendingexpenseclaim`} component={PendingHRExpneseList}/>
        <Route path={`${match.url}/expdetails/:id`} component={HRExpenseDetails}/>
        <Route path={`${match.url}/expedit/:id`} component={NewExpenseClaim}/>
        <Route path={`${match.url}/new401kform`} component={New401KForm}/>
        <Route path={`${match.url}/401kformlist`} component={FourOKkList}/>
        <Route path={`${match.url}/401kformedit/:id`} component={New401KForm}/>
        <Route path={`${match.url}/401kdetail/:id`} component={Detail401KForm}/>

        <Route path={`${match.url}/addresschange`} component={ChangeAddress}/>
        <Route path={`${match.url}/dpsauthorization`} component={DepositAuthorization}/>
        <Route path={`${match.url}/empexit`} component={EmployeeExit}/>
        <Route path={`${match.url}/empinfo`} component={NewEmployee}/>
        <Route path={`${match.url}/empphone`} component={EmployeePhone}/>
        <Route path={`${match.url}/evaluation`} component={EvaluationForm}/>
        <Route path={`${match.url}/itassets`} component={ITAsset}/>
        <Route path={`${match.url}/recreq`} component={RecruitmentRequisition}/>
        <Route path={`${match.url}/trainingreq`} component={TrainingRequestForm}/>
        <Route path={`${match.url}/syschangerequest`} component={SysChangeRequest}/>
    </Switch>
);

export default HRM;
