import React, {useState, useEffect} from "react";
import {Form, Row, Col, Input, Button, Card, Select, Checkbox, notification, Spin, Breadcrumb} from 'antd';
import jsonfile from './user.json'
import {CisUI} from '../../../../util/CISUI'
import InputFields from '../../../../util/InputField'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {Link, useHistory} from "react-router-dom";
import SelectData from "../../../../util/selectdata";
import IntlMessages from "../../../../util/IntlMessages";
import {HomeOutlined} from "@ant-design/icons";
import {AddOutlined} from "@material-ui/icons";

const { Option } = Select;

const NewUser = (props) => {

    const formRef = React.createRef();
    const [form] = Form.useForm();
    // const [loading, setLoading] = useState([]);
    const [user, setUser] = useState([]);
    const [roles, setRoles] = useState([]);
    // const [position, setPosition] = useState({
    //     position : null,
    //     roles : null,
    // });

    // const handleChange=(e)=> {
    //     this.setState({
    //         [e.target.name]: e.target.value
    //     })
    // }

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;

    if(userID > 0) {
        isEdit = 1;
    }

    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    let Title = jsonfile.addtitle;

    useEffect(() => {

        if(isEdit === 1 ) {
            //userData = RemoteJson(jsonfile.urls.view+"/"+userID);
            // console.log(typeof userData);
            Title = jsonfile.edittitle;
            endpoint = jsonfile.urls.edit+"/"+userID;
            redirectto = "../../"+jsonfile.urls.list;

            axios.get(Config.apiserver + jsonfile.urls.view+"/"+userID)
                .then(res => {
                    console.log(res)
                    if(res.data.status === 1) {
                        setUser(res.data.data);
                        setRoles(res.data.roles);
                    }
                })
                .catch(errors => {

                });
        }

    }, []);


    function onChange(e) {
        console.log(`checked = ${e.target.value}`);
    }


    const history = useHistory();

    const onFinish = (values) => {

        let input = document.getElementsByName("select");
        const av = [];
        //console.log(input.length+" Found ");
        for (var i = 0; i < input.length; i++) {
            av[i] = [input[i].value];
        }
        // console.log("Input Tag Data : "+av);

        // setLoading({loading:true})
        //
        // console.log(values);
        document.getElementById("loader").style.display = "block";

        axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if(res.data.status === 1) {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    const Positions = SelectData("userpositions");

    const onPositionChange = (value) => {

        axios.get(Config.apiserver + "user/allroles?usefor="+value)
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    setRoles(res.data.data);
                    form.setFieldsValue({
                        user_roles: null,
                    });
                }
            })
            .catch(errors => {

            });
    }

    return (
        <>
            <Breadcrumb separator=">" style={{padding: '10px'}}>
                <Breadcrumb.Item><Link to="/main/dashboard"><HomeOutlined/></Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to={"/"+jsonfile.urls.add}>{jsonfile.addtitle}</Link></Breadcrumb.Item>
            </Breadcrumb>

            <Card title={<IntlMessages id={Title} />} extra={
                isEdit === 1
                    ? CisUI().addAction(redirectto)
                    : CisUI().addAction(redirectto)
            }>
                {CisUI().showLoading}
                <Form
                    form={form}
                    {...CisUI().formItemLayout}
                    name="input"
                    ref={formRef}
                    autoComplete={false}
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                    initialValues={{ remember: true }}
                >

                    <Row gutter={24}>

                        <Col lg={6} md={6} sm={12} xs={24} >
                            <div id="area" className="gx-form-row0">
                                <Form.Item
                                    name="area"
                                    label={<IntlMessages id="Position"/>}
                                    initialValue={user.area}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Select level a position"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a position"
                                        optionFilterProp="children"

                                        onChange={onPositionChange}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            Positions.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>

                            </div>
                        </Col>


                        <Col lg={6} md={6} sm={12} xs={24} id="uroles" >
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="user_roles"
                                    label={<IntlMessages id="Access Level/Role"/>}
                                    autoComplete={false}
                                    initialValue={user.user_roles}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Select a Role"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select Role"
                                        optionFilterProp="children"
                                        // defaultValue={user.user_roles}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            roles.map((items, index) =>
                                                <Option key={++index} value={items.id} >{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>

                            </div>
                        </Col>

                    </Row>

                    {InputFields(jsonfile.input,user,isEdit,props, "", redirectto)}
                </Form>


            </Card>

        </>
    );
};

export default NewUser;