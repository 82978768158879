import { Button, Card, Col, Image, Modal, Row, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import SignaturePad from "react-signature-canvas";
import signatureIcon from "assets/images/signature.png";
import typeIcon from "assets/images/type-icon.svg";
import drawIcon from "assets/images/draw-icon.svg";
import TypeSignature from "./TypeSignature";
import { CloudUploadOutlined } from "@ant-design/icons";
// import { API_URL } from "util/config";
// import { COMMONURL } from "util/Routes";
// import { headers } from "util/Api";
import ImgCrop from "antd-img-crop";

let sigPad = {};
const CanvasSignature = ({ signatureData, setSignatureData }) => {
  const [signatureType, setSignatureType] = useState("type"); // draw, type, upload
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    if (signatureType === "draw") {
      setSignatureData(sigPad.getTrimmedCanvas().toDataURL("image/png"));
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setSignatureType("type");
  };
  const onClear = () => {
    if (signatureType === "draw") {
      setSignatureData(null);
      sigPad.clear();
    } else {
      setSignatureData(null);
    }
  };

  useEffect(() => {
    if (signatureType === "draw") {
      var canvas = document.querySelector(".signature_pad");
      canvas.width = 650;
    }
  }, [signatureType]);

  return (
    <>
      <Modal
        onCancel={handleCancel}
        title={signatureData ? "Signature Preview" : "Add Signature"}
        open={isModalOpen}
        width={700}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,

          <Button onClick={onClear}>Clear</Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Save
          </Button>,
        ]}
      >
        {signatureType === "draw" ? (
          <SignaturePad
            ref={(ref) => {
              sigPad = ref;
            }}
            canvasProps={{ className: "signature_pad" }}
          />
        ) : signatureType === "type" ? (
          <TypeSignature setSignatureData={setSignatureData} />
        ) : signatureData ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Image preview={false} src={signatureData} height={100} />
          </div>
        ) : (
          ""
        )}
        <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
          <Col span={8} className="draw_signature">
            <Card>
              <Image
                src={drawIcon}
                preview={false}
                className="signature_icon"
                onClick={() => setSignatureType("draw")}
              />
              <p>Draw Signature</p>
            </Card>
          </Col>
          <Col span={8} className="make_signature">
            <Card>
              <Image
                src={typeIcon}
                preview={false}
                className="signature_icon"
                onClick={() => setSignatureType("type")}
              />
              <p>Type Signature</p>
            </Card>
          </Col>
          <Col span={8} className="upload_signature">
            <Card onClick={() => setSignatureType("upload")}>
              <UploadSignature setSignatureData={setSignatureData} />
            </Card>
          </Col>
        </Row>
      </Modal>
      {/* <span>Signature:</span> */}
      <div className="signature" onClick={showModal}>
        <Image
          src={signatureData ? signatureData : signatureIcon}
          preview={false}
          className="signature_img"
        />
      </div>

      {signatureData ? (
        <div className="signature_preview">
          <Image preview={false} src={signatureData} height={50} />
          <Button onClick={onClear} type="link">
            Clear
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default CanvasSignature;

function UploadSignature({ setSignatureData }) {
  const [file, setFile] = useState([]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const handleChange = (info) => {
    getBase64(info.file.originFileObj, (imageUrl) => {
      setSignatureData(imageUrl);
    });
  };

  const beforeUpload = (event) => {
    const isJpgOrPng =
      event.type === "image/jpg" ||
      event.type === "image/jpeg" ||
      event.type === "image/png" ||
      event.type === "application/pdf";

    if (!isJpgOrPng) {
      message.error("You can only upload jpg, jpeg, png and pdf file only!");
      return false;
    }

    if (event.size / 1024 / 1024 > 2) {
      message.error("File size must be less than 2MB!");
      return false;
    }
    setFile(event);
    return true;
  };
  return (
    <>
      <ImgCrop aspectSlider rotationSlider zoomSlider showReset>
        <Upload
          maxCount={1}
          onChange={handleChange}
          disabled={false}
          // action={API_URL + COMMONURL.FILEUPLOAD}
          action={false}
          // headers={headers}
          method="POST"
          beforeUpload={beforeUpload}
          data={{
            file: file,
            source: "signature",
            file_id: file.uid,
          }}
        >
          <>
            <CloudUploadOutlined
              style={{
                color: "#50A7B3",
                fontSize: "55px",
                marginTop: "15px",
                marginBottom: "15px",
                width: '40px',
                height: '60px'
              }}
            />
            <p>Upload Signature</p>
          </>
        </Upload>
      </ImgCrop>
    </>
  );
}
