import React, {useState} from "react";
import {Form, Input, Collapse, Popconfirm, notification, Card} from 'antd';
import jsonfile from './localexpenses.json'
import {Link, useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import SelectData from "../../../../util/selectdata";
import axios from '../../../../util/Api'
import jsPDF from 'jspdf';
import moment from 'moment';
import DonationConfig from '../../../../util/DonationConfig';

const Panel = Collapse.Panel;

const DistrictSummaryReport = () => {
    const ref = React.createRef();
    const formRef = React.createRef();
    const [monthStart,setMonthStart] = useState("");
    const [reportClosed,setReportClosed] = useState(false);

    const [state, setState] = useState({
        reqURL: Config.apiserver + jsonfile.urls.monthly,
        filtervalue: '',
        reportTitle: '',
        reportData: [],
        totalContribution: 0,
        month: "",
        region: "",
        district: "",
        locals: [],
        localData: [],
        contribution: [],
        expenses: [],
        totalExpenses: 0,
        netTitheAfterExpense: 0,
        ddf: 0,
        NetTitheToDistrict: 0,
        missionOffering: 0,
        MinistryIncome: 0,
        Mckeowns: 0,
        InternalMissions: 0,
        NationalPrayer: 0,
        PENSOS: 0,
        netCashToDistrict: 0,
        district_id : 0,
        reportClosed : 0,
    });

    const history = useHistory();


     const [form] = Form.useForm();

    const onFinish = values => {
        console.log('Received values of form: ', values);
        let wkd = "";
        let wk = "";
        let dt = "";
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    wk = formRef.current.getFieldValue('date');
                    const dt = CisUI().ParseDateFormat(values[key]);
                    return `${key}=${dt}`
                } else if (key === 'month') {
                    wk = formRef.current.getFieldValue('month');
                    // console.log("Selected Week : "+ wk);
                    const mnts = CisUI().ParseDateFormatFromMonth(wk);
                    //console.log(mnts);
                    wkd = mnts;
                    return `${key}=${mnts}`
                } else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined", "");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup);
        const newURL = Config.apiserver + jsonfile.urls.monthly + "?" + qsup;
        //console.log("NEURL :" + newURL);
        //console.log("Month : " + wkd);
        // const WKR = CisUI().ParseDateFormat(wk);
        // //const reportTitle = "Between " + CisUI().DateFormat(WKR[0]) + " and " + CisUI().DateFormat(WKR[1]);
        // const reportTitle = "Monthly Report For the month of "+CisUI().getMonthTitle(wkd);

        const dts = values['month'];
        //console.log("Dt : "+dts);
        //console.log("Selected Date : "+ moment(dts).format("MMMM YYYY"));
        const WKR = CisUI().getMonthNameTimeStamp(dts);
        const reportTitle = "Monthly Report For the month of "+WKR;
        setMonthStart(moment(dts).format("YYYY-MM")+"-01");

        const url = Config.apiserver + jsonfile.urls.monthly;
        document.getElementById("loader").style.display = "block";
        axios.get(newURL)
            .then((res) => {
                // console.log(res.data);
                document.getElementById("loader").style.display = "none";
                if (res.data.status === 1) {
                    //console.log("Response : "+ res.data);
                    const data = res.data.data;
                    setState({
                        reqURL: newURL,
                        contribution: data.contribution,
                        totalContribution: data.totalContribution,
                        locals: data.locals,
                        localData: data.localData,
                        district: data.district,
                        region: data.region,
                        reportTitle: reportTitle,
                        month: WKR,
                        expenses: data.expenses,
                        totalExpenses: data.totalExpenses,
                        netTitheAfterExpense: data.netTitheAfterExpense,
                        ddf: data.ddf,
                        NetTitheToDistrict: data.NetTitheToDistrict,
                        missionOffering: data.missionOffering,
                        MinistryIncome: data.MinistryIncome,
                        Mckeowns: data.Mckeowns,
                        InternalMissions: data.InternalMissions,
                        NationalPrayer: data.NationalPrayer,
                        PENSOS: data.PENSOS,
                        netCashToDistrict: data.netCashToDistrict,
                        district_id : data.district_id,
                        reportClosed : data.reportClosed,
                    });

                    if(data.reportClosed === 1) {
                        setReportClosed(true);
                    }
                    else {
                        setReportClosed(false);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    };


    const MonthClose = () => {
        console.log("Month : "+monthStart);
        if(monthStart === "" || state.district_id === 0) {
            notification.warning({
                message: 'Alert',
                type : "error",
                description: "Month should be selected and report should be loaded"
            });
        }
        else {
            document.getElementById("loader").style.display = "block";
            axios.post(Config.apiserver + jsonfile.urls.MonthClose, {"month": monthStart, "district_id": state.district_id})
                .then((res) => {
                    document.getElementById("loader").style.display = "none";
                    if (res.data.status === 1) {
                        notification.warning({
                            message: 'Alert',
                            type : "success",
                            description: res.data.msg
                        });
                        setReportClosed(true);
                    }
                    else {
                        notification.warning({
                            message: 'Alert',
                            type : "error",
                            description: res.data.msg
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    document.getElementById("loader").style.display = "none";
                });
        }

    }

    const handleOnFilter = ({target: {name, value}}) => {
        //filterValue = value;
        console.log("filteringwith :" + value);
        setState({filtervalue: "test"});
    }

    let searchView = "";
    if (jsonfile.has_search) {
        searchView = <Card className="advance-search-card no-print">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']}>
                <Panel header="Advance Search" key="1">
                    <Form
                        key={5}
                        form={form}
                        ref={formRef}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input, "", 0, 'month')}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const print = () => {
        // window.print();
        window.open(Config.baseurl+"monthlyreportprint.html?url="+window.btoa(state.reqURL)+"&filename=district_monthly_report&data=districtmonthlyData&subtitle="+state.reportTitle+"&rptype=districtmonthlyreport","_blank");
    }


    const listAction = () =>
        (
            <div className="no-print">
                <a id="rploader" style={{width: '40px', height : '40px', display : 'none'}}>
                    <img src="/assets/images/loader.svg" style={{width: '40px', height : '40px'}} alt="loader" /> Please wait ...
                </a>
                <Link to={"../"+jsonfile.urls.add} className="btn btn-text-adjust btn-circle-md" title="Add New">
                    <i className="fas fa-plus"/>
                </Link>
                <button onClick={print} className="btn btn-export-print btn-circle-md" title="Print"><i
                    className="fas fa-print"/></button>
            </div>
        );

    const getExtra = (
        <>
            {listAction()}
        </>
    );


    const ReportData = state.reportData;
    const expenseTypes = SelectData("donation/expensetype?area=6");

    const options = {
        orientation: 'landscape',
        unit: 'in',
        format: [4, 2]
    };

    return (
        <div>
            <div className="page-break" />
            {searchView}
                <Card id="reportview" extra={getExtra}>
                {CisUI().showLoading}
                <div ref={ref}>
                    <div className="col-md-12 text-right" style={{textAlign: 'right'}}>
                        {
                            state.district_id > 0 ?
                                reportClosed === false ?
                                    <Popconfirm title="By submitting you agree that your monthly returns will be closed and entry will be disabled for this month." onConfirm={MonthClose} okText="Confirm"
                                                cancelText="Cancel">
                                        <button className="ant-btn ant-btn-primary">
                                            <i className="fas fa-unlock"/> Submit
                                        </button>
                                    </Popconfirm>
                                    :<button className="ant-btn ant-btn-primary" type="button"><i className="fa fa-lock"></i> Month Closed</button>

                                : ""
                        }
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3>District Monthly Report</h3>
                            <h2>{state.reportTitle}</h2>
                        </div>
                        <div className="col-md-6">
                            <strong>Month :</strong> {state.month}
                        </div>
                        <div className="col-md-6 text-right">
                            <table width={400}>
                                <tr>
                                    <th style={{textAlign: 'left'}}>Region</th>
                                    <td>: {state.region}</td>
                                </tr>
                                <tr>
                                    <th style={{textAlign: 'left'}}>District</th>
                                    <td>: {state.district}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <h4>Locals Income & Expenses</h4>
                    <table className="report table table-bordered">
                        <thead>
                        <tr>
                            <th>Title</th>
                            {
                                state.locals.map((items, index) =>
                                    <th style={{textAlign: 'right'}}>{items.name}</th>
                                )
                            }
                            <th style={{textAlign: 'right'}}>Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            state.localData.map((items, index) => {

                                    if (items.type === 'total') {
                                        return <tr>
                                            <td style={{fontWeight: 'bold'}}>{items.title}</td>
                                            {
                                                state.locals.map((items1, index1) =>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'bold'
                                                    }}>{CisUI().getCurrencyFormated1(items.localData[index1])}</td>
                                                )
                                            }
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'bold'
                                            }}>{CisUI().getCurrencyFormated1(items.total)}</td>
                                        </tr>
                                    } else if (items.type === 'minusBold') {
                                        return <tr>
                                            <td style={{fontWeight: 'bold'}}>{items.title}</td>
                                            {
                                                state.locals.map((items1, index1) =>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'bold'
                                                    }}>({CisUI().getCurrencyFormated1(items.localData[index1])})</td>
                                                )
                                            }
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'bold'
                                            }}>({CisUI().getCurrencyFormated1(items.total)})
                                            </td>
                                        </tr>
                                    } else if (items.type === 'minus') {
                                        return <tr>
                                            <td>{items.title}</td>
                                            {
                                                state.locals.map((items1, index1) =>
                                                    <td style={{textAlign: 'right'}}>({CisUI().getCurrencyFormated1(items.localData[index1])})</td>
                                                )
                                            }
                                            <td style={{textAlign: 'right'}}>({CisUI().getCurrencyFormated1(items.total)})</td>
                                        </tr>
                                    } else {
                                        return <tr>
                                            <td>{items.title}</td>
                                            {
                                                state.locals.map((items1, index1) =>
                                                    <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.localData[index1])}</td>
                                                )
                                            }
                                            <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.total)}</td>
                                        </tr>
                                    }

                                }
                            )
                        }
                        </tbody>
                    </table>
                    <h4>District Income & Expenses</h4>
                    <table className="report table table-bordered">
                        <thead>
                        <tr>
                            <th>Title</th>
                            <th style={{textAlign: 'right'}}>Amount</th>
                            <th style={{textAlign: 'right'}}>Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th>TOTAL CONTRIBUTION RECEIVED (Gross Tithe & Offering)</th>
                            <th style={{textAlign: 'right'}}></th>
                            <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.totalContribution)}</th>
                        </tr>

                        <tr>
                            <th>ALLOWABLE District EXPENSES</th>
                            <th style={{textAlign: 'right'}}></th>
                            <th style={{textAlign: 'right'}}></th>
                        </tr>
                        {
                            state.expenses.map((items, index) => {
                                    return <tr>
                                        <td>{items.title}</td>
                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.amount)}</td>
                                        <td style={{textAlign: 'right'}}></td>
                                    </tr>
                                }
                            )
                        }
                        <tr>
                            <th>Total Allowable District Expenses</th>
                            <th style={{textAlign: 'right'}}></th>
                            <th style={{textAlign: 'right'}}>({CisUI().getCurrencyFormated1(state.totalExpenses)})</th>
                        </tr>

                        <tr>
                            <th colSpan={2} style={{textAlign: 'right'}}>Net Tithe After District Allowable Expenses
                            </th>
                            <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.netTitheAfterExpense)}</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign: 'right'}}>Less: District Development Fund (DDF) : {DonationConfig.ddf}%</th>
                            <th style={{textAlign: 'right'}}>({CisUI().getCurrencyFormated1(state.ddf)})</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign: 'right'}}>Net Tithe to Region</th>
                            <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.NetTitheToDistrict)}</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign: 'right'}}>Add : Missions Offering</th>
                            <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.missionOffering)}</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign: 'right'}}><a href="#">Add : Ministry Income</a></th>
                            <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.MinistryIncome)}</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign: 'right'}}>Add : Mckeowns</th>
                            <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.Mckeowns)}</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign: 'right'}}>Add : Internal Missions</th>
                            <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.InternalMissions)}</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign: 'right'}}>Add : National Prayer</th>
                            <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.NationalPrayer)}</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign: 'right'}}>Add : PENSOS</th>
                            <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.PENSOS)}</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign: 'right'}}>NET CASH SENT TO REGION</th>
                            <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.netCashToDistrict)}</th>
                        </tr>


                        </tbody>
                    </table>
                </div>

            </Card>

        </div>
    );
};

export default DistrictSummaryReport;