import React, {useState, useEffect} from "react";
import {Form, Input, Button, Card, Modal, Select, notification} from 'antd';
import {Link} from "react-router-dom";
import jsonfile from './voucher.json';
import {CisUI} from '../../../../util/CISUI';
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";

var Barcode = require('react-barcode');
const {Option} = Select;

const ReachableContext = React.createContext();
const UnreachableContext = React.createContext();


const VoucherDetails = (props) => {

    const formRef = React.createRef();

    const [modal, contextHolder] = Modal.useModal();
    const [loading, setLoading] = useState([]);

    const [state, setState] = useState({
        po: {},
        items: [],
        isPOApprover: 0,
        hasBill: 0
    })


    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    const print = () => {
        return <Link to={"print/" + userID}>Print</Link>
    };

    const onFinish = () => {

    }

    let Title = jsonfile.details_title;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {

        Title = jsonfile.details_title;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + jsonfile.urls.list;
    }

    useEffect(() => {
        getPODetails();
    }, []);

    const getPODetails = () => {
        document.getElementById("loader").style.display = "block";
        axios.get(Config.apiserver + jsonfile.urls.view + "/" + userID)
            .then((res) => {
                // console.log(res.data);
                if (res.data.status === 1) {
                    setState({
                        po: res.data.podata,
                        items: res.data.items,
                        isPOApprover: res.data.isPOApprover,
                        hasBill: res.data.hasBill
                    });
                    document.getElementById("loadstatus").style.display = "none";
                }
                else {
                    document.getElementById("loadstatus").style.display = "block";
                    document.getElementById("loadstatus").innerText = "No data found";
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch((error) => {
                document.getElementById("loader").style.display = "block";
                document.getElementById("loadstatus").innerText = "No data found";
                console.log(error);
            });
    }

    const [form] = Form.useForm();
    const history = useHistory();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showModalCofirm = () => {
        setIsConfirmModalVisible(true);
    };

    const handleOkConfirm = () => {
        setIsConfirmModalVisible(false);
        PoStatusUpdate(state.po.id,1);
    };

    const handleCancelConfirm = () => {
        setIsConfirmModalVisible(false);
    };

    const showModalReject = () => {
        setIsRejectModalVisible(true);
    };

    const handleOkReject = () => {
        setIsRejectModalVisible(false);
        PoStatusUpdate(state.po.id,3);
    };

    const handleCancelReject = () => {
        setIsRejectModalVisible(false);
    };

    const handleChange = () => {

    }



    const PoStatusUpdate = (id,status) => {
        document.getElementById("loader").style.display = "block";
        axios.get("vendor/voucherstatuschange?vo_id="+id+"&status="+status)
            .then((res) => {
                document.getElementById("loader").style.display = "none";
                console.log(res.data);
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    getPODetails();
                }
                else {
                    document.getElementById("loader").style.display = "none";
                    notification.success({
                        message: 'Failed',
                        type: "danger",
                        description: res.data.msg
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    }

    var totalAmount = 0;

    const config = {
        title: 'Alert',
        content: (
            <>
                <ReachableContext.Consumer>{name => `Reachable: ${name}!`}</ReachableContext.Consumer>
                <br />
                <UnreachableContext.Consumer>{name => `Unreachable: ${name}!`}</UnreachableContext.Consumer>
            </>
        ),
    };

    const Print = () => {
      window.print();
    }

    return (
        <>
            <Card title={Title}>
                {CisUI().showLoading}
                {
                    isEdit === 1
                        ? CisUI().addAction('../../' + jsonfile.urls.list)
                        : CisUI().addAction('../' + jsonfile.urls.list)
                }

                <h3 id="loadstatus"></h3>

                {
                    state.po.id > 0 ?
                        <>
                            <div className="row">
                                <div className="col-md-12" style={{textAlign: 'center'}}>
                                    <div style={{
                                        width: '100%',
                                        background: 'white',
                                        border: '1px #ddd solid',
                                        padding: '20px'
                                    }}>
                                        <table style={{width: '100%'}}>
                                            <tr>
                                                <td style={{width: '50%', textAlign: 'left'}}>
                                                    <img alt="cop" style={{width: '120px'}} src={Config.print_logourl}/>
                                                    {/*<div>{Config.address}</div>*/}
                                                    {/*<div>{Config.city},{Config.state},{Config.zipcode}</div>*/}
                                                    {/*<div>Phone : {Config.phone}</div>*/}
                                                    {/*<div>Email : {Config.email}</div>*/}
                                                    {/*<div>Website : {Config.website}</div>*/}
                                                </td>
                                                <td style={{width: '50%', textAlign: 'right'}}>
                                                    <h2>VOUCHER</h2>
                                                    <div>Date : {CisUI().DateFormat(state.po.date)}</div>
                                                    <div>Voucher Number : {state.po.invoice_number}</div>
                                                    {
                                                        state.po.approved_user !== "" ?
                                                            <>
                                                                <div>Approved By : {state.po.approved_user}</div>
                                                                <div>Approved at : {CisUI().DateFormatWithTime(state.po.approved_at)}</div>
                                                            </>
                                                            : <></>
                                                    }
                                                    <div><Barcode height={50} value={state.po.invoice_number}/></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{width: '50%', textAlign: 'left', paddingTop: '20px'}}>
                                                    <h3>From : </h3>
                                                    <h5>{state.po.company}</h5>
                                                    <div>{state.po.com_address}</div>
                                                    <div>{state.po.com_city}, {state.po.com_state}, {state.po.com_zipcode}</div>
                                                    <div>Phone : {state.po.company_phone}</div>
                                                    <div>Email : {state.po?.company_email}</div>
                                                </td>
                                                <td style={{width: '50%', textAlign: 'left', paddingTop: '20px'}}>
                                                    <h3>To : </h3>
                                                    <h5>{state.po.vendor_name}</h5>
                                                    <div>{state.po.vendor_address}</div>
                                                    <div>{state.po.vendor_city}, {state.po.vendor_state}, {state.po.vendor_zipcode}</div>
                                                    <div>Contact Person : {state.po.vendor_contact}</div>
                                                    <div>Phone : {state.po.vendor_phone}</div>
                                                    <div>Email : {state.po.vendor_phone}</div>
                                                </td>
                                            </tr>
                                        </table>
                                        <table id="itemtable" style={{marginTop: '30px'}}
                                               className="ptable table table-bordered">
                                            <thead>
                                            <tr>
                                                <th>Account Payable</th>
                                                <th>Description</th>
                                                <th>Required Date</th>
                                                <th>Status</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <th>{state.po.account_number}-{state.po.account_name}</th>
                                                <th>{state.po.description}</th>
                                                <th>{CisUI().DateFormat(state.po.required_by_date)}</th>
                                                <th>{CisUI().getBillSatus(state.po.status)}</th>
                                            </tr>
                                            </tbody>
                                        </table>

                                        <table id="itemtable" style={{marginTop: '30px'}}
                                               className="ptable table table-bordered">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Account</th>
                                                <th>Description</th>
                                                <th className="no-print">Fund Program</th>
                                                <th className="no-print">Func. Expense</th>
                                                <th className="no-print">Restriction Type</th>
                                                <th >Class</th>
                                                <th>Amount</th>
                                                <th className="no-print">Attachment</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {state.items.map((item, idx) => (
                                                <tr id="addr0" key={idx}>
                                                    <td style={{width: '30px'}}>{(idx + 1)}</td>
                                                    <td >{item.account_number}-{item.account_name}</td>
                                                    <td style={{width: '70px', textAlign: 'left'}}>{item.remark}</td>
                                                    <td className="no-print" style={{width: '70px', textAlign: 'left'}}>{item.fund_program}</td>
                                                    <td className="no-print" style={{width: '70px', textAlign: 'left'}}>{item.func_expense}</td>
                                                    <td className="no-print" style={{
                                                        width: '70px',
                                                        textAlign: 'left'
                                                    }}>{item.restriction_type}</td>
                                                    <td style={{width: '70px', textAlign: 'left'}}>{item.class}</td>
                                                    <td style={{
                                                        width: '70px',
                                                        textAlign: 'right'
                                                    }}>{CisUI().getCurrencyFormated1(item.amount)}</td>
                                                    <td className="no-print" style={{width: '150px'}}>{
                                                        item.files.map((file, fl)=> {
                                                            return <a href={Config.fileserver+""+file.location} target="_blank" className="btn btn-primary btn-sm btn-circle">
                                                                <i className="fa fa-file"></i>
                                                            </a>
                                                        })
                                                    }</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <td colSpan="3" />
                                                <td colSpan="3" className="no-print" />
                                                <td style={{textAlign: 'right', fontWeight: 'bold'}}>Gross Total</td>
                                                <td style={{
                                                    textAlign: 'right',
                                                    fontWeight: 'bold'
                                                }}>{CisUI().getCurrencyFormated1(state.po.total)}</td>
                                                <td></td>
                                            </tr>
                                            </tfoot>
                                        </table>

                                    </div>
                                </div>
                            </div>

                            <div className="row no-print">
                                <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                                    {
                                        state.po.status === 0 ?
                                            state.isPOApprover === 1 ?
                                                <>
                                                    <Button id="submitbutton" type="danger" onClick={showModalReject}
                                                            htmlType="submit">Reject</Button>
                                                    <Button id="submitbutton" type="primary" onClick={showModalCofirm}
                                                            htmlType="submit">Approve</Button>
                                                </>
                                                : ""
                                            : ""
                                    }

                                    <Button id="submitbutton" type="primary" onClick={Print}
                                            htmlType="button">Print</Button>
                                    {/*<Button id="submitbutton" type="primary" htmlType="submit">Send TO Vendor</Button>*/}
                                    <Button id="submitbutton" onClick={showModal} type="primary" htmlType="submit">Send TO
                                        Anyone</Button>
                                    {
                                        state.po.status === 1 ?
                                            <>
                                                <Button id="submitbutton" type="primary" htmlType="button"><Link
                                                    to={"../../vendor/newbilltv/" + userID}>
                                                    {
                                                        state.hasBill === 0 ? "Create Bill" : "Bill Details"
                                                    }
                                                </Link></Button>
                                            </>
                                            : ""
                                    }
                                    <Button type="info" onClick={() => history.goBack()} htmlType="back">Back</Button>
                                </div>
                            </div>
                        </>
                    :
                        <></>
                }


            </Card>
            <Modal title="Send To Anyone" visible={isModalVisible} okText={"Send"} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    form={form}
                    {...CisUI().formItemLayout}
                    name="input"
                    ref={formRef}
                    className="ant-advanced-search-form"
                    onFinish={onFinish}>

                    <div className="row">
                        <div className="col-md-12">
                            <Form.Item
                                label="Email Address"
                                name="email"
                                onChange={handleChange}
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter email addres, separate by comma for multiple email "
                                    },
                                ]}
                            >
                                <Input placeholder="Enter email address" />
                            </Form.Item>
                        </div>
                    </div>

                </Form>
            </Modal>

            <Modal title="Alert" visible={isConfirmModalVisible} okText={"Confirm"} onOk={handleOkConfirm} onCancel={handleCancelConfirm}>
                <h4>Are you sure you want to approve ?</h4>
            </Modal>

            <Modal title="Alert" visible={isRejectModalVisible} okText={"Confirm"} onOk={handleOkReject} onCancel={handleCancelReject}>
                <h4>Are you sure you want to reject ?</h4>
            </Modal>

        </>
    );
};

export default VoucherDetails;