import {Form, Row, Col, Input, Button, Card, Collapse, Select, DatePicker, Checkbox, notification, Spin} from 'antd';
import React, {useState, useEffect} from "react";
import jsonfile from './journal.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import SelectData from "../../../../util/selectdata";
import moment from "moment";
import StaticSelectData from "../../../../util/StaticSelectData";

const {Option} = Select;

const JournalEdit = (props) => {

    const formRef = React.createRef();

    const [loading, setLoading] = useState([]);

    const [state, setState] = useState({
        data: [],
        rows: []
    })

    const [stateD, setStateD] = useState([]);
    const [entry, setEntryDetails] = useState({
        date: null,
        com_id: 0,
        thread: ""
    });

    const [loaded, setLoaded] = useState(false);
    const [vendor, setVendor] = useState([]);

    const [customer, setCustomer] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [accounts, setAccount] = useState([]);
    const [payable, setPayable] = useState([]);
    const [fund, setFund] = useState([]);
    const [bankaccount, setBankAccount] = useState([]);
    const [expense, setExpense] = useState([]);
    const [project, setProject] = useState([]);
    const [cashaccount, setCashAccount] = useState([]);
    const [isCash, setIsCash] = useState(true);
    const [date, setDate] = useState(true);

    const [benificiariesData, setBenificiaryData] = useState([]);

    const [podata, setPOdata] = useState([]);
    const [ttl, setTTL] = useState(
        {
            totalDebit: 0,
            totalCredit: 0
        }
    );

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.edit;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {
        //userData = RemoteJson();
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + jsonfile.urls.list;
    }


    const Companies = SelectData("company/companylists");

    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {
        setLoading({loading: true})
        values["items"] = state.rows;
        console.log(JSON.stringify(values));
        console.log(JSON.stringify(ttl));

        if (parseFloat(ttl.totalDebit).toFixed(2) === parseFloat(ttl.totalCredit).toFixed(2) && state.rows.length > 1) {

            if (ttl.totalDebit > 0 && ttl.totalCredit > 0) {
                document.getElementById("loader").style.display = "block";
                axios.post(Config.apiserver + endpoint+"/"+userID, values)
                    .then(res => {
                        console.log(res)
                        document.getElementById("loader").style.display = "none";
                        if (res.data.status === 1) {
                            notification.success({
                                message: 'Success',
                                type: "success",
                                description: res.data.msg
                            });
                            history.push("../../accounts/journlalist");
                        } else {
                            notification.warning({
                                message: 'Alert',
                                type: "warning",
                                description: res.data.msg
                            });
                        }
                        setLoading({loading: false});
                    })
                    .catch(errors => {
                        // console.log(errors.response.error);
                        setLoading({loading: false});
                        document.getElementById("loader").style.display = "none";
                    });
            } else {
                notification.warning({
                    message: 'Alert',
                    type: "warning",
                    description: "Minimum item should be 2, total debit side and total credit side should be same"
                });
            }
        } else {
            notification.warning({
                message: 'Alert',
                type: "warning",
                description: "Minimum item should be 2, total debit side and total credit side should be same"
            });
        }


    };


    const onCompanyChange = value => {
        console.log("selected : " + value);
        let fl = Companies.filter(item => item.id === value);
        if (fl.length > 0) {
            const company = fl[0];
            axios.get("billentrydataall?com_id=" + value)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.status === 1) {
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setBankAccount(res.data.bank);
                        setExpense(res.data.expense);
                        setEmployee(res.data.employee);
                        setCustomer(res.data.customer);
                        setProject(res.data.project);
                        setCashAccount(res.data.cashaccount);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            formRef.current.setFieldsValue({
                vendor: "",
                accounts_payable: "",
                accounts_id: ""
            });
        }
    }

    useEffect(() => {
        getReconcileData();
    }, []);

    const getReconcileData = () => {
        document.getElementById("loader").style.display = "block";
        const jID = props.match.params.id;
        axios.get(Config.apiserver + jsonfile.urls.view + "/" + jID)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    const jdata = res.data.data
                    setStateD(jdata);
                    if (jdata.length > 0) {
                        console.log(jdata[0].date);
                        setEntryDetails({
                            date: jdata[0].date,
                            com_id: jdata[0].com_id,
                            thread: jdata[0].thread
                        });
                    }

                    form.setFieldsValue({
                        date : moment(jdata[0].date)
                    });

                    onCompanyChangeForComapny(jdata[0].com_id);

                    let item = [];
                    let ttld = 0.00;
                    let ttlc = 0.00;
                    jdata.map((itm, indx) => {
                        item.push({
                            item_id: itm.id,
                            account_id: itm.account_id,
                            description: itm.description,
                            bank_account_id: itm.bank_account_id,
                            fund_program: itm.fund_program_id,
                            func_expense_id: itm.func_expense_id,
                            restriction_type: itm.restriction_type,
                            debit: parseFloat(itm.debit).toFixed(2),
                            credit: parseFloat(itm.credit).toFixed(2)
                        });

                       // ttld += parseFloat(itm.debit || 0).toFixed(2);
                        //ttlc += parseFloat(itm.credit || 0).toFixed(2);

                    });

                    // setTTL({
                    //    totalDebit : parseFloat(ttld).toFixed(2),
                    //    totalCredit : parseFloat(ttlc).toFixed(2),
                    // });

                    setState({
                        rows: item
                    });


                    let tDebit = 0;
                    let tCredit = 0;
                    item.map((itm, idx) => {
                        tDebit += parseFloat(itm.debit);
                        tCredit += parseFloat(itm.credit);
                    });

                    setTTL({
                        totalDebit: parseFloat(tDebit).toFixed(2),
                        totalCredit: parseFloat(tCredit).toFixed(2)
                    });


                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                document.getElementById("loader").style.display = "none";
            });
    }

    const onCompanyChangeForComapny = value => {
        console.log("selected : " + value);
        if (value > 0) {
            const company = value;
            axios.get("billentrydataall?com_id=" + value)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.status === 1) {
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setBankAccount(res.data.bank);
                        setExpense(res.data.expense);
                        setEmployee(res.data.employee);
                        setCustomer(res.data.customer);
                        setProject(res.data.project);
                        setCashAccount(res.data.cashaccount);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const appendChild = () => {
        let {data} = state;
        data.push(data.length); // data.length is one more than actual length since array starts from 0.
        setState({
            data: data,
            rows: {
                code: '',
                name: '',
                qty: 0,
                price: 0,
                amount: 0
            }
        });

    };


    const removeRow = () => {
        let {data} = state;
        data.pop(data.length); // data.length is one more than actual length since array starts from 0.
        setState({data});
    }

    const ItemCellChange = idx => e => {
        const {name, value} = e.target;
        const rows = [...state.rows];
        console.log("selectedITemID:" + idx);
        console.log("selectedValue:" + value);
        if (name === "account_id") {
            rows[idx] = {
                item_id: rows[idx].item_id,
                account_id: value,
                description: rows[idx].description,
                bank_account_id: rows[idx].bank_account_id,
                fund_program: rows[idx].fund_program,
                func_expense_id: rows[idx].func_expense_id,
                restriction_type: rows[idx].restriction_type,
                debit: parseFloat(rows[idx].debit).toFixed(2),
                credit: parseFloat(rows[idx].credit).toFixed(2)
            };
        } else if (name === "description") {
            rows[idx] = {
                item_id: rows[idx].item_id,
                account_id: rows[idx].account_id,
                description: value,
                bank_account_id: rows[idx].bank_account_id,
                fund_program: rows[idx].fund_program,
                func_expense_id: rows[idx].func_expense_id,
                restriction_type: rows[idx].restriction_type,
                debit: parseFloat(rows[idx].debit).toFixed(2),
                credit: parseFloat(rows[idx].credit).toFixed(2)
            };
        } else if (name === "fund_program") {
            rows[idx] = {
                item_id: rows[idx].item_id,
                account_id: rows[idx].account_id,
                description: rows[idx].description,
                bank_account_id: rows[idx].bank_account_id,
                fund_program: value,
                func_expense_id: rows[idx].func_expense_id,
                restriction_type: rows[idx].restriction_type,
                debit: parseFloat(rows[idx].debit).toFixed(2),
                credit: parseFloat(rows[idx].credit).toFixed()
            };
        } else if (name === "func_expense_id") {
            rows[idx] = {
                item_id: rows[idx].item_id,
                account_id: rows[idx].account_id,
                description: rows[idx].description,
                bank_account_id: rows[idx].bank_account_id,
                fund_program: rows[idx].fund_program,
                func_expense_id: value,
                debit: parseFloat(rows[idx].debit).toFixed(2),
                credit: parseFloat(rows[idx].credit).toFixed(2)
            };
        } else if (name === "debit") {
            rows[idx] = {
                item_id: rows[idx].item_id,
                account_id: rows[idx].account_id,
                description: rows[idx].description,
                bank_account_id: rows[idx].bank_account_id,
                fund_program: rows[idx].fund_program,
                func_expense_id: rows[idx].func_expense_id,
                restriction_type: rows[idx].restriction_type,
                debit: value,
                credit: parseFloat(rows[idx].credit).toFixed(2)
            };
        } else if (name === "credit") {
            rows[idx] = {
                item_id: rows[idx].item_id,
                account_id: rows[idx].account_id,
                description: rows[idx].description,
                bank_account_id: rows[idx].bank_account_id,
                fund_program: rows[idx].fund_program,
                func_expense_id: rows[idx].func_expense_id,
                restriction_type: rows[idx].restriction_type,
                debit: parseFloat(rows[idx].debit).toFixed(2),
                credit: value
            };
        } else if (name === "bank_account_id") {
            rows[idx] = {
                item_id: rows[idx].item_id,
                account_id: rows[idx].account_id,
                description: rows[idx].description,
                bank_account_id: value,
                fund_program: rows[idx].fund_program,
                func_expense_id: rows[idx].func_expense_id,
                restriction_type: rows[idx].restriction_type,
                debit: parseFloat(rows[idx].debit).toFixed(2),
                credit: parseFloat(rows[idx].credit).toFixed(2),
            };
        }
        else if (name === "restriction_type") {
            rows[idx] = {
                item_id: rows[idx].item_id,
                account_id: rows[idx].account_id,
                description: rows[idx].description,
                bank_account_id: rows[idx].bank_account_id,
                fund_program: rows[idx].fund_program,
                func_expense_id : rows[idx].func_expense_id,
                restriction_type : value,
                debit: parseFloat(rows[idx].debit).toFixed(2),
                credit: parseFloat(rows[idx].credit).toFixed(2),
            };
        }

        setState({
            rows
        });

        //console.log(rows);

        let tDebit = 0;
        let tCredit = 0;
        rows.map((item, idx) => {
            tDebit += parseFloat(item.debit);
            tCredit += parseFloat(item.credit);
        });

        setTTL({
            totalDebit: parseFloat(tDebit).toFixed(2),
            totalCredit: parseFloat(tCredit).toFixed(2)
        });

    };

    const handleAddRow = () => {
        const item = {
            item_id: 0,
            account_id: 0,
            description: '',
            bank_account_id: 0,
            fund_program: 0,
            func_expense_id: 0,
            debit: 0,
            credit: 0
        };
        setState({
            rows: [...state.rows, item]
        });
    };

    // useEffect(() => {
    //     handleAddRow();
    // }, []);

    const handleRemoveRow = idx => e => {
        let filteredArray = state.rows.filter((item, index) => index !== idx)
        setState({rows: filteredArray});
    };

    const onTabPress = (e) => {
        if (e.keyCode === 9) {
            e.preventDefault();
            alert("Execute ajax call after tab pressed");
        }
    }

    const onDateChange = (e) => {
        const nDate = CisUI().getSystemDateFormate(e);
        console.log(nDate);
        // setEntryDetails({
        //     date: nDate,
        //     com_id: jdata[0].com_id,
        //     thread: jdata[0].thread
        // });
    }


    const formField = (

        <>
            <div className="row">
                <div className="col-md-4">
                    <Form.Item
                        name="date"
                        label="Date"
                        // initialValue={moment(entry.date)}
                        rules={[
                            {
                                required: true,
                                message: "date"
                            },
                        ]}
                    >
                        <DatePicker onChange={onDateChange} format={CisUI().dateFormat} placeholder="Date"/>
                    </Form.Item>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: "right"}}>
                            <a className="btn btn-primary" onClick={handleAddRow}>Add Lines</a>
                        </div>
                    </div>
                    <table id="itemtable" className="ptable table table-bordered">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Account</th>
                            <th>Item Description</th>
                            <th>Fund Program</th>
                            <th>Functional Expense</th>
                            <th>Restriction Type</th>
                            <th>Debit ($)</th>
                            <th>Credit($)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.rows.map((item, idx) => (
                            <tr id="addr0" key={idx}>
                                <td style={{width: '30px'}}>
                                    {
                                        item.item_id === 0 ?
                                            <a onClick={handleRemoveRow(idx)}
                                               className="btn btn-primary btn-circle"><i
                                                className="fa fa-trash"></i></a>
                                            : <></>
                                    }
                                </td>
                                <td>
                                    <select name="account_id" onChange={ItemCellChange(idx)} className="form-control">
                                        <option value="0">--Select--</option>
                                        {
                                            accounts.map((items, index) =>
                                                <option value={items.id}
                                                        selected={(items.id === state.rows[idx].account_id)}>{items.name}</option>
                                            )
                                        }
                                    </select>
                                </td>
                                <td style={{width: '300px'}}><input className="form-control text-right"
                                                                    style={{textAlign: 'left'}}
                                                                    placeholder="Description" name="description"
                                                                    value={item.description}
                                                                    onChange={ItemCellChange(idx)}/></td>
                                <td style={{width: '100px'}}>
                                    <select name="fund_program" onChange={ItemCellChange(idx)} className="form-control">
                                        <option value="0">--Select--</option>
                                        {
                                            fund.map((items, index) =>
                                                <option value={items.id}
                                                        selected={(items.id === state.rows[idx].fund_program)}>{items.name}</option>
                                            )
                                        }
                                    </select>
                                </td>
                                <td style={{width: '100px'}}>
                                    <select name="func_expense_id" onChange={ItemCellChange(idx)}
                                            className="form-control">
                                        <option value="0">--Select--</option>
                                        {
                                            expense.map((items, index) =>
                                                <option value={items.id}
                                                        selected={(items.id === state.rows[idx].func_expense_id)}>{items.name}</option>
                                            )
                                        }
                                    </select>
                                </td>
                                <td style={{width: '120px'}}>
                                    <select name="restriction_type" onChange={ItemCellChange(idx)} className="form-control" >
                                        <option value="0">--Select--</option>
                                        {
                                            StaticSelectData("restrictiontype").map((items, index) =>
                                                <option value={items.id} selected={(items.id === state.rows[idx].restriction_type)} >{items.name}</option>
                                            )
                                        }
                                    </select>
                                </td>
                                <td style={{width: '150px'}}>
                                    <input className="form-control text-right"
                                           style={{textAlign: 'right'}} name="debit"
                                           value={item.debit}
                                           onChange={ItemCellChange(idx)}/></td>
                                <td style={{width: '150px'}}>
                                    <input className="form-control text-right"
                                           style={{textAlign: 'right'}} name="credit"
                                           value={item.credit}
                                           onKeyPress={onTabPress}
                                           onChange={ItemCellChange(idx)}/></td>
                            </tr>
                        ))}
                        </tbody>
                        <tfoot>
                        <tr>
                            <th colSpan={6}>Total </th>
                            <th style={{textAlign: "right"}}>{CisUI().getCurrencyFormated1(ttl.totalDebit)}</th>
                            <th style={{textAlign: "right"}}>{CisUI().getCurrencyFormated1(ttl.totalCredit)}</th>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>
    )


    return (
        <Card title={"Modify Entry - #"+entry.thread}>
            {CisUI().showLoading}
            {
                isEdit === 1
                    ? CisUI().addAction('../../accounts/journlalist')
                    : CisUI().addAction('../../accounts/journlalist')
            }
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                ref={formRef}
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{remember: true}}
            >
                {
                    formField
                }

                <div className="row">
                    <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                        <Button id="submitbutton" type="primary" htmlType="submit">
                            Save
                        </Button>
                        <a className="ant-btn ant-alert-info" onClick={() => history.goBack()} >Cancel</a>
                    </div>
                </div>

            </Form>
        </Card>
    );
}
;

export default JournalEdit;