import React, {useState,useEffect } from "react";
import {Form, Card, Tabs, Popconfirm, Calendar, notification} from 'antd';
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {values} from "lodash";


const FinancialClosing = (props) => {

    const [submitted, setSubmitted] = useState(0);

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    // const [data, setData] = useState([]);
    const [total, setTotal] = useState({
        debit : 0,
        credit : 0
    });

    const [data, setData] = useState([]);

    const [selection, setSelection] = useState({
        month : null,
        year : null
    });

    const [dataobject,setDataObject] = useState([]);

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }

    const getData = () => {
        axios.get(Config.apiserver+"settings/finclosed", null, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if(res.data.status === 1) {
                    setData(res.data.data);
                    document.getElementById("loader").style.display = "none";
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                document.getElementById("loader").style.display = "none";
                console.log(errors);
            });
    }

    useEffect(() => {
        getData();
    }, []);


    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {

        // let msg = "Sorry! request not processed, please try again";
        values["data"] = dataobject;
        if(total.debit > 0 && (total.debit === total.credit)) {
            document.getElementById("loader").style.display = "block";
            axios.post(Config.apiserver+"settings/openingbalance", values)
                .then(res => {
                    // console.log(res)
                    if (res.data.status === 1) {
                        notification.success({
                            message: 'Success',
                            type: "success",
                            description: res.data.msg
                        });
                        setSubmitted(1);
                        setDataObject(res.data.result);
                        document.getElementById("msg").style.color = "green";
                        document.getElementById("msg").innerText = res.data.msg;
                    }
                    else if (res.data.status === 3) {
                        notification.success({
                            message: 'Success',
                            type: "success",
                            description: res.data.msg
                        });
                        setSubmitted(1);
                        setDataObject(res.data.result);
                        document.getElementById("msg").style.color = "red";
                        document.getElementById("msg").innerText = res.data.msg;
                    } else {
                        notification.warning({
                            message: 'Alert',
                            type: "warning",
                            description: res.data.msg
                        });
                        document.getElementById("msg").style.color = "green";
                        document.getElementById("msg").innerText = res.data.msg;
                    }
                    document.getElementById("loader").style.display = "none";
                })
                .catch(errors => {
                    document.getElementById("loader").style.display = "none";
                    console.log(errors);
                });
        }
        else {
            notification.warning({
                message: 'Alert',
                type: "warning",
                description: "Total debit and credit are not same, please check your file."
            });
        }
    };

    const onPanelChange = (value, mode) => {
        console.log(value.format('YYYY-MM-DD'), mode);
    };

    const confirm = (e) => {
        console.log("Month : "+ selection.month);
        console.log("Year : "+ selection.year);
        let values = {}
        values["year"] = selection.year;
        values["month"] = selection.month;

        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver+"settings/fincloseadd", values, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type : "success",
                        description: res.data.msg
                    });
                    window.location.reload();
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                document.getElementById("loader").style.display = "none";
                console.log(errors);
            });

    }

    const monthCellRender = (value) => {
        const val = Math.random(0,5);
        return (
            <Popconfirm
                placement="topLeft"
                title={"Would you like to close for the month? Please note that, once it is closed, transaction input will be locked for the month."}
                description={"description"}
                onConfirm={confirm}
                okText="Yes"
                cancelText="No"
            >
                <div className="notes-month">
                    <i style={{color: 'green'}} className="fas fa-unlock" />
                    <div>
                        Locked : January 31, 2023 <br />
                        Locked by : Mr Xyz
                    </div>
                </div>
            </Popconfirm>
        );
    };

    const onMonthClick = (e) => {
        const month = moment(e).format("YYYYMM");
        const year = moment(e).format("YYYY");
        // console.log("Month : "+ month);
        // console.log("Year : "+ year);
        setSelection({
            month: month,
            year: year
        })

    }

    return (
        <Card title={"Financial Closing Status"}>
            {CisUI().showLoading}

            <Calendar mode={"year"} localizer={{monthFormat: 'MMMM, YYYY'}} onSelect={onMonthClick} monthCellRender={monthCellRender} onPanelChange={onPanelChange} />

        </Card>
    );
};

export default FinancialClosing;