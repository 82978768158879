import { Card, Col, Input, Row } from "antd";
import React, { useCallback, useState } from "react";
import "./signature.css";
import { toPng } from "html-to-image";

export default function TypeSignature({ setSignatureData }) {
  const userData = localStorage.getItem("userData");
  const [signature, setSignature] = useState(userData?.name);
  const [selectedFont, setSelectedFont] = useState("");

  const handleChange = (e) => {
    setSignature(e.target.value);
  };

  const onButtonClick = useCallback((class_name) => {
    setSelectedFont(class_name);

    toPng(document.getElementsByClassName(class_name)[0], { cacheBust: true })
      .then((dataUrl) => {
        setSignatureData(dataUrl);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const data = [
    {
      id: 2,
      fontName: "adusian_signature",
    },
    {
      id: 3,
      fontName: "alista_blair",
    },
    {
      id: 4,
      fontName: "american_signature",
    },
    {
      id: 5,
      fontName: "amigos",
    },
    {
      id: 6,
      fontName: "ampunsuhu",
    },
    {
      id: 7,
      fontName: "arington",
    },
    {
      id: 8,
      fontName: "ballonest",
    },
    {
      id: 9,
      fontName: "banteng_story",
    },
    {
      id: 10,
      fontName: "bastliga",
    },
    {
      id: 11,
      fontName: "bhasline",
    },

    {
      id: 13,
      fontName: "bilya_signature",
    },
    {
      id: 14,
      fontName: "bisqootte",
    },

    {
      id: 16,
      fontName: "bottuckey",
    },
    {
      id: 17,
      fontName: "brastagi",
    },
    {
      id: 18,
      fontName: "brittney_signature",
    },
    {
      id: 19,
      fontName: "brook_holmes",
    },
    {
      id: 20,
      fontName: "browellay_synthya",
    },

    {
      id: 22,
      fontName: "cafe_de_paris-script",
    },
    {
      id: 24,
      fontName: "carolina",
    },
    {
      id: 25,
      fontName: "cristy",
    },
    {
      id: 26,
      fontName: "getting_everything",
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <Input.TextArea value={signature} rows={3} onChange={handleChange} />
        </Col>
        {signature && (
          <Col span={24}>
            <Row
              style={{
                marginTop: "20px",
                height: "300px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              {data?.map((item) => (
                <Col span={24} key={item.id}>
                  <Card
                    className={`signature_text ${
                      selectedFont === item?.fontName ? "item_border" : ""
                    }`}
                    onClick={() => onButtonClick(item?.fontName)}
                  >
                    <h1
                      style={{
                        fontSize: "44px",
                        padding: "8px",
                      }}
                      className={item?.fontName}
                    >
                      {signature}
                    </h1>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
}
