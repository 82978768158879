import React, {useEffect, useState} from "react";
import {Form, Row, Col, Button, Radio, Checkbox, DatePicker, Input, Card,notification} from 'antd';
import jsonfile from './40kform.json';
import {CisUI} from '../../../../util/CISUI';
import InputFields from '../../../../util/InputField';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import moment from "moment";
import 'moment/locale/es-us';
import InputOnly from "../../../../util/InputOnly";
import type { RadioChangeEvent } from 'antd';
import IntlMessages from "../../../../util/IntlMessages";

const Detail401KForm = (props) => {

    const LeaveTypes = RemoteJson("vacationtype");
    const [form] = Form.useForm();
    const history = useHistory();

    const [loading, setLoading] = useState([]);
    const [value, setValue] = useState("");
    const [maritial, setMariatil] = useState("");
    const [status, setStatus] = useState("");
    const [data, setData] = useState({});
    const [signature, setSignature] = useState(CisUI().getUserInfo('name'));

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    if(isEdit === 1 ) {
        // userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }

    useEffect(()=>{
        if(isEdit === 1) {
            axios.get(Config.apiserver + jsonfile.urls.view+"/"+userID)
                .then((res) => {
                    //console.log(res.data);
                    if (res.data.status === 1) {
                        if (res.data.data) {
                            userData = res.data.data;
                            //console.log(userData);
                            setData(userData);
                            setStatus(userData?.p_status);
                            setSignature(userData?.signature);
                            setValue(userData?.type);
                            setMariatil(userData?.maritial_status);

                            const type = userData?.p_status;
                            if(type === 'start') {
                                form.setFieldsValue({
                                    'start_at': moment(userData?.plan_to),
                                    'start_amount': (userData?.rate)
                                });
                            }
                            else if(type === 'change') {
                                form.setFieldsValue({
                                    'change_at': moment(userData?.plan_to),
                                    'change_amount': (userData?.rate)
                                });
                            }
                            else if(type === 'resume') {
                                form.setFieldsValue({
                                    'resume_at': moment(userData?.plan_to),
                                    'resume_amount': (userData?.rate)
                                });
                            }
                            else if(type === 'suspend') {
                                form.setFieldsValue({
                                    'suspend_at': moment(userData?.plan_to),
                                    'suspend_amount': (userData?.rate)
                                });
                            }

                            form.setFieldsValue({
                                'name': userData?.signature,
                                'date': moment(userData?.date),
                                'effective_date': moment(userData?.effective_date),
                            });

                        } else {
                            setData({});
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setData({})
                });
        }
    },[])



    const onFinish = (values) => {
        // // console.log(values);
        document.getElementById("loader").style.display = "block";
        values["type"] = value;
        values["marital_status"] = maritial;
        values["status"] = status;

        axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg)
                    history.push(redirectto);
                }
                else {
                    CisUI().Notification('error', res.data.msg)
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    const onChange = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };
    const onChangeMaritial = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setMariatil(e.target.value);
    };
    const onChangeSatus = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setStatus(e.target.value);
    };
    const onSignatureSet = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setSignature(e.target.value);
    };


    //console.log("UserData : "+userData);

    return (
        <Card title={Title} extra={
            isEdit === 1
                ? CisUI().addAction('../../'+jsonfile.urls.list)
                : CisUI().addAction('../'+jsonfile.urls.list)
        }>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                {/*{InputFields(jsonfile.input,userData,isEdit,props)}*/}
                <div align={"center"} style={{textAlign: 'center', margin: 'auto'}}>
                    <div style={{textAlign: 'center', width: '1024px', display: 'inline-block'}} align={"center"}>
                        <Card>

                            <Row>
                                <Col className={"col-md-12"} style={{textAlign: 'center'}}>
                                    <img src={"/assets/images/logo.png"} style={{width: '100px'}} />
                                    <div style={{fontSize: '30px'}}>THE CHURCH OF PENTECOST U.S.A., INC.</div>

                                    <div style={{fontSize: '12px', textAlign: 'justify', padding: '10px'}}>
                                        PLEASE USE THIS FORM TO NOTIFY FINANCE/HR TO START YOUR 401K CONTRIBUTIONS, CHANGE YOUR 401K CONTRIBUTION RATE, SUSPEND YOUR 401K CONTRIBUTION OR RESUME YOUR 401K CONTRIBUTION. THE FINANCE/HR TEAM WILL USE THIS FORM TO UPDATE PAYROLL. BY ELECTING TO MAKE CONTRIBUTIONS TO THE PLAN, YOU ARE AUTHORIZING THE EMPLOYER TO WITHHOLD THOSE CONTRIBUTIONS FROM YOUR PAY.
                                    </div>

                                    <div style={{textAlign: 'right'}}>
                                        <div><strong>Effective Date</strong> : { CisUI().DateFormat(userData?.effective_date) } </div>
                                        <div><strong>Date</strong> : { CisUI().DateFormat(userData?.effective_date) }</div>
                                    </div>

                                    <div style={{textAlign: 'left', marginTop: '20px', marginBottom: '20px'}}>
                                        <div style={{fontSize: '20px'}}>Form Type : {data?.type}</div>
                                    </div>
                                    <div style={{textAlign: 'left', marginTop: '20px', marginBottom: '20px'}}>
                                        <div style={{fontSize: '20px'}}>Employee Information</div>
                                        <Row>
                                            <Col className={"col-md-12"}>
                                                <table className="table table-bordered">
                                                    <tr>
                                                        <th style={{textAlign: 'left', width: '130px'}}>Full Name :</th>
                                                        <td style={{textAlign: 'left'}}>{data?.name}</td>
                                                        <th style={{textAlign: 'left', width: '130px'}}>First Name :</th>
                                                        <td style={{textAlign: 'left'}}>{data?.fname}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{textAlign: 'left', width: '130px'}}>Middle Name :</th>
                                                        <td style={{textAlign: 'left'}}>{data?.mname}</td>
                                                        <th style={{textAlign: 'left', width: '100px'}}>Last Name :</th>
                                                        <td style={{textAlign: 'left'}}>{data?.lname}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{textAlign: 'left', width: '130px'}}>Date of Birth :</th>
                                                        <td style={{textAlign: 'left'}}>{CisUI().DateFormat(data?.dob)}</td>
                                                        <th style={{textAlign: 'left', width: '130px'}}>Address :</th>
                                                        <td style={{textAlign: 'left'}}>{data?.address}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{textAlign: 'left', width: '130px'}}>Date of Hire :</th>
                                                        <td style={{textAlign: 'left'}}>{CisUI().DateFormat(data?.hired)}</td>
                                                        <th style={{textAlign: 'left', width: '130px'}}></th>
                                                        <td style={{textAlign: 'left'}}></td>
                                                    </tr>
                                                </table>
                                                {/*{InputOnly(jsonfile.input,data,isEdit,props)}*/}
                                            </Col>
                                        </Row>
                                    </div>

                                    <div style={{textAlign: 'left', marginTop: '20px', marginBottom: '20px'}}>
                                        <div style={{fontSize: '20px'}}>Marital Status : {maritial}</div>
                                    </div>

                                    <div style={{textAlign: 'left', marginTop: '20px', marginBottom: '20px'}}>
                                        <div style={{fontSize: '20px', padding: '10px'}}>CONTRIBUTION (PLEASE CHECK ONLY ONE BOX AND PROVIDE REQUESTED INFORMATION WHERE INDICATED)</div>
                                        <table className={"table table-bordred"}>
                                            <thead>
                                            <tr>
                                                <th>Type</th>
                                                <th>Description</th>
                                                <th>Plan at / to</th>
                                                <th>Rate (%) or Amount ($)</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                status === 'start' ? <tr>
                                                    <td>
                                                        Start
                                                    </td>
                                                    <td>I WANT TO START MY PARTICIPATION IN THE PLAN AT</td>
                                                    <td>
                                                        {
                                                            CisUI().DateFormat(data?.plan_to)
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            CisUI().getCurrencyFormated1(data?.rate)
                                                        }
                                                    </td>
                                                </tr> : ""
                                            }

                                            {
                                                status === 'change' ? <tr>
                                                <td>
                                                    CHANGE
                                                </td>
                                                <td>I WANT TO CHANGE MY RATE OF CONTRIBUTION TO</td>
                                                    <td>
                                                        {
                                                            CisUI().DateFormat(data?.plan_to)
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            CisUI().getCurrencyFormated1(data?.rate)
                                                        }
                                                    </td>
                                            </tr> : ""
                                            }
                                            {
                                                status === 'resume' ?
                                                    <tr>
                                                <td>
                                                    RESUME
                                                </td>
                                                <td>I WANT TO RESUME MY PARTICIPATION IN THE PLAN AT</td>
                                                        <td>
                                                            {
                                                                CisUI().DateFormat(data?.plan_to)
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                CisUI().getCurrencyFormated1(data?.rate)
                                                            }
                                                        </td>
                                            </tr> : ""
                                            }
                                            {
                                                status === 'suspend' ?
                                                    <tr>
                                                    <td>
                                                        SUSPEND
                                                    </td>
                                                    <td>I WANT TO SUSPEND MY PARTICIPATION IN THE PLAN</td>
                                                        <td>
                                                            {
                                                                CisUI().DateFormat(data?.plan_to)
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                CisUI().getCurrencyFormated1(data?.rate)
                                                            }
                                                        </td>
                                                </tr> : ""
                                            }
                                            </tbody>
                                        </table>
                                    </div>

                                    <div style={{textAlign: 'left', marginTop: '20px', marginBottom: '20px'}}>
                                        <Row>
                                            <Col md={8}></Col>
                                            <Col md={8}></Col>
                                            <Col md={8} style={{textAlign: 'right'}}>
                                                <div style={{marginTop: '40px'}}>
                                                    <div id="signshow" style={{width: '200px', fontFamily: 'signature4', color: 'blue', textAlign: 'center', fontSize: '40px'}}>{signature}</div>
                                                    <div style={{width: '200px', textAlign: 'center', borderTop: '#757373 1px solid'}}>Name/Signature</div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>

                            </Row>

                        </Card>
                    </div>
                </div>

                {
                    CisUI().getUserInfo("usefor") === 33 && userData?.status === 0 ? <>

                        <Row>
                            <Col lg={24} xs={24} style={{ textAlign: "center" }}>
                                <Button type="primary" htmlType="submit">
                                    Confirm Approve
                                </Button>
                            </Col>
                        </Row>

                    </>: <></>
                }

            </Form>
        </Card>
    );
};

export default Detail401KForm;