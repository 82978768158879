import axios from 'axios';
import { setupCache } from 'axios-cache-adapter'
import config from './config'

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000
})


const token = localStorage.getItem('token');

const axiosinstance = axios.create({
  baseURL: config.apiserver,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': token,
  }
});

export default axiosinstance;

// export const HeaderRequest = () => {
//   const token = localStorage.getItem('token');
//   return {headers : {Authorization : token}};
// }