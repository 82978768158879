import React, {useState} from "react";
import {Form, Col, Input, Card, Collapse, notification} from 'antd';
import jsonfile from './vacation.json';
import {Link, useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import ActionButton from "../../../../util/actionbutton";

const Panel = Collapse.Panel;

const PendingVaction = () => {
    const value = 0;
    const history = useHistory();

    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.pendinglist,
        filtervalue : ''
    });
    const [loading, setLoading] = useState([]);

    const addtionalMenu = [
        {
            label : "Pay Now",
            url : "newpayment?bill_id=",
            hasID: 1,
            qry: "id"
        }
    ]

    const [stdata,setStdata] = useState([]);
    const [selected,setSelected] = useState([]);

    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [list, setList] = useState([]);

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(list.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };



    const checkAll = (e) => {
        var dcd = document.getElementById("datatable");
        var checkboxes = dcd.getElementsByTagName('input');
        let allselected = [];
        if(e.target.checked) {
            for(var i=1; i<checkboxes.length; i++) {
                console.log(i);
                if(checkboxes[i].checked) {
                    checkboxes[i].checked = false;
                }
                else {
                    checkboxes[i].checked = true;
                    if(!allselected.includes(checkboxes[i].value)) {
                        allselected.push(checkboxes[i].value);
                    }
                }
            }
        }else {
            allselected = [];
            for(var i=1; i<checkboxes.length; i++) {
                if(checkboxes[i].checked) {
                    checkboxes[i].checked = false;
                }
            }
        }
        setSelected(allselected);
        if(allselected.length > 0) {
            document.getElementById("prntbtn").style.display = "block";
            setStdata(Object.values(allselected).join(','));
        }
        else {
            document.getElementById("prntbtn").style.display = "none";
            setStdata("");
        }
    }

    const selectedForPrint = (e) => {
        let allselected = selected;
        if(e.target.checked === true) {
            if(!allselected.includes(e.target.value)) {
                allselected.push(e.target.value);
            }
        }
        else {
            var index = allselected.indexOf(e.target.value)
            if (index !== -1) {
                allselected.splice(index, 1);
            }
        }
        setSelected(allselected);

        if(allselected.length > 0) {
            document.getElementById("prntbtn").style.display = "block";
            setStdata(Object.values(allselected).join(','));
        }
        else {
            document.getElementById("prntbtn").style.display = "none";
            setStdata("");
        }
    }


    const headers = {
        com_id: {invisible: true},
        user_id: {invisible: true},
        emp_id: {invisible: true},
        year: {invisible: true},
        remain: {invisible: true},
        created_by: {invisible: true},
        updated_at: {invisible: true},
        updated_by: {invisible: true},
        vacation_type: {invisible: true},
        approver: {invisible: true},
        id: {
            text: <input type="checkbox" onChange={checkAll} name="checkAll" id="checkAll" value={1} />,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                row.approver === 1 ? <input type="checkbox" onChange={selectedForPrint} name={"ck_"+value} value={value} /> : <></>
            )
        },
        request_no:{
            text: <IntlMessages id="Request#"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, record) => (
                <Link to={"vacationdetail/"+record.id}>{value}</Link>
            )
        },
        company: {
            text: <IntlMessages id="Company"/>,
            sortable: true,
            filterable: true,
        },
        employee: {
            text: <IntlMessages id="Employee"/>,
            sortable: true,
            filterable: true,
        },
        start_date: {
            text: <IntlMessages id="Start Date"/>,
            sortable: true,
            filterable: true,
            transform: (value) => (
                CisUI().DateFormat(value)
            )
        },
        end_date: {
            text: <IntlMessages id="End Date"/>,
            sortable: true,
            filterable: true,
            transform: (value) => (
                CisUI().DateFormat(value)
            )
        },
        number_of_days: {
            text: <IntlMessages id="Number of Days"/>,
            sortable: true,
            filterable: true,
        },
        created_at: {
            text: <IntlMessages id="Created_at"/>,
            sortable: true,
            filterable: true,
            transform: (value) => (
                CisUI().DateFormatWithTime(value)
            )
        },
        approved_at: {
            text: <IntlMessages id="Approved Date"/>,
            sortable: true,
            filterable: true,
            transform: (value) => (
                CisUI().DateFormatWithTime(value)
            )
        },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getBillSatus(value)
            )
        },
        actions: {
            text: 'Actions',
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {row.status === 0 ? ActionButton(row.id,jsonfile.urls,jsonfile.urls.list) : <></>}
                </>
            ),
        },
    };

    const orderedHeaders = jsonfile.listView.title;

    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if(list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name} />
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        // console.log('Received values of form: ', values);
        const data = {
            ...values,
            // 'purchase_date' : values['purchase_date'].format("YYYY-MM-DD"),
        }
        // console.log(data);
        let wkd = "";
        let wk = "";
        let dt = "";
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    wk = values['date'];
                    const dt = CisUI().ParseDateFormat(values[key]);
                    // setPeriod("Between "+CisUI().DateFormat(dt[0])+" and "+CisUI().DateFormat(dt[1]));
                    return `${key}=${dt}`
                } else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.pendinglist+"/?status=0&"+qsup;
        setState({reqURL: newURL});
    };

    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    // const filterValue = "";

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.search,"",1,"date",CisUI().PeriodFiled(form))}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {CisUI().listAction("../"+jsonfile.urls.add,"",state.reqURL,"data","PendingBills",jsonfile)}
        </>
    );

    const goForApprove = () => {
        console.log(stdata);
        axios.post(Config.apiserver + "hrm/vactionapprove?status=1&tps="+stdata, null)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    history.push("../"+jsonfile.urls.pendinglist);
                    //history.push("newbill?po="+polist.po_id);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading: false});
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                setLoading({loading: false});
            });
    }

    const goForReject = () => {
        axios.post(Config.apiserver + "hrm/vactionapprove?status=3&tps="+stdata, null)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    history.push("../"+jsonfile.urls.list);
                    //history.push("newbill?po="+polist.po_id);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading: false});
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                setLoading({loading: false});
            });
    }

    return (
        <>
            {searchView}
            <div className="row" id="prntbtn" style={{padding: '10px',  display: 'none'}}>
                <a onClick={goForApprove} className="btn btn-primary" style={{width: '100px'}} >Approve</a>
                <a onClick={goForReject} className="btn btn-danger" style={{width: '100px'}} >Reject</a>
            </div>
            <div id="datatable">
                <Card title={jsonfile.pendinglist} extra={getExtra}>
                    {/*{CisUI().listAction(jsonfile.urls.add)}*/}
                    <SmartDataTable
                        name='test-fake-table'
                        data={state.reqURL}
                        dataRequestOptions={CisUI().HeaderRequest}
                        dataKey="data"
                        headers={headers}
                        orderedHeaders={orderedHeaders}
                        hideUnordered={CisUI().hideUnordered}
                        className={CisUI().sematicUI.table}
                        filterValue={state.filterValue}
                        perPage={CisUI().perPage}
                        sortable
                        withLinks
                        withHeader
                        loader={CisUI().loader}
                        parseBool={{
                            yesWord: 'Yes',
                            noWord: 'No',
                        }}
                        parseImg={{
                            style: {
                                border: '1px solid #ddd',
                                borderRadius: '50px',
                                padding: '3px',
                                width: '30px',
                                height: '30px'
                            },
                        }}
                        emptyTable={CisUI().emptyTable}
                    />
                </Card>
            </div>
        </>
    );
};

export default PendingVaction;