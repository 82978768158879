import React, {useState, useEffect} from "react";
import { Form, Col, Input, Card, Collapse,} from 'antd';
import jsonfile from './accessibility.json';
import {useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import ActionButton from "../../../../util/actionbutton";

const Panel = Collapse.Panel;

const OnlineUser = () => {
    const history = useHistory();
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.list,
        filtervalue : '',
        subtitle:''
    });
    useEffect(() => {
        setState({
            reqURL: Config.apiserver+jsonfile.urls.list,
        });
    }, []);

    const ForceToLogout = () => {

    }

    const headers = {
        id: {
            text: <IntlMessages id="Sl NO"/>,
            sortable: true,
            filterable: true,
        },
        user_name: {
            text: <IntlMessages id="Username"/>,
            sortable: true,
            filterable: true,
        },
        login_time: {
            text: <IntlMessages id="Login Time"/>,
            sortable: true,
            filterable: true,
            transform: (value) => (
                CisUI().DateFormatWithTime(value)
            )
        },
        hit_time: {
            text: <IntlMessages id="Last Activity"/>,
            sortable: true,
            filterable: true,
            transform: (value) => (
                CisUI().DateFormatWithTime(value)
            )
        },
        ip: {
            text: <IntlMessages id="IP"/>,
            sortable: true,
            filterable: true,
        },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getStatusLabel(value)
            )
        },
        actions: {
            text:  <IntlMessages id="Actions"/>,
            sortable: false,
            filterable: false,
            textAlign: "center",
            transform: (value, idx, row) => (
                <>
                    <a href="#" onClick={ForceToLogout} className="ant-btn btn-primary">Logout</a>
                </>
            ),
        },
    };

    const orderedHeaders = jsonfile.listView.title;

    const [form] = Form.useForm();

    const onFinish = values => {
        console.log('Received values of form: ', values);
        const qs = Object.keys(values).map(key => `${key}=${values[key]}`).join('&');
        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.list+"/?"+qsup;
        setState({reqURL: newURL});
    };


    return (
        <>
            <Card title={<IntlMessages id={jsonfile.listtitle} />}>
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filtervalue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    dynamic
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />

            </Card>
        </>
    );
};

export default OnlineUser;