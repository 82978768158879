import React, {useState, useEffect} from "react";
import {Form, Card, notification, Col, Select, Row, Checkbox, Input, DatePicker,InputNumber, Button} from 'antd';
import jsonfile from './asset_distribution.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "axios";
import {useHistory} from "react-router-dom";
import SelectData from "../../../../util/selectdata";
import IntlMessages from "../../../../util/IntlMessages";
import CircularProgress from "../../../../components/CircularProgress";
import moment from "moment";
import 'moment/locale/es-us';

const { Option } = Select;
const {TextArea} = Input;

const NewAssetDistribution = (props) => {

    const userID = props.match.params.id;
    const [emp, setEmp] = useState(true);
    const [other, setOther] = useState(false);
    // let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }


    // userData = RemoteJson(jsonfile.urls.view+"/"+userID);
    // console.log(userData)

    const [userData, setUserData] = useState([]);


    useEffect(() => {
        const EditData = async() => {
            await axios.get(Config.apiserver + jsonfile.urls.view + "/" + userID, CisUI().HeaderRequest)
                .then((res) => {
                    if(res.data.status === 1) {
                        setUserData(res.data.data);
                        let employee_id = res.data.data.employee_id;
                        // let other_name = res.data.data.other_name;

                        if(employee_id === 0){
                            setEmp(false);
                            setOther(true)
                        }
                        else{
                            setEmp(true);
                            setOther(false);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        EditData();
    }, [userID])


    let Title = jsonfile.edittitle;
    let endpoint = jsonfile.urls.edit+"/"+userID;
    let redirectto = "../../"+jsonfile.urls.list;

    const companylist = SelectData('company/companylists');
    const employeelist = SelectData('user/userlist');
    const assetlist = SelectData('assets/assetinfo');
    const status = SelectData('userstatus');


    function onchange(e) {
        if(e.target.checked === true) {
            setEmp(false);
            setOther(true);
        } else {
            setEmp(true);
            setOther(false);
        }

    }

    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {
        const data = {
            ...values,
            'distribution_date' : values['distribution_date'].format("YYYY-MM-DD"),
            'start_date' : values['start_date'].format("YYYY-MM-DD"),
            'end_date' : values['end_date'].format("YYYY-MM-DD")
        }
        // console.log(data);
        // console.log(values);
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type : "success",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    return (
        <Card title={Title} extra={
                isEdit === 1
                    ? CisUI().addAction('../../'+jsonfile.urls.list)
                    : CisUI().addAction('../'+jsonfile.urls.list)
            }>
            {/*{CisUI().showLoading}*/}
            {userData === "" ? <CircularProgress /> : (
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >

                        <Row gutter={24}>
                            <Col lg={8} md={8} sm={12} xs={24}>
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="com_id"
                                        label={<IntlMessages id="Company"/>}
                                        initialValue={userData.com_id}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Select a company"
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select level of company"
                                            optionFilterProp="children"
                                            // onChange={onAreaChange}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                companylist.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>

                                </div>
                            </Col>

                            <Col lg={8} md={8} sm={12} xs={24}>
                                {emp && (
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="employee_id"
                                        label={<IntlMessages id="Employee"/>}
                                        initialValue={userData.employee_id}
                                        rules={[
                                            {
                                                required: false,
                                                message: "Select a employee"
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select Employee"
                                            optionFilterProp="children"
                                            // onChange={onAreaChange}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                employeelist.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                                )}
                                {other && (
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="other_name"
                                            label={<IntlMessages id="Other"/>}
                                            initialValue={userData.other_name}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Enter Other Name"
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Other Name"/>
                                        </Form.Item>
                                    </div>

                                )}
                            </Col>

                            <Col lg={8} md={8} sm={12} xs={24}>
                                <div className="gx-form-row0">
                                <Form.Item
                                    name="others"
                                    label={<IntlMessages id="Or Other"/>}
                                    initialValue={userData.employee_id}
                                    rules={[
                                        {
                                            required: false,
                                            message: "Select a company"
                                        },
                                    ]}
                                >
                                    <Checkbox onChange={onchange}/>
                                </Form.Item>
                                </div>
                            </Col>

                            <Col lg={8} md={8} sm={12} xs={24}>
                                <div className="gx-form-row0">
                                <Form.Item
                                    name="ast_id"
                                    label={<IntlMessages id="Asset"/>}
                                    initialValue={userData.ast_id}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Select Asset"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select Asset"
                                        optionFilterProp="children"
                                        // onChange={onAreaChange}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            assetlist.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                                </div>
                            </Col>

                            <Col lg={8} md={8} sm={12} xs={24}>
                                <div className="gx-form-row0">
                                <Form.Item
                                    id="distribution_date"
                                    label={<IntlMessages id="Distribution Date" />}
                                    name="distribution_date"
                                    initialValue={moment(userData.distribution_date)}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please distribution date"
                                        },
                                    ]}
                                >
                                    <DatePicker allowClear format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"/>
                                </Form.Item>
                                </div>
                            </Col>

                            <Col lg={8} md={8} sm={12} xs={24}>
                                <div className="gx-form-row0">
                                <Form.Item
                                    id="start_date"
                                    label={<IntlMessages id="Start Date" />}
                                    name="start_date"
                                    initialValue={moment(userData.start_date)}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select start date"
                                        },
                                    ]}
                                >
                                    <DatePicker allowClear format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"/>
                                </Form.Item>
                                </div>
                            </Col>

                            <Col lg={8} md={8} sm={12} xs={24}>
                                <div className="gx-form-row0">
                                <Form.Item
                                    id="end_date"
                                    label={<IntlMessages id="End Date" />}
                                    name="end_date"
                                    initialValue={moment(userData.end_date)}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select end date"
                                        },
                                    ]}
                                >
                                    <DatePicker allowClear format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"/>
                                </Form.Item>
                                </div>
                            </Col>

                            <Col lg={8} md={8} sm={12} xs={24}>
                                <div className="gx-form-row0">
                                <Form.Item
                                    id="period"
                                    label={<IntlMessages id="Period" />}
                                    name="period"
                                    initialValue={userData.period}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Period is required"
                                        },
                                    ]}
                                >
                                    <InputNumber className="gx-mb-3 gx-w-100" placeholder="Period"/>
                                </Form.Item>
                                </div>
                            </Col>

                            <Col lg={8} md={8} sm={12} xs={24}>
                                <div className="gx-form-row0">
                                <Form.Item
                                    id="item_serial_no"
                                    label={<IntlMessages id="Item Serial Number" />}
                                    name="item_serial_no"
                                    initialValue={userData.item_serial_no}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Item Serial Number is required"
                                        },
                                    ]}
                                >
                                    <Input className="gx-mb-3 gx-w-100" placeholder="Enter Item Serial Number"/>
                                </Form.Item>
                                </div>
                            </Col>

                            <Col lg={8} md={8} sm={12} xs={24}>
                                <div className="gx-form-row0">
                                <Form.Item
                                    id="remarks"
                                    label={<IntlMessages id="Remarks" />}
                                    name="remarks"
                                    initialValue={userData.remarks}
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <TextArea placeholder="Enter Remark" rows={2}/>
                                </Form.Item>
                                </div>
                            </Col>

                            <Col lg={8} md={8} sm={12} xs={24}>
                                <div className="gx-form-row0">
                                <Form.Item
                                    name="status"
                                    label={<IntlMessages id="Status"/>}
                                    initialValue={userData.status}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Select status"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select status"
                                        optionFilterProp="children"
                                        // onChange={onAreaChange}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            status.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                                </div>
                            </Col>

                        </Row>

                <div className="row">
                    <div className="col-md-12" style={{textAlign: 'center', marginTop : '30px'}}>
                        <Button id="submitbutton" type="primary" htmlType="submit">
                            Save
                        </Button>
                        <Button type="info" onClick={() => history.push(redirectto)} htmlType="back">Cancel</Button>
                    </div>
                </div>
                        {/*{InputFields(jsonfile.input,userData,isEdit,props)}*/}


            </Form>
            ) }
        </Card>
    );
};

export default NewAssetDistribution;