import React, {useState, useEffect} from "react";
import {Form, Row, Col, Upload, Input, Button, message, Card,notification} from 'antd';
import jsonfile from './expenseclaim.json';
import {CisUI} from '../../../../util/CISUI';
import InputFields from '../../../../util/InputField';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import InputOnly from "../../../../util/InputOnly";
import { UploadOutlined } from '@ant-design/icons';


const NewExpenseClaim = (props) => {

    const [loading, setLoading] = useState([]);
    const [lineItems, setLineItems] = useState([]);

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    const [state, setState] = useState({
        po: {},
        items: [],
        total: 0
    })
    const [total,setTotal] = useState(0);

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }

    const ClaimType = RemoteJson("expclaimtype");
    const [form] = Form.useForm();
    const history = useHistory();


    useEffect(() => {
        getPODetails();
    }, []);

    const getPODetails = () => {
        if(isEdit === 1) {
            document.getElementById("loader").style.display = "block";
            axios.get(Config.apiserver + jsonfile.urls.view + "/" + userID)
                .then((res) => {
                    // console.log(res.data);
                    if (res.data.status === 1) {
                        setState({
                            po: res.data.podata,
                            items: res.data.items,
                            total: res.data.total,
                        });
                        setTotal(res.data.total);
                    }
                    document.getElementById("loader").style.display = "none";
                })
                .catch((error) => {
                    document.getElementById("loader").style.display = "block";
                    console.log(error);
                });
        }
    }

    const onFinish = (values) => {
        // // console.log(values);
        document.getElementById("loader").style.display = "block";
        values["files"] = lineItems;
        axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg)
                    history.push(redirectto);
                }
                else {
                    CisUI().Notification('error', res.data.msg)
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };


    const token = localStorage.getItem('token');
    let formData = new FormData();
    let fileUID = "";
    const uplod = {
        name: 'file',
        action: Config.apiserver+"fileupload",
        method : 'post',
        headers: {
            'Authorization': token,
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
            if (!isJpgOrPng) {
                message.error('You can only upload jpg, png,and pdf file only!');
            }
            return isJpgOrPng;
        },
        onChange(info) {
            formData.append('file', info.file);
            fileUID = info.file.uid;
            console.log("index : "+info.file.uid);
            console.log(JSON.stringify(info));
            if(info.file.status === "done") {
                if (info.file.xhr.status === 200) {
                    console.log("ServerResponse : "+info.file.response);
                    const data = info.file.response;
                    if(data.status === 1) {
                        let list = [...lineItems];
                        if(!list.hasOwnProperty(data.row_id)) {
                            list[data.row_id] = {};
                        }
                        let files = list[data.row_id].files || [];
                        let file_location = list[data.row_id].file_location || [];
                        files.push(data.data.id);
                        file_location.push(data.data.location);
                        list[data.row_id].files = files;
                        list[data.row_id].file_location = file_location;
                        setLineItems(list);
                        console.log("Files : "+JSON.stringify(list[data.row_id]));
                        message.success(`${info.file.name} file uploaded successfully`);
                    }

                } else {
                    message.error(`${info.file.name} file upload failed.`);
                }
            }
        }
    }

    const  calculateTotal = () => {
        let total = 0;
        const inputFields = document.querySelectorAll('.amountInput');

        inputFields.forEach(function(inputField) {
            total += parseFloat(inputField.value) || 0;
        });
        setTotal(total);
    }

    //console.log("UserData : "+userData);

    return (
        <Card title={Title} extra={
            isEdit === 1
                ? CisUI().addAction('../../'+jsonfile.urls.list)
                : CisUI().addAction('../'+jsonfile.urls.list)
        }>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                {/*{InputFields(jsonfile.input,userData,isEdit,props)}*/}
                <Row>
                    <Col className={"col-md-12"}>
                        {InputOnly(jsonfile.input,userData,isEdit,props)}
                    </Col>
                    <Col className={"col-md-12"}>
                        <Card title={"Expense Details"}>
                            <table className={"table table-bordered"}>
                                <thead>
                                <tr>
                                    <th>Description</th>
                                    <th style={{width: '200px', textAlign: 'right'}}>Amount ($)</th>
                                    <th>Note</th>
                                    <th>Attachment</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    isEdit === 1 ? <>
                                        {
                                            state?.items?.map((item,index)=>{
                                                return <tr>
                                                    <td>{item.item_name}</td>
                                                    <td>
                                                        <Form.Item
                                                            name={"amount_"+item.id}
                                                            initialValue={item.amount}
                                                            onChange={calculateTotal}
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: ""
                                                                },
                                                            ]}
                                                        >
                                                            <Input className="amountInput" style={{textAlign: 'right'}} />
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item
                                                            name={"note_"+item.id}
                                                            initialValue={item.remark}
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: "Enter a note here"
                                                                },
                                                            ]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Upload data= {
                                                            {
                                                                file : formData,
                                                                type : "expenseclaim",
                                                                file_id : fileUID,
                                                                row_id : item.id,
                                                                line_index : index
                                                            }
                                                        } {...uplod}>
                                                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                                        </Upload>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </>: <>
                                    {
                                        ClaimType?.map((item,index)=>{
                                            return <tr>
                                                <td>{item.name}</td>
                                                <td>
                                                    <Form.Item
                                                        name={"amount_"+item.id}
                                                        initialValue={0}
                                                        onChange={calculateTotal}
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: ""
                                                            },
                                                        ]}
                                                    >
                                                        <Input className="amountInput" style={{textAlign: 'right'}} />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item
                                                        name={"note_"+item.id}
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: "Enter a note here"
                                                            },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Upload data= {
                                                        {
                                                            file : formData,
                                                            type : "expenseclaim",
                                                            file_id : fileUID,
                                                            row_id : item.id,
                                                            line_index : index
                                                        }
                                                    } {...uplod}>
                                                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                                    </Upload>
                                                </td>
                                            </tr>
                                        })
                                    }
                                    </>
                                }
                                </tbody>
                                <tfoot>
                                <tr>
                                    <th>Total</th>
                                    <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(total)}</th>
                                    <th></th>
                                </tr>
                                </tfoot>
                            </table>
                        </Card>
                    </Col>

                    <Col lg={24} xs={24} style={{ textAlign: "center" }}>
                        {
                            ClaimType.length > 0 ?
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button> : <></>
                        }
                    </Col>

                </Row>


            </Form>
        </Card>
    );
};

export default NewExpenseClaim;