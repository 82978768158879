import React from "react";
import {Route, Switch} from "react-router-dom";

import CustomerTypeList from "../../containers/yotta/crm/customer_type/CustomerTypeList";
import NewCustomerType from "../../containers/yotta/crm/customer_type/NewCustomerType";
import LeadList from "../../containers/yotta/crm/customer_lead/LeadList";
import NewLead from "../../containers/yotta/crm/customer_lead/NewLead";
import CustomerList from "../../containers/yotta/crm/customer_info/CustomerList";
import TaskList from "../../containers/yotta/crm/activity_records/task/TaskList";
import NewTask from "../../containers/yotta/crm/activity_records/task/NewTask";
import EventList from "../../containers/yotta/crm/activity_records/event/EventList";
import NewEvent from "../../containers/yotta/crm/activity_records/event/NewEvent";
import CallLogList from "../../containers/yotta/crm/activity_records/calllog/CallLogList";
import NewCallLog from "../../containers/yotta/crm/activity_records/calllog/NewCallLog";
import VisitList from "../../containers/yotta/crm/activity_records/visit/VisitList";
import NewVisit from "../../containers/yotta/crm/activity_records/visit/NewVisit";
import NewInvoice from "../../containers/yotta/crm/invoice/newinvoice";
import EditInvoice from "../../containers/yotta/crm/invoice/editinvoice";
import InvoiceList from "../../containers/yotta/crm/invoice/invoicelist";
import InvoiceDetails from "../../containers/yotta/crm/invoice/invoicedetails";
import InvoicePayment from "../../containers/yotta/crm/invoice/payment";
import UnpaidInvoiceList from "../../containers/yotta/crm/invoice/unpaidinvoice";
import NewCreditNote from "../../containers/yotta/crm/creditnote/newcreditnote";
import NewCreditNoteBYID from "../../containers/yotta/crm/creditnote/newcreditnotebyid";
import CreditNoteList from "../../containers/yotta/crm/creditnote/creditnotelist";
import CreditNoteDetails from "../../containers/yotta/crm/creditnote/notedetails";
import PendingCreditNoteList from "../../containers/yotta/crm/creditnote/pendingcreditnote";
import CompanyList from "../../containers/yotta/company/cominfo/list";
import AutoLogoutTimer from "../../util/AutoLogoutTimer";
import PaidInvoiceList from "../../containers/yotta/crm/invoice/paidinvoice";
import InvoicePaymentHistory from "../../containers/yotta/crm/invoice/paymenthistory";
import ScreenShare from "../../containers/yotta/support/share";

const CRM = ({match}) => (
    <Switch>
        <Route path={`${match.url}/customertype`} component={CustomerTypeList}/>
        <Route path={`${match.url}/newcustomertype`} component={NewCustomerType}/>
        <Route path={`${match.url}/customertypeedit/:id`} component={NewCustomerType}/>
        <Route path={`${match.url}/leads`} component={LeadList}/>
        <Route path={`${match.url}/newlead`} component={NewLead}/>
        <Route path={`${match.url}/leadedit/:id`} component={NewLead}/>
        <Route path={`${match.url}/customers`} component={CustomerList}/>
        <Route path={`${match.url}/tasklist`} component={TaskList}/>
        <Route path={`${match.url}/newtask`} component={NewTask}/>
        <Route path={`${match.url}/taskedit/:id`} component={NewTask}/>
        <Route path={`${match.url}/eventlist`} component={EventList}/>
        <Route path={`${match.url}/newevent`} component={NewEvent}/>
        <Route path={`${match.url}/eventedit/:id`} component={NewEvent}/>
        <Route path={`${match.url}/callloglist`} component={CallLogList}/>
        <Route path={`${match.url}/newcalllog`} component={NewCallLog}/>
        <Route path={`${match.url}/calllogedit/:id`} component={NewCallLog}/>

        <Route path={`${match.url}/visitlist`} component={VisitList}/>
        <Route path={`${match.url}/newvisit`} component={NewVisit}/>
        <Route path={`${match.url}/visitedit/:id`} component={NewVisit}/>
        <Route path={`${match.url}/newinvoice`} component={NewInvoice}/>
        <Route path={`${match.url}/invoicelist`} component={InvoiceList}/>
        <Route path={`${match.url}/invoicedetails/:id`} component={InvoiceDetails}/>
        <Route path={`${match.url}/invoiceedit/:id`} component={EditInvoice}/>
        <Route path={`${match.url}/payment/:id`} component={InvoicePayment}/>
        <Route path={`${match.url}/unpaidinvoice`} component={UnpaidInvoiceList}/>
        <Route path={`${match.url}/paidinvoice`} component={PaidInvoiceList}/>
        <Route path={`${match.url}/invpaymenthistory`} component={InvoicePaymentHistory}/>
        <Route path={`${match.url}/newcreditnote`} component={NewCreditNote}/>
        <Route path={`${match.url}/creditnotelist`} component={CreditNoteList}/>
        <Route path={`${match.url}/pendingcreditnote`} component={PendingCreditNoteList}/>
        <Route path={`${match.url}/newcreditnotebyid/:id`} component={NewCreditNoteBYID}/>
        <Route path={`${match.url}/cnotedetails/:id`} component={CreditNoteDetails}/>
        <Route path={`${match.url}/screenshare`} component={ScreenShare}/>


    </Switch>
);

export default CRM;
