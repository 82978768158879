import React, {useState, useEffect} from "react";
import {Form, Input, Button, Card, Checkbox, message, Upload, Select, DatePicker, notification} from 'antd';
import jsonfile from './invoice.json';
import {CisUI} from '../../../../util/CISUI';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import SelectData from "../../../../util/selectdata";
import moment from "moment";
import StaticSelectedData from "../../../../util/StaticSelectData";
import CircularProgress from "../../../../components/CircularProgress";
import IntlMessages from "../../../../util/IntlMessages";
import { UploadOutlined } from '@ant-design/icons';

const {Option} = Select;

const FundInvoiceEdit = (props) => {

	const formRef = React.createRef();

	const [state, setState] = useState({
		data: [],
		rows: []
	})

	const [fund, setFund] = useState([]);
	const [receivable, setReceivable] = useState([]);
	const [accounts, setAccounts] = useState([]);
	const [journalPost, setJournalPost] = useState(false);
	const [customer, setcustomer] = useState([]);
	const [lineItems, setLineItems] = useState([]);
	const [totalAmount, setTotalAmount] = useState(0);
	const classifications = SelectData("classifications");

	const [bill, setBill] = useState(
		{
			total : 0,
			grand_total : 0,
			freight : 0,
			taxp : 0,
			tax_amount : 0,
			discount_p : 0,
			discount_amount : 0
		}
	);

	const [comp, setComp] = useState(
		{
			com_address: "",
			com_city: "",
			com_state: "",
			com_zip_code: ""
		}
	);

	const [vend, setVend] = useState(
		{
			customer_address: "",
			customer_city: "",
			customer_state: "",
			customer_zip_code: "",
			shipping_method: ""
		}
	);

	const handleChange = (e) => {
		setComp({
			[e.target.name]: e.target.value
		})
	}

	const userID = props.match.params.id;

	const [userData, setUserData] = useState([]);
	const [companyID, setCompanyId] = useState("");
	const [items, setItems] = useState([]);
	let edit_data = [];

	const getEditData = async() => {
		await axios.get(Config.apiserver + jsonfile.urls.view + "/" +userID, CisUI().HeaderRequest)
			.then(res => {
				// console.log(res.data)
				if (res.data.status === 1) {
					setUserData(res.data)
					setCompanyId(res.data.invoice.company_id)
					setItems(res.data.items)

					if(res.data?.invoice?.journal_post === 1) {
						setJournalPost(true);
					}

					const lines = res.data.items;
					let object = [];
					lines.map((rows, indx) => {
						object.push({
							id: rows.id,
							account_id: rows.account_id,
							fund_program_id: rows.fund_program_id,
							func_expense_id: rows.func_expense_id,
							restriction_type: rows.restriction_type,
							amount: rows.total,
							note: rows.name,
							classification : rows.class_id,
							files : rows.files,
							file_location : rows.file_location,
						})
					})

					let ttl = 0;
					object.map((item,index) => (
						ttl += parseFloat(item.amount)
					))
					setTotalAmount(ttl);

					setLineItems(object);

				} else {
					console.log("errors")
				}
			})
			.catch(errors => {
				console.log(errors);
			});
	}

	const Membr = SelectData("donation/memberlist");

	const getEditCustomerData = async(value) => {
		// console.log(value)
		await axios.get("customer/customerinfobycompany?com_id="+value)
			.then((res) => {
				// console.log(res.data);
				if(res.data.status === 1) {
					console.log(res.data.data)
					setcustomer(res.data.data);
					setReceivable(res.data.receiveable);
					setAccounts(res.data.accounts);
					setFund(res.data.fund);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	let isEdit = 0;
	if (userID > 0) {
		isEdit = 1;
	}

	useEffect(() => {
		getEditData()
		getEditCustomerData(companyID)
	}, [companyID])


	useEffect(() => {
		let totals = 0;
		if(items == ""){
			setState({
				rows: edit_data
			});
		} else {
			items.map((item, index) =>
				edit_data.push({
					code: item.code,
					name: item.name,
					qty: item.quantity,
					price: item.price,
					amount: item.total
				})
			)
			setState({
				rows: edit_data
			});

			items.map((it, i) =>
				totals += it.total
			)
			setBill({
				total: totals
			})
		}
	}, [items]);


	let endpoint = jsonfile.urls.edit + "/" + userID;
	let redirectto = "../../" + jsonfile.urls.list;

	const Companies = SelectData("company/companylists");

	const [form] = Form.useForm();
	const history = useHistory();

	const onFinish = (values) => {
		values["items"] = lineItems;
		values["summary"] = totalAmount;
		console.log(JSON.stringify(values));

		axios.post(Config.apiserver + endpoint, values)
			.then(res => {
				console.log(res)
				if (res.data.status === 1) {
					notification.success({
						message: 'Success',
						type: "success",
						description: res.data.msg
					});
					history.push(redirectto);
				} else {
					notification.warning({
						message: 'Alert',
						type: "warning",
						description: res.data.msg
					});
				}
			})
			.catch(errors => {
				console.log(errors);
			});
	};

	const onCompanyChange = value => {
		console.log("selected : "+ value);
		handleAddRow();
		let fl = Companies.filter(item => item.id === value);
		if(fl.length > 0) {
			const company = fl[0];
			const area = company['area'];
			axios.get("customer/customerinfobycompany?com_id="+value)
				.then((res) => {
					console.log(res.data);
					if(res.data.status === 1) {
						setcustomer(res.data.data);
						setReceivable(res.data.receiveable);
						setAccounts(res.data.accounts);
					}
				})
				.catch((error) => {
					console.log(error);
				});

			// ReceiveAble = SelectData("receiveable?com_id="+value+"&area="+area);
			// Accounts = SelectData("accountslist?com_id="+value+"&area="+area);

			// console.log("selected : "+ JSON.stringify(company));
			//console.log("address : "+ company.address_line_1+", "+company.address_line_2);
			document.getElementById("input_com_address").value = "Test";
			formRef.current.setFieldsValue({
				com_address: company.address_line_1+", "+company.address_line_2,
				com_city: company.city,
				com_state: company.state,
				com_zip_code: company.zip_code,
				customer: "",
				customer_address: "",
				customer_city: "",
				customer_state: "",
				customer_zip_code: "",
				shipping_method: "",
				sales_tax_pp: 0
			});
		}
	};

	const onChange = (e: CheckboxChangeEvent) => {
		console.log(`checked = ${e.target.checked}`);
		if(e.target.checked === true) {
			setJournalPost(true);
		}
		else {
			setJournalPost(false);
		}
	};

	const oncustomerChange = value => {
		console.log("selected : "+ value);
		let fl = customer.filter(item => item.id === value);
		if(fl.length > 0) {
			const company = fl[0];
			console.log("selected : "+ JSON.stringify(company));
			var bill = company["address"];

			formRef.current.setFieldsValue({
				customer_address: bill,
				customer_city: company["city"],
				customer_state: company["state_name"],
				customer_zip_code: company["zip_code"]
			});

			// var total = bill.total;
			// var freight = company.shipping_freight;
			// var taxp = company.shipping_tax;
			// var discount_p = bill.discount_p;
			// var discount_amount = total * discount_p / 100;
			// var totalBeforeTax = total - discount_amount ;
			// var taxAmount = totalBeforeTax * (taxp / 100);
			// var grandTotal = totalBeforeTax + taxAmount + freight;
			//
			// setBill({
			//     total : total,
			//     grand_total : grandTotal,
			//     freight : freight,
			//     taxp : taxp,
			//     tax_amount : taxAmount,
			//     discount_p : discount_p,
			//     discount_amount : discount_amount
			// });
		}
	}


	const ItemCellChange = idx => e => {
		const { name, value } = e.target;
		const rows = [...lineItems];
		console.log("selectedITems:"+ JSON.stringify(rows[idx]));

		if(name === "amount") {
			rows[idx] = {
				id: rows[idx].id,
				account_id: rows[idx].account_id,
				fund_program_id: rows[idx].fund_program_id,
				func_expense_id: rows[idx].func_expense_id,
				restriction_type: rows[idx].restriction_type,
				amount: value,
				note: rows[idx].note,
				classification : rows[idx].classification,
				files : rows[idx].files,
				file_location : rows[idx].file_location,
			};
		}
		else if(name === "note") {
			rows[idx] = {
				id: rows[idx].id,
				account_id: rows[idx].account_id,
				fund_program_id: rows[idx].fund_program_id,
				func_expense_id: rows[idx].func_expense_id,
				restriction_type: rows[idx].restriction_type,
				amount: rows[idx].amount,
				note: value,
				classification : rows[idx].classification,
				files : rows[idx].files,
				file_location : rows[idx].file_location,
			};
		}

		setLineItems(rows);

		let ttl = 0;
		rows.map((item,index) => (
			ttl += parseFloat(item.amount)
		))
		setTotalAmount(ttl);

	};

	const ItemCellChangeAccount = idx => e => {
		console.log("selectedValued:" + e);
		console.log("selectedValuedID:" + idx);

		const rows = [...lineItems];
		rows[idx] = {
			id: rows[idx].id,
			account_id: e,
			fund_program_id: rows[idx].fund_program_id,
			func_expense_id: rows[idx].func_expense_id,
			restriction_type: rows[idx].restriction_type,
			amount: rows[idx].amount,
			note: rows[idx].note,
			classification : rows[idx].classification,
			files : rows[idx].files,
			file_location : rows[idx].file_location,
		};

		setLineItems(rows);

	}

	const ItemCellChangeFundProgram = idx => e => {
		console.log("selectedValued:" + e);
		console.log("selectedValuedID:" + idx);

		const rows = [...lineItems];
		rows[idx] = {
			id: rows[idx].id,
			account_id: rows[idx].account_id,
			fund_program_id: e,
			func_expense_id: rows[idx].func_expense_id,
			restriction_type: rows[idx].restriction_type,
			amount: rows[idx].amount,
			note: rows[idx].note,
			classification : rows[idx].classification,
			files : rows[idx].files,
			file_location : rows[idx].file_location,
		};

		setLineItems(rows);

	}


	const ItemCellChangeRestrictionType = idx => e => {
		console.log("selectedValued:" + e);
		console.log("selectedValuedID:" + idx);

		const rows = [...lineItems];
		rows[idx] = {
			id: rows[idx].id,
			account_id: rows[idx].account_id,
			fund_program_id: rows[idx].fund_program_id,
			func_expense_id: rows[idx].func_expense_id,
			restriction_type: e,
			amount: rows[idx].amount,
			note: rows[idx].note,
			classification : rows[idx].classification,
			files : rows[idx].files,
			file_location : rows[idx].file_location,
		};

		setLineItems(rows);

	}

	const ItemCellChangeClassification = idx => e => {
		console.log("selectedValued:" + e);
		console.log("selectedValuedID:" + idx);

		const rows = [...lineItems];
		rows[idx] = {
			id: rows[idx].id,
			account_id: rows[idx].account_id,
			fund_program_id: rows[idx].fund_program_id,
			func_expense_id: rows[idx].func_expense_id,
			restriction_type: rows[idx].restriction_type,
			amount: rows[idx].amount,
			note: rows[idx].note,
			classification : e,
			files : rows[idx].files,
			file_location : rows[idx].file_location,
		};

		setLineItems(rows);

	}


	const token = localStorage.getItem('token');
	let formData = new FormData();
	let fileUID = "";
	const uplod = {
		name: 'file',
		action: Config.apiserver+"fileupload",
		method : 'post',
		headers: {
			'Authorization': token,
		},
		beforeUpload(file) {
			const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
			if (!isJpgOrPng) {
				message.error('You can only upload jpg, png,and pdf file only!');
			}
			return isJpgOrPng;
		},
		onChange(info) {
			formData.append('file', info.file);
			fileUID = info.file.uid;
			console.log("index : "+info.file.uid);
			console.log(JSON.stringify(info));
			if(info.file.status === "done") {
				if (info.file.xhr.status === 200) {
					console.log("ServerResponse : "+info.file.response);
					const data = info.file.response;
					if(data.status === 1) {
						const list = [...lineItems];
						let files = list[data.line_index].files || [];
						let file_location = list[data.line_index].file_location || [];
						files.push(data.data.id);
						file_location.push(data.data.location);
						list[data.line_index].files = files;
						list[data.line_index].file_location = file_location;
						setLineItems(list);
						console.log("Files : "+JSON.stringify(list[data.line_index]));
						message.success(`${info.file.name} file uploaded successfully`);
					}

				} else {
					message.error(`${info.file.name} file upload failed.`);
				}
			}
		}
	}

	const handleAddRow = () => {
		const item = {
			id: 0,
			account_id: null,
			fund_program_id: 1,
			func_expense_id: 1,
			restriction_type: "without",
			amount: null,
			note: null,
			classification : null,
			files : null,
			file_location : null,
		};
		setLineItems([...lineItems, item]);
	};


	const handleRemoveRow = idx => e => {
		// console.log(idx);
		// console.log(state.rows);
		let filteredArray = state.rows.filter((item, index) => index !== idx)
		setState({rows: filteredArray});
	};


	const InvoiceItems = (
		<>
			<table id="itemtable" className="ptable table table-bordered">
				<thead>
				<tr>
					<th>#</th>
					<th>Account</th>
					<th>Amount</th>
					<th>Description</th>
					<th>Fund Program</th>
					<th>Restriction Type</th>
					<th>Classification</th>
					{/*<th>Attachment</th>*/}
				</tr>
				</thead>
				<tbody>
				{lineItems.map((item, idx) => (
					<tr id="addr0" key={idx}>
						<td style={{width: '30px'}}>
							{/*<a onClick={handleRemoveRow(idx)} className="btn btn-primary btn-circle">*/}
							{/*	<i className="fa fa-trash"></i>*/}
							{/*</a>*/}
						</td>
						<td style={{width: '250px'}}>
							<Select
								showSearch
								placeholder="Select an account"
								optionFilterProp="children"
								style={{width: '100%'}}
								defaultValue={item.account_id}
								onChange={ItemCellChangeAccount(idx)}
								filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							>
								{
									accounts.map((items, index) =>
										<Option key={++index} value={items.id}>{items.name}</Option>
									)
								}
							</Select>
						</td>
						<td style={{width: '90px'}}>
							<input className="form-control text-right" style={{textAlign : 'right'}} placeholder="Amount" name="amount" value={item.amount} onChange={ItemCellChange(idx)}  />
						</td>
						<td>
							<input className="form-control text-right" style={{textAlign : 'left'}} name="note" value={item.note} onChange={ItemCellChange(idx)}  />
						</td>
						<td>
							<Select
								showSearch
								placeholder="Select a fund program"
								optionFilterProp="children"
								style={{width: '100%'}}
								defaultValue={item.fund_program_id}
								onChange={ItemCellChangeFundProgram(idx)}
								filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
							>
								{
									fund.map((items, index) =>
										<Option key={++index} value={items.id}>{items.name}</Option>
									)
								}
							</Select>
						</td>
						<td>
							<Select
								showSearch
								placeholder="Select a restriction type"
								optionFilterProp="children"
								style={{width: '100%'}}
								defaultValue={item.restriction_type === "with" ? "with" : "without"}
								onChange={ItemCellChangeRestrictionType(idx)}
								filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
							>
								{
									StaticSelectedData("restrictiontype").map((items, index) =>
										<Option key={++index} value={items.id}>{items.name}</Option>
									)
								}
							</Select>
						</td>
						<td>
							<Select
								showSearch
								placeholder="Select a class"
								optionFilterProp="children"
								style={{width: '100%'}}
								defaultValue={item.class_id}
								onChange={ItemCellChangeClassification(idx)}
								filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							>
								{
									classifications.map((items, index) =>
										<Option key={++index} value={items.id}>{items.name}</Option>
									)
								}
							</Select>
						</td>
						{/*<td>*/}
						{/*	<Upload data= {*/}
						{/*		{*/}
						{/*			file : formData,*/}
						{/*			type : "invoice",*/}
						{/*			file_id : fileUID,*/}
						{/*			row_id : item.id,*/}
						{/*			line_index : idx*/}
						{/*		}*/}
						{/*	} {...uplod}>*/}
						{/*		<Button icon={<UploadOutlined />}>Click to Upload</Button>*/}
						{/*	</Upload>*/}
						{/*</td>*/}
					</tr>
				))}
				</tbody>
				<tfoot>
				<tr>
					<td style={{textAlign: 'right', fontWeight: 'bold'}} colSpan={2}><IntlMessages id="Total"/></td>
					<td style={{
						textAlign: 'right',
						fontWeight: 'bold'
					}}>{CisUI().getCurrencyFormated1(totalAmount)}</td>
				</tr>
				</tfoot>
			</table>
		</>
	)

	return (
		<Card title={<IntlMessages id={jsonfile.edittitle+" #"+userData?.invoice?.invoice_number}/>} extra={
			isEdit === 1
				? CisUI().addAction(redirectto)
				: CisUI().addAction(redirectto)
		}>
			{ userData == "" && companyID == "" ? <CircularProgress /> : (
				<Form
					form={form}
					{...CisUI().formItemLayout}
					name="input"
					ref={formRef}
					className="ant-advanced-search-form"
					onFinish={onFinish}
					// initialValues={{remember: true}}
				>

					<div className="row">

						<div className="col-md-2">
							<div className="gx-form-row0">
								<Form.Item
									name="date"
									label={<IntlMessages id="Order Date"/>}
									initialValue={moment(userData.invoice.date)}
									rules={[
										{
											required: true,
											message: "Order date"
										},
									]}
								>
									<DatePicker format={CisUI().dateFormat} placeholder="Order Date"/>
								</Form.Item>

							</div>
						</div>

						<div className="col-md-2">
							<div className="gx-form-row0">
								<Form.Item
									name="target_date"
									label={<IntlMessages id="Target Date"/>}
									initialValue={moment()}
									rules={[
										{
											required: true,
											message: "Target Date is required"
										},
									]}
								>
									<DatePicker format={CisUI().dateFormat} placeholder="Date" />
								</Form.Item>

							</div>
						</div>

						<div className="col-md-3">
							<Form.Item
								name="member_id"
								label={<IntlMessages id="Member"/>}
								rules={[
									{
										required: true,
										message: "Select a member"
									},
								]}
							>
								<Select
									showSearch
									placeholder="Select a member"
									optionFilterProp="children"
									filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								>
									{
										Membr.map((items, index) =>
											<Option key={++index} value={items.id}>{items.name} - {items.tithe_number}</Option>
										)
									}
								</Select>
							</Form.Item>
						</div>

						<div className="col-md-3">
							<Form.Item
								name="receivable_id"
								label={<IntlMessages id="Accounts Receivable"/>}
								rules={[
									{
										required: true,
										message: "Select a accounts receivable"
									},
								]}
								initialValue={userData.invoice.receivable_id}
							>
								<Select
									showSearch
									placeholder="Select a receivable account"
									optionFilterProp="children"
									filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								>
									{
										receivable.map((items, index) =>
											<Option key={++index} value={items.id}>{items.name}</Option>
										)
									}
								</Select>
							</Form.Item>
						</div>

						{
							 userData?.invoice?.journal_post === 1 ? <>
								<div className="col-md-2">
									<Form.Item
										name="journal_post1"
										label={<IntlMessages id="Journal Post?"/>}
									>
										<Checkbox checked={journalPost} onChange={onChange}>Yes</Checkbox>
									</Form.Item>
								</div>
							</> : <></>
						}

						<div className="col-md-3">
							<Form.Item
								name="note"
								label={<IntlMessages id="Note/Remark"/>}
								rules={[
									{
										required: true,
										message: "Note is required"
									},
								]}
							>
								<Input placeholder={"Note"} />
							</Form.Item>
						</div>

					</div>


					<div className="row">
						<div className="col-md-12">
							<div className="row">
								<div className="col-md-6">
									<h2><IntlMessages id="Invoice Items"/></h2>
								</div>
								<div className="col-md-6" style={{textAlign: "right"}}>
									{/*<a className="btn btn-primary" onClick={handleAddRow}><IntlMessages id="Add More"/></a>*/}
								</div>
							</div>


							{InvoiceItems}


						</div>
					</div>

					<div className="row">
						<div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
							<Button id="submitbutton" type="primary" htmlType="submit">
								<IntlMessages id="Save Invoice"/>
							</Button>
							<Button type="info" onClick={() => history.goBack()} htmlType="back"><IntlMessages id="Cancel"/></Button>
						</div>
					</div>

				</Form>
			)}

		</Card>
	);
};

export default FundInvoiceEdit;