import React from "react";
import {Route, Switch} from "react-router-dom";
import NewCategory from "../../containers/yotta/inventory/category/new";
import CategoryList from "../../containers/yotta/inventory/category/list";
import InventoryItemList from "../../containers/yotta/inventory/items/list";
import NewInventoryItem from "../../containers/yotta/inventory/items/new";
import NewPurchaseStockOrder from "../../containers/yotta/inventory/purchase/new";
import InventoryPurchaseOrderHistory from "../../containers/yotta/inventory/purchase/list";
import InventoryPODetails from "../../containers/yotta/inventory/purchase/details";
import NewReqStockOrder from "../../containers/yotta/inventory/requisition/new";
import InventoryReqOrderHistory from "../../containers/yotta/inventory/requisition/list";
import InventoryReqDetails from "../../containers/yotta/inventory/requisition/details";
import EditInvPurchaseOrder from "../../containers/yotta/inventory/purchase/edit_purchase";


const Inventory = ({match}) => (
    <Switch>
        <Route path={`${match.url}/invaddnewcategory`} component={NewCategory}/>
        <Route path={`${match.url}/invcategorylist`} component={CategoryList}/>
        <Route path={`${match.url}/invcatedit/:id`} component={NewCategory}/>
        <Route path={`${match.url}/invitemlist`} component={InventoryItemList}/>
        <Route path={`${match.url}/invaddnewitem`} component={NewInventoryItem}/>
            <Route path={`${match.url}/invitemedit/:id`} component={NewInventoryItem}/>
        <Route path={`${match.url}/invnewpurchase`} component={NewPurchaseStockOrder}/>
        <Route path={`${match.url}/invpurhistory`} component={InventoryPurchaseOrderHistory}/>
        <Route path={`${match.url}/podetails/:id`} component={InventoryPODetails}/>
        <Route path={`${match.url}/invpoedit/:id`} component={EditInvPurchaseOrder}/>

        <Route path={`${match.url}/invnewrequisition`} component={NewReqStockOrder}/>
        <Route path={`${match.url}/invreqhistory`} component={InventoryReqOrderHistory}/>
        <Route path={`${match.url}/reqdetails/:id`} component={InventoryReqDetails}/>
    </Switch>
);

export default Inventory;
