import React from "react";
import {Route, Switch} from "react-router-dom";

import VendorTypeList from "../../containers/yotta/vendor_management/vendor_type/VendorTypeList";
import NewVendorType from "../../containers/yotta/vendor_management/vendor_type/NewVendorType";
import VendorInfoList from "../../containers/yotta/vendor_management/vendor_info/VendorInfoList";
import NewVendorInfo from "../../containers/yotta/vendor_management/vendor_info/NewVendorInfo";
import NewBill from "../../containers/yotta/vendor_management/bill/newbill";
import EditBill from "../../containers/yotta/vendor_management/bill/billedit";
import EditBillInfo from "../../containers/yotta/vendor_management/bill/billeditraw";
import NewBillForm from "../../containers/yotta/vendor_management/bill/newbillfromraw";
import BillDetails from "../../containers/yotta/vendor_management/bill/billdetails";
import BillHistory from "../../containers/yotta/vendor_management/bill/billhistory";
import BillPaymentHistory from "../../containers/yotta/vendor_management/bill_payment/paymenthistory";
import BillPaymentEdit from "../../containers/yotta/vendor_management/bill_payment/billpaymentedit";
import NewBillPayment from "../../containers/yotta/vendor_management/bill_payment/newpayment";
import PendingCheck from "../../containers/yotta/vendor_management/check/pendingcheck";
import CheckList from "../../containers/yotta/vendor_management/check/checklist";
import PendingBill from "../../containers/yotta/vendor_management/bill/pendingbill";
import UnpaidBill from "../../containers/yotta/vendor_management/bill/unpaidbill";
import NewVoucher from "../../containers/yotta/vendor_management/voucher/newvoucher";
import VoucherList from "../../containers/yotta/vendor_management/voucher/vouchers";
import VoucherDetails from "../../containers/yotta/vendor_management/voucher/voucherdeatils";
import NewBillMulitple from "../../containers/yotta/vendor_management/bill/newbilltv";
import NewMultyLineBill from "../../containers/yotta/vendor_management/bill/newmultylinebill";
import NewRequisition from "../../containers/yotta/vendor_management/requisitions/newrequisition";
import RequisitionList from "../../containers/yotta/vendor_management/requisitions/requisitionlist";
import RequisitionDetails from "../../containers/yotta/vendor_management/requisitions/requisitiondetails";
import VoidedCheck from "../../containers/yotta/vendor_management/check/voidedcheck";
import VouhcerEdit from "../../containers/yotta/vendor_management/voucher/voucheredit";
import PendingVoucher from "../../containers/yotta/vendor_management/voucher/pendingvoucher";
import RequesitionEdit from "../../containers/yotta/vendor_management/requisitions/expensereqedit";
import ApprovedVoucher from "../../containers/yotta/vendor_management/voucher/approvedvoucher";
import ApprovedRequisition from "../../containers/yotta/vendor_management/requisitions/approvedrequisition";
import PendingRequisition from "../../containers/yotta/vendor_management/requisitions/pendingrequisition";
import UnpaidVoucher from "../../containers/yotta/vendor_management/voucher/unbilledvoucher";

const Vendor = ({match}) => (
    <Switch>
        <Route path={`${match.url}/vendortype`} component={VendorTypeList}/>
        <Route path={`${match.url}/newvendortype`} component={NewVendorType}/>
        <Route path={`${match.url}/vendortypeedit/:id`} component={NewVendorType}/>
        <Route path={`${match.url}/vendorinfo`} component={VendorInfoList}/>
        <Route path={`${match.url}/newvendorinfo`} component={NewVendorInfo}/>
        <Route path={`${match.url}/vendorinfoedit/:id`} component={NewVendorInfo}/>
        {/*<Route path={`${match.url}/newbill`} component={NewBillForm}/>*/}
        <Route path={`${match.url}/newbill`} component={NewMultyLineBill}/>
        <Route path={`${match.url}/billhistory`} component={BillHistory}/>
        <Route path={`${match.url}/paymenthistory`} component={BillPaymentHistory}/>
        <Route path={`${match.url}/paymentedit/:id`} component={BillPaymentEdit}/>
        <Route path={`${match.url}/newpayment`} component={NewBillPayment}/>
        <Route path={`${match.url}/billedit/:id`} component={EditBill}/>
        <Route path={`${match.url}/billinfoedit/:id`} component={EditBillInfo}/>
        <Route path={`${match.url}/newbillt`} component={NewBill}/>
        <Route path={`${match.url}/newbilltv/:id`} component={NewBillMulitple}/>
        <Route path={`${match.url}/billdetails/:id`} component={BillDetails}/>
        <Route path={`${match.url}/pendingcheck`} component={PendingCheck}/>
        <Route path={`${match.url}/checklist`} component={CheckList}/>
        <Route path={`${match.url}/voidedcheck`} component={VoidedCheck}/>
        <Route path={`${match.url}/pendingbill`} component={PendingBill}/>
        <Route path={`${match.url}/unpaidbill`} component={UnpaidBill}/>
        <Route path={`${match.url}/newvoucher`} component={NewVoucher}/>
        <Route path={`${match.url}/voucherlist`} component={VoucherList}/>
        <Route path={`${match.url}/voucherdetails/:id`} component={VoucherDetails}/>
        <Route path={`${match.url}/voucheredit/:id`} component={VouhcerEdit}/>
        <Route path={`${match.url}/newexpense`} component={NewRequisition}/>
        <Route path={`${match.url}/vexpenselist`} component={RequisitionList}/>
        <Route path={`${match.url}/requisitiondetails/:id`} component={RequisitionDetails}/>
        <Route path={`${match.url}/expensereqedit/:id`} component={RequesitionEdit}/>
        <Route path={`${match.url}/pendingvoucher`} component={PendingVoucher}/>
        <Route path={`${match.url}/approvedvoucher`} component={ApprovedVoucher}/>
        <Route path={`${match.url}/apvrequisition`} component={ApprovedRequisition}/>
        <Route path={`${match.url}/pendingrequisition`} component={PendingRequisition}/>
        <Route path={`${match.url}/unbilledvoucher`} component={UnpaidVoucher}/>
    </Switch>
);

export default Vendor;
