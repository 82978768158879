import {Form, Input, Button, Card, Modal, notification} from 'antd';
import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import jsonfile from './invoice.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";


var Barcode = require('react-barcode');

const ReachableContext = React.createContext();
const UnreachableContext = React.createContext();


const FundRaisInvoiceDetails = (props) => {

    const formRef = React.createRef();

    const [state, setState] = useState({
        po: {},
        items: [],
        payment: [],
        hasPayment: 0
    })


    const userID = props.match.params.id;

    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    const print = () => {
        window.print();
    };

    const onFinish = () => {

    }

    let Title = jsonfile.details_title;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {

        Title = jsonfile.details_title;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + jsonfile.urls.list;
    }

    useEffect(() => {
        getPODetails();
    }, []);

    const getPODetails = () => {
        axios.get(Config.apiserver + jsonfile.urls.view + "/" + userID)
            .then((res) => {
                // console.log(res.data);
                if (res.data.status === 1) {
                    setState({
                        po: res.data.invoice,
                        items: res.data.items,
                        payment: res.data.payment,
                        hasPayment: res.data.haspayment
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const [form] = Form.useForm();
    const history = useHistory();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showModalCofirm = () => {
        setIsConfirmModalVisible(true);
    };

    const handleOkConfirm = () => {
        setIsConfirmModalVisible(false);
        PoStatusUpdate(state.po.id,1);
    };

    const handleCancelConfirm = () => {
        setIsConfirmModalVisible(false);
    };

    const showModalReject = () => {
        setIsRejectModalVisible(true);
    };

    const handleOkReject = () => {
        setIsRejectModalVisible(false);
        PoStatusUpdate(state.po.id,3);
    };

    const handleCancelReject = () => {
        setIsRejectModalVisible(false);
    };

    const handleChange = () => {

    }



    const PoStatusUpdate = (id,status) => {
        // document.getElementById("loader").style.display = "block";
        // axios.get("purchase/postatuschange?po_id="+id+"&status="+status)
        //     .then((res) => {
        //         document.getElementById("loader").style.display = "none";
        //         console.log(res.data);
        //         if(res.data.status === 1) {
        //             notification.success({
        //                 message: 'Success',
        //                 type: "success",
        //                 description: res.data.msg
        //             });
        //             getPODetails();
        //         }
        //         else {
        //             document.getElementById("loader").style.display = "none";
        //             notification.success({
        //                 message: 'Failed',
        //                 type: "danger",
        //                 description: res.data.msg
        //             });
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //         document.getElementById("loader").style.display = "none";
        //     });
    }

    var totalAmount = 0;

    const config = {
        title: 'Alert',
        content: (
            <>
                <ReachableContext.Consumer>{name => `Reachable: ${name}!`}</ReachableContext.Consumer>
                <br />
                <UnreachableContext.Consumer>{name => `Unreachable: ${name}!`}</UnreachableContext.Consumer>
            </>
        ),
    };


    return (
        <>
            <Card title={Title} extra={
                    isEdit === 1
                        ? CisUI().addAction('../../' + jsonfile.urls.list)
                        : CisUI().addAction('../' + jsonfile.urls.list)
                }>
                {CisUI().showLoading}
                <div className="row">
                    <div className="col-md-12" style={{textAlign: 'center'}}>
                        <div style={{width: '100%', background: 'white', border: '1px #ddd solid', padding: '20px'}}>
                            <table style={{width: '100%'}}>
                                <tr>
                                    <td style={{width: '50%', textAlign: 'left'}}>
                                        <img alt="cop" style={{width: '80px'}} src={Config.print_logourl}/>
                                        {/*<div>{Config.address}</div>*/}
                                        {/*<div>{Config.city},{Config.state},{Config.zipcode}</div>*/}
                                        {/*<div>Phone : {Config.phone}</div>*/}
                                        {/*<div>Email : {Config.email}</div>*/}
                                        {/*<div>Website : {Config.website}</div>*/}
                                    </td>
                                    <td style={{width: '50%', textAlign: 'right'}}>
                                        <h2>Invoice</h2>
                                        <div>Date : {CisUI().DateFormat(state.po.date)}</div>
                                        <div>Invoice Number : {state.po.invoice_number}</div>
                                        <div><Barcode height={50} value={state.po.invoice_number}/></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width: '50%', textAlign: 'left', paddingTop: '20px'}}>
                                        <h3>Company Information : </h3>
                                        <h5>{state.po?.company}</h5>
                                        <div>{state.po.com_address}</div>
                                        <div>{state.po.com_city}, {state.po.com_state}, {state.po.com_zipcode}</div>
                                        <div>Phone : {state.po.company_phone}</div>
                                        <div>Email : {state.po.company_email}</div>
                                    </td>
                                    <td style={{width: '50%', textAlign: 'left', paddingTop: '20px'}}>
                                        <h3>Member Information : </h3>
                                        <h5>{state.po.customer_name}</h5>
                                        <div>{state.po.customer_address}</div>
                                        <div>{state.po.customer_city}, {state.po.customer_state}, {state.po.customer_zipcode}</div>
                                        <div>Phone : {state.po.customer_phone}</div>
                                        <div>Email : {state.po.customer_email}</div>
                                    </td>
                                </tr>
                            </table>

                            <table id="itemtable" style={{marginTop: '30px'}} className="ptable table table-bordered">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th style={{textAlign: 'left'}}>Account</th>
                                    <th style={{textAlign: 'left'}}>Item Description</th>
                                    <th style={{textAlign: 'right'}}>Amount</th>
                                    <th className="no-print">Attachment</th>
                                </tr>
                                </thead>
                                <tbody>
                                {state.items.map((item, idx) => (
                                    <tr id="addr0" key={idx}>
                                        <td style={{width: '30px'}}>{(idx + 1)}</td>
                                        <td style={{textAlign: 'left'}}>{item.account_number}-{item.account_name}</td>
                                        <td style={{textAlign: 'left'}}>{item.name}</td>
                                        <td style={{
                                            width: '70px',
                                            textAlign: 'right'
                                        }}>{CisUI().getCurrencyFormated1((item.total))}</td>
                                        <td className="no-print">{
                                            item?.files?.map((file, fl)=> {
                                                return <a href={Config.fileserver+""+file.location} target="_blank" className="btn btn-primary btn-sm btn-circle">
                                                    <i className="fa fa-file"></i>
                                                </a>
                                            })
                                        }</td>
                                    </tr>
                                ))}
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td colSpan={1} rowSpan={7} style={{verticalAlign: "top"}}></td>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}} colSpan={2}>Total</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(state.po.total)}</td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}} colSpan={2}>Paid Amount</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(state.po.paid_amount)}</td>
                                </tr>
                                {
                                    state.po.credit_amount > 0 ?
                                        <tr>
                                            <td style={{textAlign: 'right', fontWeight: 'bold'}} colSpan={2}>Credit Note</td>
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'bold'
                                            }}>({CisUI().getCurrencyFormated1(state.po.credit_amount)})</td>
                                        </tr>
                                        : ""
                                }
                                <tr>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}} colSpan={2}>Balance Due</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(state.po.total - (state.po.paid_amount + state.po.credit_amount))}</td>
                                </tr>
                                </tfoot>
                            </table>

                        </div>
                    </div>
                </div>

                <div className="row no-print">
                    <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>

                        <Button id="submitbutton" onClick={print} type="primary" htmlType="button">Print</Button>
                        {/*<Button id="submitbutton" type="primary" htmlType="submit">Send to Customer</Button>*/}
                        {/*<Button id="submitbutton" onClick={showModal} type="primary" htmlType="submit">Send to Anyone</Button>*/}
                        {
                            state.po.status === 1 ?
                                <>
                                    <Button id="submitbutton" type="primary" htmlType="button"><Link to={"../../donation/fundpayment/"+userID}>
                                        {
                                            state.hasPayment === 0 ? "Accept Payment" : "Accept Payment"
                                        }
                                    </Link></Button>
                                </>
                                :""
                        }
                        <Button type="info" onClick={() => history.goBack()} htmlType="back">Back</Button>
                    </div>
                </div>
            </Card>
            <Modal title="Send To Anyone" visible={isModalVisible} okText={"Send"} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    form={form}
                    {...CisUI().formItemLayout}
                    name="input"
                    ref={formRef}
                    className="ant-advanced-search-form"
                    onFinish={onFinish}>

                    <div className="row">
                        <div className="col-md-12">
                            <Form.Item
                                label="Email Address"
                                name="email"
                                onChange={handleChange}
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter email addres, separate by comma for multiple email "
                                    },
                                ]}
                            >
                                <Input placeholder="Enter email address" />
                            </Form.Item>
                        </div>
                    </div>

                </Form>
            </Modal>

            <Modal title="Alert" visible={isConfirmModalVisible} okText={"Confirm"} onOk={handleOkConfirm} onCancel={handleCancelConfirm}>
                <h4>Are you sure you want to approve ?</h4>
            </Modal>

            <Modal title="Alert" visible={isRejectModalVisible} okText={"Confirm"} onOk={handleOkReject} onCancel={handleCancelReject}>
                <h4>Are you sure you want to reject ?</h4>
            </Modal>

        </>
    );
};

export default FundRaisInvoiceDetails;