import React, {useState, useEffect} from "react";
import {Form, Tooltip, Input, Button, Card, Select, DatePicker, notification} from 'antd';
import jsonfile from './requisition.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {Link, useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import SelectData from "../../../../util/selectdata";
import moment from "moment";
import StaticSelectedData from "../../../../util/StaticSelectData";
import CircularProgress from "../../../../components/CircularProgress";

const {Option} = Select;
const {TextArea} = Input;
const queryString = require('query-string');

const RequesitionEdit = (props) => {

    const formRef = React.createRef();
    const [form] = Form.useForm();
    const history = useHistory();

    const [isMultyBill, setIsMultyBill] = useState(true);
    const [lineItems, setLineItems] = useState([]);
    const [billData, setBillData] = useState([]);
    const [comp, setComp] = useState([]);
    const [vendor, setVendor] = useState([]);
    const [accounts, setAccount] = useState([]);
    const [payable, setPayable] = useState([]);
    const [fund, setFund] = useState([]);
    const [expense, setExpense] = useState([]);
    const [companyID, setCompanyId] = useState("");
    const [totalAmount, setTotalAmount] = useState(0);

    const handleChange = (e) => {
        setComp({
            [e.target.name]: e.target.value
        })
    }

    let Vendors = [];
    const Companies = SelectData("company/companylists");

    const [comapny, setCompany] = useState([]);
    const [local, setLocal] = useState([]);

    const onCompanyChange = value => {
        console.log("selected : "+ value);
        let fl = Companies.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.users);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setExpense(res.data.expense);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            formRef.current.setFieldsValue({
                vendor: "",
                accounts_payable: "",
                accounts_id: ""
            });
        }
    }

    const [loading, setLoading] = useState([]);
    const [polist, setPolist] = useState({
        po_number : "",
        date: moment(),
        due_date: moment(),
        reference_no: "",
        company_id: null,
        vendor_id: null,
        payable_id: null,
        fund_program_id : null,
        func_expense_id : null,
        amount : "",
        description : "",
        remark : "",
    });


    const [state, setState] = useState({
        po: {},
        items: []
    });

    const parsed = queryString.parse(props.location.search);

    const po = parsed.po;
    let PORData = [];
    let poid = 0;
    if(po > 0) {
        poid = po;
    }

    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + jsonfile.urls.list;

    const userID = props.match.params.id;
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
        endpoint = jsonfile.urls.edit
    }

    let POData = {};

    const getEditData = () => {
        axios.get(jsonfile.urls.view + "/" + userID)
            .then((res) => {
                console.log("voucher edit");
                console.log(res.data.podata);
                if(res.data.status === 1) {
                    const lines = res.data.items;
                    setLineItems(lines);
                    if(res.data.isMultyBill === 0) {
                        setIsMultyBill(false);
                    }
                    setBillData(res.data.podata);
                    setCompanyId(res.data.podata.company_id)
                    // console.log("BIll : "+JSON.stringify(res.data.bill));
                    setTotalAmount(res.data.podata.total || 0);

                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getEditCompanyData = (value) => {
        // const comID = CisUI().getUserInfo("com_id");
        // if(comID > 0) {
        //     document.getElementById("loader").style.display = "block";
        axios.get("billentrydata?com_id=" + value)
            .then((res) => {
                console.log(res.data);
                if (res.data.status === 1) {
                    setVendor(res.data.users);
                    setAccount(res.data.account);
                    setPayable(res.data.payable);
                    setFund(res.data.fund);
                    setExpense(res.data.expense);
                }

                // document.getElementById("loader").style.display = "none";
            })
            .catch((error) => {
                console.log(error);
                // document.getElementById("loader").style.display = "none";
            });

        // formRef.current.setFieldsValue({
        //     vendor: "",
        //     accounts_payable: "",
        //     accounts_id: ""
        // });
        // }
    }

    useEffect(() => {
        getEditData();
        getEditCompanyData(companyID)
    }, [companyID]);

    if (isEdit === 1) {
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + jsonfile.urls.list;
    }

    const onFinish = (values) => {
        setLoading({loading: true})
        values['items'] = lineItems;
        if(isMultyBill) {
            values['isMultyBill'] = 1;
        }
        else {
            values['isMultyBill'] = 0;
        }
        // console.log(JSON.stringify(values));
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                document.getElementById("loader").style.display = "none";
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading: false});
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                setLoading({loading: false});
                document.getElementById("loader").style.display = "none";
            });
    };



    const ShowVendor = (e) => {
        console.log("Vendor : "+e);
    }

    const handleAddRow = () => {
        const item = {
            id: 0,
            account_id: null,
            fund_program_id: null,
            func_expense_id: null,
            restriction_type: null,
            amount: null,
            remark: null
        };
        setLineItems([...lineItems, item]);
    };

    useEffect(() => {
        handleAddRow();
    }, []);

    const handleRemoveRow = idx => e => {
        // console.log(idx);
        // console.log(state.rows);
        let filteredArray = lineItems.filter((item, index) => index !== idx)
        setLineItems(filteredArray);

        let ttl = 0;
        filteredArray.map((item,index) => {
            ttl += parseFloat(item.amount);
        })
        setTotalAmount(ttl);
    };

    const ItemCellChange = idx => e => {
        const { name, value } = e.target;
        const rows = [...lineItems];
        console.log("selectedITems:"+ JSON.stringify(rows[idx]));

        if(name === "amount") {
            rows[idx] = {
                id: rows[idx].id,
                account_id: rows[idx].account_id,
                fund_program_id: rows[idx].fund_program_id,
                func_expense_id: rows[idx].func_expense_id,
                restriction_type: rows[idx].restriction_type,
                amount: value,
                remark: rows[idx].remark
            };
        }
        else if(name === "note") {
            rows[idx] = {
                id: rows[idx].id,
                account_id: rows[idx].account_id,
                fund_program_id: rows[idx].fund_program_id,
                func_expense_id: rows[idx].func_expense_id,
                restriction_type: rows[idx].restriction_type,
                amount: rows[idx].amount,
                remark: value
            };
        }

        setLineItems(rows);

        let ttl = 0;
        rows.map((item,index) => {
            ttl += parseFloat(item.amount);
        })
        setTotalAmount(ttl);

    };

    const ItemCellChangeAccount = idx => e => {
        console.log("selectedValued:" + e);
        console.log("selectedValuedID:" + idx);

        const rows = [...lineItems];
        rows[idx] = {
            id: rows[idx].id,
            account_id: e,
            fund_program_id: rows[idx].fund_program_id,
            func_expense_id: rows[idx].func_expense_id,
            restriction_type: rows[idx].restriction_type,
            amount: rows[idx].amount,
            remark: rows[idx].remark,
        };

        setLineItems(rows);

    }

    const ItemCellChangeFundProgram = idx => e => {
        console.log("selectedValued:" + e);
        console.log("selectedValuedID:" + idx);

        const rows = [...lineItems];
        rows[idx] = {
            id: rows[idx].id,
            account_id: rows[idx].account_id,
            fund_program_id: e,
            func_expense_id: rows[idx].func_expense_id,
            restriction_type: rows[idx].restriction_type,
            amount: rows[idx].amount,
            remark: rows[idx].remark,
        };

        setLineItems(rows);

    }

    const ItemCellChangeFunxExpense = idx => e => {
        console.log("selectedValued:" + e);
        console.log("selectedValuedID:" + idx);

        const rows = [...lineItems];
        rows[idx] = {
            id: rows[idx].id,
            account_id: rows[idx].account_id,
            fund_program_id: rows[idx].fund_program_id,
            func_expense_id: e,
            restriction_type: rows[idx].restriction_type,
            amount: rows[idx].amount,
            remark: rows[idx].remark,
        };

        setLineItems(rows);

    }

    const ItemCellChangeRestrictionType = idx => e => {
        console.log("selectedValued:" + e);
        console.log("selectedValuedID:" + idx);

        const rows = [...lineItems];
        rows[idx] = {
            id: rows[idx].id,
            account_id: rows[idx].account_id,
            fund_program_id: rows[idx].fund_program_id,
            func_expense_id: rows[idx].func_expense_id,
            restriction_type: e,
            amount: rows[idx].amount,
            remark: rows[idx].remark,
        };

        setLineItems(rows);

    }



    const MultyBillForm = (
        <>
            <div className="row">

                <div className="col-md-4">
                    <Form.Item
                        name="date"
                        label="Date"
                        initialValue={moment(billData.date)}
                        rules={[
                            {
                                required: true,
                                message: "Select a date"
                            },
                        ]}
                    >
                        <DatePicker format={CisUI().dateFormat} className="gx-mb-3 gx-w-100" placeholder="Date"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="due_date"
                        label="Due Date"
                        initialValue={moment(billData.due_date)}
                        rules={[
                            {
                                required: true,
                                message: "Select a due date"
                            },
                        ]}
                    >
                        <DatePicker format={CisUI().dateFormat} className="gx-mb-3 gx-w-100" placeholder="Due Date"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="reference_no"
                        label="Reference No#"
                        initialValue={billData.reference_no}
                        rules={[
                            {
                                required: false,
                                message: "Enter reference number"
                            },
                        ]}
                    >
                        <Input className="gx-mb-3 gx-w-100" placeholder="Enter a reference number"/>
                    </Form.Item>
                </div>

                {
                    CisUI().getUserInfo("com_id") === 0 ?

                        <div className="col-md-4">
                            <Form.Item
                                name="company_id"
                                label="Company"
                                initialValue={billData.company_id}
                                rules={[
                                    {
                                        required: true,
                                        message: "Select a company"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select a company"
                                    optionFilterProp="children"
                                    onChange={onCompanyChange}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {
                                        Companies.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        : ""
                }

                <div className="col-md-4">
                    <Form.Item
                        name="vendor_id"
                        label="Employee/User"
                        initialValue={billData.emp_id}
                        rules={[
                            {
                                required: true,
                                message: "Select a user"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a user"
                            optionFilterProp="children"
                            onChange={ShowVendor}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                vendor.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    {/*<div style={{marginTop: "5px", marginRight: "15px", textAlign: 'right', fontWeight: 'bold'}}>*/}
                    {/*    <Tooltip title="Useful information" overlayClassName="right">*/}
                    {/*        <Link to="../vendor/newvendorinfo"><i className="fa fa-plus" /> Add New Vendor</Link>*/}
                    {/*    </Tooltip>*/}
                    {/*</div>*/}
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="payable_id"
                        label="Accounts Payable"
                        initialValue={billData.account_payable}
                        rules={[
                            {
                                required: true,
                                message: "Select a accounts payable"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a payable account"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                payable.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="description"
                        label="Description(Memo)"
                        initialValue={billData.description}
                        rules={[
                            {
                                required: true,
                                message: "Enter description here"
                            },
                        ]}
                    >
                        <TextArea placeholder="Enter description here"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="remark"
                        label="Remark/Additional Notes"
                        initialValue={billData.remark}
                        rules={[
                            {
                                required: false,
                                message: "Enter something here"
                            },
                        ]}
                    >
                        <TextArea placeholder="Enter something here"/>
                    </Form.Item>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <h2>Bill Items</h2>
                </div>
                <div className="col-md-6" style={{textAlign : "right"}}>
                    <a className="btn btn-primary" onClick={handleAddRow}>Add More</a>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <table id="itemtable" className="ptable table table-bordered">
                        <thead>
                        <th>#</th>
                        <th>Account</th>
                        <th>Amount</th>
                        <th>Description</th>
                        <th>Fund Program</th>
                        <th>Functional Expense</th>
                        <th>Restriction Type</th>
                        </thead>
                        <tbody>
                        {lineItems.map((item, idx) => (
                            <tr id="addr0" key={idx}>
                                <td style={{width : '30px'}}><a onClick={handleRemoveRow(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a></td>
                                <td>
                                    <Select
                                        showSearch
                                        placeholder="Select an account"
                                        optionFilterProp="children"
                                        style={{width: '100%'}}
                                        defaultValue={item.account_id}
                                        onChange={ItemCellChangeAccount(idx)}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            accounts.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </td>
                                <td style={{width : '100px'}}>
                                    <input className="form-control text-right" style={{textAlign : 'right'}} placeholder="Amount" name="amount" value={item.amount} onChange={ItemCellChange(idx)}  />
                                </td>
                                <td style={{width : '300px'}}><input className="form-control text-right" style={{textAlign : 'left'}} name="note" value={item.remark} onChange={ItemCellChange(idx)}  /></td>
                                <td>
                                    <Select
                                        showSearch
                                        placeholder="Select a fund program"
                                        optionFilterProp="children"
                                        style={{width: '100%'}}
                                        defaultValue={item.fund_program_id}
                                        onChange={ItemCellChangeFundProgram(idx)}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            fund.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </td>
                                <td>
                                    <Select
                                        showSearch
                                        placeholder="Select a functional expense"
                                        optionFilterProp="children"
                                        style={{width: '100%'}}
                                        defaultValue={item.func_expense_id}
                                        onChange={ItemCellChangeFunxExpense(idx)}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            expense.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </td>
                                <td>
                                    <Select
                                        showSearch
                                        placeholder="Select a restriction type"
                                        optionFilterProp="children"
                                        style={{width: '100%'}}
                                        defaultValue={item.restriction_type}
                                        onChange={ItemCellChangeRestrictionType(idx)}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            StaticSelectedData("restrictiontype").map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                        <tfoot>
                        <tr>
                            <th colSpan="2">Total = </th>
                            <th style={{textAlign: 'right', fontSize: '15px'}}>{CisUI().getCurrencyFormated1(totalAmount || 0)}</th>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>
    );


    return (
        <Card title={Title+" #"+(billData.invoice_number || "")} extra={
            isEdit === 1
                ? CisUI().addAction('../../' + jsonfile.urls.list)
                : CisUI().addAction('../' + jsonfile.urls.list)
        }>
            {CisUI().showLoading}
            {
                billData == "" ? <CircularProgress/> : (
                    <Form
                        form={form}
                        {...CisUI().formItemLayout}
                        name="input"
                        ref={formRef}
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >


                        {
                            MultyBillForm
                        }

                        <div className="row">
                            <div className="col-md-12" style={{textAlign: 'center', marginTop : '30px'}}>
                                <Button id="submitbutton" type="primary" htmlType="submit">
                                    Save
                                </Button>
                                <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                            </div>
                        </div>

                    </Form>
                )
            }
        </Card>
    );
};

export default RequesitionEdit;