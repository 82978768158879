import React from "react";
import {Route, Switch} from "react-router-dom";
import AutoLogoutTimer from  "../../util/AutoLogoutTimer";
import AdminDashboard from "../../containers/yotta/dashboard/dashboard";

const Dashboard = ({match}) => (
    <Switch>
        <Route path={`${match.url}/dashboard`} component={AdminDashboard}/>
    </Switch>
);

export default Dashboard;
