import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Layout, Menu} from "antd";
import {Link} from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import {
    NAV_STYLE_ABOVE_HEADER,
    NAV_STYLE_BELOW_HEADER,
    NAV_STYLE_DEFAULT_HORIZONTAL,
    NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";
import Config from "../../util/config";
import {CisUI} from "../../util/CISUI";
import axios from "../../util/Api";


const SubMenu = Menu.SubMenu;
// const MenuItemGroup = Menu.ItemGroup;

const HorizontalNav = () => {

    const navStyle = useSelector(({settings}) => settings.navStyle);
    const {pathname} = useSelector(({common}) => common);

    const getNavStyleSubMenuClass = (navStyle) => {
        switch (navStyle) {
            case NAV_STYLE_DEFAULT_HORIZONTAL:
                return "gx-menu-horizontal gx-submenu-popup-curve";
            case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
                return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
            case NAV_STYLE_BELOW_HEADER:
                return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
            case NAV_STYLE_ABOVE_HEADER:
                return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
            default:
                return "gx-menu-horizontal";
        }
    };

    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    const [userMenu, setUserMenu] = useState([]);
    useEffect(() => {
        // console.log("Hello");
        getUserMenu();

    }, []);

    const getUserMenu = async () => {
        // const userData = localStorage.getItem("userData");
        // const dataparse = JSON.parse(userData);
        // const userId = dataparse.user_name || "";
        //const URL = Config.apiserver+"getusermenu/?username=superadmin";

        const URL = Config.apiserver + "getusermenuv2/?username=" + CisUI().getUserInfo("user_name");
        await axios.get(URL)
            .then((response) => {
                // console.log(response.data);
                if (response.status === 200) {
                    setUserMenu(response.data.allmenu);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const pageRefresh = () => {
        window.location.reload();
    }


    return (
        <Layout>
            <Menu
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}
                mode="horizontal">


                <Menu.Item key="main/refresh">
                    <button style={{background: 'none', border: 'none', color: 'white'}} onClick={pageRefresh}>
                        <i className="icon icon-culture-calendar"/>
                        {/*<span><IntlMessages id="sidebar.dashboard"/></span>*/}
                    </button>
                </Menu.Item>
                <Menu.Item key="main/dashboard">
                    <Link to="/main/dashboard">
                        <i className="icon icon-home"/>
                        {/*<span><IntlMessages id="sidebar.dashboard"/></span>*/}
                    </Link>
                </Menu.Item>

                {
                    userMenu.map((module, index) =>

                        <SubMenu key={"module_" + index + "_" + module.urlkey + "_" + module.id}
                                 popupClassName={getNavStyleSubMenuClass(navStyle)}
                                 title={<span><IntlMessages id={module.name}/> <i className="fas fa-chevron-down"
                                                                                  style={{fontSize: '10px'}}/></span>}>
                            {
                                module.submodule.map((submenu, index) => {
                                    //console.log("Menu :"+ module.mnu_name)
                                    if (submenu.menu.length === 0) {

                                        return <Menu.Item
                                            key={"submenu_" + index + "_" + submenu.urlkey + "_" + submenu.id}
                                            id="customMenu">

                                            <Link to={"/" + module.urlkey + '/' + submenu.urlkey}>
                                                <span><IntlMessages id={submenu.name}/></span>
                                            </Link>
                                        </Menu.Item>

                                    } else {
                                        return <SubMenu
                                            key={`submenu_` + index + "_" + submenu.urlkey + "_" + submenu.id}
                                            popupClassName={getNavStyleSubMenuClass(navStyle)}
                                            title={<IntlMessages id={submenu.name}/>}>

                                            {submenu.menu.map((menu, index) =>
                                                <Menu.Item key={"menu_" + index + "_" + menu.urlkey + "_" + menu.id}
                                                           style={{padding: "0!important"}} id="customMenu">

                                                    <Link to={"/" + module.urlkey + "/" + menu.urlkey}>
                                                        <IntlMessages id={menu.name}/>
                                                    </Link>
                                                </Menu.Item>
                                            )}
                                        </SubMenu>
                                    }

                                })
                            }

                        </SubMenu>
                    )
                }


            </Menu>
        </Layout>
    );
};

HorizontalNav.propTypes = {};

export default (HorizontalNav);

