import React, {useState} from "react";
import {Form, Upload, DatePicker, message, Select, Input, Button, Card, notification, Col} from 'antd';
import {CisUI} from '../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../util/config";
import axios from "../../../util/Api";
import RemoteJson from "../../../util/gerremotedata";
import StaticSelectedData from "../../../util/StaticSelectData";
const {Option} = Select;


const VacationSetup = (props) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState([]);
    const [submitted, setSubmitted] = useState(0);

    const [total, setTotal] = useState({
        debit : 0,
        credit : 0
    });

    const [dataobject,setDataObject] = useState([]);

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }


    const userPosition = RemoteJson("vacationsetup");
    const userPositionAll = RemoteJson("userpositions");


    const onFinish = (values) => {
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver+"setvactionsetup", values)
            .then(res => {
                // console.log(res)
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    document.getElementById("msg").style.color = "green";
                    document.getElementById("msg").innerText = res.data.msg;
                }
                else if (res.data.status === 3) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    document.getElementById("msg").style.color = "red";
                    document.getElementById("msg").innerText = res.data.msg;
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                    document.getElementById("msg").style.color = "green";
                    document.getElementById("msg").innerText = res.data.msg;
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                document.getElementById("loader").style.display = "none";
                console.log(errors);
            });
    };


    const getRemark = (text) => {
        if(text === "Success") {
            return <span style={{color: 'green'}}>{text}</span>;
        }
        return text;
    }

    return (
        <Card title={"Vacation Setup"}>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                <div className="row">
                    <div className="col-md-12">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th colSpan={4} style={{textAlign: 'center'}}>Limit (Days/Yearly)</th>
                                </tr>
                                <tr>
                                    <th>Position</th>
                                    <th>Approver</th>
                                    <th>Reviewer</th>
                                    <th>Vacation/Leave</th>
                                    <th>Jury Duty</th>
                                    <th>Bereavement</th>
                                    <th>Other</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                userPosition.map((item,intex) => {
                                    return <tr>
                                        <td>{item.name}</td>
                                        <td>
                                            <Form.Item
                                                name={"approver_"+item.id}
                                                initialValue={item?.approver}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Select an option"
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="Select an option"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                >
                                                    {
                                                        userPositionAll.map((items, index) =>
                                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                name={"reviewer_"+item.id}
                                                initialValue={item?.reviewer}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Select an option"
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="Select an option"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                >
                                                    {
                                                        userPositionAll.map((items, index) =>
                                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                name={"vacation_"+item.id}
                                                initialValue={item.vacation}
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Enter something here"
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                name={"jury_duty_"+item.id}
                                                initialValue={item.jury_duty}
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Enter something here"
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                name={"bereavement_"+item.id}
                                                initialValue={item.bereavement}
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Enter something here"
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                name={"other_"+item.id}
                                                initialValue={item.other}
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Enter something here"
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </table>
                    </div>

                </div>
                <Col lg={24} xs={24} style={{ textAlign: "center" }}>
                    {
                        userPosition.length > 0 ?
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button> : <></>
                    }
                </Col>
            </Form>
        </Card>
    );
};

export default VacationSetup;