import React, {useState, useEffect} from "react";
import { Form, Card, Collapse} from 'antd';
import jsonfile from './vacation.json';
import {Link, useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import ActionButton from '../../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";

const Panel = Collapse.Panel;

const VacationList = () => {
    const history = useHistory();
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.list,
        filtervalue : '',
        title: ""
    });

    useEffect(() => {
        setState(
            {
                reqURL: Config.apiserver+jsonfile.urls.list
            }
        );
    }, []);

    const headers = {
        com_id: {invisible: true},
        user_id: {invisible: true},
        emp_id: {invisible: true},
        year: {invisible: true},
        remain: {invisible: true},
        created_by: {invisible: true},
        updated_at: {invisible: true},
        updated_by: {invisible: true},
        vacation_type: {invisible: true},
        id: {
            text: <IntlMessages id="#"/>,
            sortable: true,
            filterable: true,
        },
        request_no:{
            text: <IntlMessages id="Request#"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, record) => (
                <Link to={"vacationdetail/"+record.id}>{value}</Link>
            )
        },
        company: {
            text: <IntlMessages id="Company"/>,
            sortable: true,
            filterable: true,
        },
        employee: {
            text: <IntlMessages id="Employee"/>,
            sortable: true,
            filterable: true,
        },
        start_date: {
            text: <IntlMessages id="Start Date"/>,
            sortable: true,
            filterable: true,
            transform: (value) => (
                CisUI().DateFormat(value)
            )
        },
        end_date: {
            text: <IntlMessages id="End Date"/>,
            sortable: true,
            filterable: true,
            transform: (value) => (
                CisUI().DateFormat(value)
            )
        },
        number_of_days: {
            text: <IntlMessages id="Number of Days"/>,
            sortable: true,
            filterable: true,
            transform: (value) => (
                <div style={{textAlign: "right"}}>{value}</div>
            )
        },
        created_at: {
            text: <IntlMessages id="Created_at"/>,
            sortable: true,
            filterable: true,
            transform: (value) => (
                CisUI().DateFormatWithTime(value)
            )
        },
        approved_at: {
            text: <IntlMessages id="Approved Date"/>,
            sortable: true,
            filterable: true,
            transform: (value) => (
                CisUI().DateFormatWithTime(value)
            )
        },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getBillSatus(value)
            )
        },
        actions: {
            text: 'Actions',
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {row.status === 0 || CisUI().getUserInfo("usefor") === 34 || CisUI().getUserInfo("usefor") === 1 || CisUI().getUserInfo("usefor") === 2 ? ActionButton(row.id,jsonfile.urls,jsonfile.urls.list) : <></>}
                </>
            ),
        },
    };

    const orderedHeaders = jsonfile.listView.title;

    const [form] = Form.useForm();

    const onFinish = values => {
        // console.log('Received values of form: ', values);
        const data = {
            ...values,
            // 'purchase_date' : values['purchase_date'].format("YYYY-MM-DD"),
        }
        // console.log(data);
        let wkd = "";
        let wk = "";
        let dt = "";
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    wk = values['date'];
                    const dt = CisUI().ParseDateFormat(values[key]);
                    // setPeriod("Between "+CisUI().DateFormat(dt[0])+" and "+CisUI().DateFormat(dt[1]));
                    return `${key}=${dt}`
                } else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.list+"/?"+qsup;
        setState({reqURL: newURL});
    };

    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    // const filterValue = "";

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.search,"",1,"date",CisUI().PeriodFiled(form))}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {CisUI().listAction("../"+jsonfile.urls.add, "", state.reqURL, "data", 'hrm_designation', jsonfile, state.subtitle, 'portrait')}
        </>
    );

    return (
        <>
            {searchView}
            <Card title={jsonfile.listtitle} extra={getExtra}>
                {/*{CisUI().listAction(jsonfile.urls.add)}*/}
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
        </>
    );
};

export default VacationList;