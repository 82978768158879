import React, {useEffect, useState} from "react";
import {Form, Row, Col, Button, Checkbox, Card,notification} from 'antd';
import jsonfile from './vacation.json';
import {CisUI} from '../../../../util/CISUI';
import InputFields from '../../../../util/InputField';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import InputOnly from "../../../../util/InputOnly";
import IntlMessages from "../../../../util/IntlMessages";

const NewVaction = (props) => {

    const [loading, setLoading] = useState([]);
    const [userData, setUserData] = useState({});

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    const userID = props.match.params.id;
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    if(isEdit === 1 ) {
        //userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }

    const [approved, setApprove] = useState(0);
    const [LeaveTypes, setLeveTypes] = useState({});
    const [form] = Form.useForm();
    const history = useHistory();

    useEffect(()=>{
        if(isEdit === 1) {
            getVactionDetails();
        }
        else {
            getMyVations();
        }

    },[isEdit]);

    const getMyVations = () => {
        axios.get(Config.apiserver +"myvacationtype")
            .then((res) => {
                if (res.data.status === 1) {
                    setLeveTypes(res.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getVactionDetails = () => {
        axios.get(Config.apiserver +jsonfile.urls.view+"/"+userID)
            .then((res) => {
                if (res.data.status === 1) {
                    setUserData(res.data.data);
                    setLeveTypes(res.data.vacationData)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const onFinish = (values) => {
        // // console.log(values);
        document.getElementById("loader").style.display = "block";

        if(isEdit === 1) {
            values["status"] = approved;
        }

        axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg)
                    history.push(redirectto);
                }
                else {
                    CisUI().Notification('error', res.data.msg)
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    const onApprove = (e) => {
        console.log(e);
        if(e.target.checked) {
            setApprove(1);
        }
        else {
            setApprove(0);
        }
    }


    //console.log("UserData : "+userData);

    return (
        <Card title={Title} extra={
                isEdit === 1
                    ? CisUI().addAction('../../'+jsonfile.urls.list)
                    : CisUI().addAction('../'+jsonfile.urls.list)
            }>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                {/*{InputFields(jsonfile.input,userData,isEdit,props)}*/}
                    <Row>
                        <Col className={"col-md-8"}>
                            {isEdit === 1 ? InputOnly(jsonfile.inputEdit,userData,isEdit,props): InputFields(jsonfile.input,{},isEdit,props)}

                            {
                                userData?.approver === 1 && userData?.status === 0 ? <>
                                    <Row>
                                        <Col lg={6} xs={4}>
                                            <Form.Item
                                                name={"status"}
                                                label="Status"
                                                onChange={onApprove}
                                            >
                                                <Checkbox checked={approved === 1}>Approved?</Checkbox>

                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </>: <></>
                            }

                            {
                                (userData?.approver === 1 && userData?.status === 0) || (userData?.user_id === CisUI().getUserInfo("id") && userData?.status === 0) ?
                                <Row>
                                    <Col lg={24} xs={24} style={{ textAlign: "center" }}>
                                        <Button type="primary" htmlType="submit">
                                            Save
                                        </Button>
                                    </Col>
                                </Row> : <></>
                            }
                        </Col>
                        <Col className={"col-md-4"}>
                            <Card title={"Vacation Limit"}>
                                <table className={"table table-bordered"}>
                                    <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th style={{textAlign: 'right'}}>Eligible</th>
                                        <th style={{textAlign: 'right'}}>Taken</th>
                                        <th style={{textAlign: 'right'}}>Available</th>
                                        <th style={{textAlign: 'right'}}>Pending</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        LeaveTypes?.data?.map((item,index)=>{
                                            return <tr>
                                                <td>{item.name}</td>
                                                <td style={{textAlign: 'right'}}>{item.limit}</td>
                                                <td style={{textAlign: 'right'}}>{item.taken}</td>
                                                <td style={{textAlign: 'right'}}>{item.available}</td>
                                                <td style={{textAlign: 'right'}}>{item.pendingLimit}</td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <th>Total</th>
                                        <th style={{textAlign: 'right'}}>{LeaveTypes?.totalLimit}</th>
                                        <th style={{textAlign: 'right'}}>{LeaveTypes?.totalTaken}</th>
                                        <th style={{textAlign: 'right'}}>{LeaveTypes?.totalAvailable}</th>
                                        <th style={{textAlign: 'right'}}>{LeaveTypes?.pendingLimit}</th>
                                    </tr>
                                    </tfoot>
                                </table>
                            </Card>
                        </Col>
                    </Row>
            </Form>
        </Card>
    );
};

export default NewVaction;