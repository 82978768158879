import React, {useState, useEffect} from "react";
import {Form, Card, Upload, Button, Row, notification, Col} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import jsonfile from './asset_info.json';
import {CisUI} from '../../../../util/CISUI';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import InputFieldV2 from "../../../../util/InputFieldV2";
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import StaticSelectedData from "../../../../util/StaticSelectData";

const NewAssetInfo = (props) => {

    // const [img, setImg] = useState([]);
    const [fields, setFields] = useState([]);
    const normFile = e => {
        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList;
    };

    // const handleFile = (e) => {
    //     let file = e.target.files[0];
    //     console.log(file);
    //     let reader = new FileReader();
    //     reader.onload = (e)=> {
    //         setImg({
    //             ...img,
    //             warranty_attachment : e.target.result
    //         });
    //     };
    //     reader.readAsDataURL(file);
    // }

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }


    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {
        values["custom_field"] = fields;
        const data = {
            ...values,
            'purchase_date' : values['purchase_date'].format("YYYY-MM-DD"),
            'maturity' : values['maturity'].format("YYYY-MM-DD")
        }

        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type : "success",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    const handleAddRow = () => {
        const item = {
            field_name: null,
            field_value: null
        };
        setFields([...fields, item]);
    };

    useEffect(() => {
        handleAddRow();
    }, []);

    const handleRemoveRow = idx => e => {
        // console.log(idx);
        // console.log(state.rows);
        let filteredArray = fields.filter((item, index) => index !== idx)
        setFields(filteredArray);
    };

    const ItemCellChange = idx => e => {
        const { name, value } = e.target;
        const rows = [...fields];
        console.log("selectedITems:"+ JSON.stringify(rows[idx]));

        if(name === "field_name") {
            rows[idx] = {
                field_name: value,
                field_value: rows[idx].field_value
            };
        }
        else if(name === "field_value") {
            rows[idx] = {
                field_name: rows[idx].field_name,
                field_value: value
            };
        }
        setFields(rows);

    };

    return (
        <Card title={Title} extra={
                isEdit === 1
                    ? CisUI().addAction('../../'+jsonfile.urls.list)
                    : CisUI().addAction('../'+jsonfile.urls.list)
            }>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                {InputFieldV2(jsonfile.input,userData,isEdit,props)}

                <Form.Item
                    name="warranty_attachment"
                    label="Warranty Attachment"
                    getValueFromEvent={normFile}>
                    <Upload {...props}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                    </Upload>
                </Form.Item>


                <Row gutter={24}>
                    <Col lg={12} md={12} sm={12} xs={24}>
                        <div className="row">
                            <div className="col-md-6">
                                <h2>Custom Input</h2>
                            </div>
                            <div className="col-md-6" style={{textAlign : "right"}}>
                                <a className="btn btn-primary" onClick={handleAddRow}>Add More</a>
                            </div>
                        </div>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Field Name</th>
                                    <th>Field Value</th>
                                </tr>
                            </thead>
                            <tbody>
                            {fields.map((item, idx) => (
                                <tr id="addr0" key={idx}>
                                    <td style={{width : '30px'}}><a onClick={handleRemoveRow(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a></td>
                                    <td style={{width : '200px'}}>
                                        <input className="form-control" name="field_name" value={item.field_name} onChange={ItemCellChange(idx)}  />
                                    </td>
                                    <td style={{width : '250px'}}><input className="form-control" name="field_value" value={item.field_value} onChange={ItemCellChange(idx)}  /></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                        <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                    </Col>
                </Row>

            </Form>
        </Card>
    );
};
export default NewAssetInfo;