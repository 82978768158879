import React, {useState, useEffect} from "react";
import {Form, Row, Col, Card, Select, notification} from 'antd';
import jsonfile from './company.json';
// import jsonUserfile from './../../user_management/user/user.json';
import {CisUI} from '../../../../util/CISUI';
import InputFields from '../../../../util/InputField';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import ActionButton from "../../../../util/actionbutton";
import IntlMessages from "../../../../util/IntlMessages";
import SmartDataTable from 'react-smart-data-table'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import SelectData from "../../../../util/selectdata";


const { Option } = Select;

const NewCompanyChangeRequest = (props) => {

    const formRef = React.createRef();
    const [district, setDistrict] = useState([]);
    const [local, setLocal] = useState([]);
    const [selectedValue, setSelectedValue] = useState(6);
    const userID = props.match.params.id;
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.companyuserlist+"/"+userID,
        filtervalue : '',
        area : null
    });

    const [diplay, setDisplay] = useState({
        region: "none",
        district: "none",
        local: "none",
    });

    const companyList = SelectData("company/companylists");

    const [form] = Form.useForm();

    const history = useHistory();


    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = "company/newcompany";
    let redirectto = "../"+jsonfile.urls.list;

    let areaReq = true;

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = "company/companyedit/"+userID;
        redirectto = '../../'+jsonfile.urls.list;
        areaReq = false;
    }

    // useEffect(() => {
    //     setState(
    //         {
    //             reqURL: Config.apiserver+jsonfile.urls.companyuserlist+"/"+userID,
    //             filtervalue : '',
    //             area : userData.area
    //         }
    //     );
    //
    //     form.setFieldsValue({ area: userData.area })
    //
    // }, [form,userData]);


    const onFinish = (values) => {
        // setLoading({loading:true})
        // console.log("Input Data : "+values);

        document.getElementById("loader").style.display = "block";

        let pass = 0;
        if(isEdit === 1) {
            pass = 1;
        }
        else {
            if (values['area'] <= 3) {
                pass = 1;
            }
            else if (values['area'] === 4) {
                if (values['region'] > 0) {
                    pass = 1;
                } else {
                    // notification.warning({
                    //     message: 'Alert',
                    //     type : "warning",
                    //     description: "Please select a region"
                    // });
                    pass = 1;
                }
            } else if (values['area'] === 5) {
                if (values['region'] > 0) {
                    if (values['district'] > 0) {
                        pass = 1;
                    } else {
                        // notification.warning({
                        //     message: 'Alert',
                        //     type : "warning",
                        //     description: "Please select a district"
                        // });
                        pass = 1;
                    }
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: "Please select a region & district"
                    });
                }
            } else if (values['area'] === 6) {
                if (values['region'] > 0) {
                    if (values['district'] > 0) {
                        if (values['local'] > 0) {
                            pass = 1
                        } else {
                            // notification.warning({
                            //     message: 'Alert',
                            //     type : "warning",
                            //     description: "Please select a local"
                            // });
                            pass = 1;
                        }
                    } else {
                        notification.warning({
                            message: 'Alert',
                            type: "warning",
                            description: "Please select a district"
                        });
                    }
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: "Please select a region, district & local"
                    });
                }
            }
        }

        if(pass === 1) {

            // let msg = "Sorry! request not processed, please try again";
            axios.post(Config.apiserver + endpoint, values)
                .then(res => {
                    // console.log(res)
                    if (res.data.status === 1) {
                        notification.success({
                            message: 'Success',
                            type: "warning",
                            description: res.data.msg
                        });
                        history.push(redirectto);
                    } else {
                        notification.warning({
                            message: 'Alert',
                            type: "warning",
                            description: res.data.msg
                        });
                    }
                    document.getElementById("loader").style.display = "none";
                })
                .catch(errors => {
                    console.log(errors);
                });
        }
    };


    const onCompanyChange = (e) => {
        const companyID = e;
        document.getElementById("loader").style.display = "block";
        axios.get(Config.apiserver+"company/companyinfo/"+companyID)
            .then((res) => {
                //console.log(res.data);
                if(res.data.status === 1) {
                    if(res.data.data) {
                        const data =  res.data.data;
                        form.setFieldsValue(data);
                    }
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch((error) => {
                document.getElementById("loader").style.display = "none";
            });
    }

    return (
        <>
            <Card title={<IntlMessages id={Title}/>} extra={
                    isEdit === 1
                        ? CisUI().addAction(redirectto)
                        : CisUI().addAction(redirectto)
                }>
                {CisUI().showLoading}
                <Form
                    form={form}
                    ref={formRef}
                    {...CisUI().formItemLayout}
                    name="input"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                    initialValues={{area: state.area}}
                >

                    <Row gutter={24}>

                        <Col lg={6} md={6} sm={12} xs={24} id="company" >
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="com_id"
                                    label={<IntlMessages id="Company"/>}
                                    rules={[
                                        {
                                            required: false,
                                            message: "Select a company"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a company"
                                        optionFilterProp="children"
                                        onChange={onCompanyChange}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            companyList.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>

                            </div>
                        </Col>
                    </Row>

                    {InputFields(jsonfile.input,userData,isEdit, props,'', redirectto)}
                </Form>

            </Card>

        </>
    );
};

export default NewCompanyChangeRequest;