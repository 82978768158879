import React, {useState, useEffect} from "react";
import {Form, Button, Card,  Modal, notification} from 'antd';
import jsonfile from './transaction.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {Link, useHistory} from "react-router-dom";
import moment from "moment";
import 'moment/locale/es-us';
import ActionButton from "../../../../util/actionbutton";


const TransactionDetails = (props) => {

    const history = useHistory();

    const [title, setTitle] = useState(jsonfile.addtitle);
    const [data, setData] = useState([]);

    const poid = props.match.params.id;
    const userID = poid;
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }


    useEffect(() => {
        getPODetails();
    }, []);

    const getPODetails = () => {
        document.getElementById("loader").style.display = "block";
        axios.get(Config.apiserver + jsonfile.urls.transactiondetails+"/" + poid)
            .then((res) => {
                document.getElementById("loader").style.display = "none";
                //console.log(res.data);
                if (res.data.status === 1) {
                    setData(res.data.data);
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });

    }

    const BillInfo = (
        <div className="row" style={{padding: '20px'}}>
            <h4>Transaction Information</h4>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Date : </label>
                <span> {CisUI().DateFormat(data.date)}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Company : </label>
                <span> {data.comname}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Beneficiary Type : </label>
                <span> {data.source}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Beneficiary/Source : </label>
                <span> {data.beneficiary}</span>
            </div>

            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Account Number : </label>
                <span> {data.account_number}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Account Name : </label>
                <span> {data.account_name}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Title : </label>
                <span> {data.title}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Description : </label>
                <span> {data.details}</span>
            </div>

            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Reference: </label>
                <span> {data.reference}</span>
            </div>

            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Amount : </label>
                <span style={{color: 'green'}}> {CisUI().getCurrencyFormated1(data.amount)}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Remark : </label>
                <span> {data.remark}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Created By : </label>
                <span> {data.username}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Created at : </label>
                <span> {data.created_at}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Updated at : </label>
                <span> {data.updated_at}</span>
            </div>

        </div>
    );

    return (
        <Card title={"Transaction details"} extra={
            isEdit === 1
                ? CisUI().addAction('../../' + jsonfile.urls.list)
                : CisUI().addAction('../' + jsonfile.urls.list)
        }>

            {CisUI().showLoading}

            {BillInfo}


        </Card>
    );
};

export default TransactionDetails;