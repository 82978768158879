import React, {useState, useEffect} from "react";
import {Form, Input, Button, Card, Select, DatePicker, notification} from 'antd';
import jsonfile from './purchase.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import SelectData from "../../../../util/selectdata";
import moment from "moment";
import inventory from "../../../../routes/inventory";

const {Option} = Select;

const NewReqStockOrder = (props) => {

    const formRef = React.createRef();

    const [loading, setLoading] = useState([]);

    const [state, setState] = useState({
        data: [],
        rows: []
    })

    const [loaded, setLoaded] = useState(false);
    const [vendor, setVendor] = useState([]);
    const [vendorItem, setVendorItem] = useState([]);
    const [podata, setPOdata] = useState([]);
    const [bill, setBill] = useState(
        {
            total : 0,
            grand_total : 0,
            freight : 0,
            taxp : 0,
            tax_amount : 0,
            discount_p : 0,
            discount_amount : 0
        }
    );

    const [comp, setComp] = useState(
        {
            com_address: "",
            com_city: "",
            com_state: "",
            com_zip_code: ""
        }
    );

    const [vend, setVend] = useState(
        {
            vendor_address: "",
            vendor_city: "",
            vendor_state: "",
            vendor_zip_code: "",
            shipping_method: ""
        }
    );

    const handleChange = (e) => {
        setComp({
            [e.target.name]: e.target.value
        })
    }

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {
        //userData = RemoteJson();
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../vendor/newbillt?po="+userID;

        // console.log("selected : "+ JSON.stringify(company));
        //console.log("address : "+ company.address_line_1+", "+company.address_line_2);
        //document.getElementById("input_com_address").value = "Test";
    }


    let Vendors = [];
    const Companies = SelectData("company/companylists");
    const ShippingMethod = SelectData("shippingmethod");
    const inventoryitem = SelectData("inventory/invitemlist");
    const vendorlist = SelectData("inventory/vendorlist");


    const [comapny, setCompany] = useState([]);
    const [local, setLocal] = useState([]);


    const [form] = Form.useForm();
    const history = useHistory();


    if(loaded) {
        OrderDetails();
    }

    const OrderDetails = async () => {
        return await axios.get(jsonfile.urls.view + "/" + userID)
            .then((res) => {
                if(res.data.status === 1) {
                    const podata = res.data.podata;
                    //onCompanyChange(podata.company_id);
                    setVendor(res.data.vendors);
                    setPOdata(res.data.podata);
                    formRef.current.setFieldsValue({
                        company : podata.company_id,
                        com_address: podata.com_address,
                        com_city: podata.com_city,
                        com_state: podata.com_state,
                        com_zip_code: podata.com_zipcode,
                        vendor: podata.vendor_id,
                        vendor_address: podata.vendor_address,
                        vendor_city: podata.vendor_city,
                        vendor_state: podata.vendor_state,
                        vendor_zip_code: podata.vendor_zipcode,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const onFinish = (values) => {
        // setLoading({loading: true})
        values["items"] = state.rows;
        values["summary"] = bill;
        // console.log(JSON.stringify(values));
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };


    const onVendorChange = value => {
        console.log("selected : "+ value);
        let fl = vendorlist.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            console.log("selected : "+ JSON.stringify(company));
            //console.log("address : "+ company.billing_address_line_1+", "+company.billing_address_line_2);
            //document.getElementById("input_com_address").value = company.address_line_1+", "+company.address_line_2;
            var bill1 = company["billing_address_line_1"];
            var bill2 = company["billing_address_line_2"];

            formRef.current.setFieldsValue({
                vendor_address: bill1+", "+bill2,
                vendor_city: company["billing_city"],
                vendor_state: company["state_name"],
                vendor_zip_code: company["shipping_zipcode"],
                shipping_method: company["shipping_method"],
                sales_tax_pp : company["shipping_tax"] || 0
            });


            // console.log("All: "+inventoryitem);
            let filteredArray = inventoryitem.filter((item, index) => item.vendor_id === value);
            // console.log("Filtered: "+filteredArray);
            setVendorItem(filteredArray);

        }
    }

    const appendChild = () => {
        let { data } = state;
        data.push(data.length); // data.length is one more than actual length since array starts from 0.
        setState({
            data : data,
            rows : {
                code : '',
                name : '',
                qty : 0,
                price : 0,
                amount : 0
            }
        });

    };


    const removeRow = () => {
        let { data } = state;
        data.pop(data.length); // data.length is one more than actual length since array starts from 0.
        setState({data});
    }

    const ItemCellChange = idx => e => {
        const { name, value } = e.target;
        const rows = [...state.rows];
        console.log("selectedITems:"+ JSON.stringify(rows[idx]));
        if(name === "qty") {
            const amount = value * rows[idx].price;
            rows[idx] = {
                item_id: rows[idx].item_id,
                qty: value,
                price: rows[idx].price,
                amount: amount
            };
        }
        else if(name === "price") {
            //CalculateCell();
            const amount = value * rows[idx].qty;
            rows[idx] = {
                item_id: rows[idx].item_id,
                qty: rows[idx].qty,
                price: value,
                amount: amount
            };

        }
        else if(name === "amount") {
            rows[idx] = {
                item_id: rows[idx].item_id,
                qty: rows[idx].qty,
                price: rows[idx].price,
                amount: value
            };
        }

        setState({
            rows
        });

        let totals = 0
        rows.map((item, idx) => {
            totals += item.amount;
        });

        var total = totals;
        var freight = bill.freight;
        var taxp = 0;
        var discount_p = bill.discount_p;
        var discount_amount = (parseFloat(total) * discount_p / 100) || 0;
        var totalBeforeTax = (parseFloat(total)) - parseFloat(discount_amount);
        var taxAmount = bill.taxp;
        var grandTotal = totalBeforeTax + taxAmount + parseFloat(freight);

        setBill({
            total : total,
            grand_total : grandTotal,
            freight : freight,
            taxp : taxp,
            tax_amount : taxAmount,
            discount_p : discount_p,
            discount_amount : discount_amount
        });

    };

    const ItemNameCellChange = idx => e => {
        const rows = [...state.rows];

        let fl = vendorItem.filter((item, index) => item.id === e);
        console.log(fl[0]);
        let itemPrice = fl[0]['price'];
        console.log(itemPrice);
        let total = parseInt(rows[idx].qty) * parseFloat(itemPrice);
        rows[idx] = {
            item_id: e,
            qty: rows[idx].qty,
            price: itemPrice,
            amount: total
        };

        setState({
            data : state.data,
            rows: rows
        });
    }

    const ChangeTax = e => {

        let totals = 0;
        const  value = parseFloat(e.target.value) || 0;
        state.rows.map((item, idx) => {
            totals += item.amount;
        });


        var total = totals;
        var freight = bill.freight;
        var taxp = value;
        var discount_p = bill.discount_p;
        var discount_amount = bill.discount_amount;
        var totalBeforeTax = (parseFloat(total)) - parseFloat(discount_amount);
        var taxAmount = value;
        var grandTotal = totalBeforeTax + taxAmount + parseFloat(freight);

        setBill({
            total : total,
            grand_total : grandTotal,
            freight : freight,
            taxp : taxp,
            tax_amount : taxAmount,
            discount_p : discount_p,
            discount_amount : discount_amount
        });

    }

    const ChangeFreight = e => {
        let totals = 0;
        const  value = parseFloat(e.target.value) || 0;
        state.rows.map((item, idx) => {
            totals += item.amount;
        });


        var total = totals;
        var freight = value || 0;
        var taxp = bill.taxp;
        var discount_p = bill.discount_p;
        var discount_amount = bill.discount_amount;
        var totalBeforeTax = (parseFloat(total)) - parseFloat(discount_amount);
        var taxAmount = bill.taxp;
        var grandTotal = totalBeforeTax + taxAmount + parseFloat(freight);

        setBill({
            total : total,
            grand_total : grandTotal,
            freight : freight,
            taxp : taxp,
            tax_amount : taxAmount,
            discount_p : discount_p,
            discount_amount : discount_amount
        });
    }

    const ChangeDiscount = e => {
        let totals = 0;
        const  value = parseFloat(e.target.value) || 0;
        state.rows.map((item, idx) => {
            totals += item.amount;
        });

        const BeforeTax = (totals) - value;
        const tax = bill.taxp;
        const grand = (totals + bill.freight + tax) - value;

        setBill({
            total : totals,
            grand_total : grand,
            freight : bill.freight,
            taxp : bill.taxp,
            tax_amount : bill.tax_amount,
            discount_p : bill.discount_p,
            discount_amount : value
        });
    }

    const onDiscountChange = e => {

        var total = bill.total;
        var freight = bill.freight;
        var taxp = bill.taxp;
        var discount_p = parseFloat(e.target.value) || 0;
        var discount_amount = parseFloat(total) * discount_p / 100;
        var totalBeforeTax = parseFloat(total) - parseFloat(discount_amount);
        var taxAmount = parseFloat(totalBeforeTax) * (parseFloat(taxp) / 100);
        var grandTotal = totalBeforeTax + taxAmount + + parseFloat(freight);

        setBill({
            total : total,
            grand_total : grandTotal,
            freight : freight,
            taxp : taxp,
            tax_amount : taxAmount,
            discount_p : discount_p,
            discount_amount : discount_amount
        });
    }

    const onTaxChange = e => {
        var total = bill.total;
        var freight = bill.freight;
        var taxp = parseFloat(e.target.value) || 0;
        var discount_p = bill.discount_p;
        var discount_amount = parseFloat(total) * discount_p / 100;
        var totalBeforeTax = parseFloat(total)  - parseFloat(discount_amount);
        var taxAmount = parseFloat(totalBeforeTax) * (parseFloat(taxp) / 100);
        var grandTotal = parseFloat(totalBeforeTax) + parseFloat(taxAmount) + + parseFloat(freight);

        setBill({
            total : total,
            grand_total : grandTotal,
            freight : freight,
            taxp : taxp,
            tax_amount : taxAmount,
            discount_p : discount_p,
            discount_amount : discount_amount
        });
    }

    const handleAddRow = () => {
        const item = {
            item_id : null,
            qty : 0,
            price : 0,
            amount : 0
        };
        setState({
            rows: [...state.rows, item]
        });
    };

    useEffect(() => {
        handleAddRow();
    }, []);

    const handleRemoveRow = idx => e => {
        // console.log(idx);
        // console.log(state.rows);
        let filteredArray = state.rows.filter((item, index) => index !== idx)
        setState({rows: filteredArray});
    };

    // useEffect(() => {
    //     handleAddRow();
    // }, []);


    var totalAmount = 0;

    const CalculateCell = () => {
        var table = document.getElementById('itemtable');
        for (var r = 0, n = table.rows.length; r < n; r++) {
            var rowID = table.rows[r];
            var qty = rowID.cells[3].getElementsByTagName('INPUT')[0].value || 0;
            var price = rowID.cells[4].getElementsByTagName('INPUT')[0].value || 0;
            var total = parseInt(qty) * parseFloat(price);
            totalAmount += total;
            rowID.cells[4].getElementsByTagName('INPUT')[0].value = total;
        }
    }

    const Row = ({ id }) => (
        <tr>
            <td style={{width : '30px'}}><a onClick={handleRemoveRow} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a></td>
            <td style={{width : '150px'}}><input className="form-control" placeholder="Enter Item Code" name="code" value={state.rows[id].code} onChange={ItemCellChange(id)}  /></td>
            <td><input className="form-control" placeholder="Enter Item Description" name="name" value={state.rows[id].name} onChange={ItemCellChange(id)}  /></td>
            <td style={{width : '100px'}}><input className="form-control" placeholder="Quantity" name="qty" value={state.rows[id].qty} onChange={ItemCellChange(id)}  /></td>
            <td style={{width : '100px'}}><input className="form-control" placeholder="Price" name="price" value={state.rows[id].price} onChange={ItemCellChange(id)}  /></td>
            <td style={{width : '100px'}}><input className="form-control"  name="amount" value={state.rows[id].amount} onChange={ItemCellChange(id)} /></td>
        </tr>
    );

    const formField = (

        <>
            <div className="row">
                <div className="col-md-12">
                    <Card title="Vendor Information">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="vendor"
                                        label="Vendor"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Select a vendor"
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select a vendor"
                                            optionFilterProp="children"
                                            onChange={onVendorChange}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                vendorlist.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>

                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        id="vendor_address"
                                        label="Vendor Address"
                                        name="vendor_address"
                                        onChange={handleChange}
                                        rules={[
                                            {
                                                required: false,
                                                message: "Vendor Address"
                                            },
                                        ]}
                                    >
                                        <Input value={vend.vendor_address} placeholder="Vendor Address" />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        id="vendor_city"
                                        label="Vendor City"
                                        name="vendor_city"
                                        onChange={handleChange}
                                        rules={[
                                            {
                                                required: false,
                                                message: "Vendor City"
                                            },
                                        ]}
                                    >
                                        <Input value={vend.vendor_city} placeholder="Vendor City" />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        id="vendor_state"
                                        label="Vendor State"
                                        name="vendor_state"
                                        onChange={handleChange}
                                        rules={[
                                            {
                                                required: false,
                                                message: "Vendor State"
                                            },
                                        ]}
                                    >
                                        <Input value={vend.vendor_state} placeholder="Vendor State" />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        label="Vendor Zip Code"
                                        name="vendor_zip_code"
                                        onChange={handleChange}
                                        rules={[
                                            {
                                                required: false,
                                                message: "Vendor Zip Code"
                                            },
                                        ]}
                                    >
                                        <Input value={vend.vendor_zip_code} placeholder="Vendor Zip Code" />
                                    </Form.Item>
                                </div>
                            </div>

                        </div>
                    </Card>

                </div>
            </div>
            <div className="row">
                <div className="col-md-2">
                    <div className="gx-form-row0">
                        <Form.Item
                            name="shipping_method"
                            label="Shipping Method"
                            rules={[
                                {
                                    required: false,
                                    message: "Select a shipping method"
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select a shipping method"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {
                                    ShippingMethod.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>

                    </div>
                </div>


                <div className="col-md-2">
                    <div className="gx-form-row0">
                        <Form.Item
                            name="order_date"
                            label="Order Date"
                            initialValue={moment()}
                            rules={[
                                {
                                    required: true,
                                    message: "Order date"
                                },
                            ]}
                        >
                            <DatePicker format={CisUI().dateFormat} placeholder="Order Date" />
                        </Form.Item>

                    </div>
                </div>

                <div className="col-md-2">
                    <div className="gx-form-row0">
                        <Form.Item
                            name="required_date"
                            label="Required By"
                            initialValue={moment().add(+3, 'days')}
                            rules={[
                                {
                                    required: false,
                                    message: "Required by date"
                                },
                            ]}
                        >
                            <DatePicker format={CisUI().dateFormat} placeholder="Required by date" />
                        </Form.Item>

                    </div>
                </div>

            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            <h2>Requisition Items</h2>
                        </div>
                        <div className="col-md-6" style={{textAlign : "right"}}>
                            <a className="btn btn-primary" onClick={handleAddRow}>Add More</a>
                        </div>
                    </div>
                    <table id="itemtable" className="ptable table table-bordered">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th>Unit Price($)</th>
                            <th>Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.rows.map((item, idx) => (
                            <tr id="addr0" key={idx}>
                                <td style={{width : '30px'}}><a onClick={handleRemoveRow(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a></td>
                                <td>
                                    <Select
                                        showSearch
                                        placeholder="Select an account"
                                        optionFilterProp="children"
                                        style={{width : '100%'}}
                                        onChange={ItemNameCellChange(idx)}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            vendorItem.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.item_code}-{items.item_name}</Option>
                                            )
                                        }
                                    </Select>
                                </td>
                                <td style={{width : '100px'}}><input className="form-control text-right" style={{textAlign : 'right'}} placeholder="Quantity" name="qty" value={state.rows[idx].qty} onChange={ItemCellChange(idx)}  /></td>
                                <td style={{width : '100px'}}><input className="form-control text-right" style={{textAlign : 'right'}} readOnly={true} placeholder="Price" name="price" value={state.rows[idx].price} onChange={ItemCellChange(idx)}  /></td>
                                <td style={{width : '100px'}}><input className="form-control text-right" style={{textAlign : 'right'}} readOnly={true} name="amount" value={state.rows[idx].amount} onChange={ItemCellChange(idx)}  /></td>
                            </tr>
                        ))}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td style={{textAlign : 'right', fontWeight : 'bold'}} colSpan={4}>Total</td>
                            <td style={{textAlign : 'right', fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1(bill.total || 0)}</td>
                        </tr>
                        <tr style={{display: 'none'}}>
                            <td style={{textAlign : 'right'}} colSpan={4}>Discount</td>
                            <td><input type="text" className="form-control" id="discount" style={{textAlign : 'right'}} value={bill.discount_amount || 0} onChange={ChangeDiscount} name="discount" /></td>
                        </tr>
                        <tr style={{display: 'none'}}>
                            <td style={{textAlign : 'right', fontWeight : 'bold'}} colSpan={4}>After Discount</td>
                            <td style={{textAlign: 'right', fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1((parseFloat(bill.total || 0) - parseFloat(bill.discount_amount || 0)))}</td>
                        </tr>
                        <tr style={{display: 'none'}}>
                            <td style={{textAlign : 'right'}} colSpan={4}>Sales Tax</td>
                            <td><input type="text" className="form-control" id="tax" style={{textAlign : 'right'}} value={(bill.tax_amount || 0)} onChange={ChangeTax} name="tax" /></td>
                        </tr>
                        <tr style={{display: 'none'}}>
                            <td style={{textAlign : 'right'}} colSpan={4}>Freight</td>
                            <td><input type="text" className="form-control" id="tax" style={{textAlign : 'right'}} value={bill.freight || 0} onChange={ChangeFreight} name="freight" /></td>
                        </tr>
                        <tr style={{display: 'none'}}>
                            <td style={{textAlign : 'right', fontWeight : 'bold'}} colSpan={4}>Grand Total</td>
                            <td style={{textAlign : 'right', fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1(bill.grand_total || 0)}</td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>
    )

    const runData = () => {

    }

    return (
        <Card title={Title} extra={
            isEdit === 1
                ? CisUI().addAction('../../' + jsonfile.urls.list)
                : CisUI().addAction('../' + jsonfile.urls.list)
        }>
            {CisUI().showLoading}

            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                ref={formRef}
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{remember: true}}
            >
                {
                    formField
                }

                <div onLoad={OrderDetails}></div>
                <div className="row">
                    <div className="col-md-12" style={{textAlign: 'center', marginTop : '30px'}}>
                        <Button id="submitbutton" type="primary" htmlType="submit">
                            Submit
                        </Button>
                        <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                    </div>
                </div>

            </Form>
        </Card>
    );
};

export default NewReqStockOrder;