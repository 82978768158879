import React, {useEffect} from "react";
import {Button, Col, Form, Input, Row} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";

import {userSignIn} from "../appRedux/actions";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import {CisUI} from "../util/CISUI";
import {EyeInvisibleOutlined, EyeTwoTone, LockFilled} from "@ant-design/icons";
import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";


const SignIn = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const authUser = useSelector(({auth}) => auth.authUser);
	const [passwordVisible, setPasswordVisible] = React.useState(false);

	const onFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo);
	};

	// for(var i=0, len=localStorage.length; i<len; i++) {
	//   var key = localStorage.key(i);
	//   var value = localStorage[key];
	//   console.log(key + " => " + value);
	// }


	const onFinish = values => {
		// console.log("finish",values)
		dispatch(userSignIn(values));
	};

	useEffect(() => {
		if (authUser !== null) {
			history.push('/');
		}
	}, [authUser,history]);

	return (
		<div className={"loginv2"}>
			<div className={"header_logo"}>
				<img src={"/assets/coplogo.png"} style={{width: '200px'}} />
			</div>
			<div className="gx-app-login-wrap" style={{marginTop: "-80px"}}>
				<div className="gx-app-login-container">
					<div className="gx-app-login-main-content">
						<div className="gx-app-logo-content">
							<div className="gx-app-logo-content-bg" style={{backgroundColor: '#d8d8d8 !important'}}>

							</div>
							{/*<div className="gx-app-logo-wid">*/}
							{/*<h1><IntlMessages id="app.userAuth.signIn"/></h1>*/}
							{/*<p><IntlMessages id="app.userAuth.bySigning"/></p>*/}
							{/*</div>*/}
							<div className="gx-app-logo">
								<img alt="example" src="/assets/images/coplogo.png" style={{width: '100px'}} />
								{/*<img alt="example" src="/assets/images/cislogo.png"/>*/}
							</div>
						</div>
						<div className="gx-app-login-content">

							<Form
								initialValues={{ remember: true }}
								name="basic"
								onFinish={onFinish}
								{...CisUI().formItemLayout}
								onFinishFailed={onFinishFailed}
								className="gx-signin-form gx-form-row0">

								<Form.Item
									// initialValue="demo@example.com"
									label={"Email/Userid"}
									rules={[{ required: true, message: 'The input is not valid E-mail!' }]} name="email">
									<Input placeholder="Userid"/>
								</Form.Item>
								<Form.Item
									// initialValue="demo#123"
									label={"Password"}
									rules= {[{required: true, message: 'Please input your Password!'}]}  name="password">
									<Input type={"password"} placeholder="Password"/>
								</Form.Item>
								<Row gutter={24}>
									<Col span={12} offset={15}>
										<Link to="/forgotpassword"><IntlMessages id="app.userAuth.forgotPassword"/></Link>
									</Col>
									<Col span={12} offset={15} style={{textAlign: 'right'}}>
										<Button type="primary" className="gx-mb-0" htmlType="submit">
											<IntlMessages id="app.userAuth.signIn"/>
										</Button>
								</Col>
								</Row>
							</Form>

							<div id="msg" style={{color: 'red'}}></div>

						</div>
						<InfoView/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignIn;
