import React, {useState, useEffect} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {userSignOut} from "../appRedux/actions";
import {useDispatch, useSelector} from "react-redux";


function IdleMonitor()
{
    //Modal
    const sessionTimer = 15;
    // const logoutTimer = sessionTimer + 0.10;
    const logoutTimer = 17;
    let timeOutCalled = 0;

    const [idleModal, setIdleModal] = useState(false);
    const dispatch = useDispatch();

    let idleTimeout = 1000 * 60 * sessionTimer;  //1 minute
    let idleLogout = 1000 * 60 * logoutTimer; //2 Minutes
    let idleEvent;
    let idleLogoutEvent;

    //console.log('Session Started');

    /**
     * Add any other events listeners here
     */
    const events = [
        'mousemove',
        'click',
        'keypress'
    ];

    /**
     * @method sessionTimeout
     * This function is called with each event listener to set a timeout or clear a timeout.
     */
    const sessionTimeout = () =>
    {
        if (!!idleEvent) clearTimeout(idleEvent);
        if (!!idleLogoutEvent) clearTimeout(idleLogoutEvent);

        idleEvent = setTimeout(() => setIdleModal(true), idleTimeout); //show session warning modal.
        idleLogoutEvent = setTimeout(() => logOut(), idleLogout); //Call logged out on session expire.
        // console.log('Timer Called');
    };

    /**
     * @method extendSession
     * This function will extend current user session.
     */
    const extendSession = () =>
    {
        console.log('user wants to stay logged in');
        setIdleModal(false);
        timeOutCalled = 0;
    }


    /**
     * @method logOut
     * This function will destroy current user session.
     */
    const logOut = () =>
    {
        console.log('logging out');
        setIdleModal(false);
        if(dispatch(userSignOut())) {
            window.location = "/signin";
        }
    }

    useEffect(() =>
    {
        for (let e in events)
        {
            window.addEventListener(events[e], sessionTimeout);
        }

        return () =>
        {
            for(let e in events)
            {
                window.removeEventListener(events[e], sessionTimeout);
            }
        }
    },[]);


    return (

        <Modal isOpen={idleModal} toggle={() => setIdleModal(false)}>
            <ModalHeader toggle={() => setIdleModal(false)}>
                Session Expire Warning
            </ModalHeader>
            <ModalBody>
                Your session will expire in {idleLogout / 60 / 1000} minutes. Do you want to continue?
            </ModalBody>
            <ModalFooter>
                <button className="ant-btn ant-alert-info"  onClick={()=> logOut()}>Logout</button>
                <button className="ant-btn ant-btn-primary" onClick={()=> extendSession()}>Continue</button>

            </ModalFooter>
        </Modal>
    )

}

export default IdleMonitor;