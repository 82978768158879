import React, {useState} from "react";
import {
	Row,
	Col,
	Form,
	Card,
	Input,
	Button,
	notification,
	Divider,
	Checkbox,
	Select,
	Upload,
	DatePicker,
	InputNumber, Tooltip
} from 'antd';
import jsonfile from './withhold.json';
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../../../util/gerremotedata";
import {CisUI} from "../../../../../../util/CISUI";
import Config from "../../../../../../util/config";
import axios from "axios";
import {QuestionCircleOutlined, UploadOutlined} from "@ant-design/icons";

const WithholdCertificate = (props) => {
	const history = useHistory();
	const {form} = Form.useForm();

	const userID = props.editEmpId;
	let userData = [];
	// console.log("Userid : "+props.match.params.id);
	let isEdit = 0;
	if(userID > 0) {
		isEdit = 1;
	}
	let Title = jsonfile.addtitle;
	let endpoint = jsonfile.urls.add;
	let redirectto = "../"+jsonfile.urls.list;

	if(isEdit === 1 ) {
		userData = RemoteJson(jsonfile.urls.view+"/"+userID);
		Title = jsonfile.edittitle;
		endpoint = jsonfile.urls.edit+"/"+userID;
		redirectto = "../../"+jsonfile.urls.list;
		// props.empid(userID);
	}
	console.log("General", userData)

	const onFinish = (values) => {
		// console.log("Input Data : " + values);
		document.getElementById("loader").style.display = "block";
		axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
			.then(res => {
				// console.log(res)
				if(res.data.status === 1) {
					notification.success({
						message: 'Success',
						type : "success",
						description: res.data.msg
					});
					props.empid(res.data.emp_id)
					// history.push(redirectto);
				}
				else {
					notification.warning({
						message: 'Alert',
						type : "warning",
						description: res.data.msg
					});
				}
				document.getElementById("loader").style.display = "none";
			})
			.catch(errors => {
				console.log(errors);
			});
	}

	return (
		<>
			{CisUI().showLoading}
			<Form
				form={form}
				{...CisUI().formItemLayout}
				name="input"
				className="ant-advanced-search-form"
				onFinish={onFinish}
				initialValues={{ remember: true }}
			>
				<Row gutter={24}>
					<Divider orientation="left" plain><span style={{ fontSize: "16px", fontWeight: "bold"}}>Step 1: Personal Information</span></Divider>

					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="first_name"
								label="First Name"
							>
								<Input placeholder="First Name"/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="middle_name"
								label="Middle Name"
							>
								<Input placeholder="Middle Name"/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="last_name"
								label="Last Name"
							>
								<Input placeholder="Last Name"/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="ssn_no"
								label={
									<>
										<span style={{ marginRight: "5px"}}>Social Security Number</span>
										<Tooltip title={
											<>
												<p>Does your name match the name on your social security card? If not, to ensure you get credit for your earnings, contact SSA at 800-772-1212 or got to www.ssa.gov.</p>
											</>
										}>
											<QuestionCircleOutlined style={{ color: "#2f4d67", fontWeight: "bolder", fontSize: "15px" }} />
										</Tooltip>
									</>
								}
							>
								<InputNumber className="gx-w-100" placeholder="Enter Social Security Number"/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="address"
								label="Address"
							>
								<Input.TextArea placeholder="Enter Address"/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="city"
								label="City"
							>
								<Input placeholder="Enter City"/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="state"
								label="State"
							>
								<Input placeholder="Enter State"/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="zipcode"
								label="Zipcode"
							>
								<Input placeholder="Enter zipcode"/>
							</Form.Item>
						</div>
					</Col>
				</Row>

				<Row>
					<Col lg={24} md={24} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="employee"
								label=""
							>
								<Checkbox.Group>
									<Col>
										<Checkbox
											value="1"
											style={{
												lineHeight: '32px',
											}}
										>
											Single or Married filing separately
										</Checkbox>
									</Col>
									<Col>
										<Checkbox
											value="2"
											style={{
												lineHeight: '32px',
											}}
										>
											Married filing jointly or Qualifying widow(er)
										</Checkbox>
									</Col>
									<Col>
										<Checkbox
											value="3"
											style={{
												lineHeight: '32px',
											}}
										>
											Head of household (Check only fi you're unmarried and pay more than half the costs of keeping up a home for yourself and aqualifying individual.)
										</Checkbox>
									</Col>
								</Checkbox.Group>
							</Form.Item>
						</div>
					</Col>
					<Col lg={24} md={24} sm={24} xs={24}>
						<p style={{ margin: "15px 0"}}><strong>Complete Steps 2-4 ONLY if they apply to you; otherwise, skip to Step 5.</strong> See page 2 for more information on each step, who can claim exemption from withholding, when to use the estimator at www.irs.gov/W4App, and privacy.</p>
					</Col>
				</Row>
				<Row>
					<Divider orientation="left" plain><span style={{ fontSize: "16px", fontWeight: "bold"}}>Step 2: Multiple Jobs or Spouse Works</span></Divider>
					<Col>
						<p style={{ margin: "10px 0"}}>Complete this step if you (1) hold more than one job at a time, or (2) are married filing jointly and your spouse also works. The correct amount of withholding depends on income earned from all of these jobs.</p>
						<p><strong>Do only one of the following.</strong></p>
						<p>(a) Use the estimator at www.irs.gov/W4App for most accurate withholding for this step (and Steps 3-4); or</p>
						<p>(b) Use the Multiple Jobs Worksheet on page 3 and enter the result in Step 4(c) below for roughly accurate withholding; or</p>
						<p>(c) If there are only two jobs total, you may check this box. Do the same on Form W-4 for the other job. This option is accurate for jobs with similar pay; otherwise, more tax than necessary may be withheld.</p>
						<p><strong>TIP:</strong> To be accurate, submit a 2022 Form W-4 for all other jobs. If you (or your spouse) have self-employment income, including as an independent contractor, use the estimator.</p>
						<p style={{ margin: "15px 0"}}><strong>Complete Steps 3-4(b) on Form W-4 for only ONE of these jobs.</strong> Leave those steps blank for the other jobs. (Your withholding will be most accurate if you complete Steps 3-4(b) on the Form W-4 for the highest paying job.)</p>
					</Col>
				</Row>
				<Row>
					<Divider orientation="left" plain><span style={{ fontSize: "16px", fontWeight: "bold"}}>Step 3: Claim Dependents</span></Divider>
					<Col lg={24} md={24} sm={24} xs={24}>
						<table className="table">
							<tr>
								<td>If your total income will be $200,000 or less ($400,000 or less if married filing jointly):</td>
								<td></td>
							</tr>
							<tr>
								<td style={{ paddingLeft: "10px" }}>Multiply the number of qualifying children under age 17 by $2,000</td>
								<td>
									<Form.Item
										name="total_claim_child"
									>
										<InputNumber placeholder=""/>
									</Form.Item>
								</td>
							</tr>
							<tr>
								<td style={{ paddingLeft: "10px" }}>Multiply the number of other dependents by $500</td>
								<td>
									<Form.Item
										name="total_claim_other"
									>
										<InputNumber placeholder=""/>
									</Form.Item>
								</td>
							</tr>
							<tr>
								<td>Add the amounts above and enter the total here</td>
								<td>
									<Form.Item
										name="total_claim"
									>
										<InputNumber placeholder=""/>
									</Form.Item>
								</td>
							</tr>
						</table>
					</Col>
				</Row>
				<Row>
					<Divider orientation="left" plain><span style={{ fontSize: "16px", fontWeight: "bold"}}>Step 4 (optional): Other Adjustments</span></Divider>
					<Col lg={24} md={24} sm={24} xs={24}>
						<table className="table">
							<tr>
								<td><strong>(a) Other income (not from jobs).</strong> If you want tax withheld for other income you expect this year that won't have withholding, enter the amount of other income here. This may include interest, dividends, and retirement income</td>
								<td><Form.Item
										name="income"
									>
										<InputNumber placeholder=""/>
									</Form.Item>
								</td>
							</tr>
							<tr>
								<td><strong>(b) Deductions.</strong> If you expect to claim deductions other than the standard deduction and want to reduce your withholding, use the Deductions Worksheet on page 3 and enter the result here</td>
								<td><Form.Item
										name="deduction"
									>
										<InputNumber placeholder=""/>
									</Form.Item>
								</td>
							</tr>
							<tr>
								<td><strong>(c) Extra withholding.</strong> Enter any additional tax you want withheld each pay period.</td>
								<td><Form.Item
										name="extra_holding"
									>
										<InputNumber placeholder=""/>
									</Form.Item>
								</td>
							</tr>
						</table>
					</Col>
				</Row>
				<Row>
					<Divider orientation="left" plain><span style={{ fontSize: "16px", fontWeight: "bold"}}>Step 5: Sign Here</span></Divider>
					<Col lg={24} md={24} sm={24} xs={24}>
						<p style={{ margin: "10px 0", fontWeight: "bold"}}>Under penalties of perjury, I declare that this certificate, to the best of my knowledge and belief, is true, correct, and complete.</p>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								className="form-input"
								name="emp_signature_date"
								label="Signature Date"
							>
								<DatePicker className="gx-w-100" format={CisUI().dateFormat}/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								className="form-input"
								name="emp_signature"
								label="Employee SIGNATURE"
							>
								<Upload>
									<Button>
										<UploadOutlined/> Click to upload
									</Button>
								</Upload>
							</Form.Item>
						</div>
					</Col>
					<Col lg={24} md={24} sm={24} xs={24}>
						<p style={{ margin: "10px 0"}}><strong>Employer's Only.</strong></p>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								className="form-input"
								name="employer_name"
								label="Employer's Name"
							>
								<Input className="gx-w-100" placeholder="Enter Employer's Name"/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								className="form-input"
								name="employer_address"
								label="Employer's Address"
							>
								<Input.TextArea className="gx-w-100" placeholder="Enter Employer's Address"/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								className="form-input"
								name="employement_date"
								label="First Date Of Employement"
							>
								<DatePicker className="gx-w-100" format={CisUI().dateFormat}/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								className="form-input"
								name="ein"
								label="Employer Identification Number (EIN)"
							>
								<InputNumber className="gx-w-100" placeholder="Enter Employer Identification Number"/>
							</Form.Item>
						</div>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
						<Button type="primary" htmlType="submit">
							Submit
						</Button>
						<Button type="info" onClick={() => history.goBack()} htmlType="submit">Back</Button>
					</Col>
				</Row>
			</Form>
		</>
	);
}

export default WithholdCertificate;