module.exports = {
  fileserver: 'https://emstestapi.mycopusa.com/public',
  baseurl: 'https://emstest.mycopusa.com/',
  apiserver: 'https://emstestapi.mycopusa.com/api/',

  profile_pic_url: 'https://emstestapi.mycopusa.com/public/upload/profile/',
  support_article_image: 'https://emstestapi.mycopusa.com/public/upload/support_article/',

  logourl : '/assets/images/coplogo.png',
  print_logourl : '/assets/images/cop_nlogo.png',
  company_name : 'The Church of Pentecost U.S.A., Inc',
  address : 'Wayne, New Jersey, USA',
  city : 'Wayne',
  state : 'New Jersey',
  zipcode : '11111',
  phone : "",
  email : "info@copusa.org",
  website : "https://copusa.org",
  footerText: 'All rights reserved by - The Church of Pentecost U.S.A., Inc © 2022',
  currencySymbol: "$"

}
