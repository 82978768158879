import React, {useState, useEffect} from "react";
import {Form, Row, Col, Input, Button, Collapse, Card, Table} from 'antd';
import jsonfile from './nation.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from '../../../../util/Api'
import moment from "moment";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { Document, Packer, Paragraph, TableRow, TableCell,  TextRun } from "docx";

import CanvasJSReact from './../../../canvasjs/canvasjs.react';
import DonationConfig from '../../../../util/DonationConfig';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


const Search = Input.Search;
const Panel = Collapse.Panel;

const NationGrossReport = () => {
    const value = 0;
    const ref = React.createRef();
    const formRef = React.createRef();

    const [state, setState] = useState({
        reportLoaded : false,
        reqURL: Config.apiserver + jsonfile.urls.gross,
        filtervalue: '',
        reportTitle: '',
        reportData: [],
        regions: [],
        titles: {},
        TotalPosition: 0,
        TotalRow: 0,
        pieChart : {},
        localChart : {
            tithe : [],
            expenses : [],
            ldf : [],
            netToDistrict : []
        },
        districtChart : {
            tithe : [],
            expenses : [],
            ddf : [],
            netToRegion : []
        },
        regionChart : {
            tithe : [],
            expenses : [],
            rdf : [],
            aidf : [],
            netToNation : []
        }
    });

    const [report, setReport] = useState({});

    const history = useHistory();


    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        console.log('Received values of form: ', values);
        let wkd = "";
        let wk = "";
        let dt = "";
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    wk = formRef.current.getFieldValue('date');
                    const dt = CisUI().ParseDateFormat(values[key]);
                    return `${key}=${dt}`
                } else if (key === 'month') {
                    wk = formRef.current.getFieldValue('month');
                    // console.log("Selected Week : "+ wk);
                    wkd = CisUI().getMonthTitle(formRef.current.getFieldValue('month'));
                    return `${key}=${wkd[1]}`
                } else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined", "");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup);
        const newURL = Config.apiserver + jsonfile.urls.gross + "?" + qsup;
        console.log("NEURL :" + newURL);
        console.log("Month : " + wkd);
        const WKR = CisUI().ParseDateFormat(wk);
        const reportTitle = "Between " + CisUI().DateFormat(WKR[0]) + " and " + CisUI().DateFormat(WKR[1]);

        const url = Config.apiserver + jsonfile.urls.gross;
        //const data = SelectData(url);

        document.getElementById("loader").style.display = "block";
        axios.get(newURL)
            .then((res) => {
                console.log(res.data);
                document.getElementById("loader").style.display = "none";
                if (res.data.status === 1) {
                    //console.log("Response : "+ res.data);
                    const data = res.data;
                    setState({
                        reqURL : newURL,
                        reportLoaded: true,
                        reportData: data.grossData,
                        regions: data.regions,
                        titles: data.titles,
                        TotalRow: data.grossData.length,
                        TotalPosition: (data.grossData.length - 1),
                        reportTitle : reportTitle,
                        pieChart : data.pieChart,
                        localChart : data.localChart,
                        districtChart : data.districtChart,
                        regionChart : data.regionChart,
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    };

    const colorset =
        [
            "#0f3057",
            "#00587a",
            "#008891",
            "#51adcf",
            "#0cd0e9"
        ];

    const PieChart = {
        animationEnabled: true,
        title: {
            text: "Gross Tithe Spending Analysis"
        },
        legend: {
            horizontalAlign: "right",
            verticalAlign: "center",
            reversed: false
        },
        data: [{
            type: "pyramid",
            showInLegend: true,
            legendText: "{label}",
            indexLabel: "{label} - {y}",
            yValueFormatString: "$ #,##0.#",
            toolTipContent: "<b>{label}</b>: {y}",
            dataPoints: state.pieChart
        }]
    }

    const PieChart1 = {
        exportEnabled: true,
        animationEnabled: true,
        colorSet : colorset,
        title: {
            text: "Gross Tithe Spending Analysis",
            fontColor: "#27496d"
        },
        legend: {
            cursor: "pointer"
        },
        data: [{
            type: "pie",
            indexLabel: "#percent%",
            percentFormatString: "#0.##",
            toolTipContent: "{name} (#percent%)",
            showInLegend: true,
            colorSet : colorset,
            yValueFormatString: "$ #,##0.#",
            dataPoints: state.pieChart
        }]
    }

    const LocalTitheChart = {
        exportEnabled: true,
        animationEnabled: true,
        colorset : colorset,
        title:{
            text: "Local Tithe Analysis"
        },
        axisX: {
            title: "Region"
        },
        axisY: {
            title: "Amount",
        },
        toolTip: {
            shared: true
        },
        legend: {
            cursor: "pointer",
        },
        data: [
            {
            type: "column",
            name: "Tithe",
            showInLegend: true,
            color: "#0f3057",
            yValueFormatString: "$ #,##0.#",
            dataPoints: state.localChart.tithe
            },
            {
                type: "column",
                name: "Local Expenses",
                showInLegend: true,
                color: "#008891",
                yValueFormatString: "$ #,##0.#",
                dataPoints: state.localChart.expenses
            },
            {
                type: "column",
                name: "LDF",
                showInLegend: true,
                color: "#51adcf",
                yValueFormatString: "$ #,##0.#",
                dataPoints: state.localChart.ldf
            },
            {
                type: "column",
                name: "Net Tithe To District",
                showInLegend: true,
                color: "#0cd0e9",
                yValueFormatString: "$ #,##0.#",
                dataPoints: state.localChart.netToDistrict
            }
        ]
    }

    const DistrictTitheChart = {
        exportEnabled: true,
        animationEnabled: true,
        colorset : colorset,
        title:{
            text: "District Tithe Analysis"
        },
        axisX: {
            title: "Region"
        },
        axisY: {
            title: "Amount",
        },
        toolTip: {
            shared: true
        },
        legend: {
            cursor: "pointer",
        },
        data: [
            {
                type: "column",
                name: "Tithe",
                showInLegend: true,
                color: "#0f3057",
                yValueFormatString: "$ #,##0.#",
                dataPoints: state.districtChart.tithe
            },
            {
                type: "column",
                name: "District Expenses",
                showInLegend: true,
                color: "#008891",
                yValueFormatString: "$ #,##0.#",
                dataPoints: state.districtChart.expenses
            },
            {
                type: "column",
                name: "DDF",
                showInLegend: true,
                color: "#51adcf",
                yValueFormatString: "$ #,##0.#",
                dataPoints: state.districtChart.ddf
            },
            {
                type: "column",
                name: "Net Tithe To Region",
                showInLegend: true,
                color: "#0cd0e9",
                yValueFormatString: "$ #,##0.#",
                dataPoints: state.districtChart.netToRegion
            }
        ]
    }

    const RegionTitheChart = {
        exportEnabled: true,
        animationEnabled: true,
        colorset : colorset,
        title:{
            text: "Region Tithe Analysis"
        },
        axisX: {
            title: "Region"
        },
        axisY: {
            title: "Amount",
        },
        toolTip: {
            shared: true
        },
        legend: {
            cursor: "pointer",
        },
        data: [
            {
                type: "column",
                name: "Tithe",
                showInLegend: true,
                color: "#0f3057",
                yValueFormatString: "$ #,##0.#",
                dataPoints: state.regionChart.tithe
            },
            {
                type: "column",
                name: "Region Expenses",
                showInLegend: true,
                color: "#C9CCD5",
                yValueFormatString: "$ #,##0.#",
                dataPoints: state.regionChart.expenses
            },
            {
                type: "column",
                name: "RDF",
                showInLegend: true,
                color: "#008891",
                yValueFormatString: "$ #,##0.#",
                dataPoints: state.regionChart.rdf
            },
            {
                type: "column",
                name: "AIDF",
                showInLegend: true,
                color: "#51adcf",
                yValueFormatString: "$ #,##0.#",
                dataPoints: state.regionChart.aidf
            },
            {
                type: "column",
                name: "Net Tithe To Nation",
                showInLegend: true,
                color: "#0cd0e9",
                yValueFormatString: "$ #,##0.#",
                dataPoints: state.regionChart.netToNation
            }
        ]
    }

    const handleOnFilter = ({target: {name, value}}) => {
        //filterValue = value;
        console.log("filteringwith :" + value);
        setState({filtervalue: "test"});
    }

    let searchView = "";
    if (jsonfile.has_search) {
        searchView = <Card className="advance-search-card no-print">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']}>
                <Panel header="Advance Search" key="1">
                    <Form
                        key={5}
                        form={form}
                        ref={formRef}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch([], "", 1,"date",CisUI().PeriodFiled(form))}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const print = () => {
        // window.print();
        window.open(Config.baseurl+"nationgrossreportprint.html?url="+window.btoa(state.reqURL)+"&filename=donation_gross&data=grossData&subtitle="+state.reportTitle+"&rptype=grossreport","_blank");
    }


    //for excel data
    const exportToExcel = (apiData, fileName) => {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        //console.log("File type "+fileExtension);
        document.getElementById("rploader").style.display = "inline";
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        document.getElementById("rploader").style.display = "none";
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    const getAPIData = () => {
        //console.log("test"+state.reqURL);
        axios.get(state.reqURL)
            .then((res) => {
                //console.log(res.data);
                if(res.data.status === 1) {
                    const data = res.data.grossData;
                    exportToExcel(data,"grossreport");
                    //console.log(JSON.stringify(data));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    function createHeaders(keys) {
        var result = [];
        for (var i = 0; i < keys.length; i += 1) {
            result.push({
                id: keys[i],
                name: keys[i],
                prompt: keys[i],
                padding: 0,
                fontSize : '10px'
            });
        }
        return result;
    }

    const generateDatas = function(datav,headers) {
        var result = [];
        datav.map((x, i) => {
            //console.log(JSON.stringify(x));
            //var data = {};
            var data = [];
            for (var f = 0; f < headers.length; f += 1) {
                //data[headers[f]] = " "+x[headers[f]];
                data.push(x[headers[f]]);
            }
            //data.id = (i + 1).toString();
            result.push(Object.assign([], data));
        });
        //console.log(JSON.stringify(result));
        return result;

    };

    const generateObject = function(datav,headers) {
        var result = [];
        datav.map((x, i) => {
            //console.log(JSON.stringify(x));
            //var data = {};
            var data = [];
            for (var f = 0; f < headers.length; f += 1) {
                data[headers[f]] = " "+x[headers[f]];
                //data.push(x[headers[f]]);
            }
            result.push(Object.assign({}, data));
        });
        //console.log(JSON.stringify(result));
        return result;

    };

    const exportToDocs = (apiurl,datakey,filename,jsonfile) => {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [new Paragraph("hello")],
                        }),
                    ],
                }),
            ],
        });
        const buffer = Packer.toBuffer(table);
        const blob = new Blob([buffer], {type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'my.docx';
        link.click();
    }

    const exportToPDF = () => {
        //var doc = new jsPDF('p', 'pt');
        //var URL  = Config.baseurl+"print.html?url="+window.btoa(apiurl)+"&data="+datakey;
        document.getElementById("rploader").style.display = "inline";
        var resp = state.reportData;
        var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "landscape" });

        const Headers = ["Title"];
        state.regions.map((list, index) => {
            Headers.push(list.name);
        });
        Headers.push("Total");

        var DataRows = [];
        Object.keys(state.titles).map(function (keyName, keyIndex) {
            var RowItem = [];
            RowItem.push(state.titles[keyName][0]);
            state.reportData.map((list, index) => {
                RowItem.push(CisUI().getCurrencyFormated1(list[keyName]));
            })
            DataRows.push(RowItem);
        });

        //console.log(JSON.stringify(jsonfile.listView.title));
        //doc.table(10, 10, generateDatas(datav,jsonfile.listView.print), createHeaders(jsonfile.listView.print), { autoSize: true, padding: 2, fontSize: 10 });
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        const marginX = (pageWidth - 80) / 2;
        const xOffset = (doc.internal.pageSize.width / 2);

        // const marginY = (pageHeight - 30) / 2;
        const user = localStorage.getItem('userData');
        const uj = JSON.parse(user);
        //console.log("Username : "+uj["name"]);

        const Headers2 = [
            "LOCAL EXPENSES",
            "LDF",
            "DISTRICT EXPENSES",
            "DDF",
            "REGIONAL EXPENSES",
            "RDF",
            "AIDF "+DonationConfig.aidf+"% Of RDF",
            "RDF RETAINED ("+DonationConfig.remit+"%)",
            "NET TITHE TO NATION"

        ];

        const DataRows2 = [
            CisUI().getCurrencyFormated1(state.reportData[state.TotalPosition]['localExpense']),
            CisUI().getCurrencyFormated1(state.reportData[state.TotalPosition]['ldf']),
            CisUI().getCurrencyFormated1(state.reportData[state.TotalPosition]['districtExpense']),
            CisUI().getCurrencyFormated1(state.reportData[state.TotalPosition]['ddf']),
            CisUI().getCurrencyFormated1(state.reportData[state.TotalPosition]['totalRegionExpense']),
            CisUI().getCurrencyFormated1(state.reportData[state.TotalPosition]['rdf']),
            CisUI().getCurrencyFormated1(state.reportData[state.TotalPosition]['aidf']),
            CisUI().getCurrencyFormated1(state.reportData[state.TotalPosition]['rdfRetain']),
            CisUI().getCurrencyFormated1(state.reportData[state.TotalPosition]['netToNation']),
        ]

        doc.addImage(Config.print_logourl, "PNG", marginX, 5, 80, 27);
        doc.text("National Gross Tithe Analysis Report",xOffset,37, { align: 'center' });
        doc.setFontSize(12);
        doc.text(state.reportTitle,xOffset,43, { align: 'center' });
        doc.setFontSize(8);
        doc.text("Printed By : "+uj["name"],20,50, { align: 'left' });
        doc.setFontSize(8);
        doc.text("Date/Time : "+moment().format("MM/DD/YYYY HH:mm"),20,55, { align: 'left' });
        //doc.text(0, 37, jsonfile.printtitle, 'center');
        doc.autoTable({
            margin: { top: 60 },
            didDrawPage: function (data) {
                data.settings.margin.top = 10;
            },
            headStyles : { fillColor: [52, 73, 94] },
            head: [Headers],
            body: DataRows,
        })
        doc.setFontSize(8);
        doc.text("Summary", 15,40,{ align: 'left' });
        doc.setFontSize(15);
        doc.autoTable({
            // margin: { top: 200 },
            startY: 45,
            headStyles : { fillColor: [52, 73, 94] },
            head: [Headers2],
            body: [DataRows2],
        });
        doc.setFontSize(8);

        doc.save('grossreport.pdf');
        document.getElementById("rploader").style.display = "none";


    }

    const listAction = () =>
        (
            <div className="no-print">
                <a id="rploader" style={{width: '40px', height : '40px', display : 'none'}}>
                    <img src="/assets/images/loader.svg" style={{width: '40px', height : '40px'}} alt="loader" /> Please wait ...
                </a>
                <button className="btn btn-export-excel btn-circle-md" onClick={(e) => getAPIData()} title="Export to Excel"><i
                    className="fas fa-file-excel"/></button>
                <button className="btn btn-export-pdf btn-circle-md" onClick={exportToPDF} title="Export to PDF"><i
                    className="fas fa-file-pdf"/></button>
                <button className="btn btn-export-word btn-circle-md" onClick={exportToDocs} title="Export MS Word"><i
                    className="fas fa-file-word"/></button>
                <button onClick={print} className="btn btn-export-print btn-circle-md" title="Print"><i
                    className="fas fa-print"/></button>
            </div>
        );

    const getExtra = (
        <>
            {listAction()}
        </>
    );


    const options = {
        orientation: 'landscape',
        unit: 'in',
        format: [4, 2]
    };

    return (
        <>
            {searchView}
            <Card id="reportview" extra={getExtra}>
                {CisUI().showLoading}
                <div ref={ref}>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3>National Gross Tithe Analysis Report</h3>
                            <h2>{state.reportTitle}</h2>
                        </div>
                    </div>
                    {state.reportLoaded ?
                        <Collapse
                            style={{border: 'none'}}
                            accordion
                            expandIconPosition="right"
                            defaultActiveKey={['1']}>

                            <div className="table-responsive">

                                <table className="report2 table table-bordered">

                                    <thead>
                                    <tr>
                                        <th>Region Name</th>
                                        {
                                            state.regions.map((list, index) => {
                                                return <th style={{textAlign: 'right'}}>{list.name}</th>
                                            })
                                        }
                                        <th>Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Object.keys(state.titles).map(function (keyName, keyIndex) {
                                        return (
                                            <tr>
                                                <td style={{
                                                    textAlign: 'left',
                                                    fontWeight: state.titles[keyName][1]
                                                }}>{state.titles[keyName][0]}</td>
                                                {
                                                    state.reportData.map((list, index) => {
                                                        return <td style={{
                                                            textAlign: 'right',
                                                            fontWeight: state.titles[keyName][1]
                                                        }}>{CisUI().getCurrencyFormated1(list[keyName])}</td>
                                                    })
                                                }
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                                <h3>Summary</h3>
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th style={{textAlign: 'center'}}>LOCAL EXPENSES</th>
                                        <th style={{textAlign: 'center'}}>LDF</th>
                                        <th style={{textAlign: 'center'}}>DISTRICT EXPENSES</th>
                                        <th style={{textAlign: 'center'}}>DDF</th>
                                        <th style={{textAlign: 'center'}}>REGIONAL EXPENSES</th>
                                        <th style={{textAlign: 'center'}}>RDF</th>
                                        <th style={{textAlign: 'center'}}>AIDF {DonationConfig.aidf}% of RDF</th>
                                        <th style={{textAlign: 'center'}}>RDF RETAINED ({DonationConfig.remit}%)</th>
                                        <th style={{textAlign: 'center'}}>NET TITHE TO NATION</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {state.TotalPosition > 0 ?
                                        <tr>
                                            <th style={{textAlign: 'center'}}>{CisUI().getCurrencyFormated1(state.reportData[state.TotalPosition]['localExpense'])}</th>
                                            <th style={{textAlign: 'center'}}>{CisUI().getCurrencyFormated1(state.reportData[state.TotalPosition]['ldf'])}</th>
                                            <th style={{textAlign: 'center'}}>{CisUI().getCurrencyFormated1(state.reportData[state.TotalPosition]['districtExpense'])}</th>
                                            <th style={{textAlign: 'center'}}>{CisUI().getCurrencyFormated1(state.reportData[state.TotalPosition]['ddf'])}</th>
                                            <th style={{textAlign: 'center'}}>{CisUI().getCurrencyFormated1(state.reportData[state.TotalPosition]['totalRegionExpense'])}</th>
                                            <th style={{textAlign: 'center'}}>{CisUI().getCurrencyFormated1(state.reportData[state.TotalPosition]['rdf'])}</th>
                                            <th style={{textAlign: 'center'}}>{CisUI().getCurrencyFormated1(state.reportData[state.TotalPosition]['aidf'])}</th>
                                            <th style={{textAlign: 'center'}}>{CisUI().getCurrencyFormated1(state.reportData[state.TotalPosition]['rdfRetain'])}</th>
                                            <th style={{textAlign: 'center'}}>{CisUI().getCurrencyFormated1(state.reportData[state.TotalPosition]['netToNation'])}</th>
                                        </tr>
                                        : ''
                                    }
                                    </tbody>
                                </table>

                            </div>

                            <Row>
                                <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-visit-col">
                                    <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
                                        <div className="gx-card">
                                            <div className="gx-card-body">
                                                <CanvasJSChart options={PieChart}/>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>

                                <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-visit-col">
                                    <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
                                        <h6 className="gx-text-uppercase gx-mb-2 gx-mb-xl-4">Region Tithe Analysis</h6>
                                        <CanvasJSChart options={RegionTitheChart}
                                            /* onRef = {ref => this.chart = ref} */
                                        />
                                    </div>
                                </Col>
                            </Row>

                        </Collapse>
                        : ""
                    }

                </div>

            </Card>
        </>
    );
};

export default NationGrossReport;