import React, {useEffect, useState} from "react";
import {Form, Row, Col, Button, Checkbox, Card,notification} from 'antd';
import jsonfile from './vacation.json';
import {CisUI} from '../../../../util/CISUI';
import InputFields from '../../../../util/InputField';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import InputOnly from "../../../../util/InputOnly";
import IntlMessages from "../../../../util/IntlMessages";

const VacationDetails = (props) => {

    const [loading, setLoading] = useState([]);
    const [userData, setUserData] = useState({});

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    const userID = props.match.params.id;
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    if(isEdit === 1 ) {
        //userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }

    const [approved, setApprove] = useState(0);
    const [LeaveTypes, setLeveTypes] = useState({});
    const [form] = Form.useForm();
    const history = useHistory();

    useEffect(()=>{
        if(isEdit === 1) {
            getVactionDetails();
        }
        else {
            getMyVations();
        }

    },[isEdit]);

    const getMyVations = () => {
        axios.get(Config.apiserver +"myvacationtype")
            .then((res) => {
                if (res.data.status === 1) {
                    setLeveTypes(res.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getVactionDetails = () => {
        axios.get(Config.apiserver +jsonfile.urls.view+"/"+userID)
            .then((res) => {
                if (res.data.status === 1) {
                    setUserData(res.data.data);
                    setLeveTypes(res.data.vacationData)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const onFinish = (values) => {
        // // console.log(values);
        // document.getElementById("loader").style.display = "block";
        values = userData;
        values["status"] = approved;
        console.log(JSON.stringify(values));

        axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg)
                    history.push(redirectto);
                }
                else {
                    CisUI().Notification('error', res.data.msg)
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    const onApprove = (e) => {
        console.log(e);
        if(e.target.checked) {
            setApprove(1);
        }
        else {
            setApprove(0);
        }
    }


    //console.log("UserData : "+userData);

    return (
        <Card title={"Vaction Details"} extra={
            isEdit === 1
                ? CisUI().addAction('../../'+jsonfile.urls.list)
                : CisUI().addAction('../'+jsonfile.urls.list)
        }>
            {CisUI().showLoading}
            {
                userData.hasOwnProperty("id") ?
            <>
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                {/*{InputFields(jsonfile.input,userData,isEdit,props)}*/}
                <Row>
                    <Col className={"col-md-8"}>
                        <div className={"row"}>
                            <div className={"col-md-8"}>
                                <table className="table table-bordered">
                                    <tr>
                                        <th>Request#</th>
                                        <td>{userData?.request_no}</td>
                                    </tr>
                                    <tr>
                                        <th>Company</th>
                                        <td>{userData?.company}</td>
                                    </tr>
                                    <tr>
                                        <th>Employee</th>
                                        <td>{userData?.employee}</td>
                                    </tr>
                                    <tr>
                                        <th>Vacation Type</th>
                                        <td>{userData?.vacation_type_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Start Date</th>
                                        <td>{CisUI().DateFormat(userData.start_date)}</td>
                                    </tr>
                                    <tr>
                                        <th>End Date</th>
                                        <td>{CisUI().DateFormat(userData.end_date)}</td>
                                    </tr>
                                    <tr>
                                        <th>Number of Days</th>
                                        <td>{(userData.number_of_days)} Days</td>
                                    </tr>
                                    <tr>
                                        <th>Remark/Details</th>
                                        <td>{(userData.notes)}</td>
                                    </tr>
                                    <tr>
                                        <th>Created At</th>
                                        <td>{CisUI().DateFormat(userData.created_at)}</td>
                                    </tr>
                                    <tr>
                                        <th>Created By</th>
                                        <td>{(userData.employee)}</td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td>{CisUI().getOrderStatus(userData.status)}</td>
                                    </tr>
                                    {
                                        userData.status === 1 ? <>
                                            <tr>
                                                <th>Approved At</th>
                                                <td>{CisUI().DateFormat(userData.approved_at)}</td>
                                            </tr>
                                            <tr>
                                                <th>Approved By</th>
                                                <td>{(userData.approver_name)}</td>
                                            </tr>
                                        </>:<></>
                                    }
                                </table>
                            </div>
                        </div>
                        {
                            userData?.approver === 1 && userData?.status === 0 ? <>
                                <Row>
                                    <Col lg={6} xs={4}>
                                        <Form.Item
                                            name={"status"}
                                            label="Status"
                                            onChange={onApprove}
                                        >
                                            <Checkbox checked={approved === 1}>Approved?</Checkbox>

                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={24} xs={24} style={{ textAlign: "center" }}>
                                        <Button type="primary" htmlType="submit">
                                            Confirm Approve
                                        </Button>
                                    </Col>
                                </Row>

                            </>: <></>
                        }
                    </Col>
                    <Col className={"col-md-4"}>
                        <Card title={"Vacation Limit"}>
                            <table className={"table table-bordered"}>
                                <thead>
                                <tr>
                                    <th>Type</th>
                                    <th style={{textAlign: 'right'}}>Eligible</th>
                                    <th style={{textAlign: 'right'}}>Taken</th>
                                    <th style={{textAlign: 'right'}}>Available</th>
                                    <th style={{textAlign: 'right'}}>Pending</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    LeaveTypes?.data?.map((item,index)=>{
                                        return <tr>
                                            <td>{item.name}</td>
                                            <td style={{textAlign: 'right'}}>{item.limit}</td>
                                            <td style={{textAlign: 'right'}}>{item.taken}</td>
                                            <td style={{textAlign: 'right'}}>{item.available}</td>
                                            <td style={{textAlign: 'right'}}>{item.pendingLimit}</td>
                                        </tr>
                                    })
                                }
                                </tbody>
                                <tfoot>
                                <tr>
                                    <th>Total</th>
                                    <th style={{textAlign: 'right'}}>{LeaveTypes?.totalLimit}</th>
                                    <th style={{textAlign: 'right'}}>{LeaveTypes?.totalTaken}</th>
                                    <th style={{textAlign: 'right'}}>{LeaveTypes?.totalAvailable}</th>
                                    <th style={{textAlign: 'right'}}>{LeaveTypes?.pendingLimit}</th>
                                </tr>
                                </tfoot>
                            </table>
                        </Card>
                    </Col>
                </Row>
            </Form> </> : CisUI().showLoading }
        </Card>
    );
};

export default VacationDetails;