import React, {useState, useEffect} from "react";
import {Form, Col, Table, Input, notification, Collapse, Card} from 'antd';
import jsonfile from './collection.json'
import {Link, useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import ActionButton from '../../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import Loader from "../../../../components/Loader";

const Search = Input.Search;
const Panel = Collapse.Panel;

const PendingCollectionHistory = (props) => {
    const value = 0;
    let ttlAmount = 0;
    const [total, setTotal] = useState(0);
    const [totalselected, setTotalSelected] = useState(0);
    const history = useHistory();

    const [data, setData] = useState([]);
    const [stdata, setStdata] = useState([]);
    const [selected, setSelected] = useState([]);

    const [state, setState] = useState({
        reqURL: Config.apiserver + jsonfile.urls.pendinglist,
        reqURLsum: Config.apiserver + "donation/collectionsummary"
    });

    const [isLoading, setLoading] = useState(false);
    const [reference, setReference] = useState("");
    // const [currentPageSelected, setCurrentPageSelected] = useState(1);
    const [current, setCurrent] = useState(1);

    useEffect(() => {
        getAPIData(state.reqURL);
    }, []);

    const getAPIData = (url) => {
        setLoading(true);
        axios.get(url)
            .then(res => {
                console.log(res)
                setCurrent(1);
                if (res.data.status === 1) {
                    setData(res.data.data);
                }
                setLoading(false);
            })
            .catch(errors => {
                console.log(errors);
                setLoading(false);
            });
    }

    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);

    const getTotalSummary = (url) => {
        axios.get(url)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    setTotal(res.data.total);
                }
            })
            .catch(errors => {
                console.log(errors);
                // msg = (errors);
                //setLoading({loading:false});
                //setResponse([]);
                //document.getElementById("loader").style.display = "none";
            });
    }


    const selectedToApprove = (e) => {
        let allselected = [...selected]

        console.log("PVSelected : "+ JSON.stringify(allselected));
        console.log("Name : "+ JSON.stringify(e.target.name));
        console.log("Amount : "+ JSON.stringify(e.target.value));
        console.log("Cheked : "+ e.target.checked);
        // alert(e);

        const ID = parseInt(e.target.id);
        const Amount = parseFloat(e.target.value).toFixed(2);

        let ttl = totalselected;
        console.log("TotalAmount : "+ ttl);


        if (e.target.checked === true) {
            // console.log("SELECTED FOUND");
            if (!allselected.includes(ID)) {
                allselected.push(ID);
                ttl = parseFloat(ttl) + parseFloat(Amount);
                ttlAmount = parseFloat(ttlAmount) + parseFloat(Amount);
            }
        } else {
            console.log("Not SELECTED FOUND");
            // console.log(e.target.value);
            var index = allselected.indexOf(ID);
            // console.log(index);
            if (index !== -1) {
                allselected.splice(index, 1);
                ttl = parseFloat(ttl) - parseFloat(Amount);
                ttlAmount = parseFloat(ttlAmount) - parseFloat(Amount);
            }
        }

        setTotalSelected(ttl);
        setSelected(allselected);
        console.log("found: "+ ttl);
        console.log("AllSelected: "+ JSON.stringify(allselected));
        if (allselected.length > 0) {
            document.getElementById("prntbtn").style.display = "block";
            setStdata(Object.values(allselected).join(','));
        } else {
            document.getElementById("prntbtn").style.display = "none";
            setStdata("");
        }
    }

    const checkAll = (e) => {
        // var dcd = document.getElementById("datatable");
        var dcd = document.getElementsByTagName("table")[0];
        var checkboxes = dcd.getElementsByTagName('input');
        let allselected = [];
        //let ttl = totalselected;

        var table = document.getElementsByTagName("table");
        var row = table[0].rows.length;
        var rows = table[0].rows;
        let total = 0;
        for (var x = 1; x < row; x++) {
            var cell = rows[x].cells[6].innerText;
            var rp = cell.replace("$","");
            // console.log("Amount:"+ parseFloat(rp));
            total += parseFloat(rp) || 0;
        }
        // console.log("Amount:"+ total);

        if (e.target.checked) {
            allselected = [];
            for (var i = 1; i < checkboxes.length; i++) {
                if (checkboxes[i].checked) {
                    checkboxes[i].checked = false;
                }
            }

            // setTotalSelected(0);
            //
            for (var i = 1; i < checkboxes.length; i++) {
                // console.log(i);
                // if (checkboxes[i].checked === true) {
                //     checkboxes[i].checked = false;
                // } else {
                //     checkboxes[i].checked = true;
                //     if (!allselected.includes(checkboxes[i].value)) {
                //         allselected.push(checkboxes[i].value);
                //     }
                // }

                checkboxes[i].checked = true;
                if (!allselected.includes(checkboxes[i].id)) {
                    allselected.push(parseInt(checkboxes[i].id));
                }
                else {
                    allselected.push(parseInt(checkboxes[i].id));
                }

            }
            // setTotalSelected(total);
        } else {
            allselected = [];
            for (var i = 1; i < checkboxes.length; i++) {
                if (checkboxes[i].checked) {
                    checkboxes[i].checked = false;
                }
            }
            total = 0;
        }

        // console.log(allselected)
        setTotalSelected(total);
        setSelected(allselected);

        if (allselected.length > 0) {
            document.getElementById("prntbtn").style.display = "block";
            setStdata(Object.values(allselected).join(','));
        } else {
            document.getElementById("prntbtn").style.display = "none";
            setStdata("");
        }
    }


    const headers = {
        // _id: {
        //     text: '#',
        //     invisible: false,
        //     filterable: false,
        //     transform: (value) => `${++value}`,
        // },
        id: {
            text: <input type="checkbox" onChange={checkAll} name="checkAll" id="checkAll" value={1}/>,
            // text: "#",
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                <input type="checkbox" onChange={(e)=>selectedToApprove(e)} id={value} name={"ckgroup"} value={row.amount}/>
            )
        },
        date: {
            text: <IntlMessages id="Date"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        amount: {
            text: <IntlMessages id="Amount"/>,
            sortable: true,
            filterable: true,
            class: "rightAlign",
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        actions: {
            text: 'Actions',
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {ActionButton(row.id, jsonfile.urls, jsonfile.urls.list)}
                </>
            ),
        },
    };

    const columns = [
        {
            title: <input type="checkbox" onChange={checkAll} name="checkAll" id="checkAll" value={1}/>,
            dataIndex: "id",
            key: "id",
            showSorterTooltip: false,
            align: "center",
            render: (value,record) => (
                <input type="checkbox" onChange={(e)=>selectedToApprove(e)} id={value} name={"ckgroup"} value={record.amount}/>
            ),
        },
        {
            title: <IntlMessages id="Date" />,
            dataIndex: "date",
            key: "date",
            showSorterTooltip: false,
            render: (value) => (
                CisUI().DateFormat(value)
            ),
            align: "left",
        },
        {
            title: <IntlMessages id="Member" />,
            dataIndex: "member",
            key: "member",
            showSorterTooltip: false,
            align: "left",
        },
        {
            title: <IntlMessages id="Tithe Number" />,
            dataIndex: "tithe_number",
            key: "tithe_number",
            showSorterTooltip: false,
            align: "left",
        },
        {
            title: <IntlMessages id="Contribution Type" />,
            dataIndex: "contribution_type",
            key: "contribution_type",
            showSorterTooltip: false,
            align: "left",
        },
        {
            title: <IntlMessages id="Payment Method" />,
            dataIndex: "payment_method",
            key: "payment_method",
            showSorterTooltip: false,
            align: "left",
        },
        {
            title: <IntlMessages id="Amount" />,
            dataIndex: "amount",
            key: "amount",
            showSorterTooltip: false,
            render: (value) => (
                CisUI().getCurrencyFormated1(value)
            ),
            align: "right",
        },
        {
            title: <IntlMessages id="Local" />,
            dataIndex: "local",
            key: "local",
            showSorterTooltip: false,
            align: "left",
        },
        {
            title: <IntlMessages id="District" />,
            dataIndex: "district",
            key: "district",
            showSorterTooltip: false,
            align: "left",
        },
        {
            title: <IntlMessages id="Region" />,
            dataIndex: "region",
            key: "region",
            showSorterTooltip: false,
            align: "left",
        },
        {
            title: <IntlMessages id="Status" />,
            dataIndex: "status",
            key: "status",
            showSorterTooltip: false,
            align: "center",
            render: (value) => (
                CisUI().getDonationStatus(value)
            ),
        },

    ];

    const goForApprove = () => {
        console.log(stdata);
        document.getElementById("loader").style.display = "block";
        const vl = encodeURIComponent(reference)
        axios.post(Config.apiserver + "donation/multicollectionadd?status=1&tps=" + stdata+"&ref="+vl, null)
            .then(res => {
                console.log(res)
                document.getElementById("loader").style.display = "none";
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    // history.push("../" + jsonfile.urls.pendinglist_url);
                    window.location.reload();
                    //history.push("newbill?po="+polist.po_id);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                //setLoading({loading: false});
            })
            .catch(errors => {
                document.getElementById("loader").style.display = "none";
                //console.log(errors.response.data.errors);
                //setLoading({loading: false});
            });
    }

    const goForReject = () => {
        axios.post(Config.apiserver + "donation/multicollectionadd?status=3&tps=" + stdata, null)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    // history.push("../" + jsonfile.urls.pendinglist_url);
                    window.location.reload();
                    //history.push("newbill?po="+polist.po_id);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading: false});
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                setLoading({loading: false});
            });
    }

    const orderedHeaders = jsonfile.listView.title;

    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if (list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name}/>
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        console.log('Received values of form: ', values);
        const qsup = CisUI().getQueryStrings(values);
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup);
        const newURL = Config.apiserver + jsonfile.urls.pendinglist + "/?" + qsup;
        const newSumURL = Config.apiserver + "donation/collectionsummary/?" + qsup;
        setState({reqURL: newURL, reqURLsum: newSumURL});
        setTotal(0);
        getAPIData(newURL);
        //getTotalSummary(newSumURL);
    };

    const handleOnFilter = ({target: {name, value}}) => {
        //filterValue = value;
        console.log("filteringwith :" + value);
        setState({filtervalue: "test"});
    }

    let searchView = "";
    if (jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']}>
                <Panel header="Advance Search" key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.search, "", 1, "date", CisUI().PeriodFiled(form))}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {CisUI().listActionReport("../" + jsonfile.urls.add, "", state.reqURL, "data", "contributions", jsonfile, state.subtitle, "landscape")}
        </>
    );

    const onReferenceChange = (e) => {
        //console.log(e.target.value);
          setReference(e.target.value);
    }

    const onTableChange = e => {
        console.log(e);

        var dcd = document.getElementsByTagName("table")[0];
        var checkboxes = dcd.getElementsByTagName('input');
        let allselected = [];
        //let ttl = totalselected;

        var table = document.getElementsByTagName("table");
        var row = table[0].rows.length;
        var rows = table[0].rows;
        let total = 0;
        allselected = [];
        for (var i = 1; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                checkboxes[i].checked = false;
            }
        }

        setTotalSelected(total);
        setSelected(allselected);

        if (allselected.length > 0) {
            document.getElementById("prntbtn").style.display = "block";
            setStdata(Object.values(allselected).join(','));
        } else {
            document.getElementById("prntbtn").style.display = "none";
            setStdata("");
        }

    }


    return (
        <>
            {searchView}
            <Card title={jsonfile.pendingtitle} extra={getExtra}>
               <div className="row" id="prntbtn" style={{display: 'none'}}>
                   <div className="col-md-6">
                       <div className="row">
                           <div className="col-md-8">
                               <input className="form-control" onChange={onReferenceChange} id="reference" placeholder="Remark/Memo" />
                           </div>
                           <div className="col-md-4">
                               <a onClick={goForApprove} className="btn btn-primary" style={{width: '150px'}}>Post to Financial</a>
                               <a onClick={goForReject} className="btn btn-danger" style={{width: '100px'}}>Void</a>
                           </div>
                       </div>
                   </div>
                   <div className="col-md-6">
                       <h2>Total Selected : {CisUI().getCurrencyFormated1(totalselected)}</h2>
                   </div>
               </div>
                {CisUI().showLoading}
                <div id="datatable">
                    {/*<SmartDataTable*/}
                    {/*    name='test-fake-table'*/}
                    {/*    data={state.reqURL}*/}
                    {/*    dataRequestOptions={CisUI().HeaderRequest}*/}
                    {/*    dataKey="data"*/}
                    {/*    headers={headers}*/}
                    {/*    orderedHeaders={orderedHeaders}*/}
                    {/*    hideUnordered={CisUI().hideUnordered}*/}
                    {/*    className={CisUI().sematicUI.table}*/}
                    {/*    filterValue={state.filterValue}*/}
                    {/*    perPage={CisUI().perPage}*/}
                    {/*    sortable*/}
                    {/*    // withLinks*/}
                    {/*    // withHeader*/}
                    {/*    // withToggles*/}
                    {/*    loader={CisUI().loader}*/}
                    {/*    parseBool={{*/}
                    {/*        yesWord: 'Yes',*/}
                    {/*        noWord: 'No',*/}
                    {/*    }}*/}
                    {/*    parseImg={{*/}
                    {/*        style: {*/}
                    {/*            border: '1px solid #ddd',*/}
                    {/*            borderRadius: '50px',*/}
                    {/*            padding: '3px',*/}
                    {/*            width: '30px',*/}
                    {/*            height: '30px'*/}
                    {/*        },*/}
                    {/*    }}*/}
                    {/*    emptyTable={CisUI().emptyTable}*/}
                    {/*/>*/}

                    <Table
                        size="small"
                        className="ui compact selectable table"
                        scroll={{ x: true }}
                        columns={columns}
                        dataSource={data}
                        onChange={onTableChange}
                        bordered
                        pagination={{ position: ["bottomCenter"], defaultCurrent: current, defaultPageSize: 50, showSizeChanger: true, pageSizeOptions: ['30', '50', '100','200'] }}
                        loading={{ spinning: isLoading, indicator: <Loader /> }}
                    />

                </div>

                <div className="row" style={{display: 'none'}}>
                    <div className="col-md-12" style={{textAlign: 'center'}}>
                        <h4>Total : {CisUI().getCurrencyFormated1(total || 0)}</h4>
                    </div>
                </div>

            </Card>




        </>
    );
};

export default PendingCollectionHistory;