import React, {useState} from "react";
import {Row, Col, Form, Card, Input, Button, notification, Divider, Checkbox, Select, Upload, DatePicker} from 'antd';
import jsonfile from './employee_exit.json';
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../../util/gerremotedata";
import InputFieldV2 from "../../../../../util/InputFieldV2";
import {CisUI} from "../../../../../util/CISUI";
import Config from "../../../../../util/config";
import axios from "axios";
import {UploadOutlined} from "@ant-design/icons";

const EmployeeExit = (props) => {
	const [probationarySummary, setProbationarySummary] = useState(1);

	const history = useHistory();
	const {form} = Form.useForm();

	const [isShow, setIsShow] = useState(false);
	const handleResidence = (e) => {
		e.target.checked === true ? setIsShow(true) : setIsShow(false);
	}

	const userID = props.editEmpId;
	let userData = [];
	// console.log("Userid : "+props.match.params.id);
	let isEdit = 0;
	if(userID > 0) {
		isEdit = 1;
	}
	let Title = jsonfile.addtitle;
	let endpoint = jsonfile.urls.add;
	let redirectto = "../"+jsonfile.urls.list;

	if(isEdit === 1 ) {
		userData = RemoteJson(jsonfile.urls.view+"/"+userID);
		Title = jsonfile.edittitle;
		endpoint = jsonfile.urls.edit+"/"+userID;
		redirectto = "../../"+jsonfile.urls.list;
		// props.empid(userID);
	}
	console.log("General", userData)

	const onFinish = (values) => {
		// console.log("Input Data : " + values);
		document.getElementById("loader").style.display = "block";
		axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
			.then(res => {
				// console.log(res)
				if(res.data.status === 1) {
					notification.success({
						message: 'Success',
						type : "success",
						description: res.data.msg
					});
					props.empid(res.data.emp_id)
					// history.push(redirectto);
				}
				else {
					notification.warning({
						message: 'Alert',
						type : "warning",
						description: res.data.msg
					});
				}
				document.getElementById("loader").style.display = "none";
			})
			.catch(errors => {
				console.log(errors);
			});
	}

	const handlePVSummary = (value) => {
		console.log("Prob",value)
		setProbationarySummary(value)
	}

	return (
		<Card title={isEdit === 1 ? jsonfile.edittitle : jsonfile.addtitle}
		      extra={
			      <>
				      {
					      isEdit === 1
						      ? CisUI().addAction(redirectto)
						      : CisUI().addAction(redirectto)
				      }
			      </>
		      }
		>
			{CisUI().showLoading}
			<Form
				form={form}
				{...CisUI().formItemLayout}
				name="input"
				className="ant-advanced-search-form"
				onFinish={onFinish}
				initialValues={{ remember: true }}
			>
				{InputFieldV2(jsonfile.input,userData,isEdit,props)}
				<Row gutter={24}>

					<Divider orientation="left" plain><span style={{ fontSize: "16px", fontWeight: "bold"}}>Hand Over</span></Divider>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="hand_over"
								label="Hand Over Material"
							>
								<Select
									placeholder="Please Select An Option"
								>
									<Select.Option value={1}>CHURCH VEHICLE</Select.Option>
									<Select.Option value={2}>LAPTOP</Select.Option>
									<Select.Option value={3}>ID CARD</Select.Option>
									<Select.Option value={4}>ACCESS KEYS</Select.Option>
									<Select.Option value={5}>HANDOVER NOTES</Select.Option>
									<Select.Option value={6}>CHURCH BANK ACCOUNT / CHECK BOOKS</Select.Option>
								</Select>
							</Form.Item>
						</div>
					</Col>
					<Col lg={8} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="other_materials"
								label="Others Material(Please Specify)"
							>
								<Input.TextArea placeholder="Others Material"/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={12} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								className="form-input"
								name="employee_sugnature"
								label="Employee Signature"
							>
								<Upload>
									<Button>
										<UploadOutlined/> Click to upload
									</Button>
								</Upload>
							</Form.Item>
						</div>
					</Col>
				</Row>
				<Row>
					<Divider orientation="left" plain><span style={{ fontSize: "16px", fontWeight: "bold"}}>FOR HR USE ONLY</span></Divider>
					<Col lg={12} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="hr_comment"
								label="HR Comment"
							>
								<Input.TextArea placeholder="HR Comment"/>
							</Form.Item>
						</div>
					</Col>
					<Col lg={12} md={12} sm={24} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								className="form-input"
								name="hr_signature"
								label="HR SIGNATURE"
							>
								<Upload>
									<Button>
										<UploadOutlined/> Click to upload
									</Button>
								</Upload>
							</Form.Item>
						</div>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
						<Button type="primary" htmlType="submit">
							Submit
						</Button>
						<Button type="info" onClick={() => history.goBack()} htmlType="submit">Back</Button>
					</Col>
				</Row>

			</Form>
		</Card>
	);
}

export default EmployeeExit;