import React, {useState, useEffect} from "react";
import axios from "axios";
import Config from "./config";

const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'superadmin:123456'
}

const PostToAPI = (endpoint="",values) => {

    const [response, setResponse] = useState([]);

    useEffect(() => {
        requestExecute();
    }, []);

    const requestExecute = async () => {
        return axios.post(Config.apiserver + endpoint, values, {headers: headers})
            .then(res => {
                setResponse(res.data);
            })
            .catch(errors => {
                console.log(errors);
                //setResponse([]);
            });
    };

    return response;

}

export default PostToAPI;