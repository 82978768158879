import React from "react";
import 'react-smart-data-table/dist/react-smart-data-table.css'
import { FileManager, FileNavigator } from '@opuscapita/react-filemanager';
import connector from '@opuscapita/react-filemanager-connector-node-v1';


const FileManagerDocument = (props) => {

    const apiOptions = {
        ...connector.apiOptions,
        apiRoot: `https://demo.core.dev.opuscapita.com/filemanager/master`
    };

    return (
        <div style={{ height: '480px' }}>
            <FileManager>
                <FileNavigator
                    id="filemanager-1"
                    api={connector.api}
                    apiOptions={apiOptions}
                    capabilities={connector.capabilities}
                    listViewLayout={connector.listViewLayout}
                    viewLayoutOptions={connector.viewLayoutOptions}
                />
            </FileManager>
        </div>
    );


}

export default FileManagerDocument;