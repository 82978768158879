import React, {useState, useEffect} from "react";
import {Form, Input, Button, Card, Select, DatePicker, notification} from 'antd';
import jsonfile from './purchase.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import SelectData from "../../../../util/selectdata";
import moment from "moment";
import CircularProgress from "../../../../components/CircularProgress";

const {Option} = Select;

const EditInvPurchaseOrder = (props) => {

    const formRef = React.createRef();

    const [state, setState] = useState({
        data: [],
        rows: []
    })

    const [companyID, setCompanyId] = useState(0);
    const [userData, setUserData] = useState([]);
    const [vendor, setVendor] = useState([]);
    const [items, setItems] = useState([]);
    const [inventoryitem, setInventoryItem] = useState([]);
    const [vendorItem, setVendorItem] = useState([]);

    const [bill, setBill] = useState(
        {
            total : 0,
            grand_total : 0,
            freight : 0,
            taxp : 0,
            tax_amount : 0,
            discount_p : 0,
            discount_amount : 0
        }
    );

    const [comp, setComp] = useState(
        {
            com_address: "",
            com_city: "",
            com_state: "",
            com_zip_code: ""
        }
    );

    const [vend, setVend] = useState(
        {
            vendor_address: "",
            vendor_city: "",
            vendor_state: "",
            vendor_zip_code: "",
            shipping_method: ""
        }
    );


    const Companies = SelectData("company/companylists");
    const ShippingMethod = SelectData("shippingmethod");
    // const inventoryitem = SelectData("inventory/invitemlist");
    const vendorlist = SelectData("inventory/vendorlist");

    const handleChange = (e) => {
        setComp({
            [e.target.name]: e.target.value
        })
    }

    const userID = props.match.params.id;
    // let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    const orderDetails = () => {
        axios.get(jsonfile.urls.view + "/" + userID)
            .then((res) => {
                if(res.data.status === 1) {
                    const POData = res.data.podata;
                    setUserData(res.data);
                    setCompanyId(res.data.podata.company_id)
                    const items = res.data.items;
                    setItems(res.data.items)
                    setVendor(res.data.vendors);
                    setBill({
                        total : POData.total,
                        grand_total : POData.grand_total,
                        freight : POData.freight,
                        taxp : POData.sales_tax_p,
                        tax_amount : POData.sales_tax,
                        discount_p : POData.discount_p,
                        discount_amount : POData.discount
                    });

                    let rows = [];
                    items.map((itm, idx) => {
                        rows[idx] = {
                            item_id: itm.item_id,
                            qty: itm.quantity,
                            price: itm.price,
                            amount: itm.total
                        };
                    })

                    setState({
                        data : state.data,
                        rows: rows
                    });

                    setInventoryItem(res.data.inventoryitem);

                }
            })
            .catch((error) => {
                console.log(error);
            });
    }


    useEffect(() => {
        orderDetails();
    }, [userID])


    // console.log(userData)

    let Title = jsonfile.edittitle;
    let endpoint = jsonfile.urls.edit + "/" + userID;
    let redirectto = "../../" + jsonfile.urls.list;



    const [form] = Form.useForm();
    const history = useHistory();


    const onFinish = (values) => {
        // setLoading({loading: true})
        values["items"] = state.rows;
        values["summary"] = bill;
        // console.log(JSON.stringify(values));
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    const onCompanyChange = value => {
        console.log("selected : "+ value);
        let fl = Companies.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            axios.get("vendor/vendorinfobycompany?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

           // console.log("selected : "+ JSON.stringify(company));
            //console.log("address : "+ company.address_line_1+", "+company.address_line_2);
            //document.getElementById("input_com_address").value = "Test";
            formRef.current.setFieldsValue({
                com_address: company.address_line_1+", "+company.address_line_2,
                com_city: company.city,
                com_state: company.state,
                com_zip_code: company.zip_code,
                vendor: "",
                vendor_address: "",
                vendor_city: "",
                vendor_state: "",
                vendor_zip_code: "",
                shipping_method: "",
                sales_tax_pp: 0
            });
        }
    }

    const onVendorChange = value => {
        console.log("selected : "+ value);
        let fl = vendor.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            console.log("selected : "+ JSON.stringify(company));
            //console.log("address : "+ company.billing_address_line_1+", "+company.billing_address_line_2);
            //document.getElementById("input_com_address").value = company.address_line_1+", "+company.address_line_2;
            var bill1 = company["billing_address_line_1"];
            var bill2 = company["billing_address_line_2"];

            formRef.current.setFieldsValue({
                vendor_address: bill1+", "+bill2,
                vendor_city: company["billing_city"],
                vendor_state: company["state_name"],
                vendor_zip_code: company["shipping_zipcode"],
                shipping_method: company["shipping_method"],
                sales_tax_pp : company["shipping_tax"]
            });

            // setVend({
            //     vendor_address: bill1+", "+bill2,
            //     vendor_city: company["billing_city"],
            //     vendor_state: company["state_name"],
            //     vendor_zip_code: company["shipping_zipcode"],
            //     shipping_method: company["shipping_method"]
            // })

            var total = bill.total;
            var freight = company.shipping_freight;
            var taxp = company.shipping_tax;
            var discount_p = bill.discount_p;
            var discount_amount = total * discount_p / 100;
            var totalBeforeTax = total - discount_amount ;
            var taxAmount = totalBeforeTax * (taxp / 100);
            var grandTotal = totalBeforeTax + taxAmount + freight;

            setBill({
                total : total,
                grand_total : grandTotal,
                freight : freight,
                taxp : taxp,
                tax_amount : taxAmount,
                discount_p : discount_p,
                discount_amount : discount_amount
            });

            // console.log("All: "+inventoryitem);
            let filteredArray = inventoryitem.filter((item, index) => item.vendor_id === value);
            // console.log("Filtered: "+filteredArray);
            setVendorItem(inventoryitem);

        }
    }

    const ItemNameCellChange = idx => e => {
        const rows = [...state.rows];
        rows[idx] = {
            item_id: e,
            qty: rows[idx].qty,
            price: rows[idx].price,
            amount: rows[idx].amount
        };

        setState({
            data : state.data,
            rows: rows
        });
    }

    const removeRow = () => {
        let { data } = state;
        data.pop(data.length); // data.length is one more than actual length since array starts from 0.
        setState({data});
    }

    const ItemCellChange = idx => e => {
        const { name, value } = e.target;
        const rows = [...state.rows];
        console.log("selectedITems:"+ JSON.stringify(rows[idx]));
        if(name === "qty") {
            const amount = value * rows[idx].price;
            rows[idx] = {
                item_id: rows[idx].item_id,
                qty: value,
                price: rows[idx].price,
                amount: amount
            };
        }
        else if(name === "price") {
            //CalculateCell();
            const amount = value * rows[idx].qty;
            rows[idx] = {
                item_id: rows[idx].item_id,
                qty: rows[idx].qty,
                price: value,
                amount: amount
            };

        }
        else if(name === "amount") {
            rows[idx] = {
                item_id: rows[idx].item_id,
                qty: rows[idx].qty,
                price: rows[idx].price,
                amount: value
            };
        }

        setState({
            rows
        });

        let totals = 0
        rows.map((item, idx) => {
            totals += item.amount;
        });

        var total = totals;
        var freight = bill.freight;
        var taxp = 0;
        var discount_p = bill.discount_p;
        var discount_amount = (parseFloat(total) * discount_p / 100) || 0;
        var totalBeforeTax = (parseFloat(total)) - parseFloat(discount_amount);
        var taxAmount = bill.taxp;
        var grandTotal = totalBeforeTax + taxAmount + parseFloat(freight);

        setBill({
            total : total,
            grand_total : grandTotal,
            freight : freight,
            taxp : taxp,
            tax_amount : taxAmount,
            discount_p : discount_p,
            discount_amount : discount_amount
        });

    };

    const ChangeTax = e => {

        const  value = parseFloat(e.target.value);
        let totals = 0;
        state.rows.map((item, idx) => {
            totals += item.amount;
        });

        const BeforeTax = (totals) - bill.discount;
        const tax = BeforeTax * (value / 100);
        const grand = (totals + bill.freight + tax) - bill.discount;
        setBill({
            total : totals,
            grand_total : grand,
            freight : bill.freight,
            tax : value,
            discount : bill.discount
        });
    }

    const ChangeFreight = e => {
        let totals = 0;
        const  value = parseFloat(e.target.value);
        state.rows.map((item, idx) => {
            totals += item.amount;
        });


        var total = totals;
        var freight = value || 0;
        var taxp = bill.taxp;
        var discount_p = bill.discount_p;
        var discount_amount = (parseFloat(total) * discount_p / 100) || 0;
        var totalBeforeTax = (parseFloat(total)) - parseFloat(discount_amount);
        var taxAmount = (parseFloat(totalBeforeTax) * (parseFloat(taxp) / 100)) || 0;
        var grandTotal = totalBeforeTax + taxAmount + parseFloat(freight);

        setBill({
            total : total,
            grand_total : grandTotal,
            freight : freight,
            taxp : taxp,
            tax_amount : taxAmount,
            discount_p : discount_p,
            discount_amount : discount_amount
        });
    }

    const ChangeDiscount = e => {
        let totals = 0;
        const  value = parseFloat(e.target.value);
        state.rows.map((item, idx) => {
            totals += item.amount;
        });

        const BeforeTax = (totals) - bill.discount;
        const tax = BeforeTax * (bill.tax / 100);
        const grand = (totals + bill.freight + tax) - value;

        setBill({
            total : totals,
            grand_total : grand,
            freight : bill.freight,
            tax : bill.tax,
            discount : value
        });
    }

    const onDiscountChange = e => {

        var total = bill.total;
        var freight = bill.freight;
        var taxp = bill.taxp;
        var discount_p = parseFloat(e.target.value);
        var discount_amount = parseFloat(total) * discount_p / 100;
        var totalBeforeTax = parseFloat(total) - parseFloat(discount_amount);
        var taxAmount = parseFloat(totalBeforeTax) * (parseFloat(taxp) / 100);
        var grandTotal = totalBeforeTax + taxAmount + + parseFloat(freight);

        setBill({
            total : total,
            grand_total : grandTotal,
            freight : freight,
            taxp : taxp,
            tax_amount : taxAmount,
            discount_p : discount_p,
            discount_amount : discount_amount
        });
    }

    const onTaxChange = e => {
        var total = bill.total;
        var freight = bill.freight;
        var taxp = parseFloat(e.target.value);
        var discount_p = bill.discount_p;
        var discount_amount = parseFloat(total) * discount_p / 100;
        var totalBeforeTax = parseFloat(total)  - parseFloat(discount_amount);
        var taxAmount = parseFloat(totalBeforeTax) * (parseFloat(taxp) / 100);
        var grandTotal = parseFloat(totalBeforeTax) + parseFloat(taxAmount) + + parseFloat(freight);

        setBill({
            total : total,
            grand_total : grandTotal,
            freight : freight,
            taxp : taxp,
            tax_amount : taxAmount,
            discount_p : discount_p,
            discount_amount : discount_amount
        });
    }

    const handleAddRow = () => {
        const item = {
            item_id : null,
            qty : 0,
            price : 0,
            amount : 0
        };
        setState({
            rows: [...state.rows, item]
        });
    };

    useEffect(() => {
        handleAddRow();
    }, []);

    const handleRemoveRow = idx => e => {
        // console.log(idx);
        // console.log(state.rows);
        let filteredArray = state.rows.filter((item, index) => index !== idx)
        setState({rows: filteredArray});
    };

    // useEffect(() => {
    //     handleAddRow();
    // }, []);


    var totalAmount = 0;

    const CalculateCell = () => {
        var table = document.getElementById('itemtable');
        for (var r = 0, n = table.rows.length; r < n; r++) {
            var rowID = table.rows[r];
            var qty = rowID.cells[3].getElementsByTagName('INPUT')[0].value;
            var price = rowID.cells[4].getElementsByTagName('INPUT')[0].value;
            var total = parseInt(qty) * parseFloat(price);
            totalAmount += total;
            rowID.cells[4].getElementsByTagName('INPUT')[0].value = total;
        }
    }


    const formField = (
            userData == "" && companyID == "" ? <CircularProgress/> : (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <Card title="Purchase From">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="vendor"
                                            label="Vendor"
                                            initialValue={userData.podata.vendor_id}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Select a vendor"
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Select a vendor"
                                                optionFilterProp="children"
                                                onChange={onVendorChange}
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {
                                                    vendorlist.map((items, index) =>
                                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>

                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            id="vendor_address"
                                            label="Vendor Address"
                                            name="vendor_address"
                                            onChange={handleChange}
                                            initialValue={userData.podata.vendor_address}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Vendor Address"
                                                },
                                            ]}
                                        >
                                            <Input value={vend.vendor_address} placeholder="Vendor Address" />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            id="vendor_city"
                                            label="Vendor City"
                                            name="vendor_city"
                                            initialValue={userData.podata.vendor_city}
                                            onChange={handleChange}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Vendor City"
                                                },
                                            ]}
                                        >
                                            <Input value={vend.vendor_city} placeholder="Vendor City" />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            id="vendor_state"
                                            label="Vendor State"
                                            name="vendor_state"
                                            onChange={handleChange}
                                            initialValue={userData.podata.vendor_state}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Vendor State"
                                                },
                                            ]}
                                        >
                                            <Input value={vend.vendor_state} placeholder="Vendor State" />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            label="Vendor Zip Code"
                                            name="vendor_zip_code"
                                            onChange={handleChange}
                                            initialValue={userData.podata.vendor_zipcode}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Vendor Zip Code"
                                                },
                                            ]}
                                        >
                                            <Input value={vend.vendor_zip_code} placeholder="Vendor Zip Code" />
                                        </Form.Item>
                                    </div>
                                </div>

                            </div>
                        </Card>

                    </div>
                </div>
                <div className="row" style={{display: 'none'}}>
                    <div className="col-md-2">
                        <div className="gx-form-row0">
                            <Form.Item
                                name="shipping_method"
                                label="Shipping Method"
                                initialValue={userData.podata.shipping_method}
                                rules={[
                                    {
                                        required: false,
                                        message: "Select a shipping method"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select a shipping method"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {
                                        ShippingMethod.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>

                        </div>
                    </div>

                    <div className="col-md-2">
                        <div className="gx-form-row0">
                            <Form.Item
                                name="order_date"
                                label="Order Date"
                                initialValue={moment(userData.podata.date)}
                                rules={[
                                    {
                                        required: true,
                                        message: "Order date"
                                    },
                                ]}
                            >
                                <DatePicker format={CisUI().dateFormat} placeholder="Order Date" />
                            </Form.Item>

                        </div>
                    </div>

                    <div className="col-md-2">
                        <div className="gx-form-row0">
                            <Form.Item
                                name="required_date"
                                label="Required By"
                                initialValue={moment(userData.podata.required_by_date)}
                                rules={[
                                    {
                                        required: false,
                                        message: "Required by date"
                                    },
                                ]}
                            >
                                <DatePicker format={CisUI().dateFormat} placeholder="Required by date" />
                            </Form.Item>

                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6">
                                <h2>Purchase Items</h2>
                            </div>
                            <div className="col-md-6" style={{textAlign : "right"}}>
                                <a className="btn btn-primary" onClick={handleAddRow}>Add More</a>
                            </div>
                        </div>
                        <table id="itemtable" className="ptable table table-bordered">
                            <thead>
                            <tr>
                                <th></th>
                                <th>Item</th>
                                <th>Quantity</th>
                                <th>Unit Price($)</th>
                                <th>Amount</th>
                            </tr>
                            </thead>
                            <tbody>
                            {state.rows.map((item, idx) => (
                                <tr id="addr0" key={idx}>
                                    <td style={{width : '30px'}}><a onClick={handleRemoveRow(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a></td>
                                    <td>
                                        <Select
                                            showSearch
                                            placeholder="Select an account"
                                            optionFilterProp="children"
                                            style={{width: '100%'}}
                                            defaultValue={item.item_id}
                                            onChange={ItemNameCellChange(idx)}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                inventoryitem.map((itm, index) =>
                                                    <Option key={++index} value={itm.id}>{itm.item_code}-{itm.item_name}</Option>
                                                )
                                            }
                                        </Select>
                                    </td><td style={{width : '100px'}}><input className="form-control text-right" style={{textAlign : 'right'}} placeholder="Quantity" name="qty" value={state.rows[idx].qty} onChange={ItemCellChange(idx)}  /></td>
                                    <td style={{width : '100px'}}><input className="form-control text-right" style={{textAlign : 'right'}} placeholder="Price" name="price" value={state.rows[idx].price} onChange={ItemCellChange(idx)}  /></td>
                                    <td style={{width : '100px'}}><input className="form-control text-right" style={{textAlign : 'right'}} readOnly={true} name="amount" value={state.rows[idx].amount} onChange={ItemCellChange(idx)}  /></td>
                                </tr>
                            ))}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td style={{textAlign : 'right', fontWeight : 'bold'}} colSpan={4}>Gross Total</td>
                                <td style={{textAlign : 'right', fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1(bill.total)}</td>
                            </tr>
                            <tr>
                                <td style={{textAlign : 'right'}} colSpan={4}>Discount</td>
                                <td><input type="text" className="form-control" id="discount" readOnly={true} style={{textAlign : 'right'}} value={bill.discount_amount} onChange={ChangeDiscount} name="discount" /></td>
                            </tr>
                            <tr>
                                <td style={{textAlign : 'right', fontWeight : 'bold'}} colSpan={4}>After Discount</td>
                                <td style={{textAlign: 'right', fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1((parseFloat(bill.total) - parseFloat(bill.discount_amount)))}</td>
                            </tr>
                            <tr>
                                <td style={{textAlign : 'right'}} colSpan={4}>Sales Tax</td>
                                <td><input type="text" className="form-control" id="tax" readOnly={true} style={{textAlign : 'right'}} value={bill.tax_amount} onChange={ChangeTax} name="tax" /></td>
                            </tr>
                            <tr>
                                <td style={{textAlign : 'right'}} colSpan={4}>Freight</td>
                                <td><input type="text" className="form-control" id="tax" style={{textAlign : 'right'}} value={bill.freight} onChange={ChangeFreight} name="freight" /></td>
                            </tr>
                            <tr>
                                <td style={{textAlign : 'right', fontWeight : 'bold'}} colSpan={4}>Grand Total</td>
                                <td style={{textAlign : 'right', fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1(bill.grand_total)}</td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </>
        )
    )

    const runData = () => {

    }

    return (
        <Card title={Title} extra={
                isEdit === 1
                    ? CisUI().addAction('../../' + jsonfile.urls.list)
                    : CisUI().addAction('../' + jsonfile.urls.list)
            }>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                ref={formRef}
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{remember: true}}
            >

                {
                    formField
                }

                {/*<div onLoad={OrderDetails}></div>*/}
                <div className="row">
                    <div className="col-md-12" style={{textAlign: 'center', marginTop : '30px'}}>
                        <Button id="submitbutton" type="primary" htmlType="submit">
                            Save Purchase Order
                        </Button>
                        <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                    </div>
                </div>

            </Form>
        </Card>
    );
};

export default EditInvPurchaseOrder;