import React, {useState} from "react";
import {Form, Card, Popconfirm, Button, Collapse, notification} from "antd";
import {CisUI} from "../../../../util/CISUI";
import Config from "../../../../util/config";
import jsonfile from "../../company/migration/migration.json";
import AdvancedSearch from "../../../../util/AdvancedSearch";
import ActionButton from "../../../../util/actionbutton";
import IntlMessages from "util/IntlMessages";
import SmartDataTable from 'react-smart-data-table';
import {Link, useHistory} from "react-router-dom";
import axios from "../../../../util/Api";

const Panel = Collapse.Panel;

const MigrationList = () => {

    const [form] = Form.useForm();
    const history = useHistory();
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.list,
        filtervalue : '',
        subtitle: ''
    });

    const headers = {
        id: {
            text: <IntlMessages id="ID"/>,
            sortable: true,
            filterable: true,
            invisible:false
        },
        level_name: {
            text: <IntlMessages id="Level"/>,
            sortable: true,
            filterable: true,
        },
        user_name: {
            text: <IntlMessages id="Created By"/>,
            sortable: true,
            filterable: true,
        },
        notes: {
            text: <IntlMessages id="Notes"/>,
            sortable: true,
            filterable: true,
        },
        effective_date: {
            text: <IntlMessages id="Effective Date"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                <>
                    {CisUI().DateFormat(value)}
                </>
            ),
        },
        created_at: {
            text: <IntlMessages id="Created At"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                <>
                    {CisUI().DateFormat(value)}
                </>
            ),
        },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                <>
                    {CisUI().getOrderStatus(value)}
                </>
            ),
        },
        actions: {
            text: <IntlMessages id="Actions" />,
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {getActionButton(row)}
                </>
            ),
        },
    };

    const getActionButton = (row) => {
        return <>
        {
            row.status === 0 ? <div style={{paddingTop: '10px'}}>
                <Popconfirm
                    placement="topRight"
                    title={"Are you sure you want to approve this transfer request?"}
                    onConfirm={() =>goForApprove(row?.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button className="ant-btn ant-btn-sm ant-btn-primary">Approve</Button>
                </Popconfirm>
                <Popconfirm
                    placement="topRight"
                    title={"Are you sure you want to delete this transfer request"}
                    onConfirm={(id) =>goForDelete()}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button className="ant-btn ant-btn-sm ant-btn-danger">Delete</Button>
                </Popconfirm>
            </div> : <></>
        }
        </>
    }

    const orderedHeaders = jsonfile.listView.title;


    const onFinish = values => {
        //console.log('Received values of form: ', values);
        const qsup = CisUI().getQueryStrings(values);
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.list+"/?"+qsup;
        console.log(newURL);
        setState({reqURL: newURL});
    };

    let filterValue = "";

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header={<IntlMessages id="Advance Search" />}  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.inputSearch)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const goForDelete = () => {}

    const goForApprove = (id) => {
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + "company/migrationapprove/"+id, null)
            .then(res => {
                document.getElementById("loader").style.display = "none";
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    window.location.reload();
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
            })
            .catch(errors => {
                document.getElementById("loader").style.display = "none";
                console.log(errors.response.data.errors);
            });
    }

    return (

        <>
            {searchView}
            <Card title={<IntlMessages id={jsonfile.listtitle} />}>
                {
                    CisUI().showLoading
                }
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    dynamic
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
        </>
    );
};

export default MigrationList;