import React, { useState } from "react";
import { Row, Col, Form, Card, Input, Button, notification, Divider, Checkbox, Select, Upload, DatePicker } from 'antd';
import jsonfile from './syschange.json';
import { useHistory } from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import InputFieldV2 from "../../../../util/InputFieldV2";
import { CisUI } from "../../../../util/CISUI";
import Config from "../../../../util/config";
import CanvasSignature from "components/Signature/CanvasSignature";
import SingleImage from "components/Upload/SingleImage";
import ImgCrop from "antd-img-crop";

import axios from "axios";
import { UploadOutlined } from "@ant-design/icons";

const SysChangeRequest = (props) => {

    const history = useHistory();
    const { form } = Form.useForm();
    const [isShow, setIsShow] = useState(false);

    const [file, setFile] = useState(null);
    const [signatureData, setSignatureData] = useState(false);

    const types = [
        {
            label: "Enhancement",
            value: "Enhancement"
        },
        {
            label: "Defects",
            value: "Defects"
        }
    ]

    const Priorities = [
        {
            label: "High",
            value: "High"
        },
        {
            label: "Medium",
            value: "Medium"
        },
        {
            label: "Low",
            value: "Low"
        }
    ]

    const Attachment = [
        {
            label: "Yes",
            value: "Yes"
        },
        {
            label: "No",
            value: "No"
        }
        ]

    const handleResidence = (e) => {
        e.target.checked === true ? setIsShow(true) : setIsShow(false);
    }

    const userID = props.editEmpId;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {
        userData = RemoteJson(jsonfile.urls.view + "/" + userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + jsonfile.urls.list;
        // props.empid(userID);
    }
    console.log("General", userData)

    const onFinish = (values) => {
        // console.log("Input Data : " + values);
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    props.empid(res.data.emp_id)
                    // history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    }

    return (
        <Card title="New Change Request"
              extra={
                  <>
                      {
                          isEdit === 1
                              ? CisUI().addAction(redirectto)
                              : CisUI().addAction(redirectto)
                      }
                  </>
              }
        >
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >

                <Card title={"General Information"}>
                    <Row>
                        <Col span={24}>
                            <Form.Item label={"CR Type"}>
                                <Checkbox.Group options={types} onChange={() => { }} />
                            </Form.Item>
                        </Col>
                    </Row>

                    {InputFieldV2(jsonfile.input, userData, isEdit, props)}

                    <Row>
                        <Col span={24}>
                            <Form.Item label={"Priority"}>
                                <Checkbox.Group options={Priorities} onChange={() => { }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label={"Attachment"}>
                                <Checkbox.Group options={Attachment} onChange={() => { }} />
                            </Form.Item>
                            <Form.Item label={"Link"}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24} lg={6} style={{ marginBottom: "12px" }}>
                            <div>
                                <CanvasSignature
                                    signatureData={signatureData}
                                    setSignatureData={setSignatureData}
                                />
                            </div>
                        </Col>

                    </Row>


                </Card>

            </Form>
        </Card>
    );
}

export default SysChangeRequest;