import React, {useState, useEffect} from "react";
import {Form, Col, Input, Collapse, Card, notification} from 'antd';
import jsonfile from './budget.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axios from "../../../../util/Api";

const Search = Input.Search;
const Panel = Collapse.Panel;

const SummaryByRegion = () => {
    const value = 0;
    const [state, setState] = useState({
        reqURL: Config.apiserver + jsonfile.urls.regionreport,
        filtervalue: '',
        subtitle: '',
    });

    const [reprtArea, setReportArea] = useState("");
    const [header, setHeader] = useState("");
    const [period, setPeriod] = useState("");
    const [reportyear, setReportYear] = useState({
        currentYear: "Actual",
        currentYearBudget: "Budget",
        currentYearOver: "Over Budget",
        currentYearPer: "% of Budget",
        lastYear: "2020 Actual",
        variance: "Variance",
        change: "% of Change"
    });

    const [resp, setResp] = useState({
        status: 1,
        msg: "STATEMENT OF ACTIVITIES",
        company: {},
        data: []
    });

    const [hide, setHide] = useState([]);
    const [reporttype, setReportType] = useState("periodical");
    const [company, setCompany] = useState({});

    const ref = React.createRef();
    const formRef = React.createRef();

    // const [data, setData] = useState([]);

    useEffect(() => {
        if (CisUI().getUserInfo('com_id') > 0) {
            getLedgerData();
        }

        if (CisUI().getUserInfo('comarea') <= 3) {
            setReportArea("Region");
        } else if (CisUI().getUserInfo('comarea') === 4) {
            setReportArea("District");
        } else if (CisUI().getUserInfo('comarea') === 5) {
            setReportArea("Local");
        }
    }, []);


    const getLedgerData = (url = "") => {
        document.getElementById("loader").style.display = "block";
        let urls = state.reqURL;
        if (url !== "") {
            urls = url;
        }
        axios.get(urls)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    setResp(res.data);
                    //setData(res.data.data);
                    //console.log(res.data.data.assets[0].currentAsset);
                    //console.log(res.data.data[0]["assets"]["currentAsset"]);
                    // setCompany(res.data.company);
                    // setHeader(res.data.company.legal_name);

                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                //document.getElementById("loader").style.display = "none";
            });
    }

    const history = useHistory();


    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if (list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name}/>
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        let wkd = "";
        let wk = "";
        let dt = "";
        //let qs = "";
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    wk = formRef.current.getFieldValue('date');
                    const dt = CisUI().ParseDateFormat(values[key]);
                    setPeriod("Between " + CisUI().DateFormat(dt[0]) + " and " + CisUI().DateFormat(dt[1]));
                    return `${key}=${dt}`
                } else if (key === 'month') {
                    wk = formRef.current.getFieldValue('month');
                    // console.log("Selected Week : "+ wk);
                    wkd = CisUI().getMonthTitle(formRef.current.getFieldValue('month'));
                    return `${key}=${wkd[1]}`
                } else if (key === 'reporttype') {
                    setReportType(values[key]);
                    return `${key}=${values[key]}`

                } else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        // qs = values.join('&');

        const qsup = qs.replaceAll("undefined", "");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup);
        const newURL = Config.apiserver + jsonfile.urls.regionreport + "?" + qsup;
        setState({reqURL: newURL});

        getLedgerData(newURL);
    };


    let searchView = "";
    if (jsonfile.has_search) {
        searchView = <Card className="advance-search-card no-print">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']}>
                <Panel header={<IntlMessages id={"Advance Search"}/>} key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        ref={formRef}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch([], "", 1, "", CisUI().PeriodFiled(form))}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const print = () => {
        window.open(Config.baseurl + "reports/periodicalprint.html?url=" + window.btoa(state.reqURL) + "&filename=periodical&data=periodicalData&subtitle=" + period + "&rptype=periodical", "_blank");
    }

    const listAction = () =>
        (
            <div className="no-print">
                <a id="rploader" style={{width: '40px', height: '40px', display: 'none'}}>
                    <img src="/assets/images/loader.svg" style={{width: '40px', height: '40px'}} alt="loader"/> Please
                    wait ...
                </a>
                <button onClick={print} className="btn btn-export-print btn-circle-md" title="Print"><i
                    className="fas fa-print"/></button>
            </div>
        );

    const getExtra = (
        <>
            {listAction()}
        </>
    );

    const setCollapse = (id) => {
        console.log("Slcct: ", id);
        if (hide.indexOf(id) > -1) {
            var index = hide.indexOf(id); // Let's say it's Bob.
            hide.splice(index, 1);
            document.getElementById("icon_" + id).setAttribute("class", "fas fa-chevron-right")
            var row = document.getElementsByClassName("disp_" + id);
            var i;
            for (i = 0; i < row.length; i++) {
                //row[i].style.display = 'inherit';
                //row[i].removeAttribute('style');
                row[i].style.display = 'none';
            }
        } else {
            const hd = hide;
            hd.push(id);
            setHide(hd);
            document.getElementById("icon_" + id).setAttribute("class", "fas fa-chevron-down");
            var row = document.getElementsByClassName("disp_" + id);
            var i;
            for (i = 0; i < row.length; i++) {
                //row[i].style.display = 'none';
                row[i].removeAttribute('style');
            }
        }
        console.log("hides : ", hide);
    }

    let totalDebit = 0;
    let totalCredit = 0;

    const getTitle = () => {

    }


    return (
        <>
            {searchView}
            <Card extra={getExtra}>
                {CisUI().showLoading}
                {resp.status === 1 ?
                    <div className="table-responsive">
                        <div style={{textAlign: 'center'}}>
                            <h2>{header}</h2>
                            <h4><IntlMessages id={"Performance Report By " + reprtArea}/></h4>
                            <h4>{period}</h4>
                        </div>

                        {/*<Periodicalreporttable data={resp.data}  />*/}

                        <table className="statement table">
                            <thead>
                            <tr>
                                <th style={{width: '200px'}}>{reprtArea}</th>
                                <th style={{textAlign: 'right', width: '130px'}}><IntlMessages id={"Tithe Target"}/>
                                </th>
                                <th style={{textAlign: 'right', width: '130px'}}><IntlMessages id={"Tithe Actual"}/>
                                </th>
                                <th style={{textAlign: 'right', width: '130px'}}><IntlMessages id={"Variance"}/></th>
                                <th style={{textAlign: 'right', width: '130px'}}><IntlMessages
                                    id={"Mission Offering Target"}/></th>
                                <th style={{textAlign: 'right', width: '130px'}}><IntlMessages
                                    id={"Mission Offering Actual"}/></th>
                                <th style={{textAlign: 'right', width: '130px'}}><IntlMessages id={"Variance"}/></th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                resp.data.map((item, index) => {
                                    if (item.type === 'total') {
                                        return <tr>
                                            <td style={{fontWeight: 'normal'}}>
                                                <div style={{paddingLeft: '20px', fontWeight: 'bold'}}>{item.name}</div>
                                            </td>
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'bold'
                                            }}>{CisUI().getCurrencyFormated1(item.tithe_bdgt)}</td>
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'bold'
                                            }}>{CisUI().getCurrencyFormated1(item.tithe_act)}</td>
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'bold'
                                            }}>{CisUI().getCurrencyFormated1(item.tithe_var)}</td>
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'bold'
                                            }}>{CisUI().getCurrencyFormated1(item.mission_bdgt)}</td>
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'bold'
                                            }}>{CisUI().getCurrencyFormated1(item.mission_act)}</td>
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'bold'
                                            }}>{CisUI().getCurrencyFormated1(item.mission_var)}</td>
                                        </tr>
                                    } else {
                                        return <tr>
                                            <td style={{fontWeight: 'normal'}}>
                                                <div style={{paddingLeft: '20px'}}>{item.name}</div>
                                            </td>
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'normal'
                                            }}>{CisUI().getCurrencyFormated1(item.tithe_bdgt)}</td>
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'normal'
                                            }}>{CisUI().getCurrencyFormated1(item.tithe_act)}</td>
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'normal'
                                            }}>{CisUI().getCurrencyFormated1(item.tithe_var)}</td>
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'normal'
                                            }}>{CisUI().getCurrencyFormated1(item.mission_bdgt)}</td>
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'normal'
                                            }}>{CisUI().getCurrencyFormated1(item.mission_act)}</td>
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'normal'
                                            }}>{CisUI().getCurrencyFormated1(item.mission_var)}</td>
                                        </tr>
                                    }
                                })
                            }
                            </tbody>
                        </table>

                    </div>
                    : <></>}

            </Card>
        </>
    );
};

export default SummaryByRegion;