import React from "react";
import {Route, Switch} from "react-router-dom";

import ProjectTypeList from "../../containers/yotta/project/project_type/ProjectTypeList";
import NewProjectType from "../../containers/yotta/project/project_type/NewProjectType";
import ProjectInfoList from "../../containers/yotta/project/project_info/ProjectInfoList";
import NewProjectInfo from "../../containers/yotta/project/project_info/NewProjectInfo";
import TaskList from "../../containers/yotta/crm/activity_records/task/TaskList";
import NewTask from "../../containers/yotta/crm/activity_records/task/NewTask";
import EventList from "../../containers/yotta/crm/activity_records/event/EventList";
import NewEvent from "../../containers/yotta/crm/activity_records/event/NewEvent";
import CallLogList from "../../containers/yotta/crm/activity_records/calllog/CallLogList";
import NewCallLog from "../../containers/yotta/crm/activity_records/calllog/NewCallLog";
import VisitList from "../../containers/yotta/crm/activity_records/visit/VisitList";
import NewVisit from "../../containers/yotta/crm/activity_records/visit/NewVisit";

const Project = ({match}) => (
    <Switch>
        <Route path={`${match.url}/projecttypelists`} component={ProjectTypeList}/>
        <Route path={`${match.url}/newprojecttype`} component={NewProjectType}/>
        <Route path={`${match.url}/projecttypeedit/:id`} component={NewProjectType}/>
        <Route path={`${match.url}/projectinfolists`} component={ProjectInfoList}/>
        <Route path={`${match.url}/newprojectinfo`} component={NewProjectInfo}/>
        <Route path={`${match.url}/projectinfoedit/:id`} component={NewProjectInfo}/>

            <Route path={`${match.url}/tasklist`} component={TaskList}/>
            <Route path={`${match.url}/newtask`} component={NewTask}/>
            <Route path={`${match.url}/taskedit/:id`} component={NewTask}/>
            <Route path={`${match.url}/eventlist`} component={EventList}/>
            <Route path={`${match.url}/newevent`} component={NewEvent}/>
            <Route path={`${match.url}/eventedit/:id`} component={NewEvent}/>

            <Route path={`${match.url}/callloglist`} component={CallLogList}/>
            <Route path={`${match.url}/newcalllog`} component={NewCallLog}/>
            <Route path={`${match.url}/calllogedit/:id`} component={NewCallLog}/>

            <Route path={`${match.url}/visitlist`} component={VisitList}/>
            <Route path={`${match.url}/newvisit`} component={NewVisit}/>
            <Route path={`${match.url}/visitedit/:id`} component={NewVisit}/>


    </Switch>
);

export default Project;
