import React, {useState, useEffect} from "react";
import {Form, Row, Col, Input, Button, Collapse, Card, Table} from 'antd';
import jsonfile from './cashapp.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from '../../../../util/Api'
import 'jspdf-autotable'

import CanvasJSReact from './../../../canvasjs/canvasjs.react';
import DonationConfig from '../../../../util/DonationConfig';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


const Search = Input.Search;
const Panel = Collapse.Panel;

const CashAppReprot = () => {
    const value = 0;
    const ref = React.createRef();
    const formRef = React.createRef();

    const [reportData, setReportData] = useState([]);
    const [report, setReport] = useState({});
    const [total, setTotal] = useState(0);

    const history = useHistory();


    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        console.log('Received values of form: ', values);
        let wkd = "";
        let wk = "";
        let dt = "";
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    wk = formRef.current.getFieldValue('date');
                    const dt = CisUI().ParseDateFormat(values[key]);
                    return `${key}=${dt}`
                } else if (key === 'month') {
                    wk = formRef.current.getFieldValue('month');
                    // console.log("Selected Week : "+ wk);
                    wkd = CisUI().getMonthTitle(formRef.current.getFieldValue('month'));
                    return `${key}=${wkd[1]}`
                } else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined", "");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup);
        const newURL = Config.apiserver + jsonfile.urls.list + "?" + qsup;
        console.log("NEURL :" + newURL);
        console.log("Month : " + wkd);
        const WKR = CisUI().ParseDateFormat(wk);
        const reportTitle = "Between " + CisUI().DateFormat(WKR[0]) + " and " + CisUI().DateFormat(WKR[1]);

        const url = Config.apiserver + jsonfile.urls.list;
        //const data = SelectData(url);

        document.getElementById("loader").style.display = "block";
        axios.get(newURL)
            .then((res) => {
                console.log(res.data);
                document.getElementById("loader").style.display = "none";
                if (res.data.status === 1) {
                    //console.log("Response : "+ res.data);
                    const data = res.data;
                    setReportData(data.data)
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    };

    const getData = () => {
        document.getElementById("loader").style.display = "block";
        axios.get(Config.apiserver+jsonfile.urls.list)
            .then((res) => {
                console.log(res.data);
                document.getElementById("loader").style.display = "none";
                if (res.data.status === 1) {
                    //console.log("Response : "+ res.data);
                    const data = res.data;
                    setReportData(data.data);
                    setTotal(data.total);
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    }

    useEffect(() => {
        getData();
    }, []);

    const colorset =
        [
            "#0f3057",
            "#00587a",
            "#008891",
            "#51adcf",
            "#0cd0e9"
        ];


    let searchView = "";
    if (jsonfile.has_search) {
        searchView = <Card className="advance-search-card no-print">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']}>
                <Panel header="Advance Search" key="1">
                    <Form
                        key={5}
                        form={form}
                        ref={formRef}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch([], "", 0,"date",CisUI().PeriodFiled(form))}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }


    const getAPIData = () => {
        //console.log("test"+state.reqURL);
        axios.get(Config.apiserver+"/"+jsonfile.urls.list)
            .then((res) => {
                //console.log(res.data);
                if(res.data.status === 1) {
                    const data = res.data.data;
                    setReportData(data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };


    return (
        <>
            {searchView}
            <Card id="reportview" >
                {CisUI().showLoading}
                <div ref={ref}>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3>CashApp Report</h3>
                            {/*<h2>{state.reportTitle}</h2>*/}
                        </div>
                    </div>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>January</th>
                            <th>February</th>
                            <th>March</th>
                            <th>April</th>
                            <th>May</th>
                            <th>June</th>
                            <th>July</th>
                            <th>August</th>
                            <th>September</th>
                            <th>October</th>
                            <th>November</th>
                            <th>December</th>
                            <th>Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            reportData.map((items,index)=> {
                                return <tr>
                                    <td>{items.name}</td>
                                    <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.Jan)}</td>
                                    <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.Feb)}</td>
                                    <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.Mar)}</td>
                                    <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.Apr)}</td>
                                    <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.May)}</td>
                                    <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.Jun)}</td>
                                    <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.Jul)}</td>
                                    <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.Aug)}</td>
                                    <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.Sep)}</td>
                                    <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.Oct)}</td>
                                    <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.Nov)}</td>
                                    <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.Dec)}</td>
                                    <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.total)}</th>
                                </tr>
                            })
                        }
                        </tbody>
                        {/*<tfoot>*/}
                        {/*<tr>*/}
                        {/*    <th colSpan="13">Total</th>*/}
                        {/*    <th>{CisUI().getCurrencyFormated1(total)}</th>*/}
                        {/*</tr>*/}
                        {/*</tfoot>*/}
                    </table>
                </div>

            </Card>
        </>
    );
};

export default CashAppReprot;