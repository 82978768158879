import React, {useState, useEffect} from "react";
import {Form, Row, Col, Card, Select, notification} from 'antd';
import jsonfile from './company.json';
// import jsonUserfile from './../../user_management/user/user.json';
import {CisUI} from '../../../../util/CISUI';
import InputFields from '../../../../util/InputField';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import ActionButton from "../../../../util/actionbutton";
import IntlMessages from "../../../../util/IntlMessages";
import SmartDataTable from 'react-smart-data-table'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import SelectData from "../../../../util/selectdata";


const { Option } = Select;

const NewCompanyRequest = (props) => {

    const formRef = React.createRef();
    const [district, setDistrict] = useState([]);
    const [local, setLocal] = useState([]);
    const [selectedValue, setSelectedValue] = useState(6);
    const userID = props.match.params.id;
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.companyuserlist+"/"+userID,
        filtervalue : '',
        area : null
    });

    const [diplay, setDisplay] = useState({
        region: "none",
        district: "none",
        local: "none",
    });

    const AccountsLevel = SelectData("accounts/accountslevel");
    const Regions = SelectData("regions");
    const District = SelectData("districtbyregion");
    const Local = SelectData("localbydistrict");

    const [form] = Form.useForm();

    const history = useHistory();


    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = "company/newcompany";
    let redirectto = "../"+jsonfile.urls.list;

    let areaReq = true;

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = "company/companyedit/"+userID;
        redirectto = '../../'+jsonfile.urls.list;
        areaReq = false;
    }

    // useEffect(() => {
    //     setState(
    //         {
    //             reqURL: Config.apiserver+jsonfile.urls.companyuserlist+"/"+userID,
    //             filtervalue : '',
    //             area : userData.area
    //         }
    //     );
    //
    //     form.setFieldsValue({ area: userData.area })
    //
    // }, [form,userData]);


    const onFinish = (values) => {
        // setLoading({loading:true})
        // console.log("Input Data : "+values);

        document.getElementById("loader").style.display = "block";

        let pass = 0;
        if(isEdit === 1) {
            pass = 1;
        }
        else {
            if (values['area'] <= 3) {
                pass = 1;
            }
            else if (values['area'] === 4) {
                if (values['region'] > 0) {
                    pass = 1;
                } else {
                    // notification.warning({
                    //     message: 'Alert',
                    //     type : "warning",
                    //     description: "Please select a region"
                    // });
                    pass = 1;
                }
            } else if (values['area'] === 5) {
                if (values['region'] > 0) {
                    if (values['district'] > 0) {
                        pass = 1;
                    } else {
                        // notification.warning({
                        //     message: 'Alert',
                        //     type : "warning",
                        //     description: "Please select a district"
                        // });
                        pass = 1;
                    }
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: "Please select a region & district"
                    });
                }
            } else if (values['area'] === 6) {
                if (values['region'] > 0) {
                    if (values['district'] > 0) {
                        if (values['local'] > 0) {
                            pass = 1
                        } else {
                            // notification.warning({
                            //     message: 'Alert',
                            //     type : "warning",
                            //     description: "Please select a local"
                            // });
                            pass = 1;
                        }
                    } else {
                        notification.warning({
                            message: 'Alert',
                            type: "warning",
                            description: "Please select a district"
                        });
                    }
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: "Please select a region, district & local"
                    });
                }
            }
        }

        if(pass === 1) {

            // let msg = "Sorry! request not processed, please try again";
            axios.post(Config.apiserver + endpoint, values)
                .then(res => {
                    // console.log(res)
                    if (res.data.status === 1) {
                        notification.success({
                            message: 'Success',
                            type: "warning",
                            description: res.data.msg
                        });
                        history.push(redirectto);
                    } else {
                        notification.warning({
                            message: 'Alert',
                            type: "warning",
                            description: res.data.msg
                        });
                    }
                    document.getElementById("loader").style.display = "none";
                })
                .catch(errors => {
                    console.log(errors);
                });
        }
    };

    const onAreaChange = value1 => {
        const value = selectedValue;
        if(value == 4) {
            //console.log("value : "+value);
            setDisplay({
                region: "none",
                district: "none",
                local: "none",
            });
            //document.getElementById("region").setAttribute("required","required");
            //formRef.current.setRules("required",true);
            document.getElementById("region").removeAttribute("required");
            document.getElementById("district").removeAttribute("required");
            document.getElementById("local").removeAttribute("required");
        }
        else if(value == 5) {
            setDisplay({
                region: "block",
                district: "none",
                local: "none",
            });
            document.getElementById("region").setAttribute("required","required");
            //document.getElementById("district").setAttribute("required","required");
            document.getElementById("district").removeAttribute("required");
            document.getElementById("local").removeAttribute("required");
        }
        else if(value == 6) {
            setDisplay({
                region: "block",
                district: "block",
                local: "none",
            });
            document.getElementById("region").setAttribute("required","required");
            document.getElementById("district").setAttribute("required","required");
            //document.getElementById("local").setAttribute("required","required");
            document.getElementById("local").removeAttribute("required");
        }
        else {
            setDisplay({
                region: "none",
                district: "none",
                local: "none",
            });

            document.getElementById("region").removeAttribute("required");
            document.getElementById("district").removeAttribute("required");
            document.getElementById("local").removeAttribute("required");
        }
    };

    const onRegionChange = value => {
        setDistrict(District[value]);
    };

    const onDistrictChange = value => {
        setLocal(Local[value]);
    };


    return (
        <>
            <Card title={<IntlMessages id={Title}/>} extra={
                    isEdit === 1
                        ? CisUI().addAction(redirectto)
                        : CisUI().addAction(redirectto)
                }>
                {CisUI().showLoading}
                <Form
                    form={form}
                    ref={formRef}
                    {...CisUI().formItemLayout}
                    name="input"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                    initialValues={{area: state.area}}
                >
                    {
                        isEdit === 0 ?
                            <Row gutter={24}>

                                <Col lg={6} md={6} sm={12} xs={24} id="region" >
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="region"
                                            label={<IntlMessages id="Region"/>}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Select a region"
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Select Region"
                                                optionFilterProp="children"
                                                onChange={onRegionChange}
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {
                                                    Regions.map((items, index) =>
                                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>

                                    </div>
                                </Col>

                                <Col lg={6} md={6} sm={12} xs={24} id="district" >
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="district"
                                            label={<IntlMessages id="District"/>}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Select a district"
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Select District"
                                                optionFilterProp="children"
                                                onChange={onDistrictChange}
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {
                                                    district.map((items, index) =>
                                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>

                                    </div>
                                </Col>

                                <Col lg={6} md={6} sm={12} xs={24} id="local" style={{display: diplay.local}}>
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="local"
                                            label={<IntlMessages id="Local Assembly"/>}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Select a local assembly"
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Select Local"
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {
                                                    local.map((items, index) =>
                                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>

                                    </div>
                                </Col>

                            </Row> :
                            <>
                                <Row gutter={24} style={{padding: '20px'}}>
                                    <Col lg={6} md={6} sm={12} xs={24}>
                                        <div className="gx-form-row0">
                                            <div>
                                                <label style={{fontWeight: 'bold'}} >{<IntlMessages id="Level of Company "/>}: </label>
                                                {AccountsLevel.filter(items => items.id === userData.area ).map(itm => (
                                                    <> {itm.name} </>
                                                ))}
                                            </div>
                                        </div>
                                    </Col>

                                    {
                                        userData.area >=5 ?
                                    <Col lg={6} md={6} sm={12} xs={24}>
                                        <div className="gx-form-row0">
                                            <div>
                                                <label style={{fontWeight: 'bold'}} >{<IntlMessages id="Region"/>}: </label> {userData.region}
                                            </div>
                                        </div>
                                    </Col> : <></> }

                                    {
                                        userData.area >=6 ?
                                        <Col lg={6} md={6} sm={12} xs={24} >
                                            <div className="gx-form-row0">
                                                <div>
                                                    <label style={{fontWeight: 'bold'}} >{<IntlMessages id="District"/>}: </label> {userData.district}
                                                </div>
                                            </div>
                                        </Col> : <></> }

                                </Row>
                            </>
                    }


                    {InputFields(jsonfile.input,userData,isEdit, props,'', redirectto)}
                </Form>

            </Card>

        </>
    );
};

export default NewCompanyRequest;