import React, {useState} from "react";
import {Form, Button, notification, Modal, Col, Input, Card, Collapse} from 'antd';
import jsonfile from './billpayment.json';
import {useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config, {apiserver} from "../../../../util/config";
import axios from "../../../../util/Api";
import {ExclamationCircleOutlined} from '@ant-design/icons';

const Search = Input.Search;
const Panel = Collapse.Panel;
const Meta = Card.Meta;

const {confirm} = Modal;

const VoidedCheck = () => {
    const value = 0;
    const history = useHistory();
    const [state, setState] = useState({
        reqURL: Config.apiserver + jsonfile.urls.voidedcheck,
        filtervalue: ''
    });
    const formRef = React.createRef();
    const formRef2 = React.createRef();

    const [voidcheck, setVoidCheck] = useState(0);
    const [checkno, setCheckNo] = useState("");
    const [newCheck, setNewCheck] = useState("");
    const [reason, setReason] = useState("");
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isConfirmReprintModalVisible, setIsRePrintConfirmModalVisible] = useState(false);

    const showModalCofirm = (checkID, checkNo) => e => {
        setVoidCheck(checkID);
        setCheckNo(checkNo);
        setIsConfirmModalVisible(true);
    };

    const handleOkConfirm = () => {
        setIsConfirmModalVisible(false);
    };

    const handleCancelConfirm = () => {
        setIsConfirmModalVisible(false);
    };

    const showReprintModalCofirm = (checkID, checkNo) => e => {
        setVoidCheck(checkID);
        setCheckNo(checkNo);
        setIsRePrintConfirmModalVisible(true);
    };

    const handleReprintOkConfirm = () => {
        setIsRePrintConfirmModalVisible(false);
    };

    const handleReprintCancelConfirm = () => {
        setIsRePrintConfirmModalVisible(false);
    };

    const CheckVoid = (values) => {
        console.log();
        setIsConfirmModalVisible(false);
        if (voidcheck > 0) {
            document.getElementById("loader").style.display = "block";
            axios.post(Config.apiserver + "vendor/checkvoid/" + voidcheck, values, CisUI().HeaderRequest)
                .then((res) => {
                    //console.log(res.data.data);
                    document.getElementById("loader").style.display = "none";
                    const resp = res.data;
                    if(resp.status === 1) {
                        notification.success({
                            message: 'Success',
                            type: "success",
                            description: res.data.message
                        });
                        setState({
                            reqURL: Config.apiserver + jsonfile.urls.printedcheck+"?tm="+Math.random()
                        })
                    }
                    else {
                        notification.success({
                            message: 'Failed',
                            type: "danger",
                            description: res.data.message
                        });
                    }
                    form.resetFields();
                })
                .catch((error) => {
                    console.log(error);
                    document.getElementById("loader").style.display = "none";
                });
        }
    }

    const clickTOPrint = (id) => {
        const url = Config.apiserver+"vendor/checkprint?selected="+id;
        const ck =   "../../checkprint.html?type=checkprint&url="+window.btoa(url)+"&selected="+id;
        return window.open(ck,"_blank");
    }

    const CheckRePrint = (values) => {
        console.log()
        setIsRePrintConfirmModalVisible(false);
        // clickTOPrint(voidcheck);
        if (voidcheck > 0) {
            document.getElementById("loader").style.display = "block";
            axios.post(Config.apiserver + "vendor/checkreprint/" + voidcheck, values, CisUI().HeaderRequest)
                .then((res) => {
                    //console.log(res.data.data);
                    document.getElementById("loader").style.display = "none";
                    const resp = res.data;
                    if(resp.status === 1) {
                        notification.success({
                            message: 'Success',
                            type: "success",
                            description: res.data.message
                        });
                        clickTOPrint(voidcheck);
                        setState({
                            reqURL: Config.apiserver + jsonfile.urls.printedcheck+"?tm="+Math.random()
                        });
                        form2.resetFields();
                    }
                    else {
                        notification.success({
                            message: 'Failed',
                            type: "danger",
                            description: res.data.message
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    document.getElementById("loader").style.display = "none";
                });
        }
    }

    const onChangeInput = (e) => {
        //console.log(voidcheck);
        if (e.target.name === "void_reason") {
            setReason(e.target.value);
        } else if (e.target.name === "new_check_no") {
            setNewCheck(e.target.value);
        }
    }

    const showConfirmVoid = (checkNo) => {
        confirm({
            title: 'VOID Check Confirmation For Check# ' + checkNo,
            icon: <ExclamationCircleOutlined/>,
            okText: "Submit",
            content: <>
                <div className="row">
                    <div className="col-md-12">
                        <label>Why you want to void this check ?</label>
                        <input type="text" name="void_reason" onChange={onChangeInput} className="form-control"/>
                    </div>
                    <div className="col-md-12">
                        <label>New Check Number</label>
                        <input type="text" name="new_check_no" onChange={onChangeInput} className="form-control"/>
                    </div>
                </div>
            </>,
            onOk() {
                console.log('OK');
                CheckVoid();
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    }

    const clickToVoid = (check, checkNo) => (e) => {
        //console.log(check);
        if (check > 0) {
            setVoidCheck(check);
            //console.log(check);
        }
        showConfirmVoid(checkNo);
    }

    const clickToRePrint = (checkID) => {

    }

    const headers = {
        id: {
            invisible: true
        },
        bills: {
            invisible: true
        },
        allbills: {
            invisible: true
        },
        company_id: {
            invisible: true
        },
        user_id: {
            invisible: true
        },
        vendor_id: {
            invisible: true
        },
        bank_account_id: {
            invisible: true
        },
        updated_at: {
            invisible: true
        },
        is_print: {
            invisible: true
        },
        printed_by: {
            invisible: true
        },
        status: {
            invisible: true
        },
        date: {
            text: <IntlMessages id="Date"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().DateFormat(row.date)
            )
        },
        amount: {
            text: <IntlMessages id="Amount"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(row.amount)
            )
        }
    };

    // axios.get(Config.apiserver + "vendor/paymenthistory","",CisUI().HeaderRequest)
    //     .then((res) => {
    //         console.log(res.data);
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });

    const orderedHeaders = jsonfile.listView.title;

    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if (list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name}/>
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();
    const [form2] = Form.useForm();

    const onFinish = values => {
        // console.log('Received values of form: ', values);
        const data = {
            ...values,
            // 'purchase_date' : values['purchase_date'].format("YYYY-MM-DD"),
        }
        // console.log(data);
        let wkd = "";
        let wk = "";
        let dt = "";
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    wk = values['date'];
                    const dt = CisUI().ParseDateFormat(values[key]);
                    // setPeriod("Between "+CisUI().DateFormat(dt[0])+" and "+CisUI().DateFormat(dt[1]));
                    return `${key}=${dt}`
                } else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');
        const qsup = qs.replaceAll("undefined", "");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup);
        const newURL = Config.apiserver + jsonfile.urls.printedcheck + "/?" + qsup;
        setState({reqURL: newURL});
    };

    const handleOnFilter = ({target: {name, value}}) => {
        //filterValue = value;
        console.log("filteringwith :" + value);
        setState({filtervalue: "test"});
    }

    // const filterValue = "";

    let searchView = "";
    if (jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']}>
                <Panel header="Advance Search" key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.search, "", 1, "date", CisUI().PeriodFiled(form))}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {CisUI().listAction("../" + jsonfile.urls.add, "", state.reqURL, "data", "checklist", jsonfile)}
        </>
    );

    return (
        <>
            {CisUI().showLoading}
            {searchView}

            <Card title="Voided Check List" extra={getExtra}>
                {/*{CisUI().listAction(jsonfile.urls.add)}*/}

                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>

            <Modal title="Void Check" visible={isConfirmModalVisible} footer={null}>
                <Form
                    form={form}
                    {...CisUI().formItemLayout}
                    name="input"
                    ref={formRef}
                    className="ant-advanced-search-form"
                    onFinish={CheckVoid}
                >

                    <div className="row">
                        <div className="col-md-12">
                            <Form.Item
                                name="reason"
                                label="Void Reason"
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter why you want to void"
                                    },
                                ]}
                            >
                                <Input className="gx-mb-3 gx-w-100" placeholder="Void Reason"/>
                            </Form.Item>
                        </div>

                        <div className="col-md-6">
                            <Form.Item
                                name="check_no"
                                label="Check#"
                                initialValue={checkno}
                                rules={[
                                    {
                                        required: false,
                                        message: "Enter check number"
                                    },
                                ]}
                            >
                                <Input readOnly={true} className="gx-mb-3 gx-w-100" placeholder="Check Number"/>
                            </Form.Item>
                        </div>

                        <div className="col-md-12" style={{paddingTop: '10px', textAlign: 'right'}}>
                            <Button type="primary" htmlType="submit">Submit</Button>
                            <a href="#" onClick={handleOkConfirm} className="ant-btn ant-alert-info">Cancel</a>
                        </div>

                    </div>

                </Form>
            </Modal>

            <Modal title="Re-Print Check" visible={isConfirmReprintModalVisible} footer={null}>
                <Form
                    form={form2}
                    {...CisUI().formItemLayout}
                    name="input"
                    ref={formRef2}
                    className="ant-advanced-search-form"
                    onFinish={CheckRePrint}
                >

                    <div className="row">
                        <div className="col-md-12">
                            <Form.Item
                                name="reason"
                                label="Void Reason"
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter why you want to void"
                                    },
                                ]}
                            >
                                <Input className="gx-mb-3 gx-w-100" placeholder="Void Reason"/>
                            </Form.Item>
                        </div>

                        <div className="col-md-6">
                            <Form.Item
                                name="check_no"
                                label="Check#"
                                initialValue={checkno}
                                rules={[
                                    {
                                        required: false,
                                        message: "Enter check number"
                                    },
                                ]}
                            >
                                <Input readOnly={true} className="gx-mb-3 gx-w-100" placeholder="Check Number"/>
                            </Form.Item>
                        </div>

                        <div className="col-md-6">
                            <Form.Item
                                name="new_check_no"
                                label="New Check#"
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter new check number"
                                    },
                                ]}
                            >
                                <Input className="gx-mb-3 gx-w-100" placeholder="New Check Number"/>
                            </Form.Item>
                        </div>

                        <div className="col-md-12" style={{paddingTop: '10px', textAlign: 'right'}}>
                            <Button type="primary" htmlType="submit">Submit</Button>
                            <a href="#" onClick={handleReprintOkConfirm} className="ant-btn ant-alert-info">Cancel</a>
                        </div>

                    </div>

                </Form>
            </Modal>
        </>
    );
};

export default VoidedCheck;