import React from "react";
import {Route, Switch} from "react-router-dom";

import UserList from "../../containers/yotta/user_management/user/UserList";
import NewUser from "../../containers/yotta/user_management/user/NewUser";
import EditUser from "../../containers/yotta/user_management/user/EditUser";
import LoginHistory from "../../containers/yotta/user_management/audit/LoginHistory";
import UserActivityLog from "../../containers/yotta/user_management/audit/UserActivityLog";
import UserChangeLog from "../../containers/yotta/user_management/audit/UserChangeLog";
import DeletedData from "../../containers/yotta/user_management/audit/DeletedData";
import AllUserRoles from "../../containers/yotta/user_management/user_roles/list";
import NewUserRoles from "../../containers/yotta/user_management/user_roles/new";
import OnlineUser from "../../containers/yotta/user_management/accessibility/onlineuser";
import PasswordReset from "../../containers/yotta/user_management/accessibility/passwordreset";

const User = ({match}) => (
    <Switch>
        <Route path={`${match.url}/userlist`} component={UserList}/>
        <Route path={`${match.url}/newuser`} component={NewUser}/>
        <Route path={`${match.url}/useredit/:id`} component={EditUser}/>
        <Route path={`${match.url}/userlogins`} component={LoginHistory}/>
        <Route path={`${match.url}/useractivity`} component={UserActivityLog}/>
        <Route path={`${match.url}/userchangelog`} component={UserChangeLog}/>
        <Route path={`${match.url}/deleteddata`} component={DeletedData}/>
        <Route path={`${match.url}/allroles`} component={AllUserRoles}/>
        <Route path={`${match.url}/newrole`} component={NewUserRoles}/>
        <Route path={`${match.url}/roleedit/:id`} component={NewUserRoles}/>
        <Route path={`${match.url}/onlineuser`} component={OnlineUser}/>
        <Route path={`${match.url}/userpassreset`} component={PasswordReset}/>
    </Switch>
);

export default User;
