import {Form, Input, Button, Card, Upload, message, Select, DatePicker, notification} from 'antd';
import React, {useState, useEffect} from "react";
import jsonfile from './creditnote.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import SelectData from "../../../../util/selectdata";
import moment from "moment";
import StaticSelectedData from "../../../../util/StaticSelectData";
import IntlMessages from "../../../../util/IntlMessages";
import { UploadOutlined } from '@ant-design/icons';

const {Option} = Select;

const NewCreditNote = (props) => {

    const formRef = React.createRef();
    const [receivable, setReceivable] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [customer, setcustomer] = useState([]);
    const [bill] = useState(
        {
            total : 0,
            grand_total : 0,
            freight : 0,
            taxp : 0,
            tax_amount : 0,
            discount_p : 0,
            discount_amount : 0
        }
    );

    const [fund, setFund] = useState([]);
    const [lineItems, setLineItems] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);


    const [setComp] = useState(
        {
            com_address: "",
            com_city: "",
            com_state: "",
            com_zip_code: ""
        }
    );

    const [vend] = useState(
        {
            customer_address: "",
            customer_city: "",
            customer_state: "",
            customer_zip_code: "",
            shipping_method: ""
        }
    );

    const handleChange = (e) => {
        setComp({
            [e.target.name]: e.target.value
        })
    }

    const userID = props.match.params.id;
    // let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.addnew;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {
        // userData = RemoteJson(jsonfile.urls.view + "/" + userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + jsonfile.urls.list;
    }


    const handleAddRow = () => {
        const item = {
            account_id: null,
            fund_program_id: 1,
            func_expense_id: 1,
            restriction_type: "without",
            amount: null,
            note: null,
            classification : null,
            files : [],
            file_location : [],
        };
        setLineItems([...lineItems, item]);
    };

    const classifications = SelectData("classifications");


    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {

        values["items"] = lineItems;
        values["summary"] = bill;
        console.log(JSON.stringify(values));
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                document.getElementById("loader").style.display = "none";
                console.log(res)
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }

            })
            .catch(errors => {
                document.getElementById("loader").style.display = "none";
                console.log(errors.response.data.errors);
            });
    };

    useEffect(() => {
        const onCompanyChangeForCompany = () => {
            const value = CisUI().getUserInfo('com_id') || 0;
            console.log("selected : "+ value);
            handleAddRow();
            axios.get("customer/customerinfobycompany?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setcustomer(res.data.data);
                        setReceivable(res.data.receiveable);
                        setAccounts(res.data.accounts);
                        setFund(res.data.fund);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        onCompanyChangeForCompany();
    }, []);

    /*
    const onCompanyChange = value => {
        console.log("selected : "+ value);
        handleAddRow();
        let fl = Companies.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            const area = company['area'];
            axios.get("customer/customerinfobycompany?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setcustomer(res.data.data);
                        setReceivable(res.data.receiveable);
                        setAccounts(res.data.accounts);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            // ReceiveAble = SelectData("receiveable?com_id="+value+"&area="+area);
            // Accounts = SelectData("accountslist?com_id="+value+"&area="+area);

            // console.log("selected : "+ JSON.stringify(company));
            //console.log("address : "+ company.address_line_1+", "+company.address_line_2);
            document.getElementById("input_com_address").value = "Test";
            formRef.current.setFieldsValue({
                com_address: company.address_line_1+", "+company.address_line_2,
                com_city: company.city,
                com_state: company.state,
                com_zip_code: company.zip_code,
                customer: "",
                customer_address: "",
                customer_city: "",
                customer_state: "",
                customer_zip_code: "",
                shipping_method: "",
                sales_tax_pp: 0
            });
        }
    }
     */

    const oncustomerChange = value => {
        console.log("selected : "+ value);
        let fl = customer.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            console.log("selected : "+ JSON.stringify(company));
            var bill = company["address"];

            formRef.current.setFieldsValue({
                customer_address: bill,
                customer_city: company["city"],
                customer_state: company["state_name"],
                customer_zip_code: company["zip_code"]
            });

            // var total = bill.total;
            // var freight = company.shipping_freight;
            // var taxp = company.shipping_tax;
            // var discount_p = bill.discount_p;
            // var discount_amount = total * discount_p / 100;
            // var totalBeforeTax = total - discount_amount ;
            // var taxAmount = totalBeforeTax * (taxp / 100);
            // var grandTotal = totalBeforeTax + taxAmount + freight;
            //
            // setBill({
            //     total : total,
            //     grand_total : grandTotal,
            //     freight : freight,
            //     taxp : taxp,
            //     tax_amount : taxAmount,
            //     discount_p : discount_p,
            //     discount_amount : discount_amount
            // });
        }
    }

    const ItemCellChange = idx => e => {
        const { name, value } = e.target;
        const rows = [...lineItems];
        console.log("selectedITems:"+ JSON.stringify(rows[idx]));

        if(name === "amount") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                fund_program_id: rows[idx].fund_program_id,
                func_expense_id: rows[idx].func_expense_id,
                restriction_type: rows[idx].restriction_type,
                amount: value,
                note: rows[idx].note,
                classification: rows[idx].classification,
                files : rows[idx].files,
                file_location : rows[idx].file_location,
            };
        }
        else if(name === "note") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                fund_program_id: rows[idx].fund_program_id,
                func_expense_id: rows[idx].func_expense_id,
                restriction_type: rows[idx].restriction_type,
                amount: rows[idx].amount,
                note: value,
                classification: rows[idx].classification,
                files : rows[idx].files,
                file_location : rows[idx].file_location,
            };
        }

        setLineItems(rows);

        let ttl = 0;
        rows.map((item,index) => (
            ttl += parseFloat(item.amount)
        ))
        // console.log("TTL :"+ttl)
        setTotalAmount(ttl);
    };

    const ItemCellChangeAccount = idx => e => {
        console.log("selectedValued:" + e);
        console.log("selectedValuedID:" + idx);

        const rows = [...lineItems];
        rows[idx] = {
            account_id: e,
            fund_program_id: rows[idx].fund_program_id,
            func_expense_id: rows[idx].func_expense_id,
            restriction_type: rows[idx].restriction_type,
            amount: rows[idx].amount,
            note: rows[idx].note,
            classification: rows[idx].classification,
            files : rows[idx].files,
            file_location : rows[idx].file_location,
        };

        setLineItems(rows);

    }

    const ItemCellChangeFundProgram = idx => e => {
        console.log("selectedValued:" + e);
        console.log("selectedValuedID:" + idx);

        const rows = [...lineItems];
        rows[idx] = {
            account_id: rows[idx].account_id,
            fund_program_id: e,
            func_expense_id: rows[idx].func_expense_id,
            restriction_type: rows[idx].restriction_type,
            amount: rows[idx].amount,
            note: rows[idx].note,
            classification: rows[idx].classification,
            files : rows[idx].files,
            file_location : rows[idx].file_location,
        };

        setLineItems(rows);

    }

    // const ItemCellChangeFunxExpense = idx => e => {
    //     console.log("selectedValued:" + e);
    //     console.log("selectedValuedID:" + idx);
    //
    //     const rows = [...lineItems];
    //     rows[idx] = {
    //         account_id: rows[idx].account_id,
    //         fund_program_id: rows[idx].fund_program_id,
    //         func_expense_id: e,
    //         restriction_type: rows[idx].restriction_type,
    //         amount: rows[idx].amount,
    //         note: rows[idx].note,
    //         classification: rows[idx].classification,
    //         files : rows[idx].files,
    //         file_location : rows[idx].file_location,
    //     };
    //
    //     setLineItems(rows);
    //
    // }

    const ItemCellChangeRestrictionType = idx => e => {
        console.log("selectedValued:" + e);
        console.log("selectedValuedID:" + idx);

        const rows = [...lineItems];
        rows[idx] = {
            account_id: rows[idx].account_id,
            fund_program_id: rows[idx].fund_program_id,
            func_expense_id: rows[idx].func_expense_id,
            restriction_type: e,
            amount: rows[idx].amount,
            note: rows[idx].note,
            classification: rows[idx].classification,
            files : rows[idx].files,
            file_location : rows[idx].file_location,
        };

        setLineItems(rows);

    }

    const handleRemoveRow = idx => e => {
        // console.log(idx);
        // console.log(state.rows);
        let filteredArray = lineItems.filter((item, index) => index !== idx)
        setLineItems(filteredArray);

        let ttl = 0;
        filteredArray.map((item,index) => (
            ttl += parseFloat(item.amount)
        ))
        setTotalAmount(ttl);

    };

    const ItemCellChangeClassification = idx => e => {
        console.log("selectedValued:" + e);
        console.log("selectedValuedID:" + idx);

        const rows = [...lineItems];
        rows[idx] = {
            account_id: rows[idx].account_id,
            fund_program_id: rows[idx].fund_program_id,
            func_expense_id: rows[idx].func_expense_id,
            restriction_type: rows[idx].restriction_type,
            amount: rows[idx].amount,
            note: rows[idx].note,
            classification : e,
            files : rows[idx].files,
            file_location : rows[idx].file_location,
        };

        setLineItems(rows);

    }


    const token = localStorage.getItem('token');
    let formData = new FormData();
    let fileUID = "";
    const uplod = {
        name: 'file',
        action: Config.apiserver+"fileupload",
        method : 'post',
        headers: {
            'Authorization': token,
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
            if (!isJpgOrPng) {
                message.error('You can only upload jpg, png,and pdf file only!');
            }
            return isJpgOrPng;
        },
        onChange(info) {
            formData.append('file', info.file);
            fileUID = info.file.uid;
            console.log("index : "+info.file.uid);
            console.log(JSON.stringify(info));
            if(info.file.status === "done") {
                if (info.file.xhr.status === 200) {
                    console.log("ServerResponse : "+info.file.response);
                    const data = info.file.response;
                    if(data.status === 1) {
                        const list = [...lineItems];
                        let files = list[data.line_index].files || [];
                        let file_location = list[data.line_index].file_location || [];
                        files.push(data.data.id);
                        file_location.push(data.data.location);
                        list[data.line_index].files = files;
                        list[data.line_index].file_location = file_location;
                        setLineItems(list);
                        console.log("Files : "+JSON.stringify(list[data.line_index]));
                        message.success(`${info.file.name} file uploaded successfully`);
                    }

                } else {
                    message.error(`${info.file.name} file upload failed.`);
                }
            }
        }
    }


    return (
        <Card title={<IntlMessages id={Title}/>} extra={
            isEdit === 1
                ? CisUI().addAction('../../' + jsonfile.urls.list)
                : CisUI().addAction('../' + jsonfile.urls.list)
        }>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                ref={formRef}
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{remember: true}}
            >

                <div className="row">
                    <div className="col-md-12">
                        <Card title={<IntlMessages id="Customer Information"/>}>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="customer_id"
                                            label={<IntlMessages id="Customer"/>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Select a customer"
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Select a customer"
                                                optionFilterProp="children"
                                                onChange={oncustomerChange}
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {
                                                    customer.map((items, index) =>
                                                        <Option key={++index} value={items.id}>{items.full_name}</Option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>

                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            id="customer_address"
                                            label={<IntlMessages id="Customer Address"/>}
                                            name="customer_address"
                                            onChange={handleChange}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Customer Address"
                                                },
                                            ]}
                                        >
                                            <Input value={vend.customer_address} placeholder="Customer Address" />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            id="customer_city"
                                            label={<IntlMessages id="Customer City"/>}
                                            name="customer_city"
                                            onChange={handleChange}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "customer City"
                                                },
                                            ]}
                                        >
                                            <Input value={vend.customer_city} placeholder="customer City" />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            id="customer_state"
                                            label={<IntlMessages id="Customer State"/>}
                                            name="customer_state"
                                            onChange={handleChange}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Customer State"
                                                },
                                            ]}
                                        >
                                            <Input value={vend.customer_state} placeholder="Customer State" />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            label={<IntlMessages id="Customer Zip Code"/>}
                                            name="customer_zip_code"
                                            onChange={handleChange}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "customer Zip Code"
                                                },
                                            ]}
                                        >
                                            <Input value={vend.customer_zip_code} placeholder="customer Zip Code" />
                                        </Form.Item>
                                    </div>
                                </div>

                            </div>
                        </Card>

                    </div>
                </div>
                <div className="row">

                    <div className="col-md-2">
                        <div className="gx-form-row0">
                            <Form.Item
                                name="date"
                                label={<IntlMessages id="Order Date"/>}
                                initialValue={moment()}
                                rules={[
                                    {
                                        required: true,
                                        message: "Order date"
                                    },
                                ]}
                            >
                                <DatePicker format={CisUI().dateFormat} placeholder="Order Date" />
                            </Form.Item>

                        </div>
                    </div>

                    <div className="col-md-3">
                        <Form.Item
                            name="receivable_id"
                            label={<IntlMessages id="Accounts Receivable"/>}
                            rules={[
                                {
                                    required: true,
                                    message: "Select a accounts receivable"
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select a receivable account"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {
                                    receivable.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </div>

                    <div className="col-md-3">
                        <div className="gx-form-row0">
                            <Form.Item
                                id="invoice_no"
                                label={<IntlMessages id="Invoice#/Reference"/>}
                                name="invoice_no"
                                onChange={handleChange}
                                rules={[
                                    {
                                        required: false,
                                        message: "Invoice number"
                                    },
                                ]}
                            >
                                <Input placeholder="Invoice#" />
                            </Form.Item>
                        </div>
                    </div>

                </div>


                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6">
                                <h2>{<IntlMessages id="Items"/>}</h2>
                            </div>
                            <div className="col-md-6" style={{textAlign : "right"}}>
                                <button type="button" className="btn btn-primary" onClick={handleAddRow}><IntlMessages id="Add More" /></button>
                            </div>
                        </div>
                        <table id="itemtable" className="ptable table table-bordered">
                            <thead>
                            <th>#</th>
                            <th>Account</th>
                            <th>Amount</th>
                            <th>Description</th>
                            <th>Fund Program</th>
                            <th>Restriction Type</th>
                            <th>Classification</th>
                            <th>Attachment</th>
                            </thead>
                            <tbody>
                            {lineItems.map((item, idx) => (
                                <tr id="addr0" key={idx}>
                                    <td style={{width : '30px'}}>
                                        <button type="button" onClick={handleRemoveRow(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></button>
                                    </td>
                                    <td>
                                        <Select
                                            showSearch
                                            placeholder="Select an account"
                                            optionFilterProp="children"
                                            style={{width: '100%'}}
                                            onChange={ItemCellChangeAccount(idx)}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                accounts.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </td>
                                    <td style={{width : '100px'}}>
                                        <input className="form-control text-right" style={{textAlign : 'right'}} placeholder="Amount" name="amount" value={item.amount} onChange={ItemCellChange(idx)}  />
                                    </td>
                                    <td style={{width : '300px'}}><input className="form-control text-right" style={{textAlign : 'left'}} name="note" value={item.note} onChange={ItemCellChange(idx)}  /></td>
                                    <td>
                                        <Select
                                            showSearch
                                            placeholder="Select a fund program"
                                            optionFilterProp="children"
                                            style={{width: '100%'}}
                                            defaultValue={1}
                                            onChange={ItemCellChangeFundProgram(idx)}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                fund.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </td>
                                    <td>
                                        <Select
                                            showSearch
                                            placeholder="Select a restriction type"
                                            optionFilterProp="children"
                                            style={{width: '100%'}}
                                            defaultValue={"without"}
                                            onChange={ItemCellChangeRestrictionType(idx)}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                StaticSelectedData("restrictiontype").map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </td>
                                    <td>
                                        <Select
                                            showSearch
                                            placeholder="Select a class"
                                            optionFilterProp="children"
                                            style={{width: '100%'}}
                                            onChange={ItemCellChangeClassification(idx)}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                classifications.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </td>
                                    <td>
                                        <Upload data= {
                                            {
                                                file : formData,
                                                type : "creditnote",
                                                file_id : fileUID,
                                                row_id : item.id,
                                                line_index : idx
                                            }
                                        } {...uplod}>
                                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                        </Upload>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                            <tfoot>
                            <tr>
                                <th colSpan="2">Total = </th>
                                <th style={{textAlign: 'right', fontSize: '15px'}}>{CisUI().getCurrencyFormated1(totalAmount || 0)}</th>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12" style={{textAlign: 'center', marginTop : '30px'}}>
                        <Button id="submitbutton" type="primary" htmlType="submit">
                            <IntlMessages id="Submit"/>
                        </Button>
                        <Button type="info" onClick={() => history.goBack()} htmlType="back"><IntlMessages id="Cancel"/></Button>
                    </div>
                </div>

            </Form>
        </Card>
    );
};

export default NewCreditNote;