import {Form, Row, Col, Button, Card} from 'antd';
import React, {Component,useState, useRef, useEffect} from "react";
import jsonfile from './profile.json'
import {CisUI} from '../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../util/config";
import {useHistory} from "react-router-dom";
import InputFieldV2 from "../../../util/InputFieldV2";
import {useDispatch, useSelector} from "react-redux";
import {getUserProfileData, updateProfile} from "../../../appRedux/actions";


// var SignaturePad = require('react-signature-pad');


const MyAccount = (props) => {

    const [loading, setLoading] = useState([]);

    const myRef = React.createRef();

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    const [image, setImage] = useState("");

    const user = JSON.parse(localStorage.getItem('userData'));
    const userID = user['id'];
    // console.log(userID['id'])
    // let userData = [];


    const dispatch = useDispatch();
    let userData = useSelector((state) => state.auth.profileData);
    console.log(userData)

     useEffect(() => {
         dispatch(getUserProfileData(userID))
     }, []);


    const [form] = Form.useForm();
    const history = useHistory();

    const changeImage = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = (e)=> {
            setImage({
                image : e.target.result
            });
        };
        reader.readAsDataURL(file);
    }

    const onFinish = (values) => {
        // setLoading({loading:true});
        // console.log("Input Data : "+values);
        const data = {
            ...values,
            image: image
        }
        console.log(data);
        dispatch(updateProfile(data,userID))
    };


    //console.log("UserData : "+userData);

    return (
        <Card title={jsonfile.title}>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                {/*{InputFields(jsonfile.input,userData,isEdit,props)}*/}
                {InputFieldV2(jsonfile.input,userData,1,props)}

                <Col lg={8} md={8} sm={24} xs={24}>
                    <div className="gx-form-row0" style={{marginLeft: "10px"}}>
                        <label htmlFor="image">Image</label>
                        <input name="image" type="file"  onChange={changeImage}/>
                        <img src={Config.profile_pic_url + userData.image} alt="Image" style={{ width: "120px", height: "100px", marginTop: "10px"}}/>
                    </div>
                </Col>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                        <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                    </Col>
                </Row>
            </Form>

        </Card>
    );
};

export default MyAccount;